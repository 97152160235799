import React from 'react';
import {Divider, Empty, Space, Tag, Timeline} from 'antd';
import {useIntl} from "umi";
import get from "lodash/get";
import dayjs from "dayjs";

import LeadName from "@/components/Lead/LeadName";
import {PrettyDateWithTime} from "@/components/PrettyDate";
import {LeadStateTagPresentation} from "@/components/Filters/LeadStateFilters";
import {LeadActivityEvents, StandaloneEvents} from "@/typings/models/LeadActivity";
import {LeadDetailsModel} from "@/typings/models/Lead";

import styles from "./index.less"
import isEmpty from "lodash/isEmpty";

const LeadActivity = ({leadDetails}: { leadDetails: LeadDetailsModel }) => {
  const activities = get(leadDetails, 'activities', []).filter(activity => activity?.activity_type !== 'comment')
  const intl = useIntl()

  const EVENT_TO_I18N_ID = {
    [LeadActivityEvents.lead_state_changed]: 'pages.lead.activities.changedStatus',
    [LeadActivityEvents.assignee_changed]: 'pages.lead.activities.assigned',
    [LeadActivityEvents.unassigned]: 'pages.lead.activities.assigned',
    [LeadActivityEvents.created]: 'pages.lead.activities.created',
    [LeadActivityEvents.branch_changed]: 'pages.lead.activities.changedBranch',
    [LeadActivityEvents.customer_data_changed]: 'pages.lead.activities.customerChanged',
    [LeadActivityEvents.archived]: 'pages.lead.activities.archived',
    [LeadActivityEvents.unarchived]: 'pages.lead.activities.unarchived',
    [LeadActivityEvents.appointment_rescheduled]: 'pages.lead.activities.rescheduledAppointment',
  };

  const getValueComponent = (event, key) => {

    const value = event[key]
    if (isEmpty(value)) return " - "

    switch (event.activity_type) {

      case LeadActivityEvents.lead_state_changed:
        return <LeadStateTagPresentation state={value}/>
      case LeadActivityEvents.assignee_changed:
        return <LeadName lead={{customer: value}} readonly/>
      case LeadActivityEvents.branch_changed:
        return <Tag>{value?.name}</Tag>
      case LeadActivityEvents.appointment_rescheduled:
        return <Space direction={"vertical"}>
          <PrettyDateWithTime date={value?.utc_start_time}/>
          <PrettyDateWithTime date={value?.utc_end_time}/>
        </Space>
    }

    return get(value, 'label', get(value, 'name', get(value, 'title', value)))
  }


  if (activities.length === 0) return <Empty/>

  const groupedActivities = activities.reverse().reduce((groups, activity) => {
    let activityDate = dayjs(activity.created).format('YYYY-MM-DD');
    const today = dayjs().format('YYYY-MM-DD');
    const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');

    if (activityDate === today)
      activityDate = intl.formatMessage({id: 'pages.common.today', defaultMessage: 'Today'})
    else if (activityDate === yesterday)
      activityDate = intl.formatMessage({id: 'pages.common.yesterday', defaultMessage: 'Yesterday'});
    else
      activityDate = dayjs(activity.created).format('dddd, DD MMMM YYYY');

    if (!groups[activityDate])
      groups[activityDate] = [];

    activity.created_time = dayjs(activity.created).format('HH:mm');
    groups[activityDate].push(activity);
    return groups;
  }, {});

  return <div className={styles.leadActivityContainer}>
    {Object.keys(groupedActivities).map((date, index) => (
      <div key={index}>
        <Divider><Tag className={styles.activityDate}>{date}</Tag></Divider>
        <Timeline>
          {groupedActivities[date].map((event, index) => {
            const i18nKey = EVENT_TO_I18N_ID[event.activity_type]
            const eventTitle = intl.formatMessage({id: i18nKey, defaultMessage: event.activity_type})
            const eventFrom = intl.formatMessage({
              id: `${i18nKey}__from`,
              defaultMessage: intl.formatMessage({id: 'pages.common.from', defaultMessage: 'from'})
            })
            const eventTo = intl.formatMessage({
              id: `${i18nKey}__to`,
              defaultMessage: intl.formatMessage({id: 'pages.common.to', defaultMessage: 'to'})
            })
            return (
              <Timeline.Item
                key={index}
                dot={groupedActivities[date].length > 1 ? undefined : null}
              >
                <div className={styles.activityItemContainer}>
                  <div className={styles.activityData}><Space direction={"vertical"}>
                    <Space>
                      <LeadName lead={{customer: event.editor}} readonly nameClassName={styles.activityEditorName}/>
                    </Space>
                    <Space style={{marginBottom: 5}} wrap className={styles.activityDetails}>
                      <span>{eventTitle}</span>
                      {!StandaloneEvents.includes(event.activity_type) && <>
                        <Space>
                          {eventFrom}
                          {getValueComponent(event, 'prev_value')}
                        </Space>
                        <Space>
                          {eventTo}
                          {getValueComponent(event, 'new_value')}
                        </Space>
                      </>}
                    </Space>
                  </Space></div>
                  <div className={styles.activityTime}>{event.created_time}</div>
                </div>

              </Timeline.Item>
            )
          })}
        </Timeline>
      </div>
    ))}
  </div>
};

export default LeadActivity;
