import {ROUTE_PATHS} from '../../config/RoutePaths';
import {SOLUTION_KEYS} from "@/constants/solutions";
import PERMISSIONS from "@/constants/permissions";
import {BackofficeUserModel} from "@/typings/api/user";
import {hasAccess} from "@/utils/access";


// +* refers to the business partner who has access to all the permissions and * means everybody can see the page
// All lead generation parts are currently only restricted to the business partner
export const routeToPermissionMap = {
  '/reports/*': [PERMISSIONS.Analytics.VIEW, PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.CHECK]: ['*'],
  [ROUTE_PATHS.MIMIC]: ['*'],
  [ROUTE_PATHS.USER_FORGOT_PASSWORD]: ['*'],
  [ROUTE_PATHS.RESET_PASSWORD]: ['*'],
  [ROUTE_PATHS.CUSTOMERS]: [PERMISSIONS.Customers.VIEW, PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.SUPPORT]: ['*'],
  [ROUTE_PATHS.SERVICE_REQUESTS]: [PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.LOCATIONS]: [PERMISSIONS.Locations.MANAGE, PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.STUDIO]: [],
  [ROUTE_PATHS.IMAGES]: [PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.TAG_STATISTICS]: [PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.PROMOTIONS]: [PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.SETTINGS]: [PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.COMPONENTS]: ['*'],
  [ROUTE_PATHS.PROFILE]: ['*'],
  [ROUTE_PATHS.LANGUAGE]: ['*'],
  [ROUTE_PATHS.BILLINGS]: [PERMISSIONS.Billing.VIEW, PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.BILLINGS_DETAILS]: [PERMISSIONS.Billing.VIEW, PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.ROOT]: ['*'],
  [ROUTE_PATHS.LOGIN]: ['*'],
  [ROUTE_PATHS.USER_MANAGEMENT]: [PERMISSIONS.Users.CREATE, PERMISSIONS.Users.EDIT, PERMISSIONS.Users.DELETE, PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.REPORTS_LEAD_MANAGEMENT]: [PERMISSIONS.Analytics.VIEW, PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.REPORTS_DASHBOARD_LEAD_MANAGEMENT]: [PERMISSIONS.Analytics.VIEW, PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.REPORTS_FUNNEL_LEAD_MANAGEMENT]: [PERMISSIONS.Analytics.VIEW, PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.CALENDAR]: [PERMISSIONS.Today.VIEW],
  [ROUTE_PATHS.TODAY]: [PERMISSIONS.Today.VIEW],
  [ROUTE_PATHS.LEADS]: [PERMISSIONS.Leads.VIEW, PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.TASKS]: ['*'],
  "/roi": [PERMISSIONS.Analytics.VIEW, PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.AUTOMATION]: [PERMISSIONS.EmailAutomation.VIEW, PERMISSIONS.BUSINESS_PARTNER],
  [ROUTE_PATHS.OPEN_PROPOSALS]: [],
  [ROUTE_PATHS.RolesAndRights]: [PERMISSIONS.BUSINESS_PARTNER]
};

export function getRequiredPermissionsForPath(path: string, roles?: { [key: string]: string[] }): string[] {
  const mapping = roles ?? routeToPermissionMap;
  for (const pattern in mapping) {
    if (pathMatchesPattern(path, pattern)) {
      return mapping[pattern];
    }
  }
  return [];
}

export function pathMatchesPattern(path, pattern) {
  const regexPattern = new RegExp('^' + pattern.replace('*', '.*') + '$');
  return regexPattern.test(path);
}

export function getRoleDefaultRoute(user: BackofficeUserModel, journeyId: string, selectedSolution) {

  // Manager | Partner | Analytics
  if (hasAccess(user, [PERMISSIONS.Analytics.VIEW, PERMISSIONS.BUSINESS_PARTNER], {
    asterisk: true
  })) {
    return selectedSolution === SOLUTION_KEYS.LEAD_MANAGEMENT ?
      ROUTE_PATHS.REPORTS_DASHBOARD_LEAD_MANAGEMENT :
      ROUTE_PATHS.REPORTS_DASHBOARD
  }

  // Simple Sales Agent
  if (hasAccess(user, PERMISSIONS.Today.VIEW))
    return ROUTE_PATHS.TODAY

  // Lead management if user has access to it
  if (hasAccess(user, PERMISSIONS.Leads.VIEW)) return ROUTE_PATHS.LEADS

  // Last resort
  return ROUTE_PATHS.PROFILE
}
