import React, {useEffect, useState} from 'react';
import {Alert, Button, Divider, Modal, Space, Spin, Steps, Tabs} from 'antd';
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import {useDispatch, useIntl, useSelector} from "umi";
import {ProForm} from "@ant-design/pro-form";

import {F} from "@/utils/intl";
import * as LeadSelectors from "@/selectors/leadSelectors";
import {TaskAutomationModel} from "@/typings/models/TaskAutomationModel";

import styles from "./index.less";
import RobotOutlined from "@ant-design/icons/RobotOutlined";
import {bookAppointmentRequest, fetchWidgetJourneyConfigRequest} from "@/services/api/widget";
import MagicAppointmentBookingLocationSelector from "@/pages/Tasks/MagicAppointmentBookingLocationSelector";
import CreateTaskForm from "@/pages/Tasks/CreateTaskForm";
import AppointmentSlotSelector from "@/components/Kanban/ServiceRequests/Calendar/AppointmentSlotSelector";
import {ClockCircleOutlined, EnvironmentOutlined} from "@ant-design/icons";
import MagicAppointmentBookingLeadDataForm from "@/pages/Tasks/MagicAppointmentBookingLeadDataForm";
import omit from "lodash/omit";

const MagicAppointmentBookingModal = () => {

  const [currentStep, setCurrentStep] = useState(0);

  const [loading, setLoading] = useState(false);
  const [activeAutomationId, setActiveAutomationId] = useState()
  const [doneAutomationIds, setDoneAutomationIds] = useState([])

  const [journeyConfig, setJourneyConfig] = useState()
  const [journeyConfigLoading, setJourneyConfigLoading] = useState(false)

  const [selectedLocationType, setSelectedLocationType] = useState()
  const [selectedLocation, setSelectedLocation] = useState()
  const [selectedTimeslot, setSelectedTimeslot] = useState()

  const [bookingLoading, setBookingLoading] = useState(false)

  const configuration = useSelector(LeadSelectors.selectLeadsMagicAppointmentBooking)
  const leadId = configuration?.leadId
  const lead = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadData(state, leadId))

  const intl = useIntl()

  const [taskForm] = ProForm.useForm();

  const dispatch = useDispatch()
  const open = !isEmpty(configuration)

  const getAutomatedTasks = (filteredAutomationIds = doneAutomationIds) => (configuration?.tasks || [])
    .filter(automatedTask => !filteredAutomationIds.includes(automatedTask.id))
  // @ts-ignore
  const automatedTasks: TaskAutomationModel[] = getAutomatedTasks()

  const automatedTasksMap = automatedTasks.reduce((acc, automatedTask) => {
    acc[automatedTask.id] = automatedTask
    return acc
  }, {})

  const activeAutomatedTask = automatedTasksMap[activeAutomationId]

  const handleTaskResult = () => {
    setDoneAutomationIds(prev => {
      const next = [...prev, activeAutomationId]
      const automations = getAutomatedTasks(next)
      if (automations.length > 0) {
        setActiveAutomationId(automations[0].id)
      } else {
        toggle()
      }
      return next
    })
  }
  const onStepChange = (nextStep: number) => {
    if (nextStep < currentStep) setCurrentStep(nextStep)

  }
  const fetchJourneyConfig = () => {
    setJourneyConfigLoading(true)
    fetchWidgetJourneyConfigRequest({
      params: {
        journeyId: configuration.journey
      },
      query: {
        apiKey: configuration.api_key
      },
      baseUrl: configuration.base_url
    }).then(response => {
      setJourneyConfigLoading(false)
      setJourneyConfig(response?.data?.data?.configuration)
    })
  }

  const handleSubmit = (values) => {
    const data = {
      Customer: {
        'ServiceRequest.location': selectedLocationType.label,
        ServiceRequest: {
          appointment: {
            resource_id: selectedTimeslot.resource_id,
            project_id: selectedLocation.id || selectedTimeslot.resource_id,
            start: selectedTimeslot.rawStart,
            end: selectedTimeslot.rawEnd,
          },
          type: "appointment",
        },
        ...omit(values, ['meta'])
      },
      meta: values.meta || {},
      journey_hash: configuration?.journey,
    }


    setBookingLoading(true)
    bookAppointmentRequest({
      query: {
        apiKey: configuration.api_key
      },
      data: {
        data
      },
      baseUrl: configuration.base_url
      }
    ).then(response => {
      setBookingLoading(false)
      setCurrentStep(3)
      taskForm.setFieldValue('due_date', selectedTimeslot.start.add(activeAutomatedTask.task_due_in_days + 1, 'days'))
    })
  }

  useEffect(() => {
    if (open)
      fetchJourneyConfig()

    setCurrentStep(0)
  }, [open]);

  useEffect(() => {
    if (open && activeAutomationId) {
      taskForm.setFieldsValue({
        title: activeAutomatedTask.task_title,
        description: activeAutomatedTask.task_description,
        due_date: moment().add(activeAutomatedTask.task_due_in_days + 1, 'days')
      })
    }
    if (!activeAutomatedTask) {
      setActiveAutomationId(automatedTasks?.[0]?.id)
    }
    if (!open) {
      setDoneAutomationIds([])
    }
  }, [open, activeAutomatedTask])

  const handleLocationChange = location => {
    setCurrentStep(1)
    setSelectedLocation(location)
  }

  const handleTimeslotChange = timeslot => {
    setCurrentStep(2)
    setSelectedTimeslot(timeslot)
  }

  const toggle = () => {
    dispatch({
      type: 'leads/closeMagicAppointmentBooking',
    });
  };

  const contentMap = open ? {
    0: <MagicAppointmentBookingLocationSelector booking={journeyConfig?.booking}
                                                onLocationChange={handleLocationChange}
                                                onLocationTypeChange={setSelectedLocationType}
                                                magicAppointmentBookingConfiguration={configuration}/>,
    1: <AppointmentSlotSelector location={selectedLocation}
                                magicAppointmentBookingConfiguration={configuration}
                                onSelectSlot={handleTimeslotChange}/>,
    2: <div>
      <Divider/>
      <Space direction={"vertical"}>
        <Space>
          <EnvironmentOutlined/>
          <div>
            {selectedLocation?.name}
          </div>
        </Space>
        <Space>
          <ClockCircleOutlined/>
          <div>
            <div>
              {selectedTimeslot?.start?.format("dddd, DD MMMM YYYY - HH:mm")}
            </div>
          </div>
        </Space>
      </Space>
      <Divider/>
      <MagicAppointmentBookingLeadDataForm locationType={selectedLocationType} onSubmit={handleSubmit} lead={lead} loading={bookingLoading}/>
    </div>,
    3: <div>
      <Tabs animated size={"small"}
            activeKey={activeAutomationId} items={automatedTasks.map(automatedTask => ({
        key: automatedTask.id,
        label: automatedTask.name
      }))} onChange={setActiveAutomationId}/>

      <CreateTaskForm leadId={leadId}
                      form={taskForm}
                      setLoading={setLoading}
                      updateRequest={() => {
                        dispatch({
                          type: 'leads/fetch'
                        })
                      }}
                      onTaskCreated={handleTaskResult}/>
    </div>
  } : {}

  const footerMap = {
    3: <div>
      <Button className={'button-primary-bordered'} onClick={handleTaskResult}>
        <F id={'pages.lead.magicTasks.discard'} defaultMessage={'Discard Task'}/>
      </Button>
      <Button type="primary" loading={loading} onClick={() => taskForm.submit()}>
        <F id={'pages.lead.tasks.create'} defaultMessage={'Create Task'}/>
      </Button>
    </div>
  }
  return (
    <Modal
      wrapClassName={styles.createTaskModalWrap}
      width={700}
      title={configuration?.modal_title}
      open={open}
      footer={footerMap[currentStep] || false}
      destroyOnClose
      onCancel={toggle}
    >

      <Alert className={styles.magicTaskHint} message={configuration?.modal_hint} type="info" showIcon
             icon={<RobotOutlined/>}/>
      <Spin spinning={journeyConfigLoading}>
        <Steps
          size={"small"}
          style={{margin: "15px 0"}}
          current={currentStep}
          onChange={onStepChange}
          items={[
            {
              title: intl.formatMessage({
                id: 'pages.lead.magicAppointmentBooking.location',
                defaultMessage: 'Location'
              }),
              disabled: currentStep === 3
            },
            {
              title: intl.formatMessage({
                id: 'pages.lead.magicAppointmentBooking.time',
                defaultMessage: 'Date & Time'
              }),
              disabled: currentStep < 1 || currentStep === 3
            },
            {
              title: intl.formatMessage({
                id: 'pages.lead.magicAppointmentBooking.leadData',
                defaultMessage: 'Customer Data'
              }),
              disabled: currentStep < 2 || currentStep === 3
            },
            {
              title: intl.formatMessage({
                id: 'pages.lead.magicAppointmentBooking.followupTasks',
                defaultMessage: 'Followup Tasks'
              }),
              disabled: currentStep < 3
            }
          ]}
        />

        {contentMap?.[currentStep]}

      </Spin>
    </Modal>
  );
};

export default MagicAppointmentBookingModal;
