import {useIntl} from 'umi';
import * as React from 'react';

export function F({id, defaultMessage, className}: {id: string, defaultMessage?: string, className?: string}) {
  const intl = useIntl()
  return <span className={className}>
    {intl.formatMessage({
      id,
      defaultMessage: defaultMessage || id
    })}
  </span>
}

function recursiveTransform(path: string, value, result: Object) {
  if (typeof value === 'object' && !Array.isArray(value)) {
    for (let key in value) {
      recursiveTransform(`${path}.${key}`, value[key], result);
    }
  } else if (Array.isArray(value)) {
    value.forEach((item, index) => {
      recursiveTransform(`${path}[${index}]`, item, result);
    });
  } else {
    result[path] = String(value);
  }
}

export function transformObjectToLocaleMap(prefix: string, obj){
  let result = {};

  function recursiveTransform(path, value) {
    if (typeof value === 'object' && !Array.isArray(value)) {
      for (let key in value) {
        recursiveTransform(`${path}.${key}`, value[key]);
      }
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        recursiveTransform(`${path}[${index}]`, item);
      });
    } else {
      result[path] = String(value);
    }
  }

  recursiveTransform(prefix, obj);
  return result;
}
