
import { useDrag, useDrop, DndProvider } from "react-dnd";

const DraggableHeader = ({ column, index, moveColumn }) => {

  
  const [, dragRef] = useDrag({
    type: "COLUMN",
    item: { index },
  });

  const [, dropRef] = useDrop({
    accept: "COLUMN",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveColumn(draggedItem.index, index);
        draggedItem.index = index; 
      }
    },
  });

  return (
    <th ref={(node) => dragRef(dropRef(node))} style={{ cursor: 'move', minWidth: 150 }}>
      {column.title}
    </th>
  );
};
export default DraggableHeader;