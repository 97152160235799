import styles from '@/components/Kanban/ServiceRequests/index.less'
import { Badge, Popover, Tag } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'umi'
import { TasksList } from '@/pages/Tasks/TasksList'
import * as LeadSelectors from '@/selectors/leadSelectors'
import { getLeadOpenTasksCount } from '@/components/Lead/utils'
import { GoTaskList } from '@/components/Icons/CustomReactIcons'

interface TeamViewCalendarEventTasksProps {
  event: any
  refresh: () => void
}

const   TeamViewCalendarEventTasks = ({event, refresh}: TeamViewCalendarEventTasksProps) => {
  const dispatch = useDispatch()
  const [visible, setVisible] = React.useState(false)

  const leadId = event?.lead?.id
  const lead = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadData(state, leadId))
  const leadDetails = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadDetails(state, leadId))

  const onLeadClick = () => {
    setVisible(false)
    dispatch({
      type: 'leads/toggleLeadDetailsDrawer',
      payload: {
        lead: event.lead
      }
    })
  }
  const handleOpenChange = (open) => {
    if (open) refresh()
    setVisible(open)
  }
  const popoverContent = <div onClick={e => e.stopPropagation()} className={styles.eventLeadOverviewPopoverContent}>
    <TasksList leadId={leadId}
               filterColumns={column => column.dataIndex !== 'lead'}
               data={leadDetails?.tasks || []}
               hideActionText
               request={refresh}/>
  </div>

  return <Popover trigger={['click']} open={visible} onOpenChange={handleOpenChange} placement={"rightTop"} content={popoverContent}>
    <Badge size={"small"} count={getLeadOpenTasksCount(leadDetails || lead)}>
      <Tag className={styles.eventLeadTag} onClick={e => {
        e.stopPropagation()
        handleOpenChange(!visible)
      }} onMouseOver={e => e.stopPropagation()}>
        <GoTaskList width={13}/>
      </Tag>
    </Badge>
  </Popover>
}

export default TeamViewCalendarEventTasks
