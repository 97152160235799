import {fetchMeRequest, fetchPartners, refreshTokenRequest} from '@/services/api/user';
import storage, {ENGINES} from 'conversional-persistent-storage';
import {UserPartner, UserTokens} from '@/typings/models/User';
import {RequestResponse} from 'umi-request';
import get from 'lodash/get';
import {resetPersistentReduxStore} from '@/utils/persistStore';
import {BackofficeUserModel} from "@/typings/api/user";

const MIMIC_KEY = 'isMimic';
const ACCESS_TOKEN_LS_KEY = 'at';
const REFRESH_TOKEN_LS_KEY = 'rt';
const PARTNER_TOKEN = 'pt';
const LOGIN_TTL = 6 * 60 * 100000;
const STORAGE_ENGINE = ENGINES.LOCAL_STORAGE

export function setAuthenticationTokens(tokens: UserTokens): void {
  storage.setItem(ACCESS_TOKEN_LS_KEY, tokens.access_token, { ttl: LOGIN_TTL }, STORAGE_ENGINE);
  storage.setItem(REFRESH_TOKEN_LS_KEY, tokens.refresh_token, { ttl: LOGIN_TTL }, STORAGE_ENGINE);
}

export function setPartnerToken(partnerToken: string): void {
  storage.setItem(PARTNER_TOKEN, partnerToken, { ttl: LOGIN_TTL }, STORAGE_ENGINE);
}
export function getPartnerToken(): string | null {
  return storage.getItem(PARTNER_TOKEN, {}, STORAGE_ENGINE);
}

export function setMimicSession(): void {
  storage.setItem(MIMIC_KEY, "1");
}

export function isMimicSession(): boolean {
  return storage.getItem(MIMIC_KEY) === "1";
}

export function getAccessToken(): string | null {
  return storage.getItem(ACCESS_TOKEN_LS_KEY, {}, STORAGE_ENGINE);
}

export function getRefreshToken(): string | null {
  return storage.getItem(REFRESH_TOKEN_LS_KEY, {}, STORAGE_ENGINE);
}

export function getAuthorizationHeader(): { Authorization?: string } {
  const accessToken = getAccessToken();
  const partnerToken = getPartnerToken();
  const header = {}
  if (accessToken)
    header['Authorization'] = `Bearer ${accessToken}`;

  if (partnerToken)
    header['Partner-Hash'] = partnerToken;

  return header;
}

export function isExpiredToken(response: RequestResponse): boolean {
  const EXPIRE_MESSAGE = 'Expired Token';
  return response?.data?.message === EXPIRE_MESSAGE;
}

export function clearCredentials(): void {
  storage.removeItem(ACCESS_TOKEN_LS_KEY, {}, STORAGE_ENGINE);
  storage.removeItem(REFRESH_TOKEN_LS_KEY, {}, STORAGE_ENGINE);
  storage.removeItem(PARTNER_TOKEN, {}, STORAGE_ENGINE);
  resetPersistentReduxStore();
}

export function authCheck(partnerId?: string, Partners= []): Promise<BackofficeUserModel> {
  return new Promise((resolve, reject) => {
    const fetchMe = (Partners) => {
      fetchMeRequest().then((UserAPICall) => {
        const status = get(UserAPICall, 'response.status');
        const data = get(UserAPICall, 'data', {});
        if (status === 200) {
          // @ts-ignore
          resolve(data);
        } else {
          reject(data);
        }
      });
    }

    if (partnerId) {
      setPartnerToken(partnerId)
      return fetchMe(Partners)
    }

    fetchPartners().then((PartnersAPICall) => {
      const Partners: UserPartner[] = get(PartnersAPICall, 'data.data', []);
      const partnersCount = (Partners || []).length
      if (partnersCount === 0)
        return reject({message: 'No Partners found'});
      if (partnersCount > 1)
        return resolve({partnerSelect: true, Partners});
      if (partnersCount === 1)
        setPartnerToken(Partners[0].id)

      fetchMe(Partners);
    })

  });
}

export function logOut(): void {
  clearCredentials();
}

export function refreshToken(): Promise<unknown> {
  return new Promise((resolve) => {
    refreshTokenRequest({
      refresh_token: getRefreshToken(),
    }).then((result) => {
      const data = get(result, 'data', {});
      setAuthenticationTokens(data);
      resolve(data);
    });
  });
}
