import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'antd';
import moment from 'moment';
import {PlusOutlined} from "@ant-design/icons";
import {F} from "@/utils/intl";
import styles from "./index.less";
import CreateTaskForm from "@/pages/Tasks/CreateTaskForm";
import {ProForm} from "@ant-design/pro-form";
import { LeadTaskModel } from '@/typings/models/LeadTask';

type ICreateTaskModalProps = {
  updateRequest?: () => void;
  leadId?: string;
  task?: LeadTaskModel;
  setShowEdit?: () => void;
  showEdit?:boolean
};
const CreateTaskModal = ({ updateRequest, leadId, task,setShowEdit , showEdit}: ICreateTaskModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(showEdit ?? false);
  const [loading, setLoading] = useState(false);
  const [form] = ProForm.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setShowEdit && setShowEdit();
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible && form) {
      form.resetFields();
      if (task?.id) {
        form.setFieldsValue({ ...task, description: task.description_summary });
      } else {
        form.setFieldValue('due_date', moment().add(1, 'days'));
      }
    }
  }, [isModalVisible]);

  return (
    <>
      {!isModalVisible && (
        <Button type="link" size={'small'} icon={<PlusOutlined />} onClick={showModal}>
          <F id={'pages.lead.tasks.create'} defaultMessage={'Create Task'} />
        </Button>
      )}
      <Modal
        wrapClassName={styles.createTaskModalWrap}
        title={
          task?.id ? (
            <F id={'pages.lead.tasks.editTask'} defaultMessage={'Edit Task'} />
          ) : (
            <F id={'pages.lead.tasks.create'} defaultMessage={'Create Task'} />
          )
        }
        visible={isModalVisible}
        footer={
          <div>
            <Button className={'button-primary-bordered'} onClick={handleCancel}>
              <F id={'pages.common.cancel'} defaultMessage={'Cancel'} />
            </Button>
            <Button type="primary" loading={loading} onClick={() => form.submit()}>
              {task?.id ? (
                <F id={'pages.lead.tasks.editTask'} defaultMessage={'Edit Task'} />
              ) : (
                <F id={'pages.lead.tasks.create'} defaultMessage={'Create Task'} />
              )}
            </Button>
          </div>
        }
        destroyOnClose
        onCancel={handleCancel}
      >
        <CreateTaskForm
          leadId={leadId}
          form={form}
          setLoading={setLoading}
          updateRequest={updateRequest}
          task={task}
          onTaskCreated={() => {
            setShowEdit && setShowEdit();
            handleCancel();
          }}
        />
      </Modal>
    </>
  );
};

export default CreateTaskModal;
