import {getIntl} from "umi";

const AUTOMATION_LIFECYCLE_EVENT_STATE_MAP = {
  APPOINTMENT_BOOKED: 'appointment_booked_lead_state_id',
  APPOINTMENT_CANCELLED: 'appointment_cancelled_lead_state_id',
  APPOINTMENT_DONE: 'appointment_done_lead_state_id',
  APPOINTMENT_REQUESTED: 'appointment_requested_lead_state_id',
  MANUAL: '-1',
}


export const getAutomationEvents = () => {
  const intl = getIntl()
  return [
    {
      id: AUTOMATION_LIFECYCLE_EVENT_STATE_MAP.MANUAL,
      title: intl.formatMessage({
        id: 'pages.lead.status.lifecycle.manual',
        defaultMessage: 'Manual',
      }),
    },
    {
      id: AUTOMATION_LIFECYCLE_EVENT_STATE_MAP.APPOINTMENT_REQUESTED,
      title: intl.formatMessage({
        id: 'pages.lead.status.lifecycle.appointmentRequested',
        defaultMessage: 'Appointment Request',
      }),
    },
    {
      id: AUTOMATION_LIFECYCLE_EVENT_STATE_MAP.APPOINTMENT_BOOKED,
      title: intl.formatMessage({
        id: 'pages.lead.status.lifecycle.appointmentBooked',
        defaultMessage: 'On Booking',
      }),
    },
    {
      id: AUTOMATION_LIFECYCLE_EVENT_STATE_MAP.APPOINTMENT_CANCELLED,
      title: intl.formatMessage({
        id: 'pages.lead.status.lifecycle.appointmentCancelled',
        defaultMessage: 'On Cancellation',
      }),
    },
    {
      id: AUTOMATION_LIFECYCLE_EVENT_STATE_MAP.APPOINTMENT_DONE,
      title: intl.formatMessage({
        id: 'pages.lead.status.lifecycle.appointmentDone',
        defaultMessage: 'On Appointment Done',
      }),
    },
  ];
}

export const getMagicFieldTypes = () => {
  const intl = getIntl()
  return [
    {label: intl.formatMessage({id: 'pages.leads.status.off', defaultMessage: 'Off'}), value: 'none'},
    {label: intl.formatMessage({id: 'pages.leads.status.optional', defaultMessage: 'Optional'}), value: 'optional'},
    {label: intl.formatMessage({id: 'pages.leads.status.magicFields.mandatory', defaultMessage: 'Mandatory'}), value: 'mandatory'},
  ]
};


export const LEAD_STATE_TYPES = {
  OPEN: 'OPEN',
  WON: 'WON',
  LOST: 'LOST'
}

export const INITIAL_NEW_STATUS_FORM = {
  color: "#92A8D1",
  label: '',
  state_type: LEAD_STATE_TYPES.OPEN
}
