import set from "lodash/set";
import omit from "lodash/omit";
import get from "lodash/get";

import {processAPIResponse} from "@/utils/request";
import {
  fetchPartnerConfigRequest,
  fetchUiPreferencesRequest,
  setPartnerConfigRequest,
  setUiPreferencesRequest
} from "@/services/api/preferences";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";

const initialState = {
  list: {},
  partnerConfig: {},
  initialized: false,
  persistQueue: {}
}
export default {
  namespace: 'preferences',

  state: initialState,

  effects: {
    * fetch({then}, {call, put, select}) {
      const {response, data} = yield call(fetchUiPreferencesRequest);
      yield put({
        type: 'fetchPartnerConfig'
      })
      return yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'set',
            payload: data
          })
          if (then) then(data)
        },
        * onError(error) {
          if (then) then(null)
        }
      })
    },
    * fetchPartnerConfig(_, {call, put}) {
      const {response, data} = yield call(fetchPartnerConfigRequest);
      return yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'setPartnerConfig',
            payload: data
          })
        }
      }, 'data')
    },
    * changePartnerConfig({payload}, {call, put, select}) {
      const currentConfig = yield select(PreferencesSelectors.selectPartnerConfig)
      const {response, data} = yield call(setPartnerConfigRequest, {
        data: {
          ...currentConfig,
          ...omit(payload, ['then'])
        }
      });
      return yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'fetchPartnerConfig',
          })
          if (payload.then) payload.then()
        }
      })
    },
    * change({payload}, {call, put, select}) {
      const newFilter = omit(payload, ['place', 'persist', 'mode', 'local'])
      const place = payload.place
      /**
       * payload.mode can be filters / view etc. based on the requirement. Since the filters object are used
       * directly in the API Call payloads, it's important to have a clear distinction between the modes.
       * */
      const key = `ui.${place}__${payload.mode || 'filters'}`
      yield put({
        type: 'merge',
        payload: {
          key,
          value: newFilter
        }
      })
      if (payload.persist) {
        yield put({
          type: 'persistConfirm',
          payload: {
            key
          }
        })
        return
      }
      if (!payload.local)
        yield put({
          type: 'addToPersistQueue',
          payload: {
            key,
            meta: {
              place
            }
          }
        })
    },
    * persistConfirm({payload: {key}}, {call, put, select}) {
      const value = yield select((state: DefaultRootState) => get(state, `preferences.list.${key}`))
      yield put({
        type: 'removeFromPersistQueue',
        payload: {
          key
        }
      })
      yield call(setUiPreferencesRequest, {params: {key: key.split('ui.')[1]}, data: value})

    },
    * persistIgnore({payload: {key}}, {put}) {
      yield put({
        type: 'removeFromPersistQueue',
        payload: {
          key
        }
      })
    },
    * persistReset({payload: {key}}, {put}) {
      yield put({
        type: 'omit',
        payload: {
          key
        }
      })
      yield put({
        type: 'removeFromPersistQueue',
        payload: {
          key
        }
      })
    },
    * update({payload}, {call, put, select}) {
      const {key, value, listKey = 'ui'} = payload
      yield put({
        type: 'modify',
        payload: {
          key: `${listKey}.${key}`, value
        }
      })
      yield call(setUiPreferencesRequest, {params: {key}, data: value})
    }

  },
  reducers: {
    set(state, action) {
      return {
        ...state,
        initialized: true,
        list: {
          ...state.list,
          ...(action.payload || {}),
        },
      };
    },
    modify(state, action) {
      const newList = set(state.list, action.payload.key, action.payload.value);
      return {
        ...state,
        list: newList,
      };
    },
    omit(state, action) {
      return {
        ...state,
        list: omit(state.list, action.payload.key)
      };
    },
    merge(state, action) {
      const newList = set(state.list, action.payload.key, {
        ...get(state.list, action.payload.key, {}),
        ...action.payload.value,
      });
      return {
        ...state,
        list: newList,
      };
    },
    addToPersistQueue(state, action) {
      return {
        ...state,
        persistQueue: {
          ...state.persistQueue,
          [action.payload.key]: {
            time: new Date().getTime(),
            ...(action.payload.meta || {})
          }
        }
      }
    },
    removeFromPersistQueue(state, action) {
      return {
        ...state,
        persistQueue: omit(state.persistQueue, action.payload.key)
      }
    },
    setPartnerConfig(state, action) {
      return {
        ...state,
        partnerConfig: action.payload
      }
    },
    reset() {
      return initialState
    }
  }
};
