import React from "react";
import styles from "../index.less";
import {ServiceRequestModel} from "@/typings/models/ServiceRequest";
import {Space} from "antd";
import EventIcon from "@/components/Kanban/ServiceRequests/Calendar/EventIcon";
import {UnorderedListOutlined, UserOutlined} from "@ant-design/icons";
import LeadName from "@/components/Lead/LeadName";
import {useDispatch} from "umi";
import {F} from "@/utils/intl";

const TeamViewCalendarEventOverviewPopover = ({event, onOpenChange}: { event: ServiceRequestModel, onOpenChange?: (open?: boolean) => void }) => {
  const dispatch = useDispatch()
  // TODO: Translations

  const handleEventCancel = () => new Promise((resolve, reject) => {
    setTimeout(() => {
      // TODO: Replace with real cancel API call
      resolve()
      // TODO: Reload events
    }, 2000)
  })

  const onLeadClick = () => {
    onOpenChange(false)
    dispatch({
      type: 'leads/toggleLeadDetailsDrawer',
      payload: {
        lead: event.lead
      }
    })
  }
  return <div className={styles.teamViewCalendarEventPopoverContainer}>
    <div className={styles.overviewHeader}>
      <div>
        <Space>
          <EventIcon className={styles.icon} event={event}/>
          <span>{event.what}</span>
        </Space>
      </div>
      <div className={styles.eventActions}>
        {/*TODO OUT OF SCOPE*/}
        {/*<CheckAccess access={[PERMISSIONS.ServiceRequests.CANCEL, PERMISSIONS.BUSINESS_PARTNER]}>*/}
        {/*  <Popconfirm title={"Are you sure you want to cancel this event?"} onConfirm={handleEventCancel}  okText={"Yes"} cancelText={"No"}>*/}
        {/*    <Button size={"small"} shape={"round"} icon={<CloseCircleOutlined/>}></Button>*/}
        {/*  </Popconfirm>*/}
        {/*</CheckAccess>*/}
        {/*<CheckAccess access={[PERMISSIONS.ServiceRequests.RESCHEDULE, PERMISSIONS.BUSINESS_PARTNER]}>*/}
        {/*  <Tooltip title={"Reschedule"}><Button size={"small"} shape={"round"} icon={<CalendarOutlined/>}></Button></Tooltip>*/}
        {/*</CheckAccess>*/}
        {/*I Think we don't need edit*/}
        {/*<Button size={"small"} shape={"round"} icon={<EditOutlined/>}></Button>*/}
      </div>
    </div>
    <div>
      <Space>
        <UserOutlined className={styles.icon}/>
        <a onClick={onLeadClick} className={styles.teamViewCalendarEventPopoverLead}><LeadName readonly hideAvatar lead={event.lead}/></a>
      </Space>
    </div>
    <div>
      <Space className={styles.notesContainer}>
        <UnorderedListOutlined className={styles.icon}/>
        <Space>
          <span><F id={'pages.customer.notes'} defaultMessage={'Notes'}/>:</span>
          <span className={styles.notes}>{event.lead?.customer?.notes || " - "}</span>
        </Space>
      </Space>
    </div>
  </div>
}

export default TeamViewCalendarEventOverviewPopover;
