import React from "react";
import {Button, Calendar, CalendarProps} from "antd";
import dayjs from "dayjs";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import styles from "../index.less";
import de_DE from 'antd/lib/locale-provider/de_DE';
import LocaleProvider from "antd/es/locale-provider";
import {Moment} from "moment";

const InlineDatePicker = ({value, onChange, disabledDate, isWeekView = false}) => {

  const onSelect = (date: Moment) => {
    onChange(date.toDate())
  };

  const headerRender = ({ value, onChange }) => {
    const currentMonth = dayjs(value).format("MMMM YYYY");
    return (
      <div className={styles.inlineDatePickerHeader}>
        <Button
          type={"text"}
          size={"small"}
          onClick={() => onChange(value.clone().subtract(1, "month"))}
        >
          <LeftOutlined />
        </Button>
        <span style={{ fontWeight: "bold" }}>{currentMonth}</span>
        <Button
          type={"text"}
          size={"small"}
          onClick={() => onChange(value.clone().add(1, "month"))}
        >
          <RightOutlined/>
        </Button>
      </div>
    );
  }
  const dateFullCellRender = (date) => {
    const isInSelectedWeek =
      date.isAfter(dayjs(value).startOf("week")) &&
      date.isBefore(dayjs(value).endOf("week"));

    return (
      <div
        style={{
          backgroundColor: isInSelectedWeek ? "rgba(39,174,157,0.47)" : "transparent",
          padding: "4px",
          color: "rgba(0, 0, 0, 0.85)",
          fontWeight: 300
        }}
      >
        {date.format("DD")}
      </div>
    );
  };
  const isDateDisabled = date => {
    if (!disabledDate) return false
    return disabledDate(date)
  }
  const props: CalendarProps<any> = {}
  if (isWeekView) props.dateFullCellRender = dateFullCellRender
  return (
    <LocaleProvider locale={de_DE}>
      <Calendar
        fullscreen={false}
        onSelect={onSelect}
        value={dayjs(value)}
        className={styles.inlineDatePicker}
        headerRender={headerRender}
        disabledDate={isDateDisabled}
        {...props}
      />
    </LocaleProvider>
  );
};

export default InlineDatePicker;
