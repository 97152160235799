import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface PermissionCellProps {
  text: string;
  description?: string;
}

const PermissionCell: React.FC<PermissionCellProps> = ({ text, description }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{text}</span>
      {description && (
        <Tooltip title={description}>
          <InfoCircleOutlined
            style={{ marginLeft: 8, fontSize: '12px', position: 'relative', top: '-2px' }}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default PermissionCell;