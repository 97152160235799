import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  DndContext,
  DragOverlay,
  MeasuringStrategy,
  MouseSensor,
  rectIntersection,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {arrayMove} from "@dnd-kit/sortable";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import {useDispatch} from "umi";
import get from "lodash/get";
import omit from "lodash/omit";

import LeadColumn from "@/components/Kanban/LeadColumn";
import LeadCard from "@/components/Kanban/LeadCard";
import SkeletonKanbanBoard from "@/components/Kanban/SkeletonKanbanBoard";
import {LeadGroupedModel} from "@/typings/models/Lead";

import ClientOnlyPortal from "./ClientOnlyPortal";
import styles from "./styles.less"
import {Spin} from "antd";
import {LeadCardClickProvider} from "@/components/Kanban/LeadCardContext";

type ILeadColumn = {
  id: number,
  order: number,
  name: string,
  color: string
}
type ILeadKanbanBoardProps = {
  groupedLeads: LeadGroupedModel[];
  columns: ILeadColumn[];
  groupId?: string;
  loading?: boolean;
  isHeatmap?: boolean;
  request: () => void;
};
export default function LeadKanbanBoard({
                                          groupedLeads,
                                          loading,
                                          request,
                                          groupId = 'id',
                                        }: Readonly<ILeadKanbanBoardProps>) {
  const [items, setItems] = useState({});
  const [containers, setContainers] = useState([]);
  const [columns, setColumns] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [activeItemOriginalContainer, setActiveItemOriginalContainer] = useState(null);
  const [moveHistory, setMoveHistory] = useState({});
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;
  const [isDraggingContainer, setIsDraggingContainer] = useState(false);

  const kanbanContainerRef = useRef(null);

  const dispatch = useDispatch();

  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleMouseDown = useCallback((e) => {
    if (activeId) return;
    setIsDraggingContainer(true);
    kanbanContainerRef.current.style.cursor = "grabbing";
    startX.current = e.pageX - kanbanContainerRef.current.offsetLeft;
    scrollLeft.current = kanbanContainerRef.current.scrollLeft;
  }, [activeId]);

  const handleMouseMove = useCallback((e) => {
    if (!isDraggingContainer || activeId) return;
    e.preventDefault();
    const x = e.pageX - kanbanContainerRef.current.offsetLeft;
    const walk = (x - startX.current) * 1.5;
    kanbanContainerRef.current.scrollLeft = scrollLeft.current - walk;
  }, [activeId, isDraggingContainer]);

  const handleMouseUpOrLeave = useCallback(() => {
    setIsDraggingContainer(false);
    kanbanContainerRef.current.style.cursor = "grab";
  }, []);

  // Custom handler to allow horizontal scrolling in the parent
  const handleWheel = (e) => {
    if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
      if (kanbanContainerRef.current) {
        kanbanContainerRef.current.scrollLeft += e.deltaX;
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (request) request();
  }, []);

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  useEffect(() => {
    if (groupedLeads) {
      const _columns = groupedLeads
        .map((groupedLead) => ({
          id: groupedLead.filter_id,
          title: groupedLead.title,
          ...(groupedLead.meta || {}),
          ...omit(groupedLead, ['data']),
        }))
        .filter((groupedLead) => groupedLead.id !== 'null');
      let columnsRepresentation = {};
      // Sort the columns by order in ascending order
      _columns.sort((a, b) => a.order - b.order);

      // Initialize empty arrays for each column using `reduce`
      columnsRepresentation = _columns.reduce((acc, c) => {
        const leads = groupedLeads.find((leadGroup) => leadGroup.filter_id == c[groupId]);
        acc[`column-${c[groupId]}`] = leads?.data || [];
        return acc;
      }, {});
      setColumns(_columns);
      setItems(columnsRepresentation);
      setContainers(Object.keys(columnsRepresentation));
    }
  }, [groupedLeads]);

  const moveBetweenContainers = useCallback(
    (activeContainer, overContainer, active, over, overId) => {
      if (!activeContainer || !overContainer || activeContainer === overContainer) return;

      setItems((prevItems) => {
        const activeItems = prevItems[activeContainer];
        const overItems = prevItems[overContainer];
        const activeIndex = activeItems.indexOf(active.id);

        if (activeIndex === -1) return prevItems;

        // Determine the new position in the target container
        const overIndex = overId ? overItems.indexOf(overId) : -1;
        const isBelowOverItem =
          over &&
          active.rect?.current?.translated &&
          active.rect.current.translated.top >= (over.rect?.top || 0) + (over.rect?.height || 0);

        const newIndex = overIndex >= 0 ? overIndex + (isBelowOverItem ? 1 : 0) : overItems.length;

        // Skip state updates if the item would stay in the same place
        if (activeContainer === overContainer && activeIndex === newIndex) return prevItems;

        // Create updated items
        return {
          ...prevItems,
          [activeContainer]: activeItems.filter((_, index) => index !== activeIndex),
          [overContainer]: [
            ...overItems.slice(0, newIndex),
            active.id,
            ...overItems.slice(newIndex),
          ],
        };
      });

      // Update move history and flag
      setMoveHistory({
        activeIndex: items[activeContainer]?.indexOf(active.id),
        newIndex: items[overContainer]?.indexOf(overId),
        overContainer,
        itemId: active.id,
      });
      recentlyMovedToNewContainer.current = true;
    },
    []
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        //distance: 5,
        delay: 100,
        tolerance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 5,
        delay: 0,
        tolerance: 5,
      },
    }),
  );

  const findContainer = (id) => {
    if (id in items) return id;
    if (containers.includes(`column-${id}`)) return `column-${id}`
    return containers.find((key) => items[key].includes(id));
  };

  // region Drag events
  function handleDragStart({active}) {
    setActiveId(active.id);
    const currentContainer = findContainer(active.id);
    if (currentContainer)
      setActiveItemOriginalContainer(currentContainer.split('-')[1]);

  }

  function handleDragOver({active, over}) {
    const overId = over?.id;
    if (!overId || active.id in items) return;
    const overContainer = findContainer(overId);
    const activeContainer = findContainer(active.id);

    if (!overContainer || !activeContainer) return;

    if (activeContainer !== overContainer) {
      moveBetweenContainers(activeContainer, overContainer, active, over, overId);
    }
  }

  function handleDragEnd({active, over}) {
    if (!over) return reset();

    if (active.id in items && over?.id) return reset();

    const activeContainer = findContainer(active.id);

    if (!activeContainer) return reset();

    const overContainer = findContainer(over.id);

    // Bypass in a column move
    if (activeContainer === overContainer && isEmpty(moveHistory)) return reset();

    if (overContainer) {
      const activeIndex = items[activeContainer].indexOf(active.id);
      const overIndex = items[overContainer].indexOf(over.id);

      if (activeIndex !== overIndex) {
        setItems((items) => ({
          ...items,
          [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
        }));
      }

      leadDropAPICallHandler(active.id, overIndex, activeIndex);
    }

    reset();
  }

  const handleDragCancel = () => {
    setActiveId(null);
    setActiveItemOriginalContainer(null);
  };

  // endregion

  function reset() {
    setActiveId(null);
    setActiveItemOriginalContainer(null);
    setMoveHistory({});
  }

  const leadDropAPICallHandler = (leadId, overIndex, activeIndex) => {
    let payload = null;
    if (!isEmpty(moveHistory)) {
      // Change status
      const column = findColumn(get(moveHistory, 'overContainer'));
      payload = {
        leadId,
        lead_state_id: column.id,
      };
    }
    if (!payload) return;

    dispatch({
      type: 'leads/edit',
      payload,
      previousState: activeItemOriginalContainer
    });
  };
  const findColumn = (columnId: string) => columns.find((c) => 'column-' + c[groupId] === columnId);
  const getActiveColumnItem = () => findColumn(activeId);

  /**
   * By active, we mean the current dragged element which the shadow is visible
   * */
  const renderActiveElement = () => {
    if (!activeId) return null;

    const activeColumn = getActiveColumnItem();
    if (containers.includes(activeId))
      return (
        <LeadColumn
          id={activeId}
          leadIds={items[activeId]}
          name={activeColumn.title || activeColumn.label || activeColumn.name}
          color={getActiveColumnItem().color}
          dragOverlay
        />
      );

    return <LeadCard id={activeId} dragOverlay/>;
  };

  if (loading && (columns || []).length === 0) return <SkeletonKanbanBoard/>;

  return (
    <LeadCardClickProvider>
      <Spin spinning={loading}>
        <div className={classNames(styles.kanban, 'client-only-kanban')}
             onMouseDown={handleMouseDown}
             onMouseMove={handleMouseMove}
             onMouseUp={handleMouseUpOrLeave}
             onMouseLeave={handleMouseUpOrLeave}
             onWheel={handleWheel}
             id={'#kanban'} ref={kanbanContainerRef}>
          <DndContext
            sensors={sensors}
            collisionDetection={rectIntersection}
            measuring={{
              droppable: {
                strategy: MeasuringStrategy.WhileDragging,
              },
            }}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            <div className={styles.kanbanContainer}>
              {containers.map((containerId) => {
                const column = columns.filter((c) => 'column-' + c[groupId] === containerId)[0];
                if (!column) return null;
                return (
                  <LeadColumn
                    id={containerId}
                    key={containerId}
                    leadIds={items[containerId]}
                    name={column.label || column.title || column.name}
                    color={column.color}
                    isSortingContainer={isSortingContainer}
                    {...column}
                  />
                );
              })}
            </div>
            <ClientOnlyPortal selector=".client-only-kanban">
              <DragOverlay>{renderActiveElement()}</DragOverlay>
            </ClientOnlyPortal>
          </DndContext>
        </div>
      </Spin>
    </LeadCardClickProvider>
  );
}
