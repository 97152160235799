import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useModel, useSelector} from "umi";
import CustomerDrawer from "@/components/CustomerDrawer";
import UserDrawer from "@/components/UserDrawer";
import ServiceRequestDrawer from "@/components/ServiceRequestDrawer";
import PromotionDrawer from "@/components/PromotionDrawer";
import BranchDrawer from "../LocationDrawers/BranchDrawer";
import AddLeadDrawer from "../LeadSource";
import LeadDetailsDrawer from "@/components/Lead/LeadDetailsDrawer";
import LeadAssignmentDrawer from "@/components/Lead/LeadAssignmentDrawer";
import LeadMagicFieldsEditorModal from "@/components/Lead/LeadMagicFieldsEditorModal";
import CreateServiceRequestDrawer from "@/components/Kanban/ServiceRequests/CreateServiceRequestDrawer";
import PreferencesPersistQueue from "@/components/Preferences/PreferencesPersistQueue";
import MagicTasksModal from "@/pages/Tasks/MagicTasksModal";
import MagicAppointmentBookingModal from "@/pages/Tasks/MagicAppointmentBookingModal";
import PERMISSIONS from "@/constants/permissions";
import {hasAccess} from "@/utils/access";
import UserEditDrawer from "../UserDrawer/Edit/index";


// const journeyWatcherActions = ["serviceRequest/fetch", "customers/fetch", "customers/fetchStates", "analytics/init", "location/init", {
//   type: "serviceRequest/fetchInfinite",
//   payload: {
//     reset: true
//   }
// }]

const DvaWatcher: React.FC = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const store: DefaultRootState = useSelector((store) => store);
  const {initialState, setInitialState} = useModel('@@initialState');
  const [initialized, setInitialized] = useState(false)

  const customerDrawer = store.customers.drawer;
  const serviceRequestDrawer = store.serviceRequest.drawer;

  function checkJourneyChange() {
    if (!hasAccess(initialState.currentUser, PERMISSIONS.BUSINESS_PARTNER)) return

    const prev = prevStore.current;
    if (
      store?.journey?.selectedJourney?.hashId &&
      prev?.journey?.selectedJourney?.hashId !== store?.journey?.selectedJourney?.hashId
    ) {
      dispatch({
        type: 'journey/fetchConfig',
        payload: {journeyId: store?.journey?.selectedJourney?.hashId},
      });
    }
  }

  const prevStore = useRef<DefaultRootState>();
  useEffect(() => {
    if (prevStore.current) {
      checkJourneyChange();
    }
    prevStore.current = store;
  }, [store]);

  useEffect(() => {
    if (initialState && initialState?.currentUser?.Journeys) {
      dispatch({
        type: 'journey/gotJourneys',
        payload: initialState.currentUser.Journeys,
      });
    }
  }, [initialState]);

  useEffect(() => {
    if (initialState?.selectedSolution && initialized) {
      dispatch({
        type: 'preferences/update',
        payload: {
          key: 'selectedSolution',
          value: {
            value: initialState.selectedSolution,
          }
        }
      })
    }
    setInitialized(true)
  }, [initialState?.selectedSolution]);

  useEffect(() => {
    setInitialState({
      ...initialState,
      config: {...initialState?.config, ...store?.journey?.config},
    });
  }, [JSON.stringify(store?.journey?.config)]);
  return (
    <>
      {!customerDrawer?.nested && <CustomerDrawer/>}
      {!serviceRequestDrawer?.nested && <ServiceRequestDrawer/>}
      <BranchDrawer/>
      <PromotionDrawer/>
      <UserDrawer/>
      <UserEditDrawer/>
      <AddLeadDrawer/>
      <LeadDetailsDrawer/>
      <LeadAssignmentDrawer/>
      <LeadMagicFieldsEditorModal/>
      <MagicTasksModal/>
      <MagicAppointmentBookingModal/>
      <CreateServiceRequestDrawer/>
      <PreferencesPersistQueue/>

    </>
  );
};

export default DvaWatcher
