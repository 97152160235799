import React, {useEffect, useState} from 'react';
import {Button, Form, Popconfirm, Switch, Tag} from 'antd';
import Table from '@/components/Table';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {FormattedMessage as F, useDispatch, useIntl, useModel, useSelector} from 'umi';

import * as AutomationSelectors from '@/selectors/automationSelector';
import {SOLUTION_KEYS} from '@/constants/solutions';
import {CheckAccess} from '@/utils/access';
import PERMISSIONS from '@/constants/permissions';
import NewEmailAutomation from '@/pages/Automation/NewEmailAutomation';

const TRACKING_LABEL = 'Email Automation';
const REDUX_ACTION = 'automation/fetchEmailAutomationList';
const EmailAutomationList = () => {
  const intl = useIntl();
  const { initialState, setInitialState } = useModel('@@initialState');

  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editorContent, setEditorContent] = useState('');
  const [currentAutomation, setCurrentAutomation] = useState(null);
  const [updatingRecord, setUpdatingRecord] = useState(null);
  const { automations, loading } = useSelector((state: DefaultRootState) => ({
    automations: AutomationSelectors.selectEmailAutomationList(state),
    loading: state.loading.effects[REDUX_ACTION],
    users: state.user?.list,
  }));

  useEffect(() => {
    setInitialState({
      ...initialState,
      selectedSolution: SOLUTION_KEYS.LEAD_MANAGEMENT,
    });
  }, []);

  const handleAddAutomation = () => {
    setCurrentAutomation(null);
    showModal();
  };

  const handleEditAutomation = (automation) => {
    setCurrentAutomation(automation);
    showModal();
  };

  const fetchData = () => {
    dispatch({
      type: REDUX_ACTION,
    });
  };

  const create = (automation) => {
    dispatch({
      type: 'automation/createEmailAutomation',
      payload: {
        ...automation,
        intl,
        cb: (succeeded) => {
          if (succeeded) {
            form.resetFields();
            setIsModalVisible(false);
          }
        },
      },
    });
  };

  const update = (automation) => {
    setUpdatingRecord({ [automation.id]: { loading: true, error: '' } });
    dispatch({
      type: 'automation/editEmailAutomation',
      payload: {
        cb: (data, succeeded) => {
          if (succeeded) {
            form.resetFields();

            setIsModalVisible(false);
          }
          setUpdatingRecord((prev) => ({
            ...prev,
            [automation.id]: { loading: false, error: data.message },
          }));
        },
        intl,
        id: automation.id,
        ...automation,
        automation_type: automation?.automation_type?.key
          ? automation?.automation_type?.key
          : automation?.automation_type,
      },
    });
  };
  const deleteAutomation = (id: string, resolve: Function) => {
    dispatch({
      type: 'automation/deleteEmailAutomation',
      payload: {
        id,
        cb: resolve,
        intl,
      },
    });
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (automation) => {
    form
      .validateFields()
      .then((values) => {
        if (currentAutomation) {
          values['id'] = automation?.id;
          update(values);
        } else create(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'pages.emailAutomation.name', defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: 'pages.emailAutomation.trigger', defaultMessage: 'Type' }),
      dataIndex: ['automation_type', 'label'],
      key: 'automation_type',
    },
    {
      title: intl.formatMessage({ id: 'pages.emailAutomation.status', defaultMessage: 'Status' }),
      dataIndex: 'from_state_id',
      key: 'from_state_id',
      render: (text, record) => (
        <div>
          <Tag color={record['from_state']?.color}>{record['from_state']?.label}</Tag>
          {record['to_state'] && (
            <>
              <span style={{ margin: '0 8px' }}>
                <F id={'pages.emailAutomation.toState'} defaultMessage={'to'} />
              </span>
              <Tag color={record['to_state']?.color}>{record['to_state']?.label}</Tag>
            </>
          )}
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'pages.emailAutomation.active', defaultMessage: 'Active' }),
      dataIndex: 'is_active',
      key: 'is_active',
      render: (active, record) => (
        <Switch
          checked={record.is_active}
          onChange={() => {
            update({ ...record, is_active: !record.is_active });
          }}
          loading={updatingRecord?.[record.id]?.loading === true}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'pages.emailAutomation.actions', defaultMessage: 'Actions' }),
      key: 'actions',
      render: (text, item) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CheckAccess access={[PERMISSIONS.EmailAutomation.EDIT, PERMISSIONS.BUSINESS_PARTNER]}>
            <Button type="link" onClick={() => handleEditAutomation(item)}>
              <EditOutlined />
            </Button>
          </CheckAccess>
          <CheckAccess access={[PERMISSIONS.EmailAutomation.DELETE, PERMISSIONS.BUSINESS_PARTNER]}>
            <Popconfirm
              onConfirm={() => new Promise((resolve) => deleteAutomation(item.id, resolve))}
              placement={'topRight'}
              title={
                <F
                  id={'pages.automations.removeConfirm'}
                  defaultMessage={'Are you sure to remove this automation?'}
                />
              }
            >
              <a>
                <DeleteOutlined disabled={!item?.is_deletable} />
              </a>
            </Popconfirm>
          </CheckAccess>
        </div>
      ),
    },
  ];
  return (
    <>
      <Table
        search={false}
        loading={loading}
        columns={columns}
        dataSource={automations.data ?? []}
        request={fetchData}
        rowKey="id"
        total={automations.total}
        links={automations.links}
        // loading={loading}
        toolBarRender={() => (
          <CheckAccess access={[PERMISSIONS.EmailAutomation.CREATE, PERMISSIONS.BUSINESS_PARTNER]}>
            <Button type="primary" onClick={handleAddAutomation}>
              <PlusOutlined />{' '}
              {intl.formatMessage({
                id: 'pages.emailAutomation.newAutomation',
                defaultMessage: 'New Automation',
              })}
            </Button>
          </CheckAccess>
        )}
      />
      <NewEmailAutomation
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        form={form}
        editorContent={editorContent}
        setEditorContent={setEditorContent}
        automation={currentAutomation}
      />
    </>
  );
};

export default EmailAutomationList;
