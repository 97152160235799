import * as React from 'react';
import { useState } from 'react';
import { Button, Popconfirm, Space, Switch } from 'antd';
import Table from '@/components/Table';
import type { ProColumns } from '@ant-design/pro-table';
import { FormattedMessage as F, useDispatch, useIntl } from 'umi';
import PrettyDate from '@/components/PrettyDate';
import CreateTaskModal from '@/pages/Tasks/CreateTaskModal';
import LeadCard from '@/components/Kanban/LeadCard';
import { LeadTaskModel } from '@/typings/models/LeadTask';
import { editLeadTaskRequest } from '@/services/api/leadTask';
import DoTask from '@/components/Lead/LeadTasks/DoTask';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

type ITasksTableProps = {
  data: LeadTaskModel[];
  request: () => void;
  loading?: boolean;
  leadId?: string;
  filterColumns?: (column) => boolean;
  hideCreate?: boolean;
  hideShowDone?: boolean;
};
export function TasksTable({
  data,
  request,
  loading,
  leadId,
  filterColumns = (column) => true,
  hideCreate,
  hideShowDone,
}: ITasksTableProps) {
  const [showDone, setShowDone] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const dispatch = useDispatch();
  const [localLoading, setLocalLoading] = useState(false);
  const toggleShowDone = () => setShowDone((prev) => !prev);
  function doTask(task: LeadTaskModel) {
    setLocalLoading(true);
    editLeadTaskRequest({
      params: {
        taskId: task.id,
      },
      data: {
        ...task,
        due_date: task.due_date.replace('.000000Z', 'Z'),
        is_completed: true,
      },
    }).then(() => {
      setLocalLoading(false);
      request();
    });
  }
  const deleteTask = (id: string, resolve: Function) => {
    dispatch({
      type: 'leadTasks/deleteTask',
      payload: {
        taskId: id,
        cb: resolve,
        intl,
      },
    });
  };

  const intl = useIntl();
  const columns: ProColumns<any>[] = [
    {
      title: <F id="pages.lead.tasks.taskTitle" defaultMessage="Title" />,
      dataIndex: 'title',
    },
    {
      title: <F id="pages.lead.tasks.taskDescription" defaultMessage="Description" />,
      dataIndex: 'description_summary',
    },
    {
      title: <F id="pages.lead.tasks.taskDue" defaultMessage="Due date" />,
      dataIndex: 'due_date',
      render: (date) => <PrettyDate date={date} />,
    },
    {
      title: <F id="pages.lead.tasks.lead" defaultMessage="Lead" />,
      dataIndex: 'lead',
      width: 200,
      render: (lead) =>
        lead &&
        lead?.customer && (
          <LeadCard
            leadContextMenuProps={{
              hideTasksBadge: true,
            }}
            overrideVisibleProperties={['name', 'email', 'priority']}
            ignoreHeatmap
            disableDrag
            hideAssignee
            lead={lead}
            id={lead.id}
          />
        ),
    },
    {
      title: <F id="pages.locations.actions" defaultMessage="Actions" />,
      dataIndex: '',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <DoTask task={record} updateRequest={request} />

          <Button
            style={{ marginLeft: 15 }}
            icon={<EditOutlined />}
            size={'small'}
            onClick={() => {
              setShowEdit(true);
              setSelectedTask(record);
            }}
          />

          <Popconfirm
            onConfirm={() => new Promise((resolve) => deleteTask(record.id, resolve))}
            placement={'topRight'}
            title={
              <F
                id={'pages.lead.tasks.removeConfirm'}
                defaultMessage={'Are you sure to remove this task?'}
              />
            }
          >
            <Button style={{ marginLeft: 15 }} icon={<DeleteOutlined />} size={'small'} />
          </Popconfirm>
        </div>
      ),
    },
  ].filter(filterColumns);

  return (
    <>
      {showEdit && (
        <CreateTaskModal
          leadId={leadId}
          showEdit={showEdit}
          updateRequest={request}
          task={selectedTask}
          setShowEdit={() => {
            setSelectedTask(undefined);
            setShowEdit(false);
          }}
        />
      )}
      <Table
        headerTitle={intl.formatMessage({
          id: 'pages.lead.tasks',
          defaultMessage: 'TASKS',
        })}
        columns={columns}
        size={'small'}
        pagination={false}
        isCard={false}
        search={false}
        request={request}
        fullScreen={false}
        loading={loading || localLoading}
        dataSource={data.filter((task) => (showDone ? task.is_completed : !task.is_completed))}
        toolBarRender={() => (
          <Space>
            {!hideCreate && <CreateTaskModal leadId={leadId} updateRequest={request} />}
            {!hideShowDone && (
              <Switch
                checked={showDone}
                onChange={toggleShowDone}
                checkedChildren={<F id="pages.lead.tasks.showOpen" defaultMessage="Show Open" />}
                unCheckedChildren={<F id="pages.lead.tasks.showDone" defaultMessage="Show Done" />}
              />
            )}
          </Space>
        )}
      />
    </>
  );
}
