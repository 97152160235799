import * as React from 'react'
import {useEffect} from 'react'
import {connect, useModel, useSelector} from 'umi'
import {Spin} from 'antd';

import {JourneyModel} from "@/typings/models/Journey";
import {DispatchFunction} from '@/typings/models/ReduxState';
import {getRoleDefaultRoute} from '@/typings/roleMapping';
import * as PreferencesSelector from "@/selectors/preferencesSelectors";
import isEmpty from "lodash/isEmpty";
import {hasAccess} from "@/utils/access";
import PERMISSIONS from "@/constants/permissions";

interface RootProps {
  selectedJourney: JourneyModel;
  journeyList: JourneyModel[];
  history: History;
  config: any;
  dispatch: DispatchFunction;

}

const Root: React.FC<RootProps> = ({
                                     selectedJourney,
                                     history,
                                     journeyList,
                                     config,
                                     dispatch,
                                   }: RootProps) => {
  const {initialState, setInitialState} = useModel('@@initialState');

  const isPreferencesInitialized = useSelector(PreferencesSelector.isPreferencesListInitialized)
  const selectedSolution = useSelector(PreferencesSelector.selectSelectedSolution)

  const user = initialState?.currentUser
  const isLoggedIn = Boolean(user)

  useEffect(() => {
    dispatch({
      type: 'preferences/fetch'
    })
  }, []);

  useEffect(() => {
    if (!isLoggedIn || isEmpty(user))
      return history.push('/user/login');
    if (!isPreferencesInitialized) return
    if (selectedSolution) setInitialState({
      ...initialState,
      selectedSolution
    })
    const journeyId = selectedJourney?.hashId ?? journeyList?.[0]?.hashId;
    if (journeyId) {
      if (!config?.[journeyId] && hasAccess(initialState?.currentUser, PERMISSIONS.BUSINESS_PARTNER))
        dispatch({
          type: 'journey/select',
          payload: {
            journeyId: journeyId,
          },
        });

      const defaultRoute = getRoleDefaultRoute(user, journeyId, selectedSolution)
      history.push(defaultRoute);
    }
  }, [selectedJourney, JSON.stringify(journeyList), isPreferencesInitialized]);
  useEffect(() => {
    setInitialState({
      ...initialState,
      config: {...initialState?.config, ...config},
    });
  }, [JSON.stringify(config)]);

  return <Spin/>;
};
export default connect(({journey, user}) => ({
  selectedJourney: journey.selectedJourney,
  journeyList: journey.list,
  config: journey.config,

}))(Root);
