import {IAgentWithAvailability} from "@/typings/models/Assignee";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import {ServiceRequestModel, ServiceRequestStatus} from "@/typings/models/ServiceRequest";

export const WEEKDAY_NAME_MAPPING = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};

export const WEEKDAY_INDEX_MAPPING = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
}

function calculateUnavailableTimes(availability) {
  const dayStart = "00:00";
  const dayEnd = "23:59";
  const unavailableTimes = [];

  const sortedAvailability = availability.sort((a, b) => {
    const [startA] = a.split("-");
    const [startB] = b.split("-");
    return startA.localeCompare(startB);
  });

  const [firstStart] = sortedAvailability[0].split("-");
  if (firstStart > dayStart) {
    unavailableTimes.push(`${dayStart}-${firstStart}`);
  }

  // Add gaps between availability ranges
  for (let i = 0; i < sortedAvailability.length - 1; i++) {
    const [, currentEnd] = sortedAvailability[i].split("-");
    const [nextStart] = sortedAvailability[i + 1].split("-");
    if (currentEnd < nextStart) {
      unavailableTimes.push(`${currentEnd}-${nextStart}`);
    }
  }

  const [, lastEnd] = sortedAvailability[sortedAvailability.length - 1].split("-");
  if (lastEnd < dayEnd) {
    unavailableTimes.push(`${lastEnd}-${dayEnd}`);
  }

  return unavailableTimes;
}

const setHourAndMinute = (date: Date, hour: number, minute: number) =>
  dayjs(date).set('hour', hour).set('minute', minute).set('second', 0).set('millisecond', 0).toDate();

export const convertAgentAvailabilityToUnavailableEvents = (currentDate: Date, agentWithAvailability: IAgentWithAvailability) => {
  try {
    const currentDay = currentDate.getDay();
    const currentDayAvailability = agentWithAvailability?.availability?.working_hours?.[WEEKDAY_NAME_MAPPING[currentDay]];
    if (isEmpty(currentDayAvailability)) return [{
      utc_start_time: setHourAndMinute(currentDate, 0, 0),
      utc_end_time: setHourAndMinute(currentDate, 23, 59),
      assigneeId: agentWithAvailability.id,
      notAvailable: true
    }]

    const unavailableTimes = calculateUnavailableTimes(currentDayAvailability);
    const events = []
    for (const time of unavailableTimes) {
      const [start, end] = time.split("-");
      const startHour = parseInt(start.split(":")[0]);
      const startMinute = parseInt(start.split(":")[1]);

      const endHour = parseInt(end.split(":")[0]);
      const endMinute = parseInt(end.split(":")[1]);

      events.push({
        utc_start_time: setHourAndMinute(currentDate, startHour, startMinute),
        utc_end_time: setHourAndMinute(currentDate, endHour, endMinute),
        assigneeId: agentWithAvailability.id,
        notAvailable: true
      })
    }
    return events
  } catch (e) {
    return []
  }
}

export const getAgentsUnavailableEvents = (currentDate: Date, agentsWithAvailability: IAgentWithAvailability[]) => {
  return agentsWithAvailability.reduce((events, agent) => {
    return events.concat(convertAgentAvailabilityToUnavailableEvents(currentDate, agent));
  }, [])
}


export const processServiceRequestsForCalendar = (serviceRequests: ServiceRequestModel[]) =>
  serviceRequests.map(serviceRequest => ({
    ...serviceRequest,
    utc_start_time: new Date(serviceRequest.utc_start_time),
    utc_end_time: ([
      ServiceRequestStatus.cancelled_by_customer,
      ServiceRequestStatus.declined,
      ServiceRequestStatus.rescheduled,
      ServiceRequestStatus.rescheduled_by_customer,
    ].includes(serviceRequest.status)) ?
      dayjs(serviceRequest.utc_start_time).add(30, 'minute').toDate() :
      new Date(serviceRequest.utc_end_time),
    assigneeId: serviceRequest?.lead?.assignee ? serviceRequest?.lead?.assignee?.id : null,
    isDraggable: ([
      ServiceRequestStatus.tentative,
      ServiceRequestStatus.confirmed,
    ].includes(serviceRequest.status))
  }))
