import {LeadReportItemModel} from "@/typings/models/LeadReportItem";
import {colorMap, CONVERSION_RATE, LEADS, REVENUE, similarColorsMap} from "./constants";
import isEmpty from 'lodash/isEmpty';
import {getMetricOptions} from "@/pages/LeadManagementReports/Performance/constants";


export const exportToCSV = (headers: string[], data, filename: string = 'data.csv', convertedRows = []) => {
  try {
    if (!Array.isArray(headers)) {
      throw new Error('Invalid input: headers should be an array');
    }
    const rows = convertedRows?.length ? convertedRows : Object.keys(data).map(key => headers?.map(header => key[header]));
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n';
    csvContent += rows.map(row => row.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error exporting to CSV:', error);
  }
};
export const exportPerfomanceDataToCSV = (headers: string[], data: any[], filename: string = 'data.csv', convertedRows = []) => {
  try {
    if (!Array.isArray(headers)) {
      throw new Error('Invalid input: headers should be an array');
    }
    if (!Array.isArray(data)) {
      throw new Error('Invalid input: data should be an array');
    }

    const rows = convertedRows?.length ? convertedRows : data.map(item => headers?.map(header => item[header]));
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n';
    csvContent += rows.map(row => row.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error exporting to CSV:', error);
  }
};
export const transformLeadReportDataToBarChartFormat = (data: LeadReportItemModel[], key, selectedColumns: string[], intl) => {
  let transformedData = [];
  data?.forEach((item) => {
    const { label, values } = item;
    const { revenue, count, conversion_rate } = values;
    selectedColumns.some(c => c === REVENUE) && typeof revenue === 'number' && transformedData.push({ [key]: label?.title, category: getMetricOptions().find(m => m.value === REVENUE)?.label, value: revenue });
    selectedColumns.some(c => c === LEADS) && typeof count === 'number' && transformedData.push({ [key]: label?.title, category: getMetricOptions().find(m => m.value === LEADS)?.label, value: count });
    selectedColumns.some(c => c === CONVERSION_RATE) && typeof conversion_rate === 'number' && transformedData.push({
      [key]: label?.title, category: getMetricOptions().find(m => m.value === CONVERSION_RATE)?.label, value: conversion_rate
    });
  });
  return transformedData;
};



export const generateGradientColors = (colorKey: keyof typeof colorMap, length: number): string[] => {
  const similarColors = similarColorsMap[colorKey];
  const colors = [];

  for (let i = 0; i < length; i++) {
    colors.push(similarColors[i % similarColors?.length]);
  }

  return colors;
};

export const convertBreakdownApiResponseToFunnelData = (chartData, hasBreakdown = true, isByPercentage = false) => {
  if (typeof chartData !== 'object' || chartData === null || isEmpty(chartData)) return;

  if (!hasBreakdown) {
    const labels = Object.keys(chartData);
    const values = Object.values(chartData);
    let dataValues = values.map(value => [value]);

    if (isByPercentage) {
      const totalValue = values.reduce((acc, value) => acc + value, 0);
      dataValues = values.map(value => [parseFloat((value / totalValue) * 100).toFixed(2)]);
    }
    const data = {
      labels,
      colors: [['#FFB178']],
      gradientDirection: 'horizontal',
      values: dataValues
    };

    return data;
  }
  const labels =  Object.keys(chartData);
  const subLabels = Object.keys(chartData[labels[0]]);
  const values = Object.keys(chartData)?.map((key: any) => {
    const stepTotal = Object.keys(chartData[key])?.reduce((sum: number, subItemKey: any) => sum + chartData[key][subItemKey], 0);
    let cumulativePercentage = 0;
    const percentages = Object.keys(chartData[key])?.map((subItemKey: any, index: number, array: any[]) => {
      let subItemValue = isByPercentage ? stepTotal !== 0 ? (chartData[key][subItemKey] / stepTotal) * 100 : 0 : chartData[key][subItemKey];
      if (isByPercentage) {
        subItemValue = parseFloat(subItemValue.toFixed(2)); // Round to 2 decimal places
        cumulativePercentage += subItemValue;
        // Adjust the last item to ensure the sum is exactly 100%
        if (index === array?.length - 1) {
          subItemValue += 100 - cumulativePercentage;
        }
      }
      return subItemValue;
    });
    return percentages;
  });

  const colors = subLabels.map((_, index) => generateGradientColors(Object.keys(colorMap)[index] as keyof typeof colorMap, subLabels?.length));

  const data = {
    labels,
    subLabels,
    colors: colors,
    gradientDirection: 'horizontal',
    values,
  };
  return data;
};
