import React, {useEffect, useState} from 'react';
import {useDispatch, useIntl, useSelector} from 'umi';
import {Button, Divider, message, Modal, Space, Steps} from 'antd';
import isEmpty from "lodash/isEmpty";

import * as LeadSelectors from "@/selectors/leadSelectors"
import {LeadCardOrderedProperties, validateLeadField} from "@/components/Lead/LeadProperties";
import {F} from "@/utils/intl";

import styles from './index.less';
import {CheckCircleOutlined, CloseCircleOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import CardHelpTooltip from "@/components/Help/CardHelpTooltip";

const LeadMagicFieldsEditorModal: React.FC = () => {

  const [current, setCurrent] = useState(0);
  const configuration = useSelector(LeadSelectors.selectLeadsMagicFieldEditor)
  const lead = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadData(state, configuration?.leadId))
  const dispatch = useDispatch()
  const intl = useIntl();

  const open = isEmpty(configuration)

  const mandatoryFields = configuration?.magicFields?.mandatory || []
  const optionalFields = configuration?.magicFields?.optional || []

  const fields = [
    ...mandatoryFields,
    ...optionalFields
  ]

  const currentField = fields[current]

  const isLastField = () => current === fields.length - 1
  useEffect(() => {
    if (!lead?.lastEditAt) return

    // Automatically go to next step if the field is already set
    if (!isLastField()){
      if (validateLeadField(lead, currentField))
        setCurrent(prev => prev + 1)
      return
    }

    // Last field, check if all fields are set
    const allFieldsAreValid = fields.every(field => validateLeadField(lead, field))
    if (allFieldsAreValid){
      Modal.confirm({
        title: intl.formatMessage({
          id: 'pages.leads.status.magicFields.modal.success.title',
          defaultMessage: 'All fields are set'
        }),
        content: intl.formatMessage({
          id: 'pages.leads.status.magicFields.modal.success.content',
          defaultMessage: 'Do you want to close the magic field editor?'
        }),
        onOk: toggle,
      })
    }
  }, [lead?.lastEditAt]);

  useEffect(() => {
    setCurrent(0)
  }, [open]);

  function toggle() {
    const isValid = mandatoryFields.every(field => validateLeadField(lead, field))
    if (!isValid) return message.error(intl.formatMessage({
      id: 'pages.leads.status.magicFields.modal.mandatoryFields.errorMessage',
      defaultMessage: "Not all mandatory fields are set."
    }))
    dispatch({
      type: 'leads/closeMagicFieldsEditor',
    });
  }


  return (
    <div className={styles.drawerContainer}>
      <Modal
        width={400}
        title={<Space>
          {intl.formatMessage({
              id: 'pages.leads.status.magicFields.modalTitle',
              defaultMessage: 'Data entry notice',
            })}
          <CardHelpTooltip title={intl.formatMessage({
            id: 'pages.leads.status.magicFields.modalTooltip',
            defaultMessage: 'Description',
          })}/>
        </Space>}
        open={!open}
        destroyOnClose
        onCancel={toggle}
        footer={null}
      >
        {!isEmpty(lead) && <div className={styles.magicFieldEditorContainer}>
          <div style={{display: "flex"}}>

            <Steps
              // progressDot
              current={current}
              onChange={setCurrent}
              direction={"vertical"}
              size={"small"}
              className={styles.magicFieldSteps}
              items={[
                ...mandatoryFields.map((field) => ({
                  icon: validateLeadField(lead, field) ? <CheckCircleOutlined/> : <CloseCircleOutlined/>,
                  title: <Space>
                    <F id={`pages.lead.fields.${field}`}/>
                    <strong style={{fontSize: 20, color: "red"}}>*</strong>
                  </Space>,
                })),
                ...optionalFields.map((field) => ({
                  icon: validateLeadField(lead, field) ? <CheckCircleOutlined/> : <CloseCircleOutlined/>,
                  title: <Space>
                    <F id={`pages.lead.fields.${field}`}/>
                  </Space>
                }))
              ]}
            />
            <div className={styles.magicFieldContent}>
              <LeadCardOrderedProperties spaceSize={24} lead={lead}
                                         overrideVisibleProperties={[currentField]}/>
              <Divider/>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                  <Button size={"small"} type={"ghost"} onClick={() => setCurrent(prev => prev - 1)} disabled={current === 0} icon={<LeftOutlined/>}/>
                  <Button size={"small"} type={"ghost"} onClick={() => setCurrent(prev => prev + 1)} icon={<RightOutlined/>} disabled={isLastField()}/>
              </div>
            </div>

          </div>

        </div>}
      </Modal>
    </div>
  );
};

export default React.memo(LeadMagicFieldsEditorModal, () => true);
