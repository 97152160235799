import React, {useEffect} from "react";
import {ProForm, ProFormText, ProFormTextArea} from "@ant-design/pro-form";
import {LeadModel} from "@/typings/models/Lead";
import {Spin} from "antd";
import {F} from "@/utils/intl";
import {ILocationType} from "conversional-types/src/bookingTypes";


interface MagicAppointmentBookingLeadDataFormProps {
  lead: LeadModel,
  loading: boolean,
  locationType: ILocationType,
  onSubmit: (values: any) => void
}

export default function MagicAppointmentBookingLeadDataForm({lead, onSubmit, locationType, loading = false}: MagicAppointmentBookingLeadDataFormProps) {
  const [form] = ProForm.useForm();

  useEffect(() => {
    form.setFieldsValue(lead?.customer)
  }, [JSON.stringify(lead)])
  return (
    <Spin spinning={loading}>
      <ProForm form={form} style={{margin: "10px 0"}}
               onFinish={async (values) => {
                 onSubmit(values)
               }}
      >
        <ProFormText
          name="firstname"
          label={<F id={"pages.customers.firstName"} defaultMessage={"First Name"}/>}
          rules={[{required: true}]}
        />
        <ProFormText
          name="lastname"
          label={<F id={"pages.customers.lastName"} defaultMessage={"Last Name"}/>}
          rules={[{required: true}]}
        />
        <ProFormText
          name="email"
          label={<F id={"pages.customers.email"} defaultMessage={"E-mail"}/>}
          rules={[{required: true}]}
        />
        <ProFormText
          name="phone"
          label={<F id={"pages.customers.phone"} defaultMessage={"Phone"}/>}
          rules={[{required: true}]}
        />
        <ProFormText
          name={['meta', 'PLZ']}
          label="PLZ"
          rules={[{required: locationType.key === 'home'}]}
        />
        <ProFormText
          name={['meta', 'Wohnort']}
          label="Wohnort"
          rules={[{required: locationType.key === 'home'}]}
        />
        <ProFormTextArea
          name={['meta', 'Adresse']}
          label="Adresse"
          rules={[{required: locationType.key === 'home'}]}
        />
        <ProFormText
          name={['meta', 'message']}
          label="Nachricht"
          rules={[{required: false}]}
        />
      </ProForm>
    </Spin>
  );
}
