import {ProForm, ProFormDatePicker, ProFormText, ProFormTextArea} from "@ant-design/pro-form";
import moment from "moment/moment";
import React from "react";
import {createLeadTaskRequest, editLeadTaskRequest} from "@/services/api/leadTask";
import {useDispatch, useIntl} from "umi";
import { LeadTaskModel } from "@/typings/models/LeadTask";
import { isEmpty } from "lodash";

type ICreateTaskFormProps = {
  leadId: string,
  setLoading: (loading: boolean) => void,
  updateRequest: () => void,
  onTaskCreated: () => void,
  form: any,
  task:LeadTaskModel
}
const CreateTaskForm = (({leadId, form, setLoading, updateRequest, onTaskCreated,task}: ICreateTaskFormProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const handleFinish = async (data) => {
    setLoading(true)
    if (leadId)
      data.leadId = leadId
    if (!isEmpty(task)) {
      data.due_date = moment(data.due_date).format('YYYY-MM-DD[T]00:00:00[Z]');
      editLeadTaskRequest({ data, params: { taskId: task?.id } }).then(() => {
        setLoading(false);
        onTaskCreated();
        updateRequest
          ? updateRequest()
          : dispatch({
              type: 'leadTasks/fetch',
            });
      });
    } else{
    data.due_date = `${data.due_date}T00:00:00Z`

      createLeadTaskRequest({ data }).then(() => {
        setLoading(false);
        onTaskCreated();
        updateRequest
          ? updateRequest()
          : dispatch({
              type: 'leadTasks/fetch',
            });
      });}
  };

  return <ProForm onFinish={handleFinish} submitter={false} form={form}>
    <ProFormText
      name="title"
      label={intl.formatMessage({
        id: 'pages.lead.tasks.taskTitle',
        defaultMessage: 'Title',
      })}
      rules={[{required: true}]}
    />
    <ProFormTextArea
      name="description"
      label={intl.formatMessage({
        id: 'pages.lead.tasks.taskDescription',
        defaultMessage: 'Description',
      })}
      rules={[{required: false}]}
    />
    <ProFormDatePicker
      name="due_date"
      style={{width: '100%'}}
      fieldProps={{
        style: {
          width: '100%',
        },
        disabledDate: (current) => {
          return current && current < moment().startOf('day');
        },
      }}
      label={intl.formatMessage({
        id: 'pages.lead.tasks.taskDue',
        defaultMessage: 'Due Date',
      })}
      rules={[{required: true}]}
    />
  </ProForm>
})

export default CreateTaskForm
