import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useModel, useSelector} from 'umi';
import debounce from "lodash/debounce";

import MyPageContainer from "@/components/MyPageContainer";
import LeadKanbanBoard from "@/components/Kanban/Board";
import Card from "@/components/Card";
import LeadToolbar from "@/components/Lead/LeadToolbar";
import {SOLUTION_KEYS} from "@/constants/solutions";
import * as LeadSelectors from "@/selectors/leadSelectors";
import * as LeadStateSelectors from "@/selectors/leadStateSelectors";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import LeadsTable from "@/components/Kanban/LeadsTable";
import LeadRibbonActions from "@/components/Lead/LeadRibbonActions";
import {FILTER_PLACES} from "@/components/Filters/LeadFilters";
import LeadViewSwitch from "@/components/Lead/LeadViewSwitch";
import TeamViewCalendar from '@/components/Kanban/ServiceRequests/TeamViewCalendar';

import styles from "./index.less"

const TRACKING_LABEL = "Customers"
const REDUX_ACTION = 'leads/fetch'

const Leads: React.FC = () => {
  const {initialState, setInitialState} = useModel('@@initialState');

  const dispatch = useDispatch()
  const teamViewCalendarRef = useRef(null)
  const [localLoading, setLocalLoading] = useState()

  const [tablePayload, setTablePayload] = useState({})
  const {
    leadView,
    filters,
    date,
    loading,
    list,
    states,
    groupedList,
  } =
    useSelector((state: DefaultRootState) => ({
      leadView: PreferencesSelectors.selectLeadView(state),
      filters: PreferencesSelectors.selectFilters(state, FILTER_PLACES.LEAD_LIST),
      list: LeadSelectors.selectLeads(state),
      groupedList: LeadSelectors.selectGroupedLeads(state),
      states: LeadStateSelectors.selectStates(state),
      date: state.date,
      loading: state.loading.effects[REDUX_ACTION],
    }));

  const fetchTableData = useCallback(debounce((payload = tablePayload) => {
    setTablePayload(payload)
    dispatch({
      type: REDUX_ACTION,
      payload,
    });
  }, 200), [])

  const fetchKanbanData = useCallback(debounce((payload = tablePayload) => {
    setTablePayload(payload)
    dispatch({
      type: REDUX_ACTION,
      payload: {
        ...payload,
        group_by: 'lead_state',
      },
    });
  }, 200), [])

  const fetchCalendarData = () => {
    dispatch({
      type: 'serviceRequest/fetchCalendarView'
    })
  }

  const REFRESH_FUNCTIONS = {
    'kanban': fetchKanbanData,
    'table': fetchTableData,
    'calendar': () => {
      teamViewCalendarRef.current?.fetch?.()
    },
  }
  const refresh = REFRESH_FUNCTIONS[leadView] || fetchKanbanData
  useEffect(() => {
    setInitialState({
      ...initialState,
      selectedSolution: SOLUTION_KEYS.LEAD_MANAGEMENT,
      hideFooter: true
    })

    dispatch({
      type: 'leads/fetchAssignees',
    })
    dispatch({
      type: 'leadStates/fetch',
    });
    dispatch({
      type: 'user/getUserBranches',
    });

    setInterval(() => {
      // refresh()
    }, 1000)

    return () => {
      setInitialState({
        ...initialState,
        hideFooter: false
      })
    }
  }, []);
  useEffect(() => {
    refresh()
  }, [date.updatedAt, JSON.stringify(filters)])
  return (
    <MyPageContainer className={styles.leadsPageContainer} label={TRACKING_LABEL}
                     extraProps={{
                       actions: <LeadViewSwitch/>,
                       topActions: [<LeadRibbonActions/>]
                     }}>
      <Card className={styles.leadToolbarCardContainer}>
        <LeadToolbar loading={loading || localLoading} request={refresh}/>
      </Card>
      {leadView === 'kanban' && (
        <LeadKanbanBoard
          loading={loading}
          request={refresh}
          groupedLeads={groupedList?.data || []}
          columns={states}
        />
      )}

      {leadView === 'table' && <LeadsTable list={list} request={refresh} loading={loading}/>}
      {leadView === 'calendar' &&
        // <LeadsCalendar list={calendarViewServiceRequests} request={refresh} loading={calendarLoading}/>
        <TeamViewCalendar ref={teamViewCalendarRef} setLoading={setLocalLoading}/>
      }
    </MyPageContainer>
  );
};

export default Leads;
