import { CameraOutlined, ClockCircleOutlined } from '@ant-design/icons'
import React from 'react'
import { ServiceRequestModel } from '@/typings/models/ServiceRequest'
import { PiCalendarX } from '@/components/Icons/CustomReactIcons'

const EventIcon = ({event, className}: { event: ServiceRequestModel, className?: string }) => {
  const isRescheduled = event.status === 'rescheduled'
  const isCancelled = ['cancelled_by_customer', 'declined'].includes(event.status)
  if (isRescheduled) return <ClockCircleOutlined className={className}/>
  if (isCancelled) return <PiCalendarX className={className}/>

  return <CameraOutlined className={className}/>
}

export default EventIcon
