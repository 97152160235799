import {LeadStateModel} from "@/typings/models/LeadState";

export const sortByKey = (array, key) => {
  return array.sort(function (a, b) {
    const x = a[key];
    const y = b[key];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

export const emptyFunction = () => {};


// TODO: Enhance Duplicate Code with Lightweight Launcher
export const extractPathVariableParts = (schema = "") => {
  const splittedBySlash = schema.split("/").filter(part => part.length > 0);
  return splittedBySlash.map(part => {
    if (part.startsWith(":")) {
      return {
        type: "param",
        value: part.split(":")[1]
      }
    }
    return {
      type: "const",
      value: part
    }
  });
}

type IURLParamBuilderOptions = {
  params?: Record<string, unknown>
  queryString?: Record<string, unknown>,
  root?: string
  thirdParty?: boolean
}

const AddPathVariableFromParams = (params: Record<string, unknown>, part: { type?: string, value?: any }) => params?.[part.value] ? `/${params?.[part.value]}` : ""

const addPathVariableToURL = (url, schemaParts: { type?: string, value?: any }[], params: Record<string, unknown>) => {
  for (const part of schemaParts.filter(part => part.type && part.value)) {
    url += part.type === "param" ? AddPathVariableFromParams(params, part) : `/${part.value}`
  }
  return url

}
const addQueryStringToUrl = (url: string, queryString: Record<string, unknown>) => {
  const queryStringEntries = Object.entries(queryString).filter(([_, value]) => value !== undefined);
  const queryStringString = queryStringEntries.map(([key, value], index) => `${index === 0 ? '?' : '&'}${key}=${value}`).join('');
  url += queryStringString;
  return url

}
const addRootToUrl = (root: string) => {
  if (!root || typeof root !== "string") {
    root = "/";
  }
  if (root?.endsWith('/'))
    root = root?.substring(0, root?.length - 1)

  return root

}

export const URLParamBuilder = (path: string, options: IURLParamBuilderOptions = {}, separateQueryStringsWithSlash = true) => {
  const { queryString = {}, params = {} } = options
  let { root, thirdParty } = options;

  let url = thirdParty ? path : addRootToUrl(root);

  const schemaParts = extractPathVariableParts(path)
  url = addPathVariableToURL(url, schemaParts, params)
  url += separateQueryStringsWithSlash ? "/" : "";

  url =  addQueryStringToUrl(url, queryString)
  return url;
}


export function getExportURL(list: any): string | boolean {
  try {
    return list.links.export || false
  }
  catch(e){
    return false
  }
}

export function capitalizeFirstLetter(str = "") {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeAllLetters(str = "") {
  return str.toUpperCase()
}


export function getInitials(string = "", count = 2){
  try{
    return string.split(" ").slice(0,count).map(str => capitalizeFirstLetter(str[0])).join("")
  }catch(e){
    return "CV"
  }
}

export function extractMagicFieldsFromLeadState(leadState: LeadStateModel): {optional?: string[], mandatory?: string[]} {
  const configuration = leadState?.meta?.magicFields
  const magicFields = {}
  Object.entries(configuration || {}).forEach(([field, magicFieldType]) => {
    if (!magicFields[magicFieldType])
      magicFields[magicFieldType] = []

    magicFields[magicFieldType].push(field)
  })

  return magicFields
}

export function saveChartData(state: any, key: string, payload: any) {
  return {
    ...state,
    [key]: payload.data
  }
}

export const parseSort = (sortString) => {
  const sort_direction = sortString.startsWith('-') ? 'desc' : 'asc';
  const sort_by = sortString.replace('-', '').replace('+', '');
  return {sort_by, sort_direction};
};

export const filterNullUndefined = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) return obj;

  return Object.entries(obj).reduce((acc, [key, value]) => {
    const filteredValue = filterNullUndefined(value); // Recursively process nested objects/arrays

    if (filteredValue !== null && filteredValue !== undefined) {
      acc[key] = filteredValue;
    }

    return acc;
  }, Array.isArray(obj) ? [] : {});
};


export function hexToRgba(hex: string, opacity: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
