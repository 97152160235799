import React from 'react';
import {connect, FormattedMessage as F, useDispatch, useIntl} from 'umi';
import {Button, Popconfirm, Tag, Tooltip} from 'antd';
import type {ProColumns} from "@ant-design/pro-table";
import {DeleteOutlined, EditOutlined, UserAddOutlined} from '@ant-design/icons';

import MyPageContainer from "@/components/MyPageContainer";
import Table from "@/components/Table";
import {UserModel} from '@/typings/models/User';
import {CheckAccess} from "@/utils/access";
import PERMISSIONS from "@/constants/permissions";
import UserManagementTabs from './UserManagementTabs';
import {usePreviouslySelectedSolution} from "@/utils/hooks";

const TRACKING_LABEL = "UserManagement"

type IUserManagementProps = {
  loading?: boolean,
  listData: {
    data: UserModel[],
    total: number,
    links: {
      next: string,
      prev: string,
      first: string,
      last: string,
    }
  }
}

const UserManagement: React.FC<IUserManagementProps> = ({listData, loading}: IUserManagementProps) => {
  const intl = useIntl();
  const dispatch = useDispatch()
  usePreviouslySelectedSolution()

  function fetchTableData(payload?: { search: string }) {
    dispatch({
      type: 'user/getAllUsers',
      payload,
    });
  }

  function handleDeleteUser(userId: string) {
    if (userId != 'null' && userId !== undefined)
      dispatch({
        type: 'user/delete',
        payload: {userId, intl},
      });
  }

  function toggleDrawer() {
    dispatch({
      type: 'user/toggleDrawer',
    });
  }
  function toggleEditDrawer() {
    dispatch({
      type: 'user/toggleEditDrawer'
    });
  }

  const columns: ProColumns<any>[] = [
    {
      title: <F id="pages.users.user" defaultMessage="User" />,
      dataIndex: 'firstname',
      render: (name, record) => [record.firstname, record.lastname].filter(Boolean).join(''),
    },
    {
      title: <F id="pages.users.email" defaultMessage="Email" />,
      dataIndex: 'email',
      responsive: ['xxl', 'xl'],
    },
    {
      title: <F id="pages.users.role" defaultMessage="Role" />,
      dataIndex: 'roles',
      render: (
        roles: { id: string; name: string; description: string | null; permissions: string[] }[],
      ) => (
        <>
          {roles.map((role) => (
            <Tag color="blue" key={role.id}>
              {role.name}
            </Tag>
          ))}
        </>
      ),
      responsive: ['xxl', 'xl'],
    },
    {
      title: <F id="pages.common.actions" />,
      render: (_, user: UserModel) => (
        <>
          <CheckAccess access={[PERMISSIONS.Users.EDIT, PERMISSIONS.BUSINESS_PARTNER]}>
            <Button
              type="link"
              onClick={() => {
                dispatch({
                  type: 'user/getUser',
                  payload: { id: user.id },
                });
                toggleEditDrawer();
              }}
            >
              <EditOutlined />
            </Button>
          </CheckAccess>

          <CheckAccess access={[PERMISSIONS.Users.DELETE, PERMISSIONS.BUSINESS_PARTNER]}>
            <Popconfirm
              onConfirm={() => handleDeleteUser(user.id)}
              placement={'topRight'}
              title={
                <F
                  id={'pages.users.removeUserConfirmation'}
                  defaultMessage="Are you sure you eant to remove the user"
                />
              }
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </CheckAccess>
        </>
      ),
    },
  ];
  return (
    <MyPageContainer label={TRACKING_LABEL}>
      <UserManagementTabs/>
      <Table
        headerTitle={intl.formatMessage({
          id: 'pages.users.tableTitle',
          defaultMessage: 'Users',
        })}
        pagination={true}
        total={listData?.total}
        links={listData?.links}
        totalPages={listData?.total_pages}

        rowKey={'id'}
        columns={columns}
        label={TRACKING_LABEL}
        dataSource={listData?.data}
        loading={loading}
        request={fetchTableData}
        pageSize={listData?.page_size}
        currentPage={listData?.current_page}
        toolBarRender={() => (
          <CheckAccess access={[PERMISSIONS.Users.CREATE, PERMISSIONS.BUSINESS_PARTNER]}>
            <Tooltip title={<F id={'pages.users.newUser'} defaultMessage={'New user'}/>}>
              <UserAddOutlined onClick={toggleDrawer}/>
            </Tooltip>
          </CheckAccess>
        )}
      />
    </MyPageContainer>
  );
};

export default connect(({user, loading}) => ({
  listData: user?.list,
  loading: loading.effects["user/getAllUsers"],
}))(UserManagement);
