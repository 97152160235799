import {useCallback, useEffect, useState} from 'react';
import {Button, Dropdown, Input, Menu, Table} from 'antd';
import isEmpty from 'lodash/isEmpty';
import {CopyOutlined, DeleteOutlined, MoreOutlined, PlusOutlined,} from '@ant-design/icons';
import {useDispatch, useIntl, useSelector} from 'umi';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import './index.less';
import DraggableHeader from './DraggableHeader';
import MyPageContainer from '@/components/MyPageContainer';
import EditableInput from './EditableInput';
import PermissionCell from './PermissionCell';
import {createRoleColumns, generateInitialData, sortCategories} from './utils';
import PermissionCheckbox from './PermissionCheckbox';
import UserManagementTabs from '../UserManagement/UserManagementTabs';
import {usePreviouslySelectedSolution} from "@/utils/hooks";

const RolePermissionTable = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  usePreviouslySelectedSolution()
  const [searchText, setSearchText] = useState('');
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [updateQueue, setUpdateQueue] = useState([]);
  const [processingQueue, setProcessingQueue] = useState(false);

  const { permissionsGroupByCategory, permissionsGroupByRole } = useSelector(
    (state: DefaultRootState) => ({
      loading: state.loading.effects['roles/fetchPermissionListGroupByCategoryRequest'],
      permissionsGroupByCategory: state.roles?.permissionsGroupByCategory,
      permissionsGroupByRole: state.roles?.permissionsGroupByRole,
    }),
  );

  const fetchData = () => {
    dispatch({ type: 'roles/fetchPermissionListGroupByCategoryRequest' });
    dispatch({ type: 'roles/fetchPermissionGroupByRoleRequest' });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isEmpty(permissionsGroupByCategory) || isEmpty(permissionsGroupByRole)) return;
    const initialColumns = [
      {
        title: (
          <Input
            placeholder={intl.formatMessage({
              id: 'search.permissions',
              defaultMessage: 'Search permissions',
            })}
            onChange={(e) => setSearchText(e.target.value)}
            allowClear
          />
        ),
        dataIndex: 'permission',
        key: 'permission',
        fixed: 'left',
        width: 100,

        render: (text, record) => <PermissionCell text={text} description={record.description} />,
      },
    ];

    const roleColumns = createRoleColumns(permissionsGroupByRole.data, createRoleColumn);
    setColumns([...initialColumns, ...roleColumns]);

    const sortedCategories = sortCategories(permissionsGroupByCategory.data);
    const initialData = generateInitialData(sortedCategories, permissionsGroupByRole.data);

    setData(initialData);
  }, [JSON.stringify(permissionsGroupByCategory), JSON.stringify(permissionsGroupByRole)]);

  const createRoleColumn = (roleName, key) => ({
    title: renderRoleHeader(roleName, key),
    dataIndex: key,
    key,
    render: (value, record) =>{


      const shouldDisable = record.isGroup
        ? record[record.group + key + '-locked']
        : record[key + '-locked'];
     return renderCheckbox(value, record.key, key,shouldDisable )
    },
  });

  const renderRoleHeader = (roleName, key) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span style={{ fontSize: 12 }}>
          {intl.formatMessage({ id: 'pages.roles.role', defaultMessage: 'Role' })}
        </span>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item icon={<DeleteOutlined />} onClick={() => deleteRole(key)}>
                {intl.formatMessage({
                  id: 'pages.roles.deleteRole',
                  defaultMessage: 'Delete Role',
                })}
              </Menu.Item>
              <Menu.Item icon={<CopyOutlined />} onClick={() => duplicateRole(roleName, key)}>
                {intl.formatMessage({
                  id: 'pages.roles.duplicateRole',
                  defaultMessage: 'Duplicate Role',
                })}
              </Menu.Item>
            </Menu>
          }
        >
          <MoreOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      </div>
      <EditableInput
        defaultValue={roleName}
        onSubmit={(e) => updateRoleName(key, e.target.value)}
        style={{ marginTop: 4 }}
      />
    </div>
  );



  const updateRoleName = (key, name) => {
    dispatch({
      type: 'roles/updateRole',
      payload: {
        id: key,
        name,
        permissions: permissionsGroupByRole?.data.find((role) => role.id === key)?.permissions,
        intl,
        cb: () => {
          fetchData();
        },
      },
    });
  };


  const addRole = () => {
    const newRoleKey = `role_${Date.now()}`;
    const newRoleName = intl.formatMessage({
      id: 'pages.roles.newRole',
      defaultMessage: 'New Role',
    });
    const newRole = { id: newRoleKey, name: newRoleName, permissions: [] };

    setColumns([...columns, createRoleColumn(newRoleName, newRoleKey)]);
    setData(data.map((row) => ({ ...row, [newRoleKey]: false })));

    dispatch({
      type: 'roles/createRole',
      payload: newRole,
    });
  };

  const deleteRole = (key) => {
    setColumns(columns.filter((col) => col.key !== key));
    setData(
      data.map((row) => {
        const { [key]: _, ...rest } = row;
        return rest;
      }),
    );

    dispatch({
      type: 'roles/deleteRole',
      payload: {
        id: key,
        intl,
        cb: () => {},
      },
    });
  };

  const duplicateRole = (roleName, key) => {
    const newKey = `${key}_copy`;
    const newRoleName = `${roleName} ${intl.formatMessage({ id: 'copy', defaultMessage: 'Copy' })}`;
    const newRole = {
      id: newKey,
      name: newRoleName,
      permissions: permissionsGroupByRole?.data.find((role) => role.id === key)?.permissions || [],
    };

    setColumns([...columns, createRoleColumn(newRoleName, newKey)]);
    setData(data.map((row) => ({ ...row, [newKey]: row[key] || false })));

    dispatch({
      type: 'roles/createRole',
      payload: newRole,
    });
  };


  const filteredData = data.filter((item) =>
    item.permission.toLowerCase().includes(searchText.toLowerCase()),
  );

  const moveColumn = useCallback(
    (dragIndex, hoverIndex) => {
      const updatedColumns = update(columns, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, columns[dragIndex]],
        ],
      });
      setColumns(updatedColumns);
      const reorderedRoles = updatedColumns.slice(1).map((col) => col.key);
      dispatch({
        type: 'roles/reorderRole',
        payload: {
          order: reorderedRoles,
          intl,
          cb: () => {
            fetchData()
          },
        },
      });
    },
    [columns],
  );
  const processQueue = useCallback(() => {
    if (processingQueue || updateQueue.length === 0) return;

    setProcessingQueue(true);

    const [currentUpdate, ...remainingQueue] = updateQueue;
    setUpdateQueue(remainingQueue);

    dispatch({
      type: 'roles/updateRole',
      payload: {
        ...currentUpdate,
        intl,
        cb: (data, success) => setProcessingQueue(false),
      },
    });
  }, [processingQueue, updateQueue, dispatch, intl]);

  useEffect(() => {
    if (!processingQueue) {
      processQueue();
    }
  }, [updateQueue, processingQueue, processQueue]);

  const handlePermissionChange = (rowKey, colKey, checked, justThatRow = false) => {


    const role = permissionsGroupByRole?.data.find((role) => role.id === colKey);
    if (!role) return;

    let updatedPermissions;
    if (!justThatRow) {
      if (rowKey.startsWith('group-')) {
        const category = rowKey.split('group-')[1];
        const categoryPermissions =
          permissionsGroupByCategory?.data[category]?.map((perm) => perm.name) || [];
        if (checked) {
          updatedPermissions = [...new Set([...role.permissions, ...categoryPermissions])];
        } else {
          updatedPermissions = role.permissions.filter(
            (perm) => !categoryPermissions.includes(perm),
          );
        }
      } else {
        updatedPermissions = checked
          ? [...new Set([...role.permissions, rowKey])]
          : role.permissions.filter((perm) => perm !== rowKey);
      }

      setUpdateQueue((prevQueue) => [
        ...prevQueue.filter((update) => update.id !== colKey), // Avoid duplicates
        { ...role, id: colKey, permissions: updatedPermissions },
      ]);

      setData((prevData) => {
        const updatedData = prevData.map((row) => {
          if (row.key === rowKey) {
            return { ...row, [colKey]: checked };
          }
          if (rowKey.startsWith('group-') && row.group === rowKey.split('group-')[1]) {
            return { ...row, [colKey]: checked };
          }
          return row;
        });

        if (!rowKey.includes('group')) {
          const category = `group-${updatedData.find((item) => item.key === rowKey)?.group}`;
          const categoryData = updatedData.filter(
            (item) =>
              item.group === updatedData.find((item) => item.key === rowKey)?.group &&
              !item.isGroup,
          );
          const allChecked = categoryData.every((item) => item[colKey] === true);
          const someChecked = categoryData.some((item) => item[colKey] === true);

          const isChecked = allChecked;
          const isIndeterminate = someChecked && !allChecked;
          handlePermissionChange(
            category,
            colKey,
            isIndeterminate ? 'indeterminate' : isChecked,
            true,
          );
        }
        return updatedData;
      });
    }
    else{
      setData((prevData) => {
        const updatedData = prevData.map((row) => {
          if (row.key === rowKey) {
            return { ...row, [colKey]: checked };
          }
          return row;
        });
        return updatedData;
      });
    }
  };
  const renderCheckbox = (checked, rowKey, colKey, disabled) => (
    <PermissionCheckbox
      disabled={disabled}
      checked={checked}
      rowKey={rowKey}
      colKey={colKey}
      onChange={handlePermissionChange}
    />
  );
  return (
    <MyPageContainer label={'Roles'} extraProps={{ exportTable: {}, hasRangePicker: false }}>
            <UserManagementTabs />

      <DndProvider backend={HTML5Backend}>
        <div style={{ marginBottom: 16 }}>
          <Button icon={<PlusOutlined />} onClick={addRole}>
            {intl.formatMessage({ id: 'pages.roles.addRole', defaultMessage: 'Add Role' })}
          </Button>
        </div>
        <Table
          columns={columns.map((col, index) => ({
            ...col,
            title: <DraggableHeader column={col} index={index} moveColumn={moveColumn} />,
          }))}
          dataSource={filteredData}
          pagination={false}
          rowClassName={(record) => (record.isGroup ? 'group-row' : '')}
          scroll={{ x: 'max-content' }}
        />
      </DndProvider>
    </MyPageContainer>
  );
};

export default RolePermissionTable;
