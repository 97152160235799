import React from "react";
import {Col, ColProps, Row} from "antd";
import classNames from "classnames";

import {LeadDetailsModel} from "@/typings/models/Lead";

import {getLeadStyles} from "@/components/Lead/utils";
import styles from "./index.less"
import {F} from "@/utils/intl";

const LeadPreferencesList = ({leadDetails, grid, className}: {
  leadDetails: LeadDetailsModel,
  grid: ColProps,
  className?: ColProps,
}) => {
  const preferences = getLeadStyles(leadDetails)
  if (preferences.length === 0) return null

  return <div className={classNames(styles.leadPreferencesListContainer, className)}>
    <div className={styles.preferencesTitle}>
      <F id={"pages.lead.preferences"} defaultMessage={"Preferences"}/>
    </div>
    <Row gutter={[16, 16]}>
      {preferences.map((style, index) => (
        <Col {...grid} key={index}>
          <div className={styles.leadDetailsFieldName} style={{marginBottom: 5}}>
            {style.feature}
          </div>
          <div className={styles.leadDetailsFieldValue}>
            {style.parameter}
          </div>
        </Col>
      ))}
    </Row>
  </div>
}

export default LeadPreferencesList
