import {useSortable} from "@dnd-kit/sortable";
import {Row, Spin, Tag} from "antd";
import classNames from "classnames";
import moment from 'moment';

import {useDispatch, useIntl, useSelector} from "umi";
import React, {useEffect, useState} from "react";

import {getKanbanCardStyles} from "@/components/Kanban/utils";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors"
import * as LeadSelectors from "@/selectors/leadSelectors"

import styles from "./styles.less"
import {LeadCardOrderedProperties} from "@/components/Lead/LeadProperties";
import {useLeadCardClick} from "@/components/Kanban/LeadCardContext";
import OpenTaskCountBadge from "@/components/Lead/LeadTasks/OpenTaskCountBadge";

function mergeRefs(refs) {
  return (node) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(node);
      } else if (ref && typeof ref === "object") {
        ref.current = node;
      }
    });
  };
}

type LeadCardProps = {
  disabled?: boolean,
  id: string,
  dragOverlay?: any,
  hideAssignee?: boolean,
  ignoreHeatmap?: boolean,
  hideActions?: boolean
  disableDrag?: boolean
  archive?: boolean,
  overrideVisibleProperties?: string[]
  leadContextMenuProps?: any,
  recalculateCardHeight?: (height?: number) => void
}
const LeadCard = (props: LeadCardProps) => {
  const {
    id,
    dragOverlay,
    archive,
    overrideVisibleProperties,
    leadContextMenuProps = {},
    recalculateCardHeight,
    ignoreHeatmap
  } = props;
  const dispatch = useDispatch()
  const lead = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadData(state, id))
  const [draggable, setDraggable] = useState(true)
  const intl = useIntl()

  const cardRef = React.useRef(null);
  const {shouldIgnoreClick} = useLeadCardClick()
  const loading = false
  const {isHeatMap} =
    useSelector((state: DefaultRootState) => ({
      isHeatMap: PreferencesSelectors.selectIsHeatMap(state),
    }));

  useEffect(() => {
    if (cardRef?.current && recalculateCardHeight) recalculateCardHeight(cardRef.current.clientHeight)
  }, [cardRef?.current]);

  const disableDrag = !draggable || props.disableDrag || props.disabled
  const {
    setNodeRef,
    // setActivatorNodeRef,
    listeners,
    isDragging,
    // isSorting,
    // over,
    // overIndex,
    transform,
    transition,
    attributes
  } = useSortable({
    id: id,
    disabled: disableDrag,
    data: {
      type: "FIELD"
    }
  });

  const getBackgroundColor = () => {
    const currentDate = new Date();
    const modifiedDate = new Date(lead?.modified);
    const dayDiff = Math.floor((currentDate - modifiedDate) / (1000 * 60 * 60 * 24));
    let color;
    if (dayDiff <= 1) {
      color = '#e8f5de';
    } else if (dayDiff <= 3) {
      color = '#f7f4d4';
    } else if (dayDiff <= 7) {
      color = '#f7e2d4';
    } else if (dayDiff <= 14) {
      color = '#f7d4d4';
    } else {
      color = '#f7cccc';
    }
    return color;
  }

  const handleLeadDetails = (e) => {
    if (shouldIgnoreClick) return

    dispatch({
      type: "leads/toggleLeadDetailsDrawer",
      payload: {
        lead
      }
    })
  }
  const handleVisiblePropertiesChange = () => {
    if (recalculateCardHeight) recalculateCardHeight(cardRef.current?.clientHeight);
  }
  if (!lead?.customer) return null
  return (
    <div
      ref={mergeRefs([disableDrag ? null : setNodeRef, cardRef])}
      className={classNames(styles.kanbanCard, {
        [styles.draggable]: !disableDrag,
      }, 'lead-card')}
      style={{
        ...getKanbanCardStyles({
          transform,
          transition,
          dragOverlay,
          isDragging,
        }),
        borderTopColor: lead?.lead_state?.color,
        backgroundColor: isHeatMap ? getBackgroundColor() : null,
      }}
      onClick={handleLeadDetails}
      {...attributes}
      {...listeners}
    >
      <Spin spinning={loading}>
        <OpenTaskCountBadge lead={lead} className={styles.leadTaskBadge}/>
        <LeadCardOrderedProperties onVisiblePropertiesChange={handleVisiblePropertiesChange}
                                   overrideVisibleProperties={overrideVisibleProperties} lead={lead}/>
        {isHeatMap && !ignoreHeatmap && (
          <Row>
            <Tag color="blue">
              {intl.formatMessage(
                {id: 'pages.leads.modified', defaultMessage: 'Modified about {days} days ago'},
                {
                  days: moment().diff(moment(lead.modified), 'days'),
                },
              )}
            </Tag>
          </Row>
        )}
      </Spin>
    </div>
  );
};


export default React.memo(LeadCard, (prevProps, nextProps) => prevProps.id === nextProps.id)
