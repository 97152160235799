import * as React from 'react'
import type { Settings as LayoutSettings } from '@ant-design/pro-layout'
import { PageLoading } from '@ant-design/pro-layout'
import { getDvaApp, history } from 'umi'
import { BreadcrumbItemProps } from 'antd'
import throttle from 'lodash/throttle'
import get from 'lodash/get'
import { registerTheme } from '@antv/g2'

import RightContent from '@/components/RightContent'
import Footer from '@/components/Footer'
import { fetchMeRequest } from '@/services/api/user'
import { FONT_FAMILY } from '@/constants/theme'
import { initializeSentry } from '@/services/sentry'
import { initializeTracker, trackPageView } from '@/services/tracker'
import MenuHeader from '@/components/menu/MenuHeader'
import MenuFooter from '@/components/menu/MenuFooter'
import { initializeLocale } from '@/utils/locale'
import { API } from '@/services/api/typings'
import { BackofficeUserModel } from '@/typings/api/user'
import MenuItem from '@/components/menu/MenuItem'
import { selectCurrentJourneyConfig } from '@/services/selectors'
import { checkUserAccessToPath, getDefaultSolution, getPermissionsSet, getUserSolutions } from './utils/access'

import { getGlobalInitialState, persistGlobalState } from './utils/persistStore'

initializeSentry();
initializeTracker();
initializeLocale();

const loginPath = '/user/login';

/** When obtaining user information is slow, one will be displayed loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};
registerTheme('cv', {
  fontFamily: FONT_FAMILY,
  defaultColor: 'red',
});

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<IInitialState & {
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  selectedSolution?: string;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
}> {
  const fetchUserInfo = async (): Promise<BackofficeUserModel> => {
    try {
      const APICall = await fetchMeRequest();
      const status = get(APICall, 'response.status');
      const data = get(APICall, 'data');
      if (status === 200) {
        if (window.location.pathname === "/") {
          history.push("/check");
        }
        return data;
      } else {
        history.push(loginPath);
      }
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };
  // If it is a login page, do not execute
  const { location: { pathname } } = history;
  if (!pathname.includes('/user/') && !pathname.includes('/set-password/') && !pathname.includes("/mimic")) {
    const currentUser = await fetchUserInfo();
    if (currentUser)
      currentUser.permissionsSet = getPermissionsSet(currentUser)
    const solutions = getUserSolutions(currentUser)
    const selectedSolution = getDefaultSolution(currentUser)
    return {
      fetchUserInfo,
      currentUser,
      solutions,
      selectedSolution,
      settings: {},
    };
  }
  // TODO: specify default solution or if the user should only see one of them

  return {
    fetchUserInfo,
    selectedSolution: 'leadGeneration',
    settings: {},
  };
}

type IInitialState = {
  collapsed?: boolean,
  hideFooter?: boolean,
  disableContentMargin?: boolean,
  config?: any,
  settings?: Record<string, unknown>,
  currentUser?: BackofficeUserModel,
  selectedSolution?: string,
  breadcrumb?: BreadcrumbItemProps[],
  solutions: []
}

type ILayoutFunctionProps = {
  initialState: IInitialState,
  currentUser: BackofficeUserModel,
  setInitialState: (state: IInitialState) => void
}

const filterMenu = (initialState: IInitialState, menuData) => {
  const user = initialState?.currentUser;
  const selectedSolution = initialState.selectedSolution;
  const journeyConfig = selectCurrentJourneyConfig(window.getState());

  return menuData.filter(menu => {
    const { path, solution, journeyConfigAccessKey } = menu;

    const hasUserAccess = checkUserAccessToPath(user, path);
    if (!hasUserAccess) return false;

    if (solution && selectedSolution !== solution) return false;

    if (journeyConfigAccessKey) {
      return get(journeyConfig, journeyConfigAccessKey);
    }

    return true;
  });
};

const processMenuItems = (initialState, menuItems) => {
  const user = initialState?.currentUser?.User
  const processedMenuItems =  menuItems.map((item) => {

    // Deprecated functionality after permissions v1

    // const customDisplayName = getRoleCustomSidebarMenuDisplayName(user, item.path);
    // if (customDisplayName) {
    //   item.locale = customDisplayName;
    // }
    return item;
  });
  return filterMenu(initialState, processedMenuItems)
};
// ProLayout Supported api https://procomponents.ant.design/components/layout
export const layout = ({ initialState, setInitialState }: ILayoutFunctionProps) => {
  return {
    rightContentRender: () => <RightContent />,
    siderWidth: 300,
    disableContentMargin: !!initialState?.disableContentMargin,
    menuHeaderRender: (logo, title) => <MenuHeader logo={logo} />,
    menuFooterRender: () => <MenuFooter />,
    onMenuHeaderClick: () => {},
    footerRender: () => !initialState.hideFooter && <Footer />,
    onPageChange: () => {
      if (window.innerWidth < 992) {
        if (!initialState.collapsed) {
          setInitialState({
            ...initialState,
            collapsed: true,
          });
        }
      }
      trackPageView();
      const { location } = history;
      // If not logged in, redirect to login
      if (!initialState?.currentUser && !location.pathname.includes('/user/') && !location.pathname.includes('/set-password/')) {
        history.push(loginPath);
      }
    },
    onCollapse: (collapsed) =>
      setInitialState({
        ...initialState,
        collapsed,
      }),
    postMenuData: (menuData) => processMenuItems(initialState, menuData),
  //  menuItemRender: (item, dom) => <MenuItem  item={item}>{dom}</MenuItem>,
  menuItemRender: (item, dom) => {
    const isDisabled = item.disabled; // Check if the item is disabled
    return (
      <div
        key={item.name}
        style={{ pointerEvents: isDisabled ? 'none' : 'auto', opacity: isDisabled ? 0.8 : 1 }}
      >
        <MenuItem item={item}>{dom}</MenuItem>
      </div>
    );
  },
    headerContentRender: () => <></>,
    collapsedButtonRender: false,
    collapsed: initialState?.collapsed,
    ...initialState?.settings,
    journeyConfig: initialState?.config,
  };
};


const throttledOnStateChange = throttle(state => {
  persistGlobalState(state)
}, 500, {
  trailing: true
})

export const dva = {
  config: {
    initialState: getGlobalInitialState(),
    onStateChange(state) {
      throttledOnStateChange(state);
    },
    onError: () => {},
  },
};

window.g_app = () => getDvaApp();
window.getState = () => getDvaApp()._store.getState();

const mockConsoleMethod = (realConsoleMethod) => {
  const ignoredMessages = [
    '[React Intl]',
    '"key" prop',
  ];

  return (message = '', ...args) => {
    const containsIgnoredMessage = ignoredMessages.some(
      (ignoredMessage) => typeof message === 'string' && message.includes(ignoredMessage),
    );

    if (!containsIgnoredMessage) {
      realConsoleMethod(message, ...args);
    }
  };
};

console.warn = mockConsoleMethod(console.warn);
console.error = mockConsoleMethod(console.error);
