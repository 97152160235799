import React, {useEffect, useState} from 'react';
import {useDispatch, useIntl, useSelector} from 'umi';
import {Button, Divider, Form, Input, message, Popover, Row, Space, Typography} from 'antd';
import isEmpty from 'lodash/isEmpty';
import ProForm, {ProFormSelect, ProFormSwitch} from '@ant-design/pro-form';

import Drawer from '@/components/Drawer';
import DrawerSection from '@/components/Drawer/DrawerSection';
import MapWithAutocomplete from '../../AutoComplete/MapWithAutocomplete';
import {BranchModel} from '@/typings/models/Location';
import ServiceForm from './Service';
import styles from './index.less';
import * as JourneySelectors from "@/selectors/journeySelectors";
import getLocationTypes from '@/constants/locationTypes';
import LocationAgentsForm from './LocationAgentsForm';
import {fetchTimeZone} from '@/components/AutoComplete/util';

const DRAWER_WIDTH = Math.max(0.4 * window.innerWidth, 520);

const BranchDrawer: React.FC = () => {
  const [newBranchFormData, setNewLocationFormData] = useState<BranchModel>(undefined);
  const [showChooseServiceType, setShowChooseServiceType] = useState(false);
  const [selectedNewServiceType, setSelectedNewServiceType] = useState<string | null>(null);
  const [timeZone, setTimeZone] = useState<string | null>(null);

  const journeys = useSelector(JourneySelectors.selectConversionalJourneys);
  const {
    visible,
    branch,
    createLocationLoading,
    selectedService,
    selectedBranch,
    fetchLocationLoading,
    newService,
    timezones,
    branchStaffList
  } = useSelector(
    ({
      location: {
        branchDrawer,
        selectedBranch,
        changeLoadings,
        selectedService,
        newService,
        timezones,
        branchStaffList
      },
      loading,
    }: DefaultRootState) => ({
      visible: branchDrawer.visible,
      branch: branchDrawer.branch,
      createLocationLoading: loading.effects['location/createBranch'],
      fetchLocationLoading: loading.effects['location/getBranch'],
      selectedBranch,
      selectedService,
      loadings: changeLoadings,
      newService,
      timezones,
      branchStaffList
    }),
  );

  const dispatch = useDispatch();
  const intl = useIntl();
  const [branchForm] = Form.useForm();


  const handleNewServiceTypeClick = (option: string) => {
    setSelectedNewServiceType(option);
    onCreateService(option)
    setShowChooseServiceType(false);
    setNewServiceData({ service_type: option });
  };

  const setNewServiceData = (data) => {
    dispatch({
      type: 'location/setNewService',
      payload: { ...newService, ...data },
    });
  }

  const toggle = () => {
    setSelectedNewServiceType('');
    dispatch({
      type: 'location/setNewService',
      payload: {},
    });
    dispatch({
      type: 'location/toggleBranchDrawer',
    });
    dispatch({
      type: 'location/setBranchStaffList',
      payload:[],
    });
  };

  const resetStateAndFormFields = () => {
    branchForm.resetFields();
    setSelectedNewServiceType(null);
    dispatch({
      type: 'location/setBranch',
      payload: {},
    });
    dispatch({
      type: 'location/setService',
      payload: {},
    });
    branchForm.setFieldsValue({
      name: '',
      is_active: true,
    });
  };
  useEffect(() => {
    if(timeZone)
    branchForm.setFieldsValue({
      timezone: timeZone,
    });
  }, [timeZone]);
  const fetchBranchStaff = (branchId: string) => {
    dispatch({
      type: 'location/fetchBranchStaff',
      payload: {
        branchId,
      },
    });

  }
  const setBranchToDrawer = (branch) => {
    dispatch({
      type: 'location/setBranchToDrawer',
      payload: {
        branch,
      },
    });

  }
  const fetchBranch = (branchId: string) => {
    dispatch({
      type: 'location/getBranch',
      payload: {branchId: branchId},
    });

  }


  useEffect(() => {
    if (!visible || isNewBranch()) {
      resetStateAndFormFields();
    } else {
      dispatch({
        type: 'location/fetchAgents',
        payload: {
          branchId: branch?.id,
        },
      });
    }
  }, [visible]);

  useEffect(() => {
    branchForm.setFieldsValue({
      name: branch?.name,
      is_active: isNewBranch() ? true : branch.is_active,
    });
    if (!isEmpty(selectedBranch) && selectedBranch.id) {
      fetchBranchStaff(selectedBranch.id);
      fetchBranchTimeZone()
      branchForm.setFieldsValue({
        lead_source_id: selectedBranch?.lead_source?.id,
        timezone:selectedBranch?.timezone
      });
      selectedBranch?.lead_source?.id && fetchJournalConfig(selectedBranch?.lead_source?.id);
    }
  }, [selectedBranch, branchForm, branch]);

  const fetchJournalConfig = (journeyId: string) => {
    dispatch({
      type: 'journey/fetchConfig',
      payload: { journeyId: journeyId },
    });
  };
  const fetchBranchTimeZone = async ()=>{
    if (selectedBranch.latitude && selectedBranch.longitude && selectedBranch?.timezone === null) {
      const timeZone = await fetchTimeZone(selectedBranch.latitude, selectedBranch.longitude);
      setTimeZone(timeZone);
    }
  }


  const isBranchDataValid = () => newBranchFormData.name && newBranchFormData.address;

  const onCreateBranch = () => {
    if (!isBranchDataValid()) {
      message.error(
        intl.formatMessage({
          id: 'pages.common.form.validationError',
        }),
      );
      return;
    }
    dispatch({
      type: 'location/createBranch',
      payload: {
        ...newBranchFormData,
      },
      intl,
      cb: (data , success) => {
        if(success)
       { fetchBranchStaff(data?.branch?.id);
       fetchBranch(data?.branch?.id);
       setBranchToDrawer(data?.branch);
}
      },
    });
  };

  const isNewBranch = () => branch.id === 'NEW';

  const onAddressChange =  (_, values) => {

    if (!isNewBranch()) return;
    setNewLocationFormData({ ...newBranchFormData, ...values });
  };

  const handleFormChange = (changedValues, values) => {
    if (isNewBranch()) {
      setNewLocationFormData({
        ...newBranchFormData,
        ...values,
      });
    } else {
      const [field] = Object.keys(changedValues);
      onValueChange(field, changedValues[field]);
    }
  };
function onCreateService(serviceType="") {
    dispatch({
      type: 'location/createService',
      payload: {
        cb: (serviceType: string) => {},
        data: {
          branch_id: selectedBranch?.id,
          service_type: serviceType,
        },
      },
    });
  }
  const onValueChange = (name: string, value: any) => {
    if (isNewBranch()) return;
    const payload =
      name === 'branchAddress'
        ? { branchId: branch.id, ...value }
        : { branchId: branch.id, [name]: value };
    dispatch({
      type: 'location/updateBranch',
      payload: {
        ...payload,
      },
    });
  };

  const getFooter = () => (
    <div style={{ textAlign: 'right' }}>
      <Space>
        <Button
          className={'button-secondary button-bordered'}
          size={'large'}
          type={'primary'}
          onClick={toggle}
        >
          {intl.formatMessage({ id: 'app.settings.close', defaultMessage: 'Close' })}
        </Button>
        {isNewBranch() && (
          <Button
            size={'large'}
            loading={createLocationLoading}
            onClick={onCreateBranch}
            type={'primary'}
          >
            {intl.formatMessage({ id: 'pages.location.add', defaultMessage: 'Add' })}
          </Button>
        )}
      </Space>
    </div>
  );
  const branchHasAllServiceTypesCreated = () => {
    return ["home","store","online"].every((serviceType) => isServiceCreatedForBranch(serviceType));
  }

  const isServiceCreatedForBranch = (serviceType: string) => {
    return selectedBranch?.services?.some((service) => service.service_type === serviceType);
  };

  const title = !isNewBranch()
    ? `${intl.formatMessage({
        id: 'pages.locations.branchDetails',
        defaultMessage: 'Branch Details',
      })} - ${branch?.name} `
    : intl.formatMessage({ id: 'pages.locations.addBranch', defaultMessage: 'Add Branch' });

  const popoverContent = (
    <div>
      <Space>
        {getLocationTypes()
          .filter((t) => !isServiceCreatedForBranch(t.value))
          .map((option) => (
            <Row key={option.value}>
              <Button
                type={selectedNewServiceType === option.value ? 'primary' : 'default'}
                onClick={() => handleNewServiceTypeClick(option.value)}
              >
                {option.label}
              </Button>
            </Row>
          ))}
      </Space>
    </div>
  );
  function isNewService() {
    return selectedService?.id === undefined;
  }
  const updateServiceAgents = (serviceData, id=null) => {
    if (isNewService()) return;
    dispatch({
      type: 'location/updateService',
      payload: {
        fetchBranch: false,
        id: isEmpty(id) ? selectedService?.id : id,
        intl: intl,
        cb: (data, success) => {

        },
        data: serviceData,
      },
    });
  };

  const newServiceChange = (serviceData) => {
    dispatch({
      type: 'location/setNewService',
      payload: { ...newService, ...serviceData },
    });
  };
  return (
    <div className={styles.drawerContainer}>
      <Drawer
        width={DRAWER_WIDTH}
        footer={getFooter()}
        title={<DrawerTitle title={title} />}
        visible={visible}
        destroyOnClose
        onVisibleChange={toggle}
      >
        <ProForm
          disabled={fetchLocationLoading}
          form={branchForm}
          submitter={false}
          onValuesChange={handleFormChange}
          initialValues={isNewBranch() ? { is_active: true } : branch}
        >
          <DrawerSection title={''}>
            <Form.Item
              label={intl.formatMessage({
                id: 'pages.location.branchName',
                defaultMessage: 'Branch Name',
              })}
              required
              name={'name'}
            >
              <Input
                onChange={(e) => {
                  const nameValue = e.target.value;
                  branchForm.setFieldsValue({ name: nameValue });
                  if (isNewBranch()) {
                    setNewLocationFormData({ ...newBranchFormData, name: nameValue });
                  } else {
                    onValueChange('name', nameValue);
                  }
                }}
              />
            </Form.Item>

            <ProFormSelect
              disabled={journeys?.length === 0 || fetchLocationLoading}
              label={intl.formatMessage({
                id: 'pages.leads.filters.leadSource.placeholder',
                defaultMessage: 'Lead Source',
              })}
              name={'lead_source_id'}
              tooltip={intl.formatMessage({
                id: 'pages.locations.optionalLeadSourceTooltip',
                defaultMessage:
                  'Optional. Pick only if the location should only show in a specific Journey UI.',
              })}
              mode="single"
              style={{ width: '100%' }}
              placeholder={intl.formatMessage({
                id: 'pages.locations.leadSource.selectPlaceholder',
                defaultMessage: 'Please select lead source',
              })}
              options={(journeys || []).map((j) => ({
                value: j.hashId,
                label: j.name,
              }))}
            />

            <MapWithAutocomplete
              addressModel={{
                address: branch.address,
                latitude: branch.latitude,
                longitude: branch.longitude,
              }}
              onChange={async (addressValues) => {
                if (addressValues.latitude && addressValues.longitude) {
                  const timeZone = await fetchTimeZone(
                    addressValues.latitude,
                    addressValues.longitude,
                  );
                  setTimeZone(timeZone);
                }
                isNewBranch()
                  ? onAddressChange(undefined, addressValues)
                  : onValueChange('branchAddress', addressValues);
              }}
              hasTimeZones
            />
            <ProFormSwitch
              label={intl.formatMessage({
                id: 'pages.locations.branchStatus',
                defaultMessage: 'Branch Status',
              })}
              required={isNewBranch()}
              hasFeedback
              name={'is_active'}
              checkedChildren={intl.formatMessage({
                id: 'pages.common.active',
                defaultMessage: 'Active',
              })}
              unCheckedChildren={intl.formatMessage({
                id: 'pages.common.inActive',
                defaultMessage: 'Inactive',
              })}
              fieldProps={{
                onChange: (value) => {
                  onValueChange('is_active', value);
                },
              }}
            />
          </DrawerSection>
        </ProForm>
        <Divider />
        {!isNewBranch() && (
          <DrawerSection
            title={intl.formatMessage({
              id: 'pages.locations.agents',
              defaultMessage: 'Assign Sales Agents',
            })}
          >
            <LocationAgentsForm
              branchStaff={branchStaffList?.data || []}
              onChange={isNewService() ? newServiceChange : updateServiceAgents}
              selectedService={selectedService}
              branch={selectedBranch}
            />
          </DrawerSection>
        )}
        {!isNewBranch() && (
          <DrawerSection
            title={
              <div className={styles.serviceHeader}>
                <Typography.Text className={styles.serviceTitle}>
                  {intl.formatMessage({
                    id: 'pages.locations.services',
                    defaultMessage: 'Services',
                  })}
                </Typography.Text>
                {!branchHasAllServiceTypesCreated() && (
                  <Popover
                    content={popoverContent}
                    title={null}
                    trigger="click"
                    open={showChooseServiceType}
                    onOpenChange={setShowChooseServiceType}
                    placement="bottom"
                  >
                    <Button type="link" style={{ color: '#27AE9D', fontSize: 14, fontWeight: 600 }}>
                      {`+ ${intl.formatMessage({
                        id: 'pages.locations.addNewService',
                        defaultMessage: 'Add New Service',
                      })}`}
                    </Button>
                  </Popover>
                )}
              </div>
            }
          >
            <div className={styles.serviceFormContainer}>
              <ServiceForm
                services={selectedBranch?.services}
                branch={selectedBranch}
                selectedNewServiceType={selectedNewServiceType}
              />
            </div>
          </DrawerSection>
        )}
      </Drawer>
    </div>
  );
};

type DrawerTitleProps = {
  title: string;
};

const DrawerTitle: React.FC<DrawerTitleProps> = ({ title }) => {
  return (
    <div className={styles.drawerTitle} title={title}>
      {title}
    </div>
  );
};

export default BranchDrawer;
