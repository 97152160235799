import React from "react";
import styles from "../index.less";
import {Space} from "antd";
import {MailOutlined, PhoneOutlined} from "@ant-design/icons";
import LeadName from "@/components/Lead/LeadName";
import LeadEmail from "@/components/Lead/LeadEmail";
import LeadPhone from "@/components/Lead/LeadPhone";

const CalendarHeaderAgentOverviewPopover = ({agent = {}}: { }) => {

  const onAgentClick = () => {}
  return <div className={styles.eventLeadOverviewPopoverContent}>
    <div className={styles.leadNameContainer} onClick={onAgentClick}>
      <LeadName nameClassName={styles.leadName} avatarClassName={styles.leadAvatar} lead={{customer: agent}} readonly/>
    </div>
    <div>
      <Space>
        <MailOutlined/>
        <LeadEmail lead={{customer: agent}} readonly/>
      </Space>
    </div>
    <div>
      <Space>
        <PhoneOutlined/>
        <LeadPhone lead={{customer: agent}} readonly/>
      </Space>
    </div>
    {/*<div>*/}
    {/*  <Space>*/}
    {/*    <Space>*/}
    {/*      <CalendarOutlined/>*/}
    {/*      <span>Appointments Today</span>*/}
    {/*    </Space>*/}
    {/*    <span>10</span>*/}
    {/*  </Space>*/}
    {/*</div>*/}
    {/*<div>*/}
    {/*  <Space>*/}
    {/*    <Space>*/}
    {/*      <IoLanguageOutline className={'anticon'}/>*/}
    {/*      <span>Language</span>*/}
    {/*    </Space>*/}
    {/*    <span>{languages['en-GB'].icon}</span>*/}
    {/*  </Space>*/}
    {/*</div>*/}

  </div>
}

export default CalendarHeaderAgentOverviewPopover;
