import React from "react";
import {Popover} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";

import LeadName from "@/components/Lead/LeadName";

import styles from "../index.less";
import CalendarHeaderAgentOverviewPopover
  from "@/components/Kanban/ServiceRequests/Calendar/CalendarHeaderAgentOverviewPopover";

const CalendarHeaderAgent = ({resource = {}}) => {
  return (
    <div className={styles.calendarHeaderAgent}>
      <LeadName className={styles.agentContainer} showFlag={false} nameClassName={styles.calendarHeaderAgentName} lead={{customer: resource}} readonly/>
      <Popover placement={"rightTop"} content={<CalendarHeaderAgentOverviewPopover agent={resource}/>}>
        <EllipsisOutlined className={styles.options}/>
      </Popover>
    </div>
  );
};

export default CalendarHeaderAgent;
