import React, {useEffect, useState} from 'react';
import {FormattedMessage as F, useIntl, useModel} from 'umi';
import MyPageContainer from '@/components/MyPageContainer';
import {SOLUTION_KEYS} from "@/constants/solutions";
import Card from "@/components/Card";
import {Tabs} from "antd";
import EmailAutomationList from "@/pages/Automation/EmailAutomation";
import TaskAutomationList from "@/pages/Automation/TaskAutomation";

const {TabPane} = Tabs

const TABS = {
  EMAIL_AUTOMATION: 'email',
  TASK_AUTOMATION: 'task',
};

const CONTENT = {
  [TABS.EMAIL_AUTOMATION]: EmailAutomationList,
  [TABS.TASK_AUTOMATION]: TaskAutomationList,
}

const TRACKING_LABEL = 'Email Automation';
const REDUX_ACTION = 'automation/fetchEmailAutomationList'
const AutomationList = () => {
  const intl = useIntl();
  const {initialState, setInitialState} = useModel('@@initialState');
  const [activeKey, setActiveKey] = useState(TABS.TASK_AUTOMATION)
  useEffect(() => {
    setInitialState({
      ...initialState,
      selectedSolution: SOLUTION_KEYS.LEAD_MANAGEMENT,
    })
  }, [])
  const Component = CONTENT[activeKey]
  return (
    <MyPageContainer label={TRACKING_LABEL} extraProps={{exportTable: {}, hasRangePicker: false}}>
      <div className={'page-tab-navigation'}>
        <Card>
          <Tabs defaultActiveKey={TABS.EMAIL_AUTOMATION} activeKey={activeKey} onChange={setActiveKey}>
            <TabPane
              tab={
                <a><F id="pages.leads.tasks.automation" defaultMessage="Task"/></a>
              }
              key={TABS.TASK_AUTOMATION}
            />
            <TabPane
              tab={
                <a><F id="pages.emailAutomation.headline" defaultMessage="Email"/></a>
              }
              key={TABS.EMAIL_AUTOMATION}
            />
          </Tabs>
        </Card>
      </div>
      <Component/>
    </MyPageContainer>
  );
};

export default AutomationList;
