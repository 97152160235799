import React from 'react';
import {Tabs} from 'antd';
import {FormattedMessage as F, NavLink, withRouter} from 'umi';
import Card from '@/components/Card';

const TABS = {
  USERS_LIST: {
    path: 'usermanagement',
    KEY: '1',
  },
  ROLES_RIGHTS: {
    path: 'RolesAndRights',
    KEY: '2',
  }

};
const { TabPane } = Tabs;
const UserManagementTabs: React.FC = ({ location }) => {
  function getActiveKey() {
    const { pathname } = location;
    if (pathname.includes(TABS.USERS_LIST.path)) return TABS.USERS_LIST.KEY;
    if (pathname.includes(TABS.ROLES_RIGHTS.path)) return TABS.ROLES_RIGHTS.KEY;
  }

  // TODO: Do The Rest
  return (
    <div className={"page-tab-navigation"}>
      <Card>
        <Tabs defaultActiveKey={TABS.USERS_LIST.KEY} activeKey={getActiveKey()} onChange={() => {}}>
          <TabPane
            tab={
              <NavLink to={`/${TABS.USERS_LIST.path}`}>
                <F id='component.userManagement.userList' defaultMessage="Profile" />
              </NavLink>
            }
            key={TABS.USERS_LIST.KEY}
          />

          <TabPane
            tab={
              <NavLink to={`/${TABS.ROLES_RIGHTS.path}`}>
                <F id="component.userManagement.rolesAndRights" defaultMessage="Roles and Rights" />
              </NavLink>
            }
            key={TABS.ROLES_RIGHTS.KEY}
          />
        </Tabs>
      </Card>
    </div>
  );
};

export default withRouter(UserManagementTabs);
