import React, {useEffect, useRef, useState} from 'react';
import MyPageContainer from "@/components/MyPageContainer";
import {Card} from "antd";
import TeamViewCalendar from "@/components/Kanban/ServiceRequests/TeamViewCalendar";
import styles from "@/pages/Leads/index.less";
import LeadToolbar from "@/components/Lead/LeadToolbar";
import {SOLUTION_KEYS} from "@/constants/solutions";
import {useModel} from "umi";
import {LEAD_VIEWS} from "@/components/Lead/constants";


const Calendar: React.FC = () => {
  const {initialState, setInitialState} = useModel('@@initialState');

  const teamViewCalendarRef = useRef(null)
  const [localLoading, setLocalLoading] = useState()
  useEffect(() => {
    setInitialState({
      ...initialState,
      selectedSolution: SOLUTION_KEYS.LEAD_MANAGEMENT,
      collapsed: true,
      hideFooter: true
    })
    return () => {
      setInitialState({
        ...initialState,
        hideFooter: false
      })
    }
  }, []);

  return (
    <MyPageContainer>
      <Card className={styles.leadToolbarCardContainer}>
        <LeadToolbar loading={localLoading} request={() => teamViewCalendarRef.current?.fetch?.()} overrideLeadView={LEAD_VIEWS.CALENDAR}/>
      </Card>
      <TeamViewCalendar ref={teamViewCalendarRef} setLoading={setLocalLoading}/>
    </MyPageContainer>
  );
};

export default Calendar
