import * as React from 'react';
import {ReactElement, useEffect} from 'react';
import {useModel} from 'umi';
import {checkUserAccessToPath} from '@/utils/access';
import ForbiddenPage from '@/pages/403';
import {normalizePath} from '@/utils/pathUtils';
import {useHistory} from 'react-router-dom';

interface AccessControlWrapperProps {
  children?: ReactElement | null;
  route: any;
  isUserLoggedIn: boolean;
}

const AccessControlWrapper: React.FC<AccessControlWrapperProps> = ({
                                                                     children,
                                                                     route,
                                                                   }: AccessControlWrapperProps) => {
  const {path} = route;
  const history = useHistory();
  const {initialState, setInitialState} = useModel('@@initialState');
  const user = initialState?.currentUser

  const isLoggedIn = Boolean(user)

  useEffect(() => {
    if (isLoggedIn) return;

    history.push('/user/login');

  }, [isLoggedIn, history]);

  if (!isLoggedIn) return null;
  if (checkUserAccessToPath(user, normalizePath(path))) return children;
  return <ForbiddenPage/>;
};

export default AccessControlWrapper;
