const PERMISSIONS = {
  All: {
    ALL: '*',
  },
  BUSINESS_PARTNER: '+*',
  Analytics: {
    VIEW: 'analytics_view',
  },
  Billing: {
    VIEW: 'billing_view',
  },
  Permissions: {
    VIEW: 'permissions_view',
    EDIT:'permissions_edit',
  },
  Customers: {
    VIEW: 'customer_view',
    EDIT: 'customer_edit',
    LIKES_VIEW: 'customer_likes_view',
  },
  LeadStates: {
    VIEW: 'leadStates_view',
    CREATE: 'leadStates_create',
    EDIT: 'leadStates_edit',
    DELETE: 'leadStates_delete',
  },
  Leads: {
    VIEW: 'leads_view',
    VIEW_ALL: 'leads_view_all',
    CREATE: 'leads_create',
    EDIT: 'leads_edit',
    ASSIGN: 'leads_assign',
    CHANGE_BRANCH: 'leads_change_branch',
    // New
    ARCHIVE: 'leads_archive',
  },
  Tasks: {
    VIEW: 'tasks_view',
    CREATE: 'tasks_create',
    EDIT: 'tasks_edit',
  },
  Locations: {
    VIEW: 'locations_view',
    CREATE: 'locations_create',
    EDIT: 'locations_edit',
    DELETE: 'locations_delete',
    MANAGE: 'branches_manage'
  },
  Roles: {
    VIEW: 'roles_view',
    CREATE: 'roles_create',
    EDIT: 'roles_edit',
    DELETE: 'roles_delete',
  },
  Users: {
    VIEW: 'users_view',
    CREATE: 'users_create',
    DELETE: 'users_delete',
    EDIT: 'users_edit',
  },
  BranchServices: {
    VIEW: 'branchService_view',
    CREATE: 'branchService_create',
    EDIT: 'branchService_edit',
    DELETE: 'branchService_delete',
    AGENTS_VIEW: 'branchService_agents_view',
  },
  ServiceRequests: {
    VIEW: 'serviceRequests_view',
    CREATE: 'serviceRequests_create',
    EDIT: 'serviceRequests_edit',
    RESCHEDULE: 'serviceRequests_reschedule',
    STATS: 'serviceRequests_stats',
    CANCEL: 'serviceRequests_cancel',
  },
  Today: {
    VIEW: 'todays_tasks_view',
  },
  EmailAutomation: {
    VIEW: 'emailAutomations_view',
    EDIT: 'emailAutomations_edit',
    CREATE: 'emailAutomations_create',
    DELETE: 'emailAutomations_delete',
  },
  LeadSource: {
    VIEW: 'leadSources_view',
    EDIT: 'leadSources_edit',
    CREATE: 'leadSources_create',
    DELETE: 'leadSources_delete',
  }
};

export default PERMISSIONS;
