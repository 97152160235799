// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/opt/atlassian/pipelines/agent/build/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelAnalytics0 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/analytics.ts';
import ModelAutomation1 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/automation.ts';
import ModelBilling2 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/billing.ts';
import ModelCustomer3 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/customer.ts';
import ModelDate4 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/date.ts';
import ModelImage5 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/image.ts';
import ModelJourney6 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/journey.ts';
import ModelLead7 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/lead.ts';
import ModelLeadsAnalytics8 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/leadsAnalytics.ts';
import ModelLeadSource9 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/leadSource.ts';
import ModelLeadState10 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/leadState.ts';
import ModelLeadTask11 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/leadTask.ts';
import ModelLocation12 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/location.ts';
import ModelPeriodSelector13 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/periodSelector.ts';
import ModelPreferences14 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/preferences.ts';
import ModelPromotion15 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/promotion.ts';
import ModelRole16 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/role.ts';
import ModelServiceRequest17 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/serviceRequest.ts';
import ModelTagStatistics18 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/tagStatistics.ts';
import ModelUser19 from '/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/models/user.ts';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'analytics', ...ModelAnalytics0 });
app.model({ namespace: 'automation', ...ModelAutomation1 });
app.model({ namespace: 'billing', ...ModelBilling2 });
app.model({ namespace: 'customer', ...ModelCustomer3 });
app.model({ namespace: 'date', ...ModelDate4 });
app.model({ namespace: 'image', ...ModelImage5 });
app.model({ namespace: 'journey', ...ModelJourney6 });
app.model({ namespace: 'lead', ...ModelLead7 });
app.model({ namespace: 'leadsAnalytics', ...ModelLeadsAnalytics8 });
app.model({ namespace: 'leadSource', ...ModelLeadSource9 });
app.model({ namespace: 'leadState', ...ModelLeadState10 });
app.model({ namespace: 'leadTask', ...ModelLeadTask11 });
app.model({ namespace: 'location', ...ModelLocation12 });
app.model({ namespace: 'periodSelector', ...ModelPeriodSelector13 });
app.model({ namespace: 'preferences', ...ModelPreferences14 });
app.model({ namespace: 'promotion', ...ModelPromotion15 });
app.model({ namespace: 'role', ...ModelRole16 });
app.model({ namespace: 'serviceRequest', ...ModelServiceRequest17 });
app.model({ namespace: 'tagStatistics', ...ModelTagStatistics18 });
app.model({ namespace: 'user', ...ModelUser19 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
