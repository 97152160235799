import {extend, RequestOptionsWithResponse, ResponseError} from 'umi-request';
import PATHS from "@/services/paths"
import env from "@/constants/environment"
import {clearCredentials, getAuthorizationHeader, isExpiredToken, refreshToken} from "@/services/auth";
import storage, {ENGINES} from 'conversional-persistent-storage';

export const originalRequest = extend({
  /**
   * Commented this application-level timeout settings because
   * it was preventing the long lasting requests to be made
   * without any further information so it was way too misleading
   * for debugging one issue (#980)
   */
  // timeout: 5000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  getResponse: true,
  // credentials: 'same-origin',
  credentials: 'include',
});

originalRequest.use(async (ctx, next) => {
  const {req} = ctx;
  const {url, options} = req;
  if (options.thirdPartyCall) {
    ctx.req.url = url
    ctx.req.credentials = 'omit'
    return await next()
  }

  if (!(options.absoluteURL || url.startsWith("http"))) {
    if (url.indexOf(PATHS.API_PATH_PREFIX) !== 0) {
      ctx.req.url = PATHS.API_PATH_PREFIX + ctx.req.url;
    }

    const rootUrl = storage.getItem("rootApiUrl", {}, ENGINES.LOCAL_STORAGE)
    ctx.req.url = (rootUrl || env.ROOT_URL) + ctx.req.url;

  }
  if (!options.noAuthHeader) {
    ctx.req.options.headers = {
      ...ctx.req.options.headers,
      ...getAuthorizationHeader()
    }
  }

  // ctx.req.options = {
  //   ...options,
  //   journeyId: 'vr4Qm48A',
  // };
  await next();
});

type IRequestOptions = {
  getResponse?: boolean,
  absoluteURL?: string,
  thirdPartyCall?: boolean,
  method: string,
  data?: Record<string, unknown>,
  params?: Record<string, unknown>,
  noAuthHeader?: boolean,
  queryString?: Record<string, string>
}
export function request<T>(url: string, options: IRequestOptions): Promise<unknown> {
  return new Promise((resolve) => {
    requestHandler(url, options, resolve)
  })
}

/** This function is written in order to inject the resolve function as a dependency. */
function requestHandler(url: string, options: IRequestOptions, resolve: (value: unknown) => void) {
  originalRequest(url, options).then((response) => {
    resolve(response)
  }).catch((error) => {
    errorHandler(error, url, options as RequestOptionsWithResponse, resolve)
  })
}

// region Error Handling


let isRefreshing = false;
/**
 * Putting a request in queue is for that when we find out that our access token is expired and not valid anymore
 * we tend to do the refreshing process. Meanwhile some requests will be made. Those requests would not successfully responded
 * because we have not got the new access token yet. So we check it out every 100ms if we have finished our refresh token process.
 * If it was not finished yet, the request will be back in the queue. In case refreshing the token is done, we will resend the request.
 * The important fact is that we keep the `resolve` function so that our component/redux action will get to know when the promise is
 * successfully resolved.
 * */
function putRequestInQueue(url: string, options: RequestOptionsWithResponse, resolve: (value: unknown) => void) {
  setTimeout(() => {
    isRefreshing ? putRequestInQueue(url, options, resolve) : requestHandler(url, options as IRequestOptions, resolve)
  }, 100)
}

function handleRedirectToLogin() {
  clearCredentials()
  if (location.pathname?.includes('/user/')) return
  location.replace(`/user/login?redirect=${location.pathname}`)
}


/**
 * When access token expires, we tend to refresh the access token using our refresh token
 * While the token is being refreshed, every other request goes into a queue-like concept
 * Every queue member checks itself every 100ms so that if the token is refreshed, we retry the request
 * The control flow of resolving the promise is kept carefully so that user do not face any lag/glitch
 * */
async function handleTokenRefreshing(error: ResponseError, url: string, options: RequestOptionsWithResponse, resolve: (value: unknown) => void) {
  if (isRefreshing) {
    return putRequestInQueue(url, options, resolve)
  }
  isRefreshing = true
  await refreshToken()
  isRefreshing = false
  return requestHandler(url, options as IRequestOptions, resolve)
}

export async function handleUnauthorizedError(error: ResponseError, url: string, options: RequestOptionsWithResponse, resolve: (value: unknown) => void) {
  return isExpiredToken(error) ? await handleTokenRefreshing(error, url, options, resolve) : handleRedirectToLogin()
}

export async function errorHandler(error: ResponseError, url: string, options: RequestOptionsWithResponse, resolve: (value: unknown) => void) {
  const {response} = error
  if (response?.status === 401) {
    await handleUnauthorizedError(error, url, options, resolve)
  }
  if (!isRefreshing)
    resolve({
      response,
      data: error.data
    })
}
// endregion
