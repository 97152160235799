import React, {useCallback, useRef, useState} from "react";
import {useDispatch, useModel} from "umi";
import {message} from "antd";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {Calendar, dayjsLocalizer, Views} from "react-big-calendar";

import TeamViewCalendarEvent from "@/components/Kanban/ServiceRequests/Calendar/TeamViewCalendarEvent";
import CalendarHeaderAgent from "@/components/Kanban/ServiceRequests/Calendar/CalendarHeaderAgent";
import {ServiceRequestModel} from "@/typings/models/ServiceRequest";
import {hasAccess} from "@/utils/access";
import PERMISSIONS from "@/constants/permissions";

import {rescheduleServiceRequestRequest} from "@/services/api/serviceRequest";
import {formatToISOStringWithOffset} from "@/utils/date";
import styles from "../index.less";
import {CalendarEventsContext} from "@/components/Kanban/ServiceRequests/Calendar/context";
import isEqual from "lodash/isEqual";

const DragAndDropCalendar = withDragAndDrop(Calendar)

const localizer = dayjsLocalizer(dayjs)
const SCROLL_TO_HOUR = 8
const SCROLL_TO_TIME = new Date(1970, 1, 1, SCROLL_TO_HOUR)
const RESOURCE_ACCESSOR = 'assigneeId'
const START_ACCESSOR = 'utc_start_time'
const END_ACCESSOR = 'utc_end_time'

const CALENDAR_STATIC_PROPS = {
  defaultDate: new Date(),
  resizable: true,
  steps: 60,
  scrollToTime: SCROLL_TO_TIME,
  localizer
}

type BigCalendarWrapperProps = {
  currentDate: Date,
  events: any[],
  resources: any[],
  onEventsChange: (events: any[]) => void
  wrapperClassName?: string,
  view: string
}
const BigCalendarWrapper: React.FC<BigCalendarWrapperProps> = ({
                                                                 events,
                                                                 resources,
                                                                 currentDate,
                                                                 onEventsChange,
                                                                 view = Views.DAY,
                                                                 wrapperClassName,
                                                                 request,
                                                                 ...restProps
                                                               }) => {
  const {initialState, setInitialState} = useModel('@@initialState');

  const dispatch = useDispatch()

  const [eventsLoading, setEventsLoading] = useState({})
  const hasRescheduleAccess = hasAccess(initialState.currentUser, [PERMISSIONS.ServiceRequests.RESCHEDULE, PERMISSIONS.BUSINESS_PARTNER])
  const [isDraggingContainer, setIsDraggingContainer] = useState(false)

  const isDraggable = (event: ServiceRequestModel) => hasRescheduleAccess && event.isDraggable
  const isResizable = (event: ServiceRequestModel) => hasRescheduleAccess && !isEmpty(event.service) && event.isResizable


  const handleSelectEvent = useCallback(
    (event) => {
      //   window.alert(event.title)
    },
    []
  )
  const handleSelectSlot = useCallback(
    ({start, end, resourceId}) => {
      const title = window.prompt('New Event Name')
      if (title) {
        onEventsChange((prev) => [...prev, {
          [START_ACCESSOR]: start,
          [END_ACCESSOR]: end,
          title,
          [RESOURCE_ACCESSOR]: resourceId
        }])
      }
    },
    [onEventsChange]
  )

  const moveEvent = ({
                       event,
                       start,
                       end,
                       resourceId,
                       isAllDay: droppedOnAllDaySlot = false,
                     }) => {
    const { allDay, id, lead, utc_start_time, utc_end_time } = event;

    const updatedAllDay = !allDay && droppedOnAllDaySlot ? true : allDay;

    onEventsChange((prev) =>
      prev.map((ev) =>
        ev.id === id
          ? {
            ...ev,
            [START_ACCESSOR]: start,
            [END_ACCESSOR]: end,
            [RESOURCE_ACCESSOR]: resourceId,
            allDay: updatedAllDay,
          }
          : ev
      )
    );

    const assigneeChanged = lead?.assignee?.id !== resourceId;
    const timeChanged = !isEqual(utc_start_time, start) || !isEqual(utc_end_time, end);

    if (!assigneeChanged && !timeChanged) return;

    setEventsLoading((prev) => ({ ...prev, [id]: true }));

    const changeTimeFunction = timeChanged
      ? rescheduleServiceRequestRequest
      : () => Promise.resolve({});

    changeTimeFunction({
      params: { serviceRequestId: id },
      data: { start: formatToISOStringWithOffset(start), end: formatToISOStringWithOffset(end) },
    }).then(async ({ response, data }) => {
      const onEnd = async () => {
        if (request) await request();
        setEventsLoading((prev) => ({ ...prev, [id]: false }));
      };

      if (response) {
        response.status === 200 ? message.success(data.message) : message.error(data?.message || data.error?.message);
      }

      if (assigneeChanged) {
        dispatch({
          type: 'leads/edit',
          payload: { leadId: lead.id, assignee_id: resourceId },
          then: onEnd,
        });
      } else {
        await onEnd();
      }
    });
  };


  const resizeEvent = useCallback(
    ({event, start, end}) => {
      onEventsChange((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {}
        const filtered = prev.filter((ev) => ev.id !== event.id)
        return [...filtered, {...existing, [START_ACCESSOR]: start, [END_ACCESSOR]: end}]
      })
    },
    [onEventsChange]
  )

  const props: any = {}
  if ((resources || []).length > 0) {
    props.resourceAccessor = RESOURCE_ACCESSOR
    props.resources = resources
    props.resourceIdAccessor = 'id'
    props.resourceTitleAccessor = 'name'
  }

  // region Scroll
  const startX = useRef(0);
  const startY = useRef(0);
  const scrollLeft = useRef(0);
  const scrollTop = useRef(0);
  const getScrollContainer = () => document.querySelector(".rbc-time-content")
  const handleMouseDown = useCallback((e) => {
    const isClickOnCalendarCard = e.target.closest('[role="team-view-calendar-event"]');
    if (isClickOnCalendarCard) return;

    setIsDraggingContainer(true);

    const scrollElement = getScrollContainer();
    scrollElement.style.cursor = "grabbing";

    startX.current = e.pageX;
    startY.current = e.pageY;
    scrollLeft.current = scrollElement.scrollLeft;
    scrollTop.current = scrollElement.scrollTop;

  }, []);

  const handleMouseMove = useCallback((e) => {
    if (!isDraggingContainer) return;
    e.preventDefault();

    const scrollElement = getScrollContainer();
    const dx = (e.pageX - startX.current) * 1.2;
    const dy = e.pageY - startY.current;

    scrollElement.scrollLeft = scrollLeft.current - dx;
    scrollElement.scrollTop = scrollTop.current - dy;

  }, [isDraggingContainer]);
  const handleMouseUpOrLeave = useCallback(() => {
    const scrollElement = getScrollContainer()
    scrollElement.style.cursor = "grab";
    setIsDraggingContainer(false);
  }, []);

  // endregion

  return <div
    onMouseDown={handleMouseDown}
    onMouseMove={handleMouseMove}
    onMouseUp={handleMouseUpOrLeave}
    onMouseLeave={handleMouseUpOrLeave}
    className={classNames(styles.bigCalendarWrapper, wrapperClassName)}>
    {/*@ts-ignore*/}
    <CalendarEventsContext.Provider value={{loading: eventsLoading}}>
      <DragAndDropCalendar
        formats={{
          timeGutterFormat: 'HH:mm',
        }}
        toolbar={false}
        startAccessor={START_ACCESSOR}
        endAccessor={END_ACCESSOR}
        date={currentDate}
        draggableAccessor={isDraggable}
        resizableAccessor={isResizable}
        components={
          {
            event: TeamViewCalendarEvent,
            resourceHeader: CalendarHeaderAgent,
          }
        }
        showAllDayHeader={false}
        onSelectEvent={handleSelectEvent}
        onEventDrop={moveEvent}
        onEventResize={resizeEvent}
        events={events}
        view={view}
        {...CALENDAR_STATIC_PROPS}
        {...props}
        {...restProps}
      />
    </CalendarEventsContext.Provider>
  </div>

}

export default BigCalendarWrapper
