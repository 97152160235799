import React, {useEffect, useState} from "react";

import {EditOutlined} from "@ant-design/icons";
import {Input} from "antd";
import {useDispatch, useModel} from "umi";

import styles from "./index.less"
import get from "lodash/get";
import classNames from "classnames";
import {hasAccess} from "@/utils/access";
import PERMISSIONS from "@/constants/permissions";
import {useLeadCardClick} from "@/components/Kanban/LeadCardContext";

const EditableTextField = ({children, editable = true, textarea = false, lead, property}) => {
  const [isEditing, setIsEditing] = useState(false)
  const {initialState, setInitialState} = useModel('@@initialState');

  const {setShouldIgnoreClick} = useLeadCardClick()

  const hasEditFieldAccess = hasAccess(initialState.currentUser, [PERMISSIONS.Leads.EDIT, PERMISSIONS.BUSINESS_PARTNER])
  const dispatch = useDispatch()
  const onSubmit = e => {
    setIsEditing(false)
    const value = e.target.value
    if (value !== lead[property]){
      dispatch({
        type: 'leads/edit',
        payload: {
          leadId: lead.id,
          [property]: value
        }
      })
    }

  }

  useEffect(() => {
    setShouldIgnoreClick(isEditing)
  }, [isEditing]);

  const handleFieldClick = () => {
    if (hasEditFieldAccess) setIsEditing(true)
  }

  if (!editable) return <div onClick={e => e.stopPropagation()}>{children}</div>
  if (!isEditing) return <div className={classNames(styles.editableTextWrapper, styles.fieldContainer)} onClick={e => e.stopPropagation()}>
    {children}
    {hasEditFieldAccess && <EditOutlined className={styles.editIcon} onClick={handleFieldClick}/> }
  </div>

  const InputComponent = textarea ? Input.TextArea : Input
  return <InputComponent onClick={e => e.stopPropagation()} bordered={false} size={"small"} className={classNames(styles.fieldContainer, {
      [styles.editingField]: isEditing
    })} autoFocus defaultValue={get(lead, property)} onBlur={onSubmit} onPressEnter={onSubmit}/>
}

export default EditableTextField
