import {URLParamBuilder} from "@/utils/functions";
import * as PATHS from "@/constants/path";
import {request} from "@/services/api/request";
import { CreateRoleRequest, DeleteRoleRequest, GetPermissionListByCategoryRequest, GetPermissionListByRoleRequest, ReorderRolesRequest, UpdateRoleRequest } from "@/typings/api/role";


 export function fetchPermissionListByCategoryRequest({ }) {
     const url = URLParamBuilder(PATHS.FETCH_PERMISSIONS_BY_CATEGORY, {})
 
   return request<GetPermissionListByCategoryRequest["response"]>(url, {
     method: 'GET'
   });
 }
 
 export function fetchPermissionListByRoleRequest({ }) {
     const url = URLParamBuilder(PATHS.FETCH_PERMISSIONS_BY_ROLE, {})
 
   return request<GetPermissionListByRoleRequest["response"]>(url, {
     method: 'GET'
   });
 }
 export function createRoleRequest({ payload }) {
   return request<CreateRoleRequest["response"]>(PATHS.CREATE_ROLE, {
     method: 'POST',
     data: payload,
   });
 }

 type UpdateRoleOptions = {
   params: UpdateRoleRequest["parameters"]
   payload: UpdateRoleRequest["payload"]
 }
 export function updateRoleRequest({ params, payload }: UpdateRoleOptions) {
 const finalUrl = URLParamBuilder(PATHS.UPDATE_ROLE, {
   params
 })
 return request<UpdateRoleRequest["response"]>(finalUrl, {
   method: 'PUT',
   data: payload,
 });
 }

 type DeleteRoleRequestOptions = {
   params: DeleteRoleRequest["parameters"]
 }
 export function deleteRoleRequest({params}: DeleteRoleRequestOptions) {
   const url = URLParamBuilder(PATHS.DELETE_ROLE, {
     params
   })
 
   return request<DeleteRoleRequest["response"]>(url, {
     method: 'DELETE'
   });
 }

 type ReorderRolesOptions = {
  payload: ReorderRolesRequest["payload"]
}
export function reorderRoleRequest({ payload }: ReorderRolesOptions) {
const finalUrl = URLParamBuilder(PATHS.REORDER_ROLE, {})
return request<UpdateRoleRequest["response"]>(finalUrl, {
  method: 'PUT',
  data: payload,
});
}