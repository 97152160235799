import { Card, Form, Space, Spin, Tabs } from 'antd';
import classNames from 'classnames';
import { FormattedMessage as F, useDispatch, useSelector } from 'umi';
import ProForm, {  } from '@ant-design/pro-form';
import isEmpty from 'lodash/isEmpty';

import { UserModel } from '@/typings/models/User';

import UserDetails from './UserDetails';
import UserWorkingHours from './UserWorkingHours';
import { useEffect } from 'react';

const EditSegmentSwitcher = ({ onUpdateUser, userData, onChangeTab }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { visible } = useSelector(
    ({ user: { editDrawer, conversionalRoles }, loading }: DefaultRootState) => ({
      ...editDrawer,
      conversionalRoles,
      updateUserLoading: loading.effects['user/edit'],
    }),
  );
 
  const resetForm = () => {
    form.resetFields();
    dispatch({
      type: 'user/setUser',
      payload: {},
    });
  };
  useEffect(() => {
    if (!visible) resetForm();
    if (visible) {
      dispatch({
        type: 'user/getUserBranches',
      });
      dispatch({
        type: 'user/fetchConversionalRoles',
      });
    }
  }, [visible]);

  useEffect(() => {
    if (!isEmpty(userData)) {
      form.setFieldsValue({
        ...userData,
        roles: userData?.roles?.map((role) => role.id ?? role) || [],
        branches: userData?.branches?.map((branch) => branch.id ?? branch) || [],
        branch_services: userData?.branch_services?.map((service) => service?.id || service) || [],
      });
    }
  }, [userData]);

  const changeUserData = (avail = null) => {
    const formValues = form.getFieldsValue();
    const userData = {
      ...formValues,
      availability: avail,
    };
    onUpdateUser(userData);
  };

  const tabs = [
    {
      key: 'details',
      label: (
        <Space>
          <F id={'components.users.details'} defaultMessage={'Details'} />
        </Space>
      ),
      children: !isEmpty(userData) ? (
        <UserDetails
          userData={userData}
          onChangeUser={(user: UserModel) => {
            changeUserData();
          }}
          form={form}
        />
      ) : (
        <Spin />
      ),
    },
    {
      key: 'workingHour',
      label: (
        <Space>
          <F id={'components.users.workingHour'} defaultMessage={'Working Hour'} />
        </Space>
      ),
      children: (
        <UserWorkingHours
          userData={userData}
          onChangeUserAvailability={(user: UserModel) => {
            changeUserData(user.availability);
          }}
        />
      ),
    },
  ];

  return (
    <Card bordered={false} style={{ width: '100%', marginBottom: '20px' }}>
      <ProForm form={form} submitter={false} onChange={() => changeUserData()}>
        <Tabs
          onChange={(key) => {
            onChangeTab(key);
          }}
          className={classNames('ant-tab-type-button')}
          defaultActiveKey="details"
          items={tabs}
        />
      </ProForm>
    </Card>
  );
};

export default EditSegmentSwitcher;
