import React, { useEffect, useState } from 'react';
import { useDispatch, useIntl, useSelector } from 'umi';
import { ProFormSelect, ProFormText } from '@ant-design/pro-form';
import isEmpty from 'lodash/isEmpty';
import { Tag } from 'antd';

import * as LocationSelectors from '@/selectors/locationSelectors';
import { BranchModel } from '@/typings/models/Location';

const UserDetails: React.FC = ({  userData, onChangeUser }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [availableServices, setAvailableServices] = useState([]);

  const { visible, conversionalRoles, updateUserLoading } = useSelector(
    ({ user: { editDrawer, conversionalRoles }, loading }: DefaultRootState) => ({
      ...editDrawer,
      conversionalRoles,
      updateUserLoading: loading.effects['user/edit'],
    }),
  );
  const branches: BranchModel[] = useSelector(LocationSelectors.selectBranches);
const handleBranchClick = (branchId,event)=>{
  const branch = branches.find(x=>x.id===branchId)
  if(isEmpty(branch)) return
  dispatch({
    type: 'location/getBranch',
    payload: { branchId: branchId },
  });
    dispatch({
      type: 'location/toggleBranchDrawer',
      payload: { branch },
    });

}
  useEffect(() => {
    if (visible && !isEmpty(userData)) {
      const userSelectedBranches = branches.filter((branch) =>
        userData?.branches?.map((branch) => branch.id).includes(branch.id),
      );
      const userSelectedServices =
        userSelectedBranches?.flatMap(
          (branch) =>
            branch.services?.map((service) => ({
              value: service.id,
              label: `${branch.name} - ${service.service_type}`,
            })) || [],
        ) || [];
      setAvailableServices(userSelectedServices);
    }
  }, [visible, JSON.stringify(userData)]);

  const handleBranchChange = (branchIds) => {
    onChangeUser();
    const newServices = branches
      .filter((branch) => branchIds.includes(branch.id))
      .flatMap(
        (branch) =>
          branch.services?.map((service) => ({
            value: service.id,
            label: `${branch.name} - ${service.service_type}`,
          })) || [],
      );

    setAvailableServices(newServices);
  };
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    return (
      <Tag
        closable={closable}
        onClose={onClose}
        onClick={(event) => {
          handleBranchClick(value, event);
        }}
        style={{ cursor: 'pointer' }}
      >
        <a style={{ textDecoration: 'underline' }}>{label}</a>
      </Tag>
    );
  };
  return (
    <div>
      <div>
        <ProFormText
          label={intl.formatMessage({
            id: 'pages.customers.firstName',
            defaultMessage: 'First Name',
          })}
          required
          name={'firstname'}
          rules={[{ required: true }]}
        />
        <ProFormText
          label={intl.formatMessage({
            id: 'pages.customers.lastName',
            defaultMessage: 'Last Name',
          })}
          required
          name={'lastname'}
          rules={[{ required: true }]}
        />

        <ProFormText
          name="email"
          required
          rules={[
            {
              required: true,
              type: 'email',
              message: intl.formatMessage({
                id: 'pages.settings.email.errorMessage',
                defaultMessage: 'Please enter a valid email.',
              }),
            },
          ]}
          label={intl.formatMessage({
            id: 'pages.resource.email',
            defaultMessage: 'Email',
          })}
        />
        <ProFormText
          name="phone"
          required
          rules={[
            {
              required: true,
              type: 'string',
              message: intl.formatMessage({
                id: 'pages.users.phone.errorMessage',
                defaultMessage: 'Please enter a valid phone.',
              }),
            },
            {
              pattern:
                /^[+]?[0-9]{1,4}?[-.\s]?[(]?[0-9]{1,3}?[)]?[-.\s]?[0-9]{1,4}[-.\s]?[0-9]{1,4}[-.\s]?[0-9]{1,9}$/,
              message: intl.formatMessage({
                id: 'pages.users.phone.invalidFormat',
                defaultMessage: 'Please enter a valid phone number.',
              }),
            },
          ]}
          label={intl.formatMessage({
            id: 'pages.users.phone',
            defaultMessage: 'Phone',
          })}
        />
        <ProFormSelect
          label={intl.formatMessage({
            id: 'pages.users.role',
            defaultMessage: 'Role',
          })}
          name={'roles'}
          required
          mode="multiple"
          style={{ width: '100%' }}
          placeholder={intl.formatMessage({
            id: 'pages.users.selectPlaceholder',
            defaultMessage: 'Please select role',
          })}
          options={(conversionalRoles || []).map((role) => ({
            value: role.id,
            label: role.name,
          }))}
          rules={[{ required: true }]}
          fieldProps={{
            onChange: (roleIds) => {
              onChangeUser();
            },
          }}
        />
        <ProFormSelect
          mode="multiple"
          rules={[{ required: true }]}
          options={(branches || []).map((branch) => ({
            value: branch.id,
            label: branch.name,
          }))}
          name="branches"
          label={intl.formatMessage({ id: 'pages.users.branches', defaultMessage: 'Branches' })}
          fieldProps={{ onChange: handleBranchChange, tagRender }}
        />
        <ProFormSelect
          mode="multiple"
          options={availableServices}
          name="branch_services"
          label={intl.formatMessage({ id: 'pages.users.services', defaultMessage: 'Services' })}
          fieldProps={{
            onChange: (serviceIds) => {
              onChangeUser();
            },
          }}
        />
        <ProFormText
          name="migration_id"
          label={intl.formatMessage({
            id: 'pages.users.migrationId',
            defaultMessage: 'Migration Id',
          })}
        />
        <ProFormText
          name="migration_source"
          label={intl.formatMessage({
            id: 'pages.users.migrationSource',
            defaultMessage: 'Migration Source',
          })}
        />
      </div>
    </div>
  );
};

export default React.memo(UserDetails, () => true);
