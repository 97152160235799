import React, {useEffect} from 'react';
import {Badge, Button, List, Popover, Space} from 'antd';
import {BellOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from "umi";

import PrettyDate from "@/components/PrettyDate";
import {F} from "@/utils/intl";
import {LeadTaskModel} from "@/typings/models/LeadTask";
import * as LeadTaskSelectors from "@/selectors/leadTaskSelectors"
import styles from "./index.less"
import DoTask from "@/components/Lead/LeadTasks/DoTask";

const LeadTasksOverview = () => {
  const dispatch = useDispatch()
  const taskList: LeadTaskModel[] = useSelector(LeadTaskSelectors.selectLeadTasks)

  const fetchTasks = () => dispatch({
    type: 'leadTasks/fetch',
  });
  useEffect(() => {
    fetchTasks()
  }, [])

  const openTasks = (taskList || []).filter(task => !task.is_completed)
  const popoverContent = (
    <div style={{width: 300}}>
      <List
        itemLayout="horizontal"
        dataSource={openTasks}
        className={styles.tasksOverviewList}
        renderItem={(task) => (
          <List.Item className={styles.taskItem}
                     actions={[<DoTask key={`do-task-${task.id}`} task={task} updateRequest={fetchTasks}/>]}>
            <List.Item.Meta
              title={<span>{task.title}</span>}
              description={<small>
                <Space>
                  <span><F id="pages.lead.tasks.taskDue" defaultMessage="Due date"/></span>
                  <PrettyDate date={task.due_date}/>
                </Space>
              </small>}
            />
          </List.Item>
        )}
      />
      <Link to="/tasks">
        <Button type="link" block>
          <F id={'pages.lead.tasks.viewAllTasks'} defaultMessage={'View all Tasks'}/>
        </Button>
      </Link>
    </div>
  );

  return (
    <Popover content={popoverContent} title={<F id={'pages.lead.tasks.openTasks'} defaultMessage={'Open Tasks'}/>}
             trigger="click" placement={"bottomRight"}>
      <Badge count={openTasks.length} size="small">
        <Button icon={<BellOutlined/>}/>
      </Badge>
    </Popover>
  );
};

export default LeadTasksOverview;
