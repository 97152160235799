import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "umi";
import {Popover, Space, Spin} from "antd";
import {ClockCircleOutlined} from "@ant-design/icons";
import classNames from "classnames";

import {ServiceRequestModel} from "@/typings/models/ServiceRequest";
import {PrettyHour} from "@/components/PrettyDate";
import TeamViewCalendarEventLead from "@/components/Kanban/ServiceRequests/Calendar/TeamViewCalendarEventLead";
import TeamViewCalendarEventOverviewPopover
  from "@/components/Kanban/ServiceRequests/Calendar/TeamViewCalendarEventOverviewPopover";
import * as LeadSelectors from "@/selectors/leadSelectors";
import TeamViewCalendarEventTasks from "@/components/Kanban/ServiceRequests/Calendar/TeamViewCalendarEventTasks";
import {CalendarEventsContext} from "@/components/Kanban/ServiceRequests/Calendar/context";

import styles from "../index.less"
import * as ServiceRequestSelectors from "@/selectors/serviceRequestSelectors";

const getCalendarEventId = (event: ServiceRequestModel) => `calendar-event-${event.id}`

const CalendarEventTime = ({event}: { event: ServiceRequestModel }) => <Space className={styles.eventTime}>
  <ClockCircleOutlined/>
  <PrettyHour date={event.utc_start_time}/>
  <PrettyHour date={event.utc_end_time}/>
</Space>


const NotAvailableCalendarEvent = ({event}: { event: ServiceRequestModel }) =>
  <div className={styles.notAvailableCalendarEvent} id={getCalendarEventId(event)}>
    {event.what}
  </div>


const BlockerCalendarEvent = ({event}: { event: ServiceRequestModel }) => {
  return <div className={classNames(styles.blockerCalendarEvent, styles.teamViewCalendarEvent)}
              style={{'--event-color': event.eventColor}}
              id={getCalendarEventId(event)}>
    <span className={styles.eventTitle}>{event.what}</span>
    <CalendarEventTime event={event}/>
  </div>
}


const ServiceCalendarEvent = ({event}: { event: ServiceRequestModel }) => {

  const calendarContext = React.useContext(CalendarEventsContext)
  const highlightedServiceRequest = useSelector(ServiceRequestSelectors.selectHighlightedServiceRequest)

  const dispatch = useDispatch()
  const [popoverOpen, setPopoverOpen] = useState(false)

  const popUpContainer = React.useRef(null)
  const isRescheduled = event.status === 'rescheduled'
  const isCancelled = ['cancelled_by_customer', 'declined'].includes(event.status)
  const leadStateColor = event.lead?.lead_state?.color
  const leadId = event?.lead?.id
  const leadDetails = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadDetails(state, leadId))

  const fetchLeadData = () => dispatch({
    type: 'leads/fetchSingleLead',
    payload: {
      leadId
    }
  })

  useEffect(() => {
    // Commented until we implement the fetchLeadData
    // if (isEmpty(leadDetails)) fetchLeadData()
  }, []);
  const onLeadClick = () => {
    dispatch({
      type: 'leads/toggleLeadDetailsDrawer',
      payload: {
        lead: event.lead
      }
    })
  }
  return (
    <Spin spinning={calendarContext.loading?.[event.id] || false}>
      <div ref={popUpContainer}
           role={'team-view-calendar-event'}
           onClick={onLeadClick} className={classNames(styles.teamViewCalendarEvent, {
        [styles.rescheduledEvent]: isRescheduled,
        [styles.cancelledEvent]: isCancelled,
        [styles.highlightedEvent]: highlightedServiceRequest?.id === event.id
      })}
           id={getCalendarEventId(event)}
           style={{'--lead-state-color': leadStateColor}}>
        <Popover
          placement={"bottomLeft"}
          open={false}
          onOpenChange={setPopoverOpen}
          content={<TeamViewCalendarEventOverviewPopover event={event} onOpenChange={setPopoverOpen}/>}>
          <div className={styles.eventHeader}>
            <TeamViewCalendarEventLead event={event}/>
            {/*<span className={styles.eventTitle}>{event.what}</span>*/}
            {/*<span className={styles.eventIcon}><EventIcon event={event}/></span>*/}
          </div>
        </Popover>
        <CalendarEventTime event={event}/>
        <div className={styles.eventLeadInfo}>
          <TeamViewCalendarEventTasks event={event} refresh={fetchLeadData}/>
        </div>
      </div>
    </Spin>
  )
}

export default function TeamViewCalendarEvent({event}: { event: ServiceRequestModel }) {
  if (event.notAvailable) return <NotAvailableCalendarEvent event={event}/>
  if (event.blocker) return <BlockerCalendarEvent event={event}/>
  return <ServiceCalendarEvent event={event}/>
}
