import request from 'umi-request';

export function makeWidgetUrl(baseUrl, url) {
  return `${baseUrl}${url}`
}

export function fetchWidgetJourneyConfigRequest({
                                                  params,
                                                  query,
                                                  baseUrl
                                                }) {
  return request(`${baseUrl}/journeys/${params.journeyId}?apiKey=${query.apiKey}`, {
    method: 'GET',
    getResponse: true
  });
}

export function fetchWidgetLocationsRequest({
                                              query,
                                              baseUrl
                                            }) {
  let url = `${baseUrl}/locations/?apiKey=${query.apiKey}&journeyId=${query.journeyId}&type=${query.type}`
  if (query.close_to)
    url += `&close_to=${query.close_to}`

  return request(url, {
    method: 'GET',
    getResponse: true
  });
}

export function fetchLocationBookingTimeslotsRequest({
                                                       query,
                                                       params,
                                                       baseUrl
                                                     }) {
  const url = `${baseUrl}/timekitAvailability/${params.locationId}/?apiKey=${query.apiKey}`
  return request(url, {
    method: 'GET',
    getResponse: true
  });
}

export function bookAppointmentRequest({
                                                       query,
                                                       data,
                                                       baseUrl
                                                     }) {
  const url = `${baseUrl}/questionnaires?apiKey=${query.apiKey}`
  return request(url, {
    method: 'POST',
    data,
    getResponse: true
  });
}
