import * as React from "react"
import {useDispatch} from "umi";

import {LeadModel} from "@/typings/models/Lead";
import LeadStateDropdownFilter from "@/components/Filters/LeadStateFilters";
import styles from "@/components/Lead/index.less";

const LeadState = ({lead, isTable}: {lead: LeadModel, isTable?: boolean}) => {
  if (!isTable) return null
  const dispatch = useDispatch()
  const onLeadStateChange = (newStateId: string) => {
    dispatch({
      type: 'leads/edit',
      payload: {
        lead_state_id: newStateId,
        leadId: lead.id
      },
      previousState: lead.lead_state?.id
    })
  }
  return <LeadStateDropdownFilter className={styles.leadStatusContainer} selectProps={{bordered: false, allowClear: false}} filterMode={"select"} onChange={onLeadStateChange} value={lead.lead_state?.id}/>
}

export default LeadState
