import {LeadDetailsModel} from "@/typings/models/Lead";
import {ServiceRequestStyleModel} from "@/typings/models/ServiceRequest";

export function getLeadOpenTasksCount(lead: LeadDetailsModel) {
  if (!lead) return 0
  if (Array.isArray(lead.tasks)){
    return lead.tasks.filter(task => !task.is_completed).length
  }
  const openTaskCount = lead.tasks_count - lead.completed_tasks_count
  return !isNaN(openTaskCount) ? openTaskCount : 0
}

export function hasDueTask(lead: LeadDetailsModel) {
  return lead.today_uncompleted_tasks_count > 0
}


export function getLeadStyles(lead: LeadDetailsModel): ServiceRequestStyleModel[]{
  return lead?.service_requests?.reduce((acc, serviceRequest) => {
    if (serviceRequest?.result?.styles) {
      acc.push(...serviceRequest.result?.styles)
    }
    return acc
  }, []) || []
}
