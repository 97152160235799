import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import dayjs from "dayjs";
import Card from "@/components/Card";
import classNames from "classnames";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {Button, Empty, Segmented, Switch} from "antd";
import {F} from "@/utils/intl";
import {useDispatch, useIntl, useModel, useSelector} from "umi";


import {FormatDate} from "@/components/PrettyDate";
import CalendarList from "@/components/Kanban/ServiceRequests/Calendar/CalendarList";
import InlineDatePicker from "@/components/Kanban/ServiceRequests/Calendar/InlineDatePicker";
import * as LeadStateSelectors from "@/selectors/leadStateSelectors";
import {LeadStatePresentation} from "@/components/Filters/LeadStateFilters";

import styles from "./index.less"

import {ServiceRequestStatus} from "@/typings/models/ServiceRequest";
import isEmpty from "lodash/isEmpty";
import * as LocationSelectors from "@/selectors/locationSelectors";
import {FILTER_PLACES} from "@/components/Filters/LeadFilters";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import * as ServiceRequestSelectors from "@/selectors/serviceRequestSelectors";
import {fetchBranchAgentsRequest} from "@/services/api/location";
import {
  getAgentsUnavailableEvents,
  processServiceRequestsForCalendar
} from "@/components/Kanban/ServiceRequests/Calendar/utils";
import {fetchCalendarViewServiceRequests} from "@/services/api/serviceRequest";
import BigCalendarWrapper from "@/components/Kanban/ServiceRequests/Calendar/BigCalendarWrapper";
import {Views} from "react-big-calendar";


const DAY_WINDOW_SIZE = 6

const TeamViewCalendar = forwardRef(({setLoading}: any, ref) => {

  const {initialState, setInitialState} = useModel('@@initialState');

  const leadStates = useSelector(LeadStateSelectors.selectStates)
  const selectedBranch = useSelector((state: DefaultRootState) => PreferencesSelectors.selectFilters(state, FILTER_PLACES.TEAM_VIEW_CALENDAR))?.calendarBranch
  const highlightedServiceRequest = useSelector(ServiceRequestSelectors.selectHighlightedServiceRequest)

  const branches = useSelector(LocationSelectors.selectBranches);
  const dispatch = useDispatch()
  const intl = useIntl()
  const [initialized, setInitialized] = useState(false)
  const [currentDate, setCurrentDate] = React.useState(new Date())
  const currentDateTimestamp = currentDate?.getTime()

  const [dateWindow, setDateWindow] = React.useState([])
  const [currentEvents, setCurrentEvents] = React.useState([])
  const [availabilityEvents, setAvailabilityEvents] = React.useState([])

  const [agentsLoading, setAgentsLoading] = React.useState(false)
  const [serviceRequestsLoading, setServiceRequestsLoading] = React.useState(false)

  const [excludedStates, setExcludedStates] = React.useState([])
  const [branchAgents, setBranchAgents] = React.useState([])

  const [showInactiveEvents, setShowInactiveEvents] = React.useState(true)

  // region Preferences
  const branchExcludedAgents = useSelector((state: DefaultRootState) => PreferencesSelectors.selectPreferencesByKey(state, `${FILTER_PLACES.TEAM_VIEW_CALENDAR}__${selectedBranch}_excludedAgents.list`, []))
  const branchWeekViewAgents = useSelector((state: DefaultRootState) => PreferencesSelectors.selectPreferencesByKey(state, `${FILTER_PLACES.TEAM_VIEW_CALENDAR}__${selectedBranch}_weekViewAgents.list`, []))
  const calendarView = useSelector((state: DefaultRootState) => PreferencesSelectors.selectPreferencesByKey(state, `${FILTER_PLACES.TEAM_VIEW_CALENDAR}__view.value`, Views.DAY))
  // endregion

  const isDayView = calendarView === Views.DAY

  const calculateAvailabilityEvents = () => {
    // TODO: Bring back the availability
    const calculated = getAgentsUnavailableEvents(currentDate, branchAgents)
    if (calculated.length > 0)
      setAvailabilityEvents([])
  }

  const fetchBranchAgents = () => {
    setAgentsLoading(true)
    fetchBranchAgentsRequest({
      params: {
        branchId: selectedBranch
      }
    }).then(({data}) => {
      setAgentsLoading(false)
      const agents = data.data
      setBranchAgents([...agents])
      if (branchWeekViewAgents.length === 0 && agents?.length > 0) {
        const selectedAgentId = agents.find(agent => agent.id === initialState?.currentUser?.User?.alias)?.id || agents[0].id
        handleWeekViewAgentChange([selectedAgentId], {
          local: true
        })
      }
    })
  };

  const fetchServiceRequests = () => {
    const startDate = dayjs(currentDate).subtract(DAY_WINDOW_SIZE, 'days').format("YYYY-MM-DD")
    const endDate = dayjs(currentDate).add(DAY_WINDOW_SIZE, 'days').format("YYYY-MM-DD")
    setDateWindow([startDate, endDate])
    setServiceRequestsLoading(true)
    setLoading(true)
    return fetchCalendarViewServiceRequests({
      query: {
        start_date: startDate,
        end_date: endDate,
        branch: selectedBranch,
        all: 1
      }
    }).then((response) => {
      const serviceRequests = response?.data?.data || []

      const processedEvents = processServiceRequestsForCalendar(serviceRequests)
      setCurrentEvents(processedEvents)
      calculateAvailabilityEvents()

      dispatch({
        type: 'leads/saveLeadsData',
        payload: {
          list: serviceRequests.map(serviceRequest => serviceRequest?.lead).filter(lead => lead)
        }
      })
      setServiceRequestsLoading(false)
      setLoading(false)
    })
  }


  useImperativeHandle(ref, () => ({
    fetch: fetchServiceRequests
  }))
  useEffect(() => {
    fetchBranchAgents()
    fetchServiceRequests()
  }, [selectedBranch]);

  useEffect(() => {
    calculateAvailabilityEvents()
  }, [JSON.stringify(branchAgents)])

  useEffect(() => {
    if (highlightedServiceRequest && highlightedServiceRequest.utc_start_time)
      setCurrentDate(dayjs(highlightedServiceRequest.utc_start_time).toDate())

    setTimeout(() => {
      dispatch({
        type: 'serviceRequest/highlightServiceRequest',
        payload: null
      })
    }, 3 * 1000)

  }, [JSON.stringify(highlightedServiceRequest)]);

  useEffect(() => {
    setInitialState({
      ...initialState,
      collapsed: true
    })
    setInitialized(true)
  }, []);

  useEffect(() => {
    const distanceFromEnd = dayjs(dateWindow[1]).diff(dayjs(currentDate), 'days')
    const distanceFromStart = dayjs(currentDate).diff(dayjs(dateWindow[0]), 'days')
    if (distanceFromStart < 0 || distanceFromEnd < 0 ||
      distanceFromStart < DAY_WINDOW_SIZE / 2 || distanceFromEnd < DAY_WINDOW_SIZE / 2)
      fetchServiceRequests()

    calculateAvailabilityEvents()
  }, [currentDateTimestamp]);

  useEffect(() => {
    if (!isEmpty(branches) && !selectedBranch) {
      dispatch({
        type: 'preferences/change',
        payload: {
          calendarBranch: branches[0].id,
          place: FILTER_PLACES.TEAM_VIEW_CALENDAR,
          persist: true
        }
      })
    }
  }, [JSON.stringify(branches)]);

  const selectNextDay = () => {
    setCurrentDate(dayjs(currentDate).add(1, isDayView ? 'day' : 'week').toDate())
  }
  const selectPreviousDay = () => {
    setCurrentDate(dayjs(currentDate).subtract(1, isDayView ? 'day' : 'week').toDate())
  }
  const selectToday = () => {
    setCurrentDate(new Date())
  }

  const isTodayDisabled = () => dayjs(currentDate).isSame(new Date(), isDayView ? 'day' : 'week')

  const handleExcludedAgentsChange = (agentId: string) => {
    const newExcludedAgents = branchExcludedAgents.includes(agentId) ? branchExcludedAgents.filter((item) => item !== agentId) : [...branchExcludedAgents, agentId]
    dispatch({
      type: 'preferences/change',
      payload: {
        list: newExcludedAgents,
        place: FILTER_PLACES.TEAM_VIEW_CALENDAR,
        mode: `${selectedBranch}_excludedAgents`,
      }
    })
  }

  const handleWeekViewAgentChange = (agentIds: string[], extraPayload = {}) => {
    dispatch({
      type: 'preferences/change',
      payload: {
        list: agentIds,
        place: FILTER_PLACES.TEAM_VIEW_CALENDAR,
        mode: `${selectedBranch}_weekViewAgents`,
        ...(extraPayload || {})
      }
    })
  }

  const handleCalendarViewChange = (view: string) => {
    dispatch({
      type: 'preferences/change',
      payload: {
        value: view,
        place: FILTER_PLACES.TEAM_VIEW_CALENDAR,
        mode: 'view',
      }
    })
  }

  const handleExcludedStatesChange = (stateId) => setExcludedStates((prev) =>
    prev.includes(stateId) ? prev.filter((item) => item !== stateId) : [...prev, stateId])

  const serviceRequestsToShow = currentEvents.filter((event) => {
    return !excludedStates.includes(event?.lead?.lead_state?.id) && (showInactiveEvents || ![ServiceRequestStatus.cancelled_by_customer, ServiceRequestStatus.rescheduled, ServiceRequestStatus.declined].includes(event.status))
  })
  const events = serviceRequestsToShow.concat(availabilityEvents)

  const agentEventMapping = serviceRequestsToShow.reduce((acc, serviceRequest) => {
    const assigneeId = serviceRequest?.lead?.assignee?.id || 'unassigned'; // Use 'unassigned' for null assigneeId
    if (!acc[assigneeId]) {
      acc[assigneeId] = {
        total: [],
        current: []
      }; // Initialize array if it doesn't exist
    }
    acc[assigneeId].total.push(serviceRequest);
    if (dayjs(serviceRequest.utc_start_time).isSame(currentDate, isDayView ? 'day' : 'week'))
      acc[assigneeId].current.push(serviceRequest)
    return acc;
  }, {})


  if (initialized &&
    !agentsLoading &&
    !serviceRequestsLoading &&
    branchAgents.length === 0) {
    return <Card className={styles.card}>
      <Empty/>
    </Card>
  }

  const getSelectedAgents = () => {
    if (isDayView) return branchExcludedAgents
    return branchWeekViewAgents
  }
  const handleCalendarListChange = (agentId) => {
    if (isDayView) return handleExcludedAgentsChange(agentId)
    handleWeekViewAgentChange([agentId])
  }

  const getResources = () => {
    if (isDayView) return branchAgents.filter((agent) => !branchExcludedAgents.includes(agent.id))
    return branchAgents.filter(agent => branchWeekViewAgents[0] === agent.id)
  }
  const getWeekRange = () => {
    const startOfWeek = dayjs(currentDate).startOf("week"); // Get Monday of the week
    const endOfWeek = dayjs(currentDate).endOf("week"); // Get Sunday of the week

    return `${startOfWeek.format("DD MMM")} - ${endOfWeek.format("DD MMM YYYY")}`;
  };


  const dayFormat = isDayView ?
    <FormatDate format={'dddd, DD MMM YYYY'} date={currentDate}/> :
    getWeekRange()

  return <Card className={styles.card} loading={agentsLoading}>
    <div className={styles.teamViewCalendarHeader}>
      <div className={styles.headerDate}>
        <div className={styles.today}>
          <Button size={"large"} onClick={selectToday} disabled={isTodayDisabled()}
                  className={'ant-btn-black-bordered'}><F
            id={"pages.leads.todayView.today"} defaultMessage={"Today"}/></Button>
        </div>
        <div className={styles.dayNavigation}>
          <div className={styles.dayControl}><Button size={"large"} icon={<LeftOutlined/>} onClick={selectPreviousDay}/>
          </div>
          <div className={styles.currentDate}>{dayFormat}</div>
          <div className={styles.dayControl}><Button size={"large"} icon={<RightOutlined/>} onClick={selectNextDay}/>
          </div>
        </div>
      </div>

      <div className={styles.headerActions}>
        <div className={styles.toggleCancelledEvents}>
          <span onClick={() => setShowInactiveEvents(e => !e)}>
            <F id={'pages.leads.serviceRequests.showInvalidEvents'} defaultMessage={'Show Cancelled/Rescheduled'}/>
          </span>
          <Switch checked={showInactiveEvents} onChange={setShowInactiveEvents}/>
        </div>
        <div>
          <Segmented value={calendarView} onChange={handleCalendarViewChange} options={[
            {
              label: intl.formatMessage({
                id: 'pages.leads.serviceRequests.dayCalendarView',
                defaultMessage: 'Day'
              }), value: Views.DAY
            },
            {
              label: intl.formatMessage({
                id: 'pages.leads.serviceRequests.weekCalendarView',
                defaultMessage: 'Week'
              }), value: Views.WEEK
            }]}
          />
        </div>
      </div>
    </div>
    <div className={styles.teamViewCalendarContainer}>
      <div className={styles.teamViewCalendarSider}>
        {/* TODO: Next iteration */}
        {/*<CheckAccess access={[PERMISSIONS.ServiceRequests.CREATE, PERMISSIONS.BUSINESS_PARTNER]}>*/}
        {/*  <div className={styles.createMeetingContainer}>*/}
        {/*    <Button className={'button-secondary button-bordered'} icon={<PlusOutlined/>}>*/}
        {/*      <F id={'pages.leads.appointment.create'} defaultMessage={'Create Meeting'}/>*/}
        {/*    </Button>*/}
        {/*  </div>*/}
        {/*</CheckAccess>*/}
        <div className={styles.inlineDayPicker}>
          <InlineDatePicker isWeekView={!isDayView} value={currentDate} onChange={setCurrentDate}/>
        </div>

        <div className={styles.agentsContainer}>
          <CalendarList eventMapping={agentEventMapping}
                        agents={branchAgents}
                        multiple={isDayView}
                        onChange={handleCalendarListChange}
                        value={getSelectedAgents()}/>
        </div>
      </div>
      {/* @ts-ignore */}
      <BigCalendarWrapper
        currentDate={currentDate}
        events={events}
        wrapperClassName={styles.calendarContainer}
        onEventsChange={setCurrentEvents}
        view={calendarView}
        resources={getResources()}
        request={fetchServiceRequests}
      />
    </div>
    <div className={styles.teamViewCalendarFooter}>
      <div className={styles.teamViewCalendarLeadStateLegends}>
        {Array.isArray(leadStates) && leadStates.map((state) => <span
          onClick={() => handleExcludedStatesChange(state.id)}
          className={classNames(styles.legend, {
            [styles.inactiveLegend]: excludedStates.includes(state.id)
          })} key={state.id}><LeadStatePresentation state={state}/></span>)}
      </div>
    </div>
  </Card>
})

export default TeamViewCalendar
