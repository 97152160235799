import React, { useEffect, useState } from 'react'
import { useDispatch, useIntl, useModel, useSelector } from 'umi'
import { Badge, Form, Space, Spin, Tabs, Tooltip } from 'antd'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import Drawer from '@/components/Drawer'
import * as LeadSelectors from '@/selectors/leadSelectors'
import LeadName from '@/components/Lead/LeadName'
import LeadActivity from '@/components/Lead/LeadActivity'
import { F } from '@/utils/intl'
import LeadComments from '@/components/Lead/LeadComments'
import LeadDetailsServiceRequests from '@/components/Lead/LeadDetailsServiceRequests'

import styles from './index.less'
import { TasksList } from '@/pages/Tasks/TasksList'
import LeadDetailsHeader from '@/components/Lead/LeadDetailsDrawer/LeadDetailsHeader'
import classNames from 'classnames'
import LeadSource from '@/components/Lead/LeadSource'
import LeadUpdatedAt from '@/components/Lead/LeadUpdatedAt'
import PERMISSIONS from '@/constants/permissions'
import { hasAccess } from '@/utils/access'
import OpenTaskCountBadge from '@/components/Lead/LeadTasks/OpenTaskCountBadge'
import { BsClipboardCheck, GoTaskList, LiaCommentsSolid, MdOutlineShowChart } from '@/components/Icons/CustomReactIcons'


const TAB_KEYS = {
  ACTIVITIES: 'activities',
  SERVICE_REQUESTS: 'serviceRequests',
  TASKS: 'tasks',
  COMMENTS: 'comments',
  PROPOSALS: 'proposals',
  DOCUMENTS: 'documents',
}
const LeadDetailsDrawer = () => {
  const {initialState, setInitialState} = useModel('@@initialState');
  const [form] = Form.useForm()
  const [activeTab, setActiveTab] = useState(TAB_KEYS.ACTIVITIES)
  const detailsDrawer = useSelector(LeadSelectors.selectLeadDetailsDrawer)
  const leadId = detailsDrawer?.leadId
  const leadDetails = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadDetails(state, leadId))
  const lead = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadData(state, leadId))
  const intl = useIntl();

  const loading = useSelector((state: DefaultRootState) => state.loading.effects['leads/fetchSingleLead'])
  useEffect(() => {
    if (isEmpty(detailsDrawer))
      return setActiveTab(TAB_KEYS.ACTIVITIES)

    if (detailsDrawer.activeTab) setActiveTab(detailsDrawer.activeTab)
  }, [leadId]);
  const commentsCount = get(leadDetails, 'activities', []).filter(activity => activity?.activity_type === 'comment').length
  const fetchLeadData = () => dispatch({
    type: 'leads/fetchSingleLead',
    payload: {
      leadId
    }
  })

  const tabs = [
    {
      key: TAB_KEYS.ACTIVITIES,
      label: <Space>
        <MdOutlineShowChart width={20}/>
        <F id={"pages.lead.drawer.activities"} defaultMessage={'Activities'}/>
      </Space>,
      children: <div>
        <LeadActivity leadDetails={leadDetails}/>
      </div>,
    },
    {
      key: TAB_KEYS.SERVICE_REQUESTS,
      label: <Space>
        <BsClipboardCheck width={20}/>
        <F id={"pages.serviceRequest.tableTitle"} defaultMessage={'Requests'}/>
      </Space>,
      access: [PERMISSIONS.ServiceRequests.VIEW, PERMISSIONS.BUSINESS_PARTNER],
      children: <div>
        <LeadDetailsServiceRequests leadDetails={leadDetails} request={fetchLeadData}/>
      </div>,
    },
    {
      key: TAB_KEYS.TASKS,
      label: <Space>

        <GoTaskList fontSize={0} width={20}/>
        <F id={"pages.lead.drawer.tasks"} defaultMessage={'Tasks'}/>
        <OpenTaskCountBadge lead={leadDetails}/>
      </Space>,
      children: <div>
        <TasksList leadId={leadId} filterColumns={column => column.dataIndex !== 'lead'} data={leadDetails?.tasks || []} request={fetchLeadData}/>
      </div>,
    },
    {
      key: TAB_KEYS.COMMENTS,
      label: <Space>
        <LiaCommentsSolid fontSize={0} width={20}/>
        <F id={"pages.lead.drawer.comments"} defaultMessage={'Comments'}/>
        {commentsCount > 0 && <Badge size={"small"} color={"volcano"} showZero={false} count={commentsCount}/>}
      </Space>,
      children: <div>
        <LeadComments leadId={leadId} leadDetails={leadDetails} request={fetchLeadData}/>
      </div>,
    },
    {
      key: TAB_KEYS.PROPOSALS,
      label: (
        <Tooltip title={intl.formatMessage({id: 'pages.common.notActivated', defaultMessage: 'Not activated'})}>
          <F id={"pages.lead.drawer.proposals"} defaultMessage={'Proposals'}/>
        </Tooltip>
      ),
      children: <div>Proposals content</div>,
      disabled: true,
    },
    {
      key: TAB_KEYS.DOCUMENTS,
      label: (
        <Tooltip title={intl.formatMessage({id: 'pages.common.notActivated', defaultMessage: 'Not activated'})}>
          <F id={"pages.lead.drawer.documents"} defaultMessage={'Documents'}/>
        </Tooltip>
      ),
      children: <div>Documents content</div>,
      disabled: true,
    },
  ].filter(tab => !tab.access || hasAccess(initialState.currentUser, tab.access));

  const dispatch = useDispatch();

  function toggle() {
    dispatch({
      type: 'leads/toggleLeadDetailsDrawer',
    });
  }

  useEffect(() => {
    if (isEmpty(detailsDrawer))
      return

    form.resetFields()
    fetchLeadData()
  }, [detailsDrawer]);


  const getTitle = () => <div>
    <Space>
      <F id={'pages.lead.drawer.details'}/>
      {lead && <LeadName readonly hideAvatar lead={lead}/>}
    </Space>
  </div>


  return (
    <div className={styles.drawerContainer}>
      <Drawer
        width={800}
        title={getTitle()}
        open={!isEmpty(detailsDrawer) && !isEmpty(lead)}
        destroyOnClose
        className={styles.drawer}
        onVisibleChange={toggle}
        drawerHeaderClassName={styles.drawerHeader}
      >
        <Spin spinning={loading}>
          {!isEmpty(lead) && <>
            <LeadDetailsHeader leadDetails={leadDetails} lead={lead}/>
            <div className={styles.leadDetailsSource}>
              <Space>
                <F className={styles.detailKey} id={'pages.leads.filters.leadSource.placeholder'} defaultMessage={'Lead Source'}/>
                <span className={styles.detailValue}><LeadSource lead={lead} fallback={" - "}/></span>
              </Space>
              <Space>
                <F className={styles.detailKey} id={'pages.lead.fields.updatedAt'} defaultMessage={'Last update'}/>
                <span className={styles.detailValue}><LeadUpdatedAt isTable lead={lead}/></span>
              </Space>
            </div>
            <Tabs className={classNames('ant-tab-type-button', styles.leadDetailsDrawerTabs)} activeKey={activeTab} onChange={setActiveTab} items={tabs}/>
          </>}
        </Spin>
      </Drawer>
    </div>
  );
};

export default React.memo(LeadDetailsDrawer, () => true);
