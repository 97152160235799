import * as React from 'react'
import {useDispatch, useHistory, useIntl} from "umi";
import {Button, Divider, List, Space, Tag, Tooltip, Typography} from "antd";
import {CalendarOutlined, PlusOutlined} from "@ant-design/icons";
import classNames from "classnames";
import dayjs from "dayjs";

import {ServiceRequestModel, ServiceRequestStatus} from "@/typings/models/ServiceRequest";
import {LeadDetailsModel} from "@/typings/models/Lead";

import Card from "@/components/Card";
import {FormatDate} from "@/components/PrettyDate";
import {capitalizeAllLetters} from "@/utils/functions";
import {F} from "@/utils/intl";
import {CheckAccess} from "@/utils/access";
import PERMISSIONS from "@/constants/permissions";

import styles from "./index.less"
import {ROUTE_PATHS} from "../../../config/RoutePaths";

function ServiceRequestList({serviceRequests}: { serviceRequests: ServiceRequestModel[] }) {
  const isGrayedOut = (serviceRequest: ServiceRequestModel) => ![ServiceRequestStatus.confirmed, ServiceRequestStatus.tentative].includes(serviceRequest.status)
  const dispatch = useDispatch()
  const history = useHistory()
  const jumpToAppointmentInCalendar = (serviceRequest: ServiceRequestModel) => {
    const currentRoute = history.location.pathname
    if (!currentRoute.includes(ROUTE_PATHS.CALENDAR))
      history.push(ROUTE_PATHS.CALENDAR)

    dispatch({
      type: 'serviceRequest/highlightServiceRequest',
      payload: {serviceRequest}
    })

    dispatch({
      type: 'leads/toggleLeadDetailsDrawer',
    })
  }
  return (
    <List
      dataSource={serviceRequests}
      renderItem={(serviceRequest) => (
        <Card
          style={{marginBottom: 5}}
          className={classNames(styles.serviceRequestCard, {
            [styles.grayedOutServiceRequest]: isGrayedOut(serviceRequest),
          })}
        >
          <Space style={{width: '100%', justifyContent: 'space-between'}}>
            <Tag>{serviceRequest?.service?.label}</Tag>
            <Space direction={'vertical'} style={{textAlign: 'center'}}>
              <strong>
                <Space>
                  <FormatDate format={'dddd, DD MMMM YYYY'} date={serviceRequest.utc_start_time}/>
                  <Tooltip title={<F id={"pages.lead.jumpToDate"} defaultMessage={"Jump to this date in Calendar"}/>}>
                    <Button type={"link"} icon={<CalendarOutlined/>} size={"small"}
                            onClick={() => jumpToAppointmentInCalendar(serviceRequest)}/>
                  </Tooltip>
                </Space>
              </strong>
              <Space>
                {serviceRequest.utc_start_time && (
                  <>
                    <FormatDate date={serviceRequest.utc_start_time} onlyTime/>
                    <span> - </span>
                    <FormatDate date={serviceRequest.utc_end_time} onlyTime/>
                  </>
                )}
              </Space>
            </Space>
            {serviceRequest.status ? (
              <Tag>{capitalizeAllLetters(serviceRequest.status)}</Tag>
            ) : (
              <span/>
            )}
          </Space>
          {!!serviceRequest?.migration_source && !!serviceRequest?.migration_id && (
            <Space
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 8,
              }}
            >
              <Typography.Text style={{fontSize: 14, fontWeight: 'bold'}}>
                {serviceRequest?.migration_source}-ID:
              </Typography.Text>
              <Typography.Text style={{fontSize: 14}} copyable>
                {serviceRequest?.migration_id}
              </Typography.Text>
            </Space>
          )}
        </Card>
      )}
    />
  );

}

export default function LeadDetailsServiceRequests({leadDetails, request}: {
  leadDetails: LeadDetailsModel,
  request: () => void
}) {
  const dispatch = useDispatch()
  const allServiceRequests = (leadDetails?.service_requests || []).sort((a, b) => {
    const dateA = a.utc_start_time ? new Date(a.utc_start_time) : new Date(a.created);
    const dateB = b.utc_start_time ? new Date(b.utc_start_time) : new Date(b.created);
    return dateB.getTime() - dateA.getTime();
  })
  const upcomingServiceRequests = allServiceRequests.filter(serviceRequest => dayjs(serviceRequest.utc_start_time).isAfter(dayjs()))
  const pastServiceRequests = allServiceRequests.filter(serviceRequest => dayjs(serviceRequest.utc_start_time).isBefore(dayjs()))

  const openAddServiceRequestDrawer = () => {
    dispatch({
      type: 'serviceRequest/toggleCreateServiceRequestDrawer',
      payload: {
        lead: leadDetails,
        then: request
      },
    })
  }
  const intl = useIntl()
  return <div>
    <div style={{textAlign: "right"}}>
      <CheckAccess access={[PERMISSIONS.ServiceRequests.CREATE, PERMISSIONS.BUSINESS_PARTNER]}>
        <Button type={"primary"} icon={<PlusOutlined/>} onClick={openAddServiceRequestDrawer}><F id={'pages.common.add'}
                                                                                                 defaultMessage={'Add'}/></Button>
      </CheckAccess>
    </div>
    <Divider><F id={"pages.leads.serviceRequests.upcomingServiceRequests"}
                defaultMessage={'Upcoming requests'}/></Divider>
    <ServiceRequestList serviceRequests={upcomingServiceRequests}/>
    <Divider><F id={"pages.leads.serviceRequests.pastServiceRequests"} defaultMessage={'Past requests'}/></Divider>
    <ServiceRequestList serviceRequests={pastServiceRequests}/>
  </div>
}
