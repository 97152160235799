import {processAPIResponse} from "@/utils/request";
import {createLeadTaskRequest, deleteLeadTaskRequest, fetchLeadTasksList} from "@/services/api/leadTask";
import { message } from "antd";

const initialState = {
  list: []
}
export default {
  namespace: 'leadTasks',

  state: initialState,

  effects: {
    * fetch({payload = {}}: { payload: { search?: string, url?: string } }, {call, put, select}) {
      const {response, data} = yield call(fetchLeadTasksList, {});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'save',
            payload: data
          })
        }
      }, 'data')
    },
    * create({payload = {}}: { payload: { data?: any, then?: () => void } }, {call, put, select}) {
      const {response, data} = yield call(createLeadTaskRequest, {data: payload.data});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'fetch'
          })
          payload.then && payload.then()
        }
      }, 'data')
    },
    * deleteTask({payload: {taskId, cb, intl}}, {call, put}) {
  const {response, data} = yield call(deleteLeadTaskRequest, {params: {taskId}});
  yield processAPIResponse(
    {response, data},
    {
      * onSuccess() {
      
        yield put({
          type: 'fetch',
        });
        cb && cb(data);
      },
      * onError(error) {
        message.error(
          error?.message || intl.formatMessage({ id: 'pages.lead.tasks.deleteTaskFailed', defaultMessage: 'Failed to delete task' }),
        );
      },
    },
  );
},

  },
 
  reducers: {
    save(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    reset(){
      return initialState
    }
  }
};
