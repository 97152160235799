import { getDays } from '@/components/LocationDrawers/BranchDrawer/Service/utils';
import { ServiceAvailability, ServiceModel } from '@/typings/models/Location';

export function getLocationAllowDateTimes(service: ServiceModel) {
  if (Array.isArray(service?.calendar?.Availability)) {
    return service?.calendar?.Availability.filter(
      (availability) => availability.allow_day_and_time !== undefined,
    );
  }
  return [];
}
export const transformToSlotsFormat = (availability: ServiceAvailability[], intl) => {
  const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const daysLookup = getDays(intl);

  return daysOfWeek.map((day) => {
    const matchedDays = availability.filter((a) => a.allow_day_and_time.day.toLowerCase() === day);
    return {
      day: day.charAt(0).toUpperCase() + day.slice(1),
      title: daysLookup?.find(x => x.day === day)?.title,
      enabled: matchedDays.length > 0,
      slots: matchedDays.map((matchedDay) => ({
        start: matchedDay.allow_day_and_time.start || null,
        end: matchedDay.allow_day_and_time.end || null,
      })),
    };
  });
};

export const transformToServerFormat = (data, intl) => {
  const result = [];
  const daysLookup = getDays(intl);
  data.forEach((day) => {
    day?.allow_day_and_time?.slots?.forEach((slot) => {
      if (slot.start && slot.end) {
        result.push({
          allow_day_and_time: {
            day: day.allow_day_and_time?.day?.toLowerCase(),
            title: daysLookup?.find(x => x.day === day.allow_day_and_time?.day?.toLowerCase() )?.title,
            start: slot.start,
            end: slot.end,
          },
        });
      }
    });
  });

  return result;
};
export function getLocationOpenPeriods(service: ServiceModel) {
  if (Array.isArray(service?.calendar?.Availability)) {
    return service?.calendar?.Availability.filter(
      (availability) => availability.allow_period !== undefined,
    ).map((availability) => availability.allow_period);
  }
  return [];
}

export function getLocationBlockPeriods(service: ServiceModel) {
  if (Array.isArray(service?.calendar?.Availability)) {
    return service?.calendar?.Availability.filter((av) => av.block_period).map(
      (av) => av.block_period,
    );
  }
  return [];
}
