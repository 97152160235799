import * as React from "react";
import {LeadDetailsModel} from "@/typings/models/Lead";
import {getLeadOpenTasksCount, hasDueTask} from "@/components/Lead/utils";
import {Badge, Space, Tooltip} from "antd";
import {F} from "@/utils/intl";

type OpenTaskCountBadgeProps = {
  lead: LeadDetailsModel,
  children?: React.ReactElement
  hide?: boolean,
  className?: string,
}
const OpenTaskCountBadge: React.FC<OpenTaskCountBadgeProps> = ({
                                                                 lead,
                                                                 className,
                                                                 children = null,
                                                                 hide = false
                                                               }: OpenTaskCountBadgeProps) => {
  if (hide) return children

  const openTaskCount = getLeadOpenTasksCount(lead)
  if (openTaskCount === 0) return children

  const badgeColor = hasDueTask(lead) ? '#ff4d4f' : '#ccc'

  const nowOpenTasks = openTaskCount - (lead.today_uncompleted_tasks_count || 0)
  return <Tooltip title={<div>
    <div>
      {nowOpenTasks > 0 && <div>
        <Space>
          <span><F id={"pages.lead.tasks.openTasks"} defaultMessage={'Open Tasks'}/>:</span>
          <span>{nowOpenTasks}</span>
        </Space>
      </div>}
      {lead.today_uncompleted_tasks_count > 0 && <div>
        <Space>
          <span><F id={"pages.lead.tasks.overdueTasks"} defaultMessage={'Overdue Tasks'}/>:</span>
          <span>{lead.today_uncompleted_tasks_count}</span>
        </Space>
      </div>}
    </div>
  </div>}>
    <Badge className={className} color={badgeColor} showZero={false} size={"small"} count={openTaskCount}>
      {children}
    </Badge>
  </Tooltip>

}

export default OpenTaskCountBadge
