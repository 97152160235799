import moment, {Moment} from "moment";
import capitalize from "lodash/capitalize"
import dayjs from "dayjs";

const MOCK_INTL = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formatMessage: (options: any) => ""
}
export const getDisabledDate = (current, options: {notAfterToday?: boolean, notBeforeToday?: boolean} = {}) => {
  let finalResult = false;
  if (options.notAfterToday){
    finalResult = finalResult || (current && current > moment().endOf('day'))
  }
  if (options.notBeforeToday){
    finalResult = finalResult || (current && current <= moment().endOf('day'))
  }
  return finalResult
}



export const DateRanges = (intl = MOCK_INTL) => {
  // TODO: LOCALE

  return [
    {
      dateDiff: 7,
      period: [moment().subtract(7, 'days'), moment()],
      title: intl.formatMessage({
        id: 'utils.date.7Days',
        defaultMessage: 'Last 7 Days',
      }),
    },
    {
      dateDiff: 30,
      period: [moment().subtract(30, 'days'), moment()],
      title: intl.formatMessage({
        id: 'utils.date.30Days',
        defaultMessage: 'Last 30 Days',
      }),
    },
    {
      dateDiff: 90,
      period: [moment().subtract(90, 'days'), moment()],
      title: intl.formatMessage({
        id: 'utils.date.90Days',
        defaultMessage: 'Last 90 Days',
      }),
    },
    {
      dateDiff: 180,
      period: [moment().subtract(180, 'days'), moment()],
      title: intl.formatMessage({
        id: 'utils.date.180Days',
        defaultMessage: 'Last 180 Days',
      }),
    }]
};

export const formatDateRanges = (intl) => {
  const formatted = {};
  DateRanges(intl).map(range => {
    formatted[range.title] = range.period;
  });
  return formatted;
}


export function daysForLocale(localeName = 'de-DE', weekday: Intl.DateTimeFormatOptions["weekday"] = 'long') {
  const format = new Intl.DateTimeFormat(localeName, { weekday }).format;
  return [...Array(7).keys()]
    .map((day) => format(new Date(Date.UTC(2021, 5, day))));
}

export function getWeekDayNameForLocale(day: number, localeName = 'de-DE', weekday: Intl.DateTimeFormatOptions["weekday"] = 'long') {
  const days = daysForLocale(localeName, weekday)
  return days[day]
}

export function transformDayNameToLocale(dayName: string, localeName = 'de-DE', weekday: Intl.DateTimeFormatOptions["weekday"] = 'long') {
  function transform(){
    const days = daysForLocale(localeName, weekday).map(dayName => dayName.toLowerCase())
    if (days.includes(dayName.toLowerCase())) {
      return dayName
    }
    const englishDays = daysForLocale('en-US', weekday).map(dayName => dayName.toLowerCase())
    const englishDayIndex = englishDays.indexOf(dayName.toLowerCase())
    if (englishDayIndex >= 0) {
      return getWeekDayNameForLocale(englishDayIndex, localeName, weekday)
    }
    return dayName
  }
  return capitalize(transform())
}

export function formatDateForAPIs(date: Moment){
  return date.format("YYYY-MM-DD")
}


export const setDateAndHour = (dateObject, timeObject) => {
  // Create a Day.js instance from the date object
  const dateInstance = dayjs(dateObject);

  // Extract the hour, minute, and second from the time object
  const timeInstance = dayjs(timeObject);

  // Combine the date and time
  const combinedDateTime = dateInstance
    .hour(timeInstance.hour()) // Set the hour
    .minute(timeInstance.minute()) // Set the minute
    .second(timeInstance.second()); // Set the second

  return combinedDateTime;
};

function getTimeZone() {
  const offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
  return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
}
export function formatToISOStringWithOffset(date) {
  // Get the local timezone offset in minutes
  const timeZoneString = getTimeZone();

  // Format the date and time components
  const formattedDate = date.toISOString().split('T')[0];
  const formattedTime = date.toTimeString().split(' ')[0];

  return `${formattedDate}T${formattedTime}${timeZoneString}`;
}
