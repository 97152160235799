import React, { useEffect, useState } from 'react';
import { useSelector, useIntl, useDispatch } from 'umi';
import {
  Table,
  Checkbox,
  Avatar,
  Typography,
  Button,
  Popover,
  Input,
  Divider,
  Popconfirm,
  Pagination,
} from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { isEmpty, set } from 'lodash';
import { AgentModel, BranchModel, BranchStaffWithServiceModel, ServiceModel } from '@/typings/models/Location';
import { checkNullString } from '@/utils/prettyname';

const { Search } = Input;

interface LocationDetailsFormProps {
  selectedService: ServiceModel;
  onChange?: (data: any, serviceId: string) => void;
  branch: BranchModel;
  branchStaff: BranchStaffWithServiceModel[];
}
interface BranchAgent {
  key: string;
  nameText: string;
  name: JSX.Element;
  id: string;
  service_types: string[];
  services:any[];
  store: boolean;
  online: boolean;
  home: boolean;
}
const LocationAgentsForm: React.FC<LocationDetailsFormProps> = ({
  branch,
  onChange,
  branchStaff
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { agentList } = useSelector(({ location }: any) => location);
  const { fetchLoading, newService } = useSelector(
    ({ location: { newService }, loading }: DefaultRootState) => ({
      fetchLoading:loading.effects['location/createService'],
      newService,
    }),
  );

  const [selectedAgents, setSelectedAgents] = useState({});
  const [selectedAgentsToAssignToBranch, setSelectedAgentsToAssignToBranch] = useState([]);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [agents, setAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  const buildAgents = (search = '') => {
    let agents = branchStaff
      ?.filter((agent) => !branchStaff.some((staff) => staff.id === agent.id))
      ?.map((agent: any) => ({
        label: getAgentDisplayName(agent),
        value: agent.id,
      }));
    if (search) {
      agents = agents.filter((agent: any) =>
        agent.label.toLowerCase().includes(search.toLowerCase()),
      );
    }
    setAgents(agents);
  };

const getAgentDisplayName =(agent)=>{
  if(!agent?.firstname && !agent?.lastname){
    return agent?.email;
  }
  return `${checkNullString(agent.firstname)} ${checkNullString(agent.lastname)}`
}
useEffect(() => {
  if (branchStaff) buildAgents();
}, [branchStaff]);



  useEffect(() => {
    const initialSelectedAgents: BranchAgent[] = branchStaff?.map((staff) => {
      return {
        key: staff.id,
        nameText: getAgentDisplayName(staff),
        name: (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {getAgentDisplayName(staff)}
          </div>
        ),
        id: staff.id,
        service_types: staff.services?.map((service) => service.service_type),
        services: staff.services,
        store: staff.services?.some((service) => service.service_type === 'store'),
        online: staff.services?.some((service) => service.service_type === 'online'),
        home: staff.services?.some((service) => service.service_type === 'home'),
      };
    });
    setSelectedAgents(initialSelectedAgents);
    setData(initialSelectedAgents);
  }, [branch, JSON.stringify(branchStaff)]);

 

  const handleRemoveAgent = (agentId: string) => {
    const updatedData = data.filter((agent) => agent.id !== agentId );
    setData(updatedData);
    removeAgentFromBranch([agentId]);
  };

  const updateSelectedAgents = (serviceId: string, agentIds: string[]) => {
    onChange && onChange({ agents: agentIds }, serviceId)
  }

  const handleCheckboxChange = (agentId: string, serviceType: string) => (e: any) => {
    const serviceId = branch.services?.filter((service) => service.service_type === serviceType)[0]
      ?.id;
    const updatedSelectedAgents = { ...selectedAgents };
    if (e.target.checked) {
     

      const updatedData = data?.map((item) => {
        if (item.key === agentId) {
          return {
            ...item,
            [serviceType]: e.target.checked,
            service_types: [...item.service_types, serviceType],
          };
        }
        return item;
      });

      setData(updatedData);
      const agents = updatedData?.filter(a=>a[serviceType]===true)?.map(a=>a.key)
      updateSelectedAgents(serviceId, agents);

    } else if (!e.target.checked) {

      updatedSelectedAgents[serviceId] =
        updatedSelectedAgents[serviceId]?.filter((a) => a.id !== agentId) || [];
    updateSelectedAgents(serviceId, updatedSelectedAgents[serviceId]);
      const updatedData = data?.map((item) => {
        if (item.key === agentId) {
          return {
            ...item,
            [serviceType]: e.target.checked,
            service_types: item.service_types.filter((type) => type !== serviceType),
          };
        }
        return item;
      });
      setData(updatedData);
    }
  }
  

  const shouldDisableAssignButton = (service_type: string) => {
    // Check if the service is already created for the branch
    const isServiceCreatedForBranch = branch?.services?.some((s: any) => s.service_type === service_type);
  
    // Check if the service is being created for the branch
    const isServiceBeingCreatedForBranch = !isEmpty(newService) && newService?.service_type === service_type;
  
    return !isServiceCreatedForBranch && !isServiceBeingCreatedForBranch;
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: 'pages.location.agents',
        defaultMessage: 'Agents',
      }),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar size="small" style={{ marginRight: 8 }}>
              {record?.nameText?.slice(0, 2)}
            </Avatar>
            {text}
          </div>
          <Popconfirm
            onConfirm={() => handleRemoveAgent(record.id)}
            placement="topRight"
            title={intl.formatMessage({
              id: 'pages.locations.deleteAgentMessage',
              defaultMessage: 'Are you sure to remove this agent?',
            })}
          >
            <MinusCircleOutlined style={{ marginLeft: 8, color: '#999' }} />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: intl.formatMessage({
        id: 'pages.location.store',
        defaultMessage: 'Store',
      }),
      dataIndex: 'store',
      key: 'store',
      align: 'center',
      render: (checked, record) => (
        <Checkbox
          disabled={shouldDisableAssignButton('store')}
          checked={checked}
          onChange={handleCheckboxChange(record.key, 'store')}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'pages.location.online',
        defaultMessage: 'Online',
      }),
      dataIndex: 'online',
      key: 'online',
      align: 'center',
      render: (checked, record) => (
        <Checkbox
          disabled={shouldDisableAssignButton('online')}
          checked={checked}
          onChange={handleCheckboxChange(record.key, 'online')}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'pages.location.home',
        defaultMessage: 'At Home',
      }),
      dataIndex: 'home',
      key: 'home',
      align: 'center',
      render: (checked, record) => (
        <Checkbox
          disabled={shouldDisableAssignButton('home')}
          checked={checked}
          onChange={handleCheckboxChange(record.key, 'home')}
        />
      ),
    },
  ];
  const addAgentToBranch = (branchStaff: string[]) => {
    
    dispatch({
      type: 'location/addAgentToBranch',
      payload: {
        cb: () => {},
        branchId: branch?.id,
        data: {
          staff:branchStaff,
        },
      },
    });
    
  }
  const removeAgentFromBranch = (branchStaff: string[]) => {
    
    dispatch({
      type: 'location/removeAgentFromBranch',
      payload: {
        cb: () => {},
        branchId: branch?.id,
        data: {
          staff:branchStaff,
        },
      },
    });
    
  }
  const paginatedAgents = searchTerm
    ? agentList?.data
        ?.filter(
          (a) =>
            !branchStaff.find((d) => d.id === a.id) &&
            (a.firstname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              a.lastname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              a.email?.toLowerCase().includes(searchTerm.toLowerCase())),
        )
        .slice((currentPage - 1) * pageSize, currentPage * pageSize)
    : agentList?.data
        ?.filter((a) => !branchStaff.find((d) => d.id === a.id))
        .slice((currentPage - 1) * pageSize, currentPage * pageSize);

  
  const popoverContent = (
    <div style={{ height: 400, width: 500, display: 'flex', flexDirection: 'column' }}>
      <Typography.Title style={{ fontSize: 14 }} level={5}>
        {intl.formatMessage({
          id: 'pages.location.chooseASalesAgent',
          defaultMessage: 'Choose a Sales Agent',
        })}
      </Typography.Title>
      <Typography.Text style={{ fontSize: 14, color: '#787878', marginBottom: 8 }} level={5}>
        {intl.formatMessage({
          id: 'pages.location.chooseASalesAgentSubTitle',
          defaultMessage:
            'Select one or more sales agent you want to assign to a branch from the list below.',
        })}
      </Typography.Text>
      <Search
        onSearch={(value) => setSearchTerm(value)}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        placeholder={intl.formatMessage({
          id: 'pages.locations.searchByAgentName',
          defaultMessage: 'Search by agent name',
        })}
        style={{ marginBottom: 12 }}
      />
      <Checkbox.Group
        style={{ width: '100%', flex: 1, overflow: 'auto' }}
        onChange={(checkedValues) => {
          setSelectedAgentsToAssignToBranch(checkedValues);
        }}
      >
        {paginatedAgents?.map((agent) => (
          <div key={agent.id} style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
            <Checkbox value={agent.id}>
              <Avatar size="small" style={{ marginRight: 8 }}>
                {agent?.firstname?.slice(0, 2)}
              </Avatar>
              <Typography.Text style={{ marginLeft: 8 }}>
                {getAgentDisplayName(agent)}
              </Typography.Text>
            </Checkbox>
          </div>
        ))}
      </Checkbox.Group>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={
          searchTerm
            ? agentList?.data?.filter(
                (a) =>
                  !branchStaff.find((d) => d.id === a.id) &&
                  (a.firstname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    a.lastname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    a.email?.toLowerCase().includes(searchTerm.toLowerCase())),
              )?.length
            : agentList?.data?.filter((a) => !branchStaff.find((d) => d.id === a.id))?.length
        }
        onChange={(page, size) => {
          setCurrentPage(page);
          setPageSize(size);
        }}
        style={{ marginTop: 12, textAlign: 'center' }}
      />
      <Divider />
      <div style={{ textAlign: 'right', marginTop: 12 }}>
        <Button
          onClick={() => {
            setSelectedAgents([]);
            setVisible(false);
            setSearchTerm('');
          }}
          style={{ marginRight: 8 }}
        >
          {intl.formatMessage({
            id: 'pages.common.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setVisible(false);
            setSearchTerm('');

            addAgentToBranch(selectedAgentsToAssignToBranch);
          }}
        >
          {intl.formatMessage({
            id: 'pages.lead.action.assign',
            defaultMessage: 'Assign',
          })}
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Table
        dataSource={data}
        columns={columns}
        loading={fetchLoading}
        pagination={false}
        bordered
      />
      {!!agentList?.data?.length && (
        <div style={{ border: '1px solid #f0f0f0', padding: 14 }}>
          <Popover
            content={popoverContent}
            trigger="click"
            open={visible}
            onOpenChange={setVisible}
          >
            <div style={{ color: '#27AE9D', cursor: 'pointer' }}>
              <PlusCircleOutlined style={{ marginRight: 8 }} />

              {intl.formatMessage({
                id: 'pages.locations.addAgent',
                defaultMessage: 'Add Agent',
              })}
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};

export default LocationAgentsForm;