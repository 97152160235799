import React, { useEffect, useState } from 'react';
import { Button, Empty, Popconfirm } from 'antd';
import { FormattedMessage as F, useIntl } from 'umi';
import { CloseCircleOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';

import { UserModel, UserPeriod } from '@/typings/models/User';
import { getUserAdditionalPeriods, getUserOffPeriods } from '@/utils/user';
import DateFormat from '@/components/format/DateFormat';
import Card from '@/components/Card';
import MyDateTimeRangePicker from '@/components/MyDateTimePicker';

import styles from './index.less';
interface UserAvailabilityFormProps {
  user: UserModel;
  mode: string;
  onChange
}

type IUserPeriodsFormProps = {
  periods: ServicePeriod[];
  mode: string;
  onChange: (availability: ServicePeriod[], resolve: Function) => void;
  onRemove: (periods: ServicePeriod[]) => void;
};

const UserPeriodsForm: React.FC<IUserPeriodsFormProps> = ({
  periods,
  onChange,
  mode,
  onRemove,
}: IUserPeriodsFormProps) => {
  const intl = useIntl();
  function removeBlockPeriod(index: number, resolve) {
    const newPeriods = [...periods];
    newPeriods.splice(index, 1);
    onChange(newPeriods, resolve);
    onRemove(newPeriods);
  }

  return (
    <div className={styles.blockPeriods}>
      {periods.length === 0 ? (
        <Empty
          style={{ border: '1px #D9D9D9 solid', padding: 15, borderRadius: 10, width:"100%", margin:0 }}
          description={mode === 'off' ? intl.formatMessage({id:"pages.users.noOffSet", defaultMessage:'No Off Days Added Yet'})  :  intl.formatMessage({id:"pages.users.noAdditionalSet", defaultMessage:'No Additional Availability Set'})}
        />
      ) : (
        <div className={styles.list}>
          {periods.map((period, index) => (
            <Card
              mini
              className={styles.card}
              key={JSON.stringify(period.start + period.end + Math.random() * 100 + index)}
              style={{ backgroundColor: '#FAFAFA' }}
            >
              <div className={styles.inner}>
                <div>
                  <div className={styles.from}>
                    <span className={styles.key}>
                      <F id={'pages.locations.from'} defaultMessage={'From'} />
                    </span>
                    <span className={styles.value}>
                      {mode === 'off' ? (
                        <DateFormat ignoreLocalTimezone date={period.start} />
                      ) : (
                        <span>
                          {' '}
                          {period.date} {period.start}{' '}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className={styles.to}>
                    <span className={styles.key}>
                      <F id={'pages.locations.to'} defaultMessage={'To'} />
                    </span>
                    <span className={styles.value}>
                      {mode === 'off' ? (
                        <DateFormat ignoreLocalTimezone date={period.start} />
                      ) : (
                        <span>
                          {' '}
                          {period.date} {period.end}{' '}
                        </span>
                      )}
                    </span>
                  </div>
                </div>
                <div className={styles.action}>
                  <Popconfirm
                    onConfirm={() => new Promise((resolve) => removeBlockPeriod(index, resolve))}
                    placement={'topRight'}
                    title={
                      mode === 'block' ? (
                        <F
                          id={'pages.location.removeBlockPeriod'}
                          defaultMessage={'Are you sure to remove this block period?'}
                        />
                      ) : (
                        <F
                          id={'pages.location.removeOpenPeriod'}
                          defaultMessage={'Are you sure to remove this open period?'}
                        />
                      )
                    }
                  >
                    <a>
                      <CloseCircleOutlined style={{ color: '#787878', fontSize: 21 }} />
                    </a>
                  </Popconfirm>
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

type IAddUserPeriodProps = { defaultRange?: Moment[]; onAdd: (period: UserPeriod) => void };
export const AddUserPeriod: React.FC<IAddUserPeriodProps> = ({
                                                                       onAdd,
                                                                       defaultRange = [],
                                                                     }: IAddUserPeriodProps) => {
  const [range, setRange] = useState<Moment[]>(defaultRange);

  function onSubmit() {
    const rangeISODates = range.map((date) => date.format());
    onAdd({
      start: rangeISODates[0],
      end: rangeISODates[1],
    });
  }

  function isDisabled() {
    if (!range) {
      return true;
    }
    if (!Array.isArray(range)) {
      return true;
    }
    return !range[0] || !range[1];
  }

  return (
    <div className={styles.addUserAvailability}>
      <div className={styles.inner}>
        <MyDateTimeRangePicker
          styles={{ flex: 1, width: '100%', paddingRight: 15 }}
          onChange={setRange}
          ranges={range}
        />
        <Button
          className={'button-secondary'}
          disabled={isDisabled()}
          onClick={onSubmit}
          type={'primary'}
        >
          <F id={'pages.common.add'} defaultMessage={'Add'} />
        </Button>
      </div>
    </div>
  );
};
const PeriodForm: React.FC<UserAvailabilityFormProps> = ({
  user,
                                                               mode,
                                                               onChange
                                                             }: UserAvailabilityFormProps) => {

  const [periods, setPeriods] = useState<UserPeriod[] | undefined>([]);

  function changed(periodList: UserPeriod[],resolve: Function) {

    if (mode === 'off') {
      user.availability.off_days = periodList.map((openPeriod) => ({
        start: openPeriod.start,
        end: openPeriod.end,
      }));

    } else {

      user.availability.extra_days = periodList.map((openPeriod) => ({
        date:  moment(openPeriod.start).format('YYYY-MM-DD'),
        start: moment(openPeriod.start).format('HH:mm'),
        end:moment(openPeriod.end).format('HH:mm'),
      }));
    }
    onChange &&
      onChange({
        ...user
      });
  }

  function onAdd(period: UserPeriod) {
    const newPeriods = [...(periods || []), period];
    setPeriods(newPeriods)
    changed(newPeriods, null);
  }
  function onRemove(newPeriods) {
    setPeriods(newPeriods)
  }
  useEffect(() => {
    const periods =
      mode !== 'off' ? getUserAdditionalPeriods(user) : getUserOffPeriods(user);
    setPeriods(periods as UserPeriod[]);
  }, [user]);

  return (
    <div>
      <AddUserPeriod onAdd={onAdd} />
      <UserPeriodsForm mode={mode} onRemove={onRemove}  onChange={changed} periods={periods as UserPeriod[]} />
    </div>
  );
};

export default PeriodForm;
