import React, {useEffect, useState} from 'react';
import {Alert, Button, Modal, Tabs} from 'antd';
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import {useDispatch, useSelector} from "umi";
import {ProForm} from "@ant-design/pro-form";

import {F} from "@/utils/intl";
import CreateTaskForm from "@/pages/Tasks/CreateTaskForm";
import * as LeadSelectors from "@/selectors/leadSelectors";
import {TaskAutomationModel} from "@/typings/models/TaskAutomationModel";

import styles from "./index.less";
import RobotOutlined from "@ant-design/icons/RobotOutlined";

const MagicTasksModal = () => {
  const [loading, setLoading] = useState(false);
  const [activeAutomationId, setActiveAutomationId] = useState()
  const [doneAutomationIds, setDoneAutomationIds] = useState([])
  const configuration = useSelector(LeadSelectors.selectLeadsMagicTasks)
  const leadId = configuration?.leadId
  const lead = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadData(state, leadId))

  const [form] = ProForm.useForm();

  const dispatch = useDispatch()
  const open = !isEmpty(configuration)

  const getAutomatedTasks = (filteredAutomationIds = doneAutomationIds) => (configuration?.automatedTasks || [])
    .filter(automatedTask => !filteredAutomationIds.includes(automatedTask.id))
  // @ts-ignore
  const automatedTasks: TaskAutomationModel[] = getAutomatedTasks()

  const automatedTasksMap = automatedTasks.reduce((acc, automatedTask) => {
    acc[automatedTask.id] = automatedTask
    return acc
  }, {})

  const activeAutomatedTask = automatedTasksMap[activeAutomationId]

  const handleTaskResult = () => {
    setDoneAutomationIds( prev => {
      const next = [...prev, activeAutomationId]
      const automations = getAutomatedTasks(next)
      if (automations.length > 0){
        setActiveAutomationId(automations[0].id)
      } else {
        toggle()
      }
      return next
    })
  }
  useEffect(() => {
    if (open && activeAutomationId) {
      form.setFieldsValue({
        title: activeAutomatedTask.task_title,
        description: activeAutomatedTask.task_description,
        due_date: moment().add(activeAutomatedTask.task_due_in_days + 1, 'days')
      })
    }
    if (!activeAutomatedTask) {
      setActiveAutomationId(automatedTasks?.[0]?.id)
    }
    if (!open){
      setDoneAutomationIds([])
    }
  }, [open, activeAutomatedTask])

  const toggle = () => {
    dispatch({
      type: 'leads/closeMagicTasks',
    });
  };
  return (
    <Modal
      wrapClassName={styles.createTaskModalWrap}
      title={<F id={'pages.lead.magicTasks.title'} defaultMessage={'Proposed Tasks'}/>}
      open={open}
      footer={
        <div>
          <Button className={'button-primary-bordered'} onClick={handleTaskResult}>
            <F id={'pages.lead.magicTasks.discard'} defaultMessage={'Discard Task'}/>
          </Button>
          <Button type="primary" loading={loading} onClick={() => form.submit()}>
            <F id={'pages.lead.tasks.create'} defaultMessage={'Create Task'}/>
          </Button>
        </div>
      }
      destroyOnClose
      onCancel={toggle}
    >
      <Alert className={styles.magicTaskHint} message={<F id={'pages.lead.magicTasks.description'} defaultMessage={'An automation has created the following tasks for you. You can accept or discard them as you like.'}/>} type="info" showIcon icon={<RobotOutlined/>}/>

      <Tabs animated size={"small"}
            activeKey={activeAutomationId} items={automatedTasks.map(automatedTask => ({
        key: automatedTask.id,
        label: automatedTask.name
      }))} onChange={setActiveAutomationId}/>
      <CreateTaskForm leadId={leadId}
                      form={form}
                      setLoading={setLoading}
                      updateRequest={() => {
                        dispatch({
                          type: 'leads/fetch'
                        })
                      }}
                      onTaskCreated={handleTaskResult}/>
    </Modal>
  );
};

export default MagicTasksModal;
