import React, {useEffect, useState} from "react";
import {useDispatch, useIntl, useSelector} from "umi";

import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import {Button, notification, Space, Typography} from "antd";
import {getFilterPlacesTitle} from "@/components/Filters/LeadFilters";
import get from "lodash/get";

import styles from "./index.less"

function PreferencesPersistQueue() {
  const dispatch = useDispatch();
  const persistQueue = useSelector(PreferencesSelectors.selectPersistQueue)
  const [shownNotifications, setShownNotifications] = useState<string[]>([])
  const intl = useIntl()


  const getActions = (key) => (
    <Space>
      {/* Hide reset for now because it was adding a lot of complexity handling previous values. */}
      {/*<Button onClick={() => handleReset(key)} size={"small"}>*/}
      {/*  {intl.formatMessage({*/}
      {/*    id: 'component.saveChangeNotification.reset',*/}
      {/*    defaultMessage: 'Reset',*/}
      {/*  })}*/}
      {/*</Button>*/}
      <Button type="primary" onClick={() => handleApprove(key)} size={"small"}>
        {intl.formatMessage({
          id: 'component.saveChangeNotification.save',
          defaultMessage: 'Save',
        })}
      </Button>
    </Space>
  );

  const closeNotification = key => {
    notification.close(key)
    setShownNotifications(prev => prev.filter(k => k !== key))
  }
  const handleApprove = (key: string) => {
    closeNotification(key)
    dispatch({
      type: 'preferences/persistConfirm',
      payload: {
        key
      }
    })

  }
  const handleReset = (key: string) => {
    closeNotification(key)
    dispatch({
      type: 'preferences/persistReset',
      payload: {
        key
      }
    })
  }
  const handleIgnore = (key: string) => {
    closeNotification(key)
    dispatch({
      type: 'preferences/persistIgnore',
      payload: {
        key
      }
    })

  }
  useEffect(() => {
    Object.keys(persistQueue).filter(key => !shownNotifications.includes(key)).forEach(key => {
      setShownNotifications(prev => [...prev, key])
      const persistQueueItem = persistQueue[key]
      notification.open({
        className: styles.persistQueueNotification,
        message: <Space>
          <span>{intl.formatMessage({
            id: 'component.saveChangeNotification.unsavedViewTitle',
            defaultMessage: 'Unsaved View',
          })}</span>
          <strong>{get(getFilterPlacesTitle(), persistQueueItem?.place)}</strong>
        </Space>,
        placement: 'bottomRight',
        description: <Typography.Text>
          {intl.formatMessage({
            id: 'component.saveChangeNotification.saveConfirmMessage',
            defaultMessage: 'You changed this view. Do you want to save your changes for your next visit?',
          })}
        </Typography.Text>,
        duration: 5,
        key,
        btn: getActions(key),
        onClose: () => handleIgnore(key)
      })
    })
  }, [JSON.stringify(persistQueue)]);
  return <div></div>
}

export default React.memo(PreferencesPersistQueue, () => true)
