import React from 'react';
import {Tabs} from 'antd';
import {FormattedMessage as F, NavLink, withRouter} from 'umi';
import Card from '@/components/Card';

const TABS = {
  PROFILE: {
    path: 'profile',
    KEY: '1',
  },
  BILLINGS: {
    path: 'billings',
    KEY: '2',
  },
  LANGUAGE: {
    path: 'language',
    KEY: '3',
  },
};
const { TabPane } = Tabs;
const AccountMenuTabs: React.FC = ({ location }) => {
  function getActiveKey() {
    const { pathname } = location;
    if (pathname.includes(TABS.PROFILE.path)) return TABS.PROFILE.KEY;
    if (pathname.includes(TABS.BILLINGS.path)) return TABS.BILLINGS.KEY;
    if (pathname.includes(TABS.LANGUAGE.path)) return TABS.LANGUAGE.KEY;
  }

  // TODO: Do The Rest
  return (
    <div className={'page-tab-navigation'}>
      <Card>
        <Tabs defaultActiveKey={TABS.PROFILE.KEY} activeKey={getActiveKey()} onChange={() => {}}>
          <TabPane
            tab={
              <NavLink to={`/${TABS.PROFILE.path}`}>
                <F id="component.account.profile" defaultMessage="Profile" />
              </NavLink>
            }
            key={TABS.PROFILE.KEY}
          />
          <TabPane
            tab={
              <NavLink to={`/${TABS.LANGUAGE.path}`}>
                <F id="component.account.language" defaultMessage="Language" />
              </NavLink>
            }
            key={TABS.LANGUAGE.KEY}
          />
        </Tabs>
      </Card>
    </div>
  );
};

export default withRouter(AccountMenuTabs);
