// @ts-nocheck

// There is an internal type error for Ant design rangepicker which we couldn't mitigate. So this file is not checked.

import {DatePicker} from "antd";
import moment, {Moment} from "moment";
import React from "react";
import styles from "./index.less"
import {RangePickerProps} from "antd/lib/date-picker";

const {RangePicker} = DatePicker

type IValue = RangePickerProps["ranges"] | Moment[]
interface MyDateTimeRangePickerProps {
  ranges: IValue,
  // onChange: (value: IValue) => void
  onChange: Function
  styles?: any
}

const MyDateTimeRangePicker: React.FC<MyDateTimeRangePickerProps> = ({ranges, onChange, styles}: MyDateTimeRangePickerProps) => {
  function disabledDate(current: Moment) {
    // Can not select days before today and today
    return current && current <= moment().subtract(1, 'day').endOf('day');
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
    <span className={styles.pickerWrapper} style={{ ...styles }}>
      <RangePicker
        style={{ ...styles }}
        disabledDate={disabledDate}
        ranges={ranges}
        onChange={onChange}
        format="YYYY-MM-DD HH:mm"
        showTime
      />
    </span>
  );
}

export default MyDateTimeRangePicker
