import React, {useEffect} from 'react';
import {Button, Popconfirm, Switch, Tag} from 'antd';
import {DeleteOutlined, EditOutlined, PlusOutlined,} from '@ant-design/icons';
import {connect, FormattedMessage as F, useIntl, useSelector} from 'umi';

import Table from '@/components/Table';
import {DispatchFunction} from '@/typings/models/ReduxState';
import {ServiceModel} from '@/typings/models/Location';
import MyPageContainer from "@/components/MyPageContainer";
import {CheckAccess} from "@/utils/access";
import PERMISSIONS from "@/constants/permissions";
import getLocationTypes from '@/constants/locationTypes';
import {usePreviouslySelectedSolution} from "@/utils/hooks";

interface ServicesManagementProps {
  dispatch: DispatchFunction;
  list: ServiceModel[];
  loading: boolean;
}

const LocationManagement: React.FC<ServicesManagementProps> = ({dispatch, list, loading}) => {
  const intl = useIntl();
  const {userBranches} = useSelector(({location: {userBranches}}: DefaultRootState) => ({
    userBranches,
  }));
  usePreviouslySelectedSolution()
  const fetchAgents = () => {
    dispatch({
      type: 'location/fetchAgents'
    });
};
  const fetchAvailableTimezones = () => {
    dispatch({
      type: 'location/getTimeZones'
    });}
function fetchBranchTableData(payload?: { search: string }) {
  dispatch({
      type: 'location/getUserBranches',
      payload
    });
    fetchAgents();

  }

  useEffect(() => {
    fetchBranchTableData();
    fetchAvailableTimezones()
  }, []);

  const toggleBranchDrawer = (payload) => {
    dispatch({
      type: 'location/toggleBranchDrawer',
      payload,
    });
  };

  const deleteBranch = (id: string, resolve: Function) => {
    dispatch({
      type: 'location/deleteBranch',
      payload: {
        branchId: id,
        cb: resolve,
        intl,
      },
    });
  };
  const branchServiceColumns = [
    {
      title: <F id="pages.locations.name" defaultMessage="Name" />,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      filters: [...new Set(userBranches?.map((item) => ({ text: item.name, value: item.id })))],
      onFilter: (value, record) => record.name.includes(value),
    },

    {
      title: <F id="pages.locations.branchStatus" defaultMessage="Branch Status" />,
      dataIndex: 'is_active',
      key: 'is_active',
      render: (active, record) => <Switch checked={record.is_active} disabled />,
      filters: [
        ...new Set([
          {
            text: intl.formatMessage({
              id: 'pages.common.active',
              defaultMessage: 'Active',
            }),
            value: true,
          },
          {
            text: intl.formatMessage({
              id: 'pages.common.inActive',
              defaultMessage: 'Inactive',
            }),
            value: false,
          },
        ]),
      ],
      onFilter: (value, record) => record?.is_active === value,
    },

    {
      title: <F id="pages.locations.address" defaultMessage="Address" />,
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => a?.address.localeCompare(b?.address),
      filters: [
        ...new Set(userBranches.map((item) => ({ text: item?.address, value: item?.address }))),
      ],
      onFilter: (value, record) => record?.address.includes(value),
    },

    {
      title: <F id="pages.locations.services" defaultMessage="Services" />,
      dataIndex: 'Services',
      key: 'Services',
      filters: [
        ...new Set(getLocationTypes().map((item) => ({ text: item?.label, value: item?.value }))),
      ],
      onFilter: (value, record) => {
        return record?.services?.some((s) => s.service_type === value);
      },

      render: (services, record) => (
        <>
          {record?.services.map((service) => (
            <Tag key={service.id} color="blue" style={{ marginBottom: 8 }}>
              {service.service_type_label}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: <F id="pages.customers.actions" defaultMessage="Actions" />,
      key: 'actions',
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CheckAccess access={[PERMISSIONS.Locations.EDIT, PERMISSIONS.BUSINESS_PARTNER]}>
            <Button
              type="link"
              onClick={() => {
                dispatch({
                  type: 'location/getBranch',
                  payload: { branchId: record.id },
                });
                toggleBranchDrawer({
                  branch: record,
                });
              }}
            >
              <EditOutlined />
            </Button>
          </CheckAccess>
          <CheckAccess access={[PERMISSIONS.Locations.DELETE, PERMISSIONS.BUSINESS_PARTNER]}>
            <Popconfirm
              onConfirm={() => new Promise((resolve, reject) => deleteBranch(record.id, resolve))}
              placement={'topRight'}
              title={
                <F
                  id="pages.locations.deleteBranchMessage"
                  defaultMessage="Are you sure to remove this branch?"
                />
              }
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </CheckAccess>
        </div>
      ),
    },
  ];
  return (
    <MyPageContainer>
      <Table
        columns={branchServiceColumns}
        loading={loading}
        dataSource={userBranches}
        request={fetchBranchTableData}
        rowKey="id"
        toolBarRender={() => (
          <>
            <CheckAccess access={[PERMISSIONS.Locations.CREATE, PERMISSIONS.BUSINESS_PARTNER]}>
              <Button
                type="primary"
                onClick={() => {
                  dispatch({
                    type: 'location/toggleBranchDrawer',
                    payload: {
                      branch: {
                        id: 'NEW',
                        name: '',
                        address: '',
                        is_active: false,
                        latitude: '',
                        longitude: '',
                        services: [],
                      },
                    },
                  });
                }}
              >
                <PlusOutlined/>{' '}
                {intl.formatMessage({
                  id: 'pages.locations.addBranch',
                  defaultMessage: 'Add branch',
                })}
              </Button>
            </CheckAccess>
          </>
        )}
      />
    </MyPageContainer>
  );
};

export default connect(({location, loading}) => ({
  list: location.list,
  loading: loading.effects['location/fetch'],
}))(LocationManagement);
