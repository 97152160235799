import {
  CONVERSION_RATE,
  GROUP_BY_ASSIGNEE,
  GROUP_BY_BRANCH,
  GROUP_BY_MARKETING_CHANNEL,
  GROUP_BY_STATUS,
  LEADS,
  REVENUE
} from "../constants"
import {getIntl} from "umi";


export const getMetricOptions = () => {
  const intl = getIntl()
  return [
    {
      value: LEADS,
      label: intl.formatMessage({ id: 'pages.leads.leads', defaultMessage: 'Leads' }),
      color: '#4096ff',
    },
    {
      value: REVENUE,
      label: intl.formatMessage({ id: 'pages.leads.revenue', defaultMessage: 'Revenue' }),
      color: '#389e0d',
    },
    {
      value: CONVERSION_RATE,
      label: intl.formatMessage({
        id: 'pages.leads.conversionRate',
        defaultMessage: 'Conversion Rate',
      }),
      color: '#faad14',

    },
  ]
}

export const getGroupByOptions = () => {
  const intl = getIntl()
  return  [
    {
      value: GROUP_BY_BRANCH,
      label: intl.formatMessage({
        id: 'pages.leads.branch',
        defaultMessage: 'Branch',
      }),
    },
    {
      value: GROUP_BY_STATUS,
      label: intl.formatMessage({
        id: 'pages.leads.lead_state',
        defaultMessage: 'Lead State',
      }),
    },
    {
      value: GROUP_BY_MARKETING_CHANNEL,
      label: intl.formatMessage({
        id: 'pages.leads.marketingChannel',
        defaultMessage: 'Marketing Channel',
      }),
    },
    {
      value: GROUP_BY_ASSIGNEE,
      label: intl.formatMessage({
        id: 'pages.leads.assignee',
        defaultMessage: 'Assignee',
      }),
    },
  ]

}
