import {IBooking} from "conversional-types/src/bookingTypes";
import React, {useEffect} from "react";
import {Button, Divider, Input, List, Segmented, Space} from "antd";
import {MagicAppointmentBookingModel} from "@/typings/models/MagicAppointmentBooking";
import {fetchWidgetLocationsRequest} from "@/services/api/widget";
import {ILocation} from "conversional-journey";
import {RightOutlined} from "@ant-design/icons";
import styles from "./index.less"

export default function MagicAppointmentBookingLocationSelector({
                                                                  booking,
                                                                  onLocationChange,
                                                                  onLocationTypeChange,
                                                                  magicAppointmentBookingConfiguration
                                                                }: {
  booking: IBooking,
  magicAppointmentBookingConfiguration: MagicAppointmentBookingModel
}) {

  const locationTypes = booking?.locations?.locationTypes || []
  const [locations, setLocations] = React.useState<ILocation[]>([])
  const [locationsLoading, setLocationsLoading] = React.useState(false)
  const [selectedLocationType, setSelectedLocationType] = React.useState<string>()
  const [searchQuery, setSearchQuery] = React.useState<string>()
  const [selectedLocationId, setSelectedLocationId] = React.useState<string>()
  const [timeslots, setTimeslots] = React.useState<any[]>([])
  const [timeslotsLoading, setTimeslotsLoading] = React.useState(false)

  useEffect(() => {
    if (locationTypes.length > 0)
      handleLocationTypeChange(locationTypes[0].typeFilter)

  }, [JSON.stringify(locationTypes)]);

  if (locationTypes.length === 0) return null

  const handleSearch = (search) => {
    setSearchQuery(search)
    handleLocationTypeChange(selectedLocationType, search)
  }
  const handleLocationTypeChange = (type: string = selectedLocationType, search: string = searchQuery) => {
    const locationTypeObject = locationTypes.find(locationType => locationType.typeFilter === type)
    setSelectedLocationType(type)
    onLocationTypeChange(locationTypeObject)
    setLocationsLoading(true)
    setSelectedLocationId(null)
    const query = {
      journeyId: magicAppointmentBookingConfiguration.journey,
      apiKey: magicAppointmentBookingConfiguration.api_key,
      type,
    }
    if (search) {
      query.close_to = search
    }
    fetchWidgetLocationsRequest({
      query,
      baseUrl: magicAppointmentBookingConfiguration.base_url
    }).then(response => {
      setLocations(Array.isArray(response.data.data) ? response.data.data : [])
      setLocationsLoading(false)
    })
  }


  const selectLocation = (location: ILocation) => {
    onLocationChange(location)
  }

  return <div>
    <Divider/>
    <Segmented onChange={handleLocationTypeChange}
               style={{marginBottom: 10}}
               options={locationTypes.map(locationType => ({
                 label: locationType.label,
                 value: locationType.typeFilter
               }))} block/>

    {locations.length > 0 &&
      <Input.Search value={searchQuery} onChange={e => setSearchQuery(e.target.value)} placeholder={"Search location"} style={{marginBottom: 10}}
                    onSearch={handleSearch}/>}

    <List
      itemLayout="horizontal"
      loading={locationsLoading}
      dataSource={locations}
      bordered
      style={{
        maxHeight: "300px",
        minHeight: 100,
        marginBottom: 10,
        overflow: "auto",
      }}
      size={"small"}
      renderItem={location => (
        <List.Item
          className={styles.locationListItem}
          style={{
            cursor: "pointer"
          }}
          onClick={() => selectLocation(location)}
          actions={[<Button icon={<RightOutlined/>} type={"text"}
                            disabled={selectedLocationId === location.id}
                            key={`select_${location.id}`}>
          </Button>]}>
          <Space direction={"vertical"}>
            <strong>{location.name}</strong>
            {location.location}
          </Space>
        </List.Item>
      )}
    />

  </div>
}
