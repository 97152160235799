import {getIntl} from "@@/plugin-locale/localeExports";


export const FILTER_PLACES = {
  LEAD_LIST: 'LEAD_LIST',
  LEAD_PERFORMANCE_REPORT: 'LEAD_PERFORMANCE_REPORT',
  LEAD_FUNNEL_REPORT: 'LEAD_FUNNEL_REPORT',
  LEAD_ROI_FUNNEL_REPORT: 'LEAD_ROI_FUNNEL_REPORT',
  TEAM_VIEW_CALENDAR: 'TEAM_VIEW_CALENDAR',
}

export const getFilterPlacesTitle = () => {
  const intl = getIntl()
  return {
    [FILTER_PLACES.LEAD_LIST]: intl.formatMessage({
    id: 'menu.Leads',
    defaultMessage: 'leads'
  }),
    [FILTER_PLACES.LEAD_PERFORMANCE_REPORT]: intl.formatMessage({
    id: 'menu.reports.performance',
    defaultMessage: 'Performance'
  }),
    [FILTER_PLACES.LEAD_FUNNEL_REPORT]: intl.formatMessage({
    id: 'menu.reports.funnel',
    defaultMessage: 'Funnel'
  }),
    [FILTER_PLACES.LEAD_ROI_FUNNEL_REPORT]: 'Lead ROI Funnel Report',
    [FILTER_PLACES.TEAM_VIEW_CALENDAR]: 'Calendar',
  }
}
