import React from 'react'

import { LeadModel } from '@/typings/models/Lead'
import EditableTextField from '@/components/Lead/EditableTextField'


const getLeadEmail = (lead: LeadModel) => lead?.customer?.email

const LeadEmail = ({lead, readonly, noLink}: {lead: LeadModel, readonly?: boolean, noLink?: boolean}) => {
  const leadEmail = getLeadEmail(lead)
  const representation = leadEmail || ' - '
  return <EditableTextField editable={!readonly} lead={lead} property={'customer.email'}>
    {leadEmail && !noLink ? <a href={`mailto:${representation}`}>{representation}</a> : <span>{representation}</span>}
  </EditableTextField>
}

export default LeadEmail
