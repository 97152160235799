import React, { useState } from 'react'
import { Button, Popconfirm, Space, Tooltip } from 'antd'
import get from 'lodash/get'
import {
  CalendarOutlined,
  DownOutlined,
  InboxOutlined,
  MailOutlined,
  PhoneOutlined,
  UndoOutlined,
  UpOutlined
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'umi'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'

import LeadName from '@/components/Lead/LeadName'
import { LeadCardOrderedProperties } from '@/components/Lead/LeadProperties'
import { LEAD_DETAILS_VISIBLE_PROPERTIES } from '@/components/Kanban/VisibilitySettings/constants'
import Card from '@/components/Card'
import { F } from '@/utils/intl'
import { LeadDetailsModel, LeadModel } from '@/typings/models/Lead'

import LeadPreferencesList from '@/components/Lead/LeadDetailsDrawer/LeadPreferencesList'
import * as PreferencesSelectors from '@/selectors/preferencesSelectors'

import styles from './index.less'


export default function LeadDetailsHeader({lead, leadDetails}: { lead: LeadModel, leadDetails: LeadDetailsModel }) {
  const [isDetailsExpanded, setIsDetailsExpanded] = React.useState(false)
  const magicAppointmentBooking = useSelector(PreferencesSelectors.selectWidgetApiAppointmentBookingIntegration)
  const [archiveLoading, setArchiveLoading] = useState(false)
  const dispatch = useDispatch()

  const openMagicAppointmentBookingModal = () => {
    dispatch({
      type: 'leads/openMagicAppointmentBooking',
      payload: {
        leadId: lead.id,
        ...magicAppointmentBooking
      }
    })
  }

  const handleArchive = () => {
    setArchiveLoading(true)
    dispatch({
      type: 'leads/edit',
      payload: {
        leadId: lead.id,
        is_archived: !lead.is_archived,
        then(){
          setArchiveLoading(false)
        }
      }
    })
  }
  const archiveAction = lead.is_archived ? <Tooltip title={<F id={"pages.lead.action.unarchive"} defaultMessage={"Unarchive"}/>}>
    <Button size={"small"} loading={archiveLoading} icon={<UndoOutlined/>} className={'button-secondary button-bordered'} onClick={handleArchive}/>
  </Tooltip> : <Popconfirm title={<F id={"pages.lead.action.archive.confirmation"}/>} onConfirm={handleArchive}>
    <Tooltip title={<F id={"pages.lead.action.archive"} defaultMessage={"archive"}/>}>
      <Button size={"small"} loading={archiveLoading} icon={<InboxOutlined/>} className={'button-secondary button-bordered'}/>
    </Tooltip>
  </Popconfirm>
  return <Card className={styles.leadDetailsHeaderCard}>
    <div className={styles.leadDetailsHeaderContainer}>
      <div className={styles.leadHeaderTop}>
        <div>
          <LeadName nameClassName={styles.leadHeaderName} avatarClassName={styles.leadHeaderAvatar} lead={lead}/>
        </div>
        <div className={styles.leadHeaderActions}>
          <Space>
            {archiveAction}
            {get(lead, 'customer.email') &&
              <a href={`mailto:${get(lead, 'customer.email')}`}><Button size={"small"} className={'button-secondary button-bordered'}
                                                                        icon={<MailOutlined/>}>
                <F id={"pages.lead.action.email"} defaultMessage={'Email'}/>

              </Button></a>}
            {get(lead, 'customer.phone') &&
              <a href={`tel:${get(lead, 'customer.phone')}`}><Button size={"small"} className={'button-secondary button-bordered'}
                                                                     icon={<PhoneOutlined/>}>
                <F id={"pages.lead.action.call"} defaultMessage={'Call'}/>
              </Button>
              </a>}

            {!isEmpty(magicAppointmentBooking) &&
              <Button size={"small"} className={'button-secondary button-bordered'}
                      onClick={openMagicAppointmentBookingModal}
                      icon={<CalendarOutlined/>}>
                <F id={"pages.lead.action.bookAppointment"} defaultMessage={'Book'}/>
              </Button>
            }
          </Space>

          {/*<LeadContextMenu leadId={lead?.id} actionsFilter={['archive']}*/}
          {/*                 archiveContent={<Button size={"large"} icon={<InboxOutlined/>}/>}/>*/}
        </div>
      </div>
      <div className={classNames(styles.leadHeaderBottom, {
        [styles.leadDetailsExpanded]: isDetailsExpanded,
      })}>
        {isDetailsExpanded && <div>
          <LeadCardOrderedProperties
            grid={{
              sm: 8,
              xs: 12
            }}
            overrideVisibleProperties={LEAD_DETAILS_VISIBLE_PROPERTIES}
            lead={lead}
            showFieldName
            isTable
            classes={{
              fieldName: styles.leadDetailsFieldName,
              fieldValue: styles.leadDetailsFieldValue
            }}
            spaceSize={24}/>
          <LeadPreferencesList
            leadDetails={leadDetails}
            grid={{
              sm: 8,
              xs: 12
            }}/>
        </div>}

        <Button className={styles.leadDetailsExpandButton} onClick={() => setIsDetailsExpanded(prev => !prev)}
                size={"small"}>
          <F id={`pages.lead.drawer.view${isDetailsExpanded ? 'Less' : 'More'}`}
             defaultMessage={`View ${isDetailsExpanded ? 'Less' : 'More'}`}/>
          {isDetailsExpanded ? <UpOutlined style={{fontSize: 10}}/> : <DownOutlined style={{fontSize: 10}}/>}
        </Button>
      </div>
    </div>
  </Card>
}
