import React from 'react';
import {Button, Input, Popover, Radio, Space} from 'antd';
import {ApiOutlined} from '@ant-design/icons';

import storage from 'conversional-persistent-storage';

import dev from '@/constants/environment/dev';
import production from '@/constants/environment/production';
import testing from '@/constants/environment/testing';
import staging from '@/constants/environment/staging';

const ENVIRONMENTS = [
  {
    title: 'Production',
    url: production.ROOT_URL,
  },
  {
    title: 'Testing',
    url: testing.ROOT_URL,
  },
  {
    title: 'Staging',
    url: staging.ROOT_URL,
  },
  {
    title: 'Development',
    url: dev.ROOT_URL,
  },
];

const EnvSwitcher: React.FC = () => {
  const [rootApiUrl, setRootApiUrl] = React.useState<string>(
    storage.getItem('rootApiUrl', undefined, 'localStorage'),
  );
  const isDevConfigured = rootApiUrl === dev.ROOT_URL;
  const onRootApiUrlChange = (url: string) => {
    setRootApiUrl(url);
    storage.setItem('rootApiUrl', url, undefined, 'localStorage');
  };

  const onRoleChange = (selectedRole: string) => {
    if (!isDevConfigured) return;
  };


  // @ts-ignore
  if (BACKOFFICE_ENV !== 'dev') return null;
  return (
    <Popover
      content={
        <div style={{ width: 450 }}>
          <Space direction={'vertical'}>
            <Radio.Group value={rootApiUrl} size={'small'}>
              {ENVIRONMENTS.map((env) => (
                <Radio.Button
                  value={env.url}
                  key={env.title}
                  onClick={() => onRootApiUrlChange(env.url)}
                >
                  {env.title}
                </Radio.Button>
              ))}
            </Radio.Group>
            <Input
              style={{ width: 400 }}
              size={'small'}
              placeholder={'Base API Url'}
              value={rootApiUrl}
              onChange={(e) => onRootApiUrlChange(e.target.value)}
            />
          </Space>
        </div>
      }
    >
      <Button
        type={'primary'}
        size={'small'}
        style={{ position: 'fixed', top: 10, right: 10, zIndex: 100000 }}
        icon={<ApiOutlined />}
      >
        ENVIRONMENT
      </Button>
    </Popover>
  );
};

export default EnvSwitcher;
