import React from 'react';
import { Checkbox } from 'antd';
import TriStateCheckbox from './TriStateCheckbox';

interface PermissionCheckboxProps {
  checked: boolean | 'indeterminate';
  rowKey: string;
  colKey: string;
  onChange: (rowKey: string, colKey: string, checked: boolean) => void;
}

const PermissionCheckbox: React.FC<PermissionCheckboxProps> = ({ checked, rowKey, colKey, onChange,disabled }) => {
  const handleChange = (e) => {
    onChange(rowKey, colKey, e.target.checked);
  };

  if (rowKey.includes('group-')) {
    return (
      <TriStateCheckbox
        disabled={disabled}
        key={rowKey + colKey}
        checked={checked === true}
        indeterminate={checked === 'indeterminate'}
        onChange={handleChange}
      />
    );
  }

  return (
    <Checkbox
    disabled={disabled}
      key={rowKey + colKey}
      checked={checked}
      onChange={handleChange}
    />
  );
};

export default PermissionCheckbox;