import { getDays } from '@/components/LocationDrawers/BranchDrawer/Service/utils';
import { ServiceAvailability, ServiceModel } from '@/typings/models/Location';
import { UserAvailability, UserModel } from '@/typings/models/User';

export function getUserWorkingHours(user: UserModel) {
    return user?.availability?.working_hours;
}
export const transformToSlotsFormat = (availability, intl) => {
  const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const daysLookup = getDays(intl);

  return daysOfWeek.map((day) => {
    const matchedDays = availability[day];
    return {
      day: day.charAt(0).toUpperCase() + day.slice(1),
      title: daysLookup?.find(x => x.day === day)?.title,
      enabled: matchedDays.length > 0,
      slots: matchedDays.map((timeRange) => {
        const [start, end] = timeRange.split('-');
        return {
          start: start || null,
          end: end || null,
        };
      }),
    };
  });
};

export const transformToServerFormat = (data, intl) => {
  const result = {};
  data.forEach((day) => {
    const dayName = day.day.toLowerCase();
    result[dayName] = day.slots.map((slot) => `${slot.start}-${slot.end}`);
  });

  return result;
};
export function getUserOffPeriods(user: UserModel) {
  return user?.availability?.off_days || [];
}

export function getUserAdditionalPeriods(user: UserModel) {
  return user?.availability?.extra_days || [];

}
