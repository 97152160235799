import React from 'react'
import * as ReactIcons from '@/components/Icons/ReactIconsMap'
import { ReactSVGIconProps } from '@/components/Icons/ReactIconsMap'


type CustomReactIconProps = ReactSVGIconProps & React.SVGProps<SVGSVGElement>;

const CustomReactIcon: React.FC<CustomReactIconProps> = ({
                                                           tag,
                                                           attr = {},
                                                           child = [],
                                                           ...props
                                                         }: CustomReactIconProps) => {
  return React.createElement(
    tag,
    { ...attr, ...props },
    child.map((node, index) => <CustomReactIcon {...node} key={index} />)
  )
}

export const GoTaskList = (props: React.SVGProps<SVGSVGElement>) =>
  <CustomReactIcon {...ReactIcons.GoTaskListIconProps} {...props} />
export const PiCalendarX = (props: React.SVGProps<SVGSVGElement>) =>
  <CustomReactIcon {...ReactIcons.PiCalendarXIconProps} {...props} />
export const LiaCommentsSolid = (props: React.SVGProps<SVGSVGElement>) =>
  <CustomReactIcon {...ReactIcons.LiaCommentsSolidIconProps} {...props} />
export const MdOutlineShowChart = (props: React.SVGProps<SVGSVGElement>) =>
  <CustomReactIcon {...ReactIcons.MdOutlineShowChartIconProps} {...props} />
export const BsClipboardCheck = (props: React.SVGProps<SVGSVGElement>) =>
  <CustomReactIcon {...ReactIcons.BsClipboardCheckIconProps} {...props} />

