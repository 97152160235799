import { processAPIResponse } from "@/utils/request";
import {
  createRoleRequest,
  deleteRoleRequest,
  fetchPermissionListByCategoryRequest,
  fetchPermissionListByRoleRequest,
  reorderRoleRequest,
  updateRoleRequest,
} from '@/services/api/role';
import { message } from "antd";

export default {
  namespace: 'roles',

  state: {
    permissionsGroupByCategory:{},
    permissionsGroupByRole:{}
  },

  effects: {

    * fetchPermissionListGroupByCategoryRequest({ }, { call, put, select }) {
      const { response, data } = yield call(fetchPermissionListByCategoryRequest, {});
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'sePermissionListGroupByCategory',
            payload: data
          })
        }
      })
    },
    * fetchPermissionGroupByRoleRequest({ }, { call, put, select }) {
    const { response, data } = yield call(fetchPermissionListByRoleRequest, {});
    yield processAPIResponse({ response, data }, {
      * onSuccess(data) {
        yield put({
          type: 'sePermissionListGroupByRole',
          payload: data
        })
      },
      * onError(error) {
        cb && cb(false);
        message.error(error?.message || (intl && intl?.formatMessage({
          id: "pages.roles.createRoleFailed",
          defaultMessage: 'Creating Role failed'
        })))
      }
    })
  },
  * createRole({payload}, {call, put, select}) {
  const intl = payload.intl
  const cb = payload.cb
  const {response, data} = yield call(createRoleRequest, {payload});

  yield processAPIResponse({response, data}, {
    * onSuccess(data) {
      cb && cb(true);
      yield put({
        type: 'fetchPermissionGroupByRoleRequest',
      })
      yield put({
        type: 'fetchPermissionListGroupByCategoryRequest',
      })
      intl && message.success(intl.formatMessage({
        id: "pages.roles.createRoleSuccess",
        defaultMessage: 'Role created successfully'
      }))
    },
    * onError(error) {
      cb && cb(false);
      message.error(error?.message || (intl && intl?.formatMessage({
        id: "pages.roles.createRoleFailed",
        defaultMessage: 'Creating Role failed'
      })))
    }
  })
},
 * updateRole({payload: {cb, intl, id, ...body}}, {call, put, select}) {

      const {response, data} = yield call(updateRoleRequest, {
        params: { roleId: id },
          payload: body
        }
      );
      yield processAPIResponse({response, data}, {

        * onSuccess(data) {
      
        intl && message.success(intl.formatMessage({
          id: "pages.roles.updateRoleSuccess",
          defaultMessage: 'Role updated successfully'
        }))
          cb && cb(data, true); 
        },
        * onError(error) {
          yield put({
            type: 'fetchPermissionGroupByRoleRequest',
          })
          yield put({
            type: 'fetchPermissionListGroupByCategoryRequest',
          })
          cb && cb({
            message: error?.message || intl.formatMessage({
              id: "pages.roles.editRoleFailed",
              defaultMessage: 'Edit Role failed'
            })
          }, false);
          message.error(error?.message || intl.formatMessage({
            id: "pages.roles.editRoleFailed",
            defaultMessage: 'Edit Role failed'
          }))
        },
      })
    },
    * deleteRole({payload: {id, cb, intl}}, {call, put}) {
      const { response, data } = yield call(deleteRoleRequest, { params: { roleId: id } });
      yield processAPIResponse({response, data}, {
        * onSuccess() {
        yield put({
          type: 'fetchPermissionGroupByRoleRequest',
        })
        yield put({
          type: 'fetchPermissionListGroupByCategoryRequest',
        })
          intl && message.success(intl.formatMessage({
            id: "pages.roles.removeRoleSuccess",
            defaultMessage: 'Role deleted successfully'
          }))
          cb && cb(data)
        },
        * onError(error) {
          message.error(error?.message || intl.formatMessage({
            id: "pages.roles.removeRoleFailed",
            defaultMessage: 'Remove Role failed'
          }))
        },
      })
    },
    * reorderRole({payload: {cb, intl, order}}, {call, put, select}) {

    const {response, data} = yield call(reorderRoleRequest, {
      payload: { order }
      }
    );
    yield processAPIResponse({response, data}, {

      * onSuccess(data) {
      intl && message.success(intl.formatMessage({
        id: "pages.roles.updateRoleSuccess",
        defaultMessage: 'Role updated successfully'
      }))
        cb && cb(data, true);
      },
      * onError(error) {
      yield put({
        type: 'fetchPermissionGroupByRoleRequest',
      })
      yield put({
        type: 'fetchPermissionListGroupByCategoryRequest',
      })
        cb && cb({
          message: error?.message || intl.formatMessage({
            id: "pages.roles.editRoleFailed",
            defaultMessage: 'Edit Role failed'
          })
        }, false);
        message.error(error?.message || intl.formatMessage({
          id: "pages.roles.editRoleFailed",
          defaultMessage: 'Edit Role failed'
        }))
      },
    })
  }
  },
  
  reducers: {
    sePermissionListGroupByCategory(state, action) {
      return {
        ...state,
        permissionsGroupByCategory: action.payload
      }
    },
    sePermissionListGroupByRole(state, action) {
      return {
        ...state,
        permissionsGroupByRole: action.payload
      }
    }
  }
};
