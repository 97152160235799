import {useEffect} from 'react'
import {useModel, useSelector} from "umi";
import * as PreferencesSelector from "@/selectors/preferencesSelectors";

export const usePreviouslySelectedSolution = () => {
  const {initialState, setInitialState} = useModel('@@initialState');
  const isPreferencesInitialized = useSelector(PreferencesSelector.isPreferencesListInitialized)
  const previouslySelectedSolution = useSelector(PreferencesSelector.selectSelectedSolution)

  useEffect(() => {
    if (previouslySelectedSolution)
      setInitialState({
        ...initialState,
        selectedSolution: previouslySelectedSolution
      })
    return () => {
    }
  }, [isPreferencesInitialized])
}
