export type ReactSVGIconProps = {
  tag: string;
  attr?: React.SVGAttributes<SVGElement>;
  child?: ReactSVGIconProps[];
};
export const GoTaskListIconProps: ReactSVGIconProps = {
  'tag': 'svg',
  'attr': { 'viewBox': '0 0 24 24', fill: 'currentColor' },
  'child': [{
    'tag': 'path',
    'attr': { 'd': 'M3 6a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm1.5 4.5h4v-4h-4Zm8.25-5a.75.75 0 0 0 0 1.5h7.5a.75.75 0 0 0 0-1.5h-7.5Zm0 6a.75.75 0 0 0 0 1.5h7.5a.75.75 0 0 0 0-1.5h-7.5Zm0 6a.75.75 0 0 0 0 1.5h7.5a.75.75 0 0 0 0-1.5h-7.5Zm-2.97-2.53a.75.75 0 0 1 0 1.06l-3.5 3.5a.75.75 0 0 1-1.06 0l-2-2a.75.75 0 1 1 1.06-1.06l1.47 1.47 2.97-2.97a.75.75 0 0 1 1.06 0Z' },
    'child': []
  }]
}

export const PiCalendarXIconProps: ReactSVGIconProps = {
  'tag': 'svg',
  'attr': { 'viewBox': '0 0 256 256', 'fill': 'currentColor' },
  'child': [{
    'tag': 'path',
    'attr': { 'd': 'M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-50.34-74.34L139.31,152l18.35,18.34a8,8,0,0,1-11.32,11.32L128,163.31l-18.34,18.35a8,8,0,0,1-11.32-11.32L116.69,152,98.34,133.66a8,8,0,0,1,11.32-11.32L128,140.69l18.34-18.35a8,8,0,0,1,11.32,11.32Z' },
    'child': []
  }]
}

export const LiaCommentsSolidIconProps: ReactSVGIconProps = {
  'tag': 'svg',
  'attr': { 'viewBox': '0 0 32 32', fill: 'currentColor'  },
  'child': [{
    'tag': 'path',
    'attr': { 'd': 'M 2 5 L 2 21 L 6 21 L 6 26.09375 L 7.625 24.78125 L 12.34375 21 L 22 21 L 22 5 Z M 4 7 L 20 7 L 20 19 L 11.65625 19 L 11.375 19.21875 L 8 21.90625 L 8 19 L 4 19 Z M 24 9 L 24 11 L 28 11 L 28 23 L 24 23 L 24 25.90625 L 20.34375 23 L 12.84375 23 L 10.34375 25 L 19.65625 25 L 26 30.09375 L 26 25 L 30 25 L 30 9 Z' },
    'child': []
  }]
}

export const BsClipboardCheckIconProps: ReactSVGIconProps = {
  'tag': 'svg',
  'attr': { 'fill': 'currentColor', 'viewBox': '0 0 16 16' },
  'child': [{
    'tag': 'path',
    'attr': {
      'fillRule': 'evenodd',
      'd': 'M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0'
    },
    'child': []
  }, {
    'tag': 'path',
    'attr': { 'd': 'M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z' },
    'child': []
  }, {
    'tag': 'path',
    'attr': { 'd': 'M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z' },
    'child': []
  }]
}

export const MdOutlineShowChartIconProps: ReactSVGIconProps = {
  'tag': 'svg',
  'attr': { 'viewBox': '0 0 24 24', fill: 'currentColor'  },
  'child': [{ 'tag': 'path', 'attr': { 'fill': 'none', 'd': 'M0 0h24v24H0V0z' }, 'child': [] }, {
    'tag': 'path',
    'attr': { 'd': 'm3.5 18.49 6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99l1.5 1.5z' },
    'child': []
  }]
}
