import React, {useEffect} from 'react';
import {Col, Form, Input, InputNumber, Modal, Row, Select, Switch} from 'antd';
import 'react-quill/dist/quill.snow.css';
import {useDispatch, useIntl, useSelector} from 'umi';
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import LeadStateFilters from "@/components/Filters/LeadStateFilters";
import {TaskAutomationModel} from "@/typings/models/TaskAutomationModel";
import isEmpty from "lodash/isEmpty";
// @ts-ignore
import * as stringUtils from "conversional-utils/string"

const {Option, OptGroup} = Select;
export const REDUX_FETCH_ACTION = 'preferences/fetchPartnerConfig'
export const REDUX_SET_ACTION = 'preferences/changePartnerConfig'

type INewTaskAutomationProps = {
  isModalVisible: boolean;
  handleCancel: () => void;
  automation?: TaskAutomationModel;
}
const NewTaskAutomation = ({
                             isModalVisible,
                             handleCancel,
                             automation,
                           }: INewTaskAutomationProps) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const loading = useSelector((state: DefaultRootState) => state.loading.effects[REDUX_FETCH_ACTION] || state.loading.effects[REDUX_SET_ACTION])

  const taskAutomationList = useSelector(PreferencesSelectors.selectTaskAutomationList)
  const dispatch = useDispatch();

  const isNew = isEmpty(automation)


  const FROM_TO_STATE_REQUIRED_RULES = (otherField) => ([
    ({getFieldValue}) => ({
      validator(_, value) {
        if (value || getFieldValue(otherField)) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(intl.formatMessage({
            id: 'pages.emailAutomation.stateRequired',
            defaultMessage: 'At least one of from and to state must be selected'
          }
        )));
      },
    }),
  ])
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const now = new Date().toISOString()
      values.modified_at = now

      if (isNew) {
        values.id = stringUtils.generateUUID()
        values.created_at = now
        taskAutomationList.push(values)
      } else {
        const index = taskAutomationList.findIndex((item) => item.id === automation.id)
        taskAutomationList[index] = {...taskAutomationList[index], ...values}
      }
      dispatch({
        type: REDUX_SET_ACTION,
        payload: {
          taskAutomationList,
          then: handleCancel
        }
      })
    })
  }

  useEffect(() => {
    form.resetFields()
    if (isModalVisible && !isNew)
      form.setFieldsValue(automation)

  }, [isModalVisible])

  return (
    <Modal
      title={isNew ? intl.formatMessage({
        id: 'pages.leads.tasks.newAutomation',
        defaultMessage: 'New Task Automation',
      }) : automation.name}
      open={isModalVisible}
      onOk={() => {
        handleSubmit()
      }}
      okButtonProps={{
        loading
      }}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        initialValues={{
          is_active: true,
          task_due_in_days: 1
        }}
        layout="vertical"
        name="email_automation_form"
      >
        <Form.Item
          name="name"
          label={intl.formatMessage({id: 'pages.leads.tasks.automation.name', defaultMessage: 'Name'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input/>
        </Form.Item>

        <Row gutter={16} style={{marginBottom: 24}}>
          <Col span={12}>
            <Form.Item
              name="from_state_id"
              dependencies={['to_state_id']}
              label={intl.formatMessage({
                id: 'pages.emailAutomation.fromState',
                defaultMessage: 'From State',
              })}
              rules={FROM_TO_STATE_REQUIRED_RULES('to_state_id')}
            >
              <LeadStateFilters filterMode={'select'} selectProps={{allowClear: true}}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="to_state_id"
              dependencies={['from_state_id']}
              rules={FROM_TO_STATE_REQUIRED_RULES('from_state_id')}
              label={intl.formatMessage({
                id: 'pages.emailAutomation.toState',
                defaultMessage: 'To State',
              })}
            >
              <LeadStateFilters filterMode={'select'} selectProps={{allowClear: true}}/>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="task_title"
          label={intl.formatMessage({
            id: 'pages.leads.tasks.automation.taskTitle',
            defaultMessage: 'Task Title',
          })}
          rules={[
            {
              required: true,
            }
          ]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          name="task_description"
          label={intl.formatMessage({
            id: 'pages.leads.tasks.automation.taskDescription',
            defaultMessage: 'Task Description',
          })}
        >
          <Input.TextArea/>
        </Form.Item>
        <Form.Item
          name="task_due_in_days"
          label={intl.formatMessage({
            id: 'pages.leads.tasks.automation.taskDueInDays',
            defaultMessage: 'Task Due in Days after Creation',
          })}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0}/>
        </Form.Item>
        <Form.Item
          initialValue={automation?.is_active ?? true}
          name="is_active"
          label={intl.formatMessage({
            id: 'pages.emailAutomation.active',
            defaultMessage: 'Active',
          })}
          valuePropName="checked"
        >
          <Switch/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewTaskAutomation;
