import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {CascaderProps, Select, SelectProps} from "antd";
import {useDispatch, useSelector} from "umi";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors"
import styles from "./index.less"
import classNames from "classnames";
import {FILTER_PLACES} from "@/components/Filters/LeadFilters";
import {useLeadCardClick} from "@/components/Kanban/LeadCardContext";

const {Option} = Select;

type ILeadDropdownOption = {
  name?: string,
  label?: string,
  title?: string,
  value?: string,
  id?: string | number,
  color?: string,
  hashId?: string,
  [otherKey: string]: any
}
export type ILeadDropdownFilterProps = {
  options: ILeadDropdownOption[],
  className?: string,
  filterKey: string,
  fetchOptionsAction?: string,
  optionLabelKey?: string,
  optionValueKey?: string,
  placeholder?: any,
  filterMode?: string | null,
  optionFilterProp?: string | null,
  localSearch?: boolean,
  autoFocus?: boolean,
  value?: any,
  optionRender?: (option: ILeadDropdownOption) => React.ReactNode,
  onChange?: (value, option) => void
  onSearch?: (query) => void,
  filterOption?: (query) => boolean,
  selectProps?: Partial<SelectProps> | Partial<CascaderProps>
  loading?: boolean
  Wrapper?: any
  multiple?: boolean,
  transformOptionBasedOnValue: (option, value) => any
}


const DEFAULT_ACTION = 'preferences/change'
const FILTER_ACTIONS = {
  [FILTER_PLACES.LEAD_LIST]: {
    action: DEFAULT_ACTION,
  }, [FILTER_PLACES.LEAD_PERFORMANCE_REPORT]: {
    action: DEFAULT_ACTION,
  }, [FILTER_PLACES.LEAD_FUNNEL_REPORT]: {
    action: DEFAULT_ACTION,
  }, [FILTER_PLACES.LEAD_ROI_FUNNEL_REPORT]: {
    action: DEFAULT_ACTION,
  }, [FILTER_PLACES.TEAM_VIEW_CALENDAR]: {
    action: DEFAULT_ACTION,
  },
}
/**
 * This is the generic component for lead table/kanban dropdown filters.
 * Using this component, only by passing the options and the key of the filter, a Select component
 * gets rendered. And by selecting one of the options, the right action for storing the filter and
 * then, the request for updating the leads table is made.
 *
 * The filters are stored in Redux and rehydrated. So they don't reset on each page reload.
 * Currently, the state is only in Redux store. But it's easy to set it in the DB and pass to the backend.
 * */
const LeadDropdownFilter = ({
                              options,
                              className,
                              filterKey,
                              onChange,
                              fetchOptionsAction,
                              optionLabelKey,
                              optionValueKey,
                              localSearch = true,
                              filterMode = FILTER_PLACES.LEAD_LIST,
                              onSearch,
                              optionFilterProp = 'label',
                              value,
                              autoFocus,
                              optionRender,
                              placeholder,
                              filterOption,
                              loading = false,
                              Wrapper = null,
                              transformOptionBasedOnValue,
                              ...restProps
                            }: ILeadDropdownFilterProps) => {
  const [open, setOpen] = useState(false)

  const selectRef = useRef()
  const dispatch = useDispatch()


  const filters = useSelector((state: DefaultRootState) => PreferencesSelectors.selectFilters(state, filterMode))
  const {setShouldIgnoreClick} = useLeadCardClick()


  const popUpClassName = `lead-dropdown-${filterKey}`
  const handleDropdownVisibleChange = (visible: boolean) => {
    setShouldIgnoreClick(visible)
    if (visible && selectRef.current) {
      setTimeout(() => {
        const options = Array.from(
          document.querySelectorAll(`.${popUpClassName} .ant-select-item-option-content`)
        );
        const maxWidth =
          options.length > 0
            ? Math.max(...options.map((el) => el.scrollWidth)) + 32 // Add some padding
            : 0;
        document.querySelectorAll(`.${popUpClassName}`).forEach(element => {
          element.style.minWidth = `${maxWidth + 10}px`;
        });
      }, 100)
    }
  }
  const getValue = () => {
    if (filterMode !== 'select')
      return filters[filterKey]
    return value
  }
  useEffect(() => {
    /**
     *  If there's the need for fetching the options, you can pass the `fetchOptionsAction` to the component.
     * */
    if (fetchOptionsAction) dispatch({
      type: fetchOptionsAction
    })

    if (autoFocus) setOpen(true)
  }, [])
  const handleChange = (value, option) => {
    // Resetting the value on clear needs explicitly setting `null` value
    value = value === undefined ? null : value
    const reduxAction = FILTER_ACTIONS[filterMode]
    if (reduxAction)
      dispatch({
        type: reduxAction.action,
        payload: {
          [filterKey]: value,
          place: filterMode
        }
      })

    onChange && onChange(value, option)
  }

  const handleSearch = (query) => {
    /**
     * Local search is enabled by default. If there's onSearch function passed, it will be called with the query.
     * Ideally, there's an invocation of the fetch action for fetching the options from the server.
     * */
    if (onSearch) onSearch(query)
  }

  // Since data types may vary among different filters, this function sets the right `value` for each option
  const getOptionValue = option => option[optionValueKey] || option.value || option.hashId || option.id

  // Since data types may vary among different filters, this function sets the right `label` for each option
  const getOptionLabel = option => option[optionLabelKey] || option.name || option.label || option.title

  const getOptions = (childrenOptions) => {
    const safeOptions = childrenOptions ? childrenOptions : Array.isArray(options) ? options : [];
    return safeOptions.map(option => {
      if (transformOptionBasedOnValue) option = transformOptionBasedOnValue(option, getValue())
      if (option.children) option.children = getOptions(option.children)
      return {
        ...option,
        label: getOptionLabel(option),
        value: getOptionValue(option),
      }
    });
  };

  const props: SelectProps = {}
  let children = null
  // If we need to customize how we show the options, e.g: Adding the assignee avatar, we can pass `optionRender` function and return the proper JSX
  if (optionRender) children = getOptions().map(option => <Option value={getOptionValue(option)}
                                                                  key={getOptionValue(option)}
                                                                  meta={option}
                                                                  label={getOptionLabel(option)}>
    {optionRender(option)}
  </Option>)
  else props.options = getOptions()


  if (autoFocus) {
    props.autoFocus = autoFocus
    props.open = open
    props.onDropdownVisibleChange = (visible) => {
      handleDropdownVisibleChange(visible)
      if (open)
        setOpen(visible)
    }
  }

  if (!onSearch) {
    props.optionFilterProp = optionFilterProp || "label"
  } else {
    props.filterOption = false
  }
  if (filterOption) props.filterOption = filterOption

  const Component = Wrapper || Select

  // @ts-ignore
  return <Select
    onClick={e => e.stopPropagation()}
    showSearch
    placeholder={placeholder}
    allowClear
    ref={selectRef}
    value={getValue()}
    onChange={handleChange}
    onSearch={handleSearch}
    onDropdownVisibleChange={handleDropdownVisibleChange}
    loading={loading}
    virtual={false}
    popupClassName={popUpClassName}
    className={classNames(styles.selectContainer, className)}
    {...(restProps.selectProps || {})}
    {...props}
  >
    {children}
  </Select>
}

export default LeadDropdownFilter
