import React from 'react';
import {Badge, Card, Space, Tabs, Typography} from 'antd';
import {ApiOutlined, FileOutlined, UserOutlined} from '@ant-design/icons';
import classNames from "classnames";
import {FormattedMessage as F, useDispatch, useModel, useSelector} from 'umi';

import CSVLeadSourceImport from './CSV/Index';
import WebhookLeadSourceConfig from './Webhook';
import AddManualLead from './ManualLead';
import {LEAD_SOURCE_TYPE_CSV, LEAD_SOURCE_TYPE_WEBHOOK} from './constants';
import { CheckAccess, hasAccess } from '@/utils/access';
import PERMISSIONS from '@/constants/permissions';

const { Text } = Typography;

const LeadSourceSwitcher = () => {
  const dispatch = useDispatch();
    const {initialState, setInitialState} = useModel('@@initialState');

  const handleSourceChange = () => {
    dispatch({
      type: 'leadSources/saveLeadSourceDetail',
      payload: {},
    });
  };
  const { leadSourceList } = useSelector((state: DefaultRootState) => ({
    leadSourceList: state.journey.list,
  }));
  const csvSourceCount = leadSourceList.filter(
    (source) => source.type === LEAD_SOURCE_TYPE_CSV,
  )?.length;
  const webhookSourceCount = leadSourceList.filter(
    (source) => source.type === LEAD_SOURCE_TYPE_WEBHOOK,
  )?.length;


  const tabs = [{
    key: "manual",
    label: <Space>
      <UserOutlined />
      <F id={"components.leadSourceSwitcher.manual"} defaultMessage={"Manual"} />
    </Space>,
    children: <AddManualLead />
  },{
    key: "csvFile",
    label: <Space>
      <FileOutlined />
      <F id={"components.leadSourceSwitcher.csvFile"} defaultMessage={"CSV File"} />
      <Badge count={csvSourceCount} color={"volcano"} />
    </Space>,
    children: <CSVLeadSourceImport />
  },{
    key: "webhook",
    label: <Space>
      <ApiOutlined />
      <F id={"components.leadSourceSwitcher.webhook"} defaultMessage={"Webhook"} />
      <Badge count={webhookSourceCount} color={"red"} />
    </Space>,
    children: <WebhookLeadSourceConfig />
  },{
    key: "Carat",
    label: <Space>
      <F id={"components.leadSourceSwitcher.carat"} defaultMessage={"Carat Planer"} />
    </Space>,
    disabled: true
  },{
    key: "QR",
    label: <Space>
      <F id={"components.leadSourceSwitcher.qr"} defaultMessage={"QR Code"} />
    </Space>,
    disabled: true
  },{
    key: "API",
    label: <Space>
      <F id={"components.leadSourceSwitcher.api"} defaultMessage={"API"} />
    </Space>,
    disabled: true
  },{
    key: "Outlook",
    label: <Space>
      <F id={"components.leadSourceSwitcher.outlook"} defaultMessage={"Outlook"} />
    </Space>,
    disabled: true
  },{
    key: "Journeys",
    label: <Space>
      <F id={"components.leadSourceSwitcher.journeys"} defaultMessage={"Journeys"} />
    </Space>,
    disabled: true
  }]
  const filteredTabs = tabs.filter(tab => {
    if (tab.key === 'manual') {
      return true;
    }
    return hasAccess(
      initialState.currentUser,
      [
        PERMISSIONS.LeadSource.VIEW,
        PERMISSIONS.LeadSource.CREATE,
        PERMISSIONS.LeadSource.EDIT,
        PERMISSIONS.LeadSource.DELETE,
      ],
      { asterisk: true },
    );

  });
  return (
    <Card
      title={
        <Text style={{ fontSize: '16px', fontWeight: '500' }}>
          <F id={'pages.leadsources.title'} defaultMessage="Select Lead Source" />{' '}
        </Text>
      }
      bordered={false}
      style={{ width: '100%', marginBottom: '20px' }}
    >
      <Tabs
        onChange={handleSourceChange}
        className={classNames('ant-tab-type-button')}
        defaultActiveKey="manual"
        items={filteredTabs}
      />
    </Card>
  );
};

export default LeadSourceSwitcher;
