import {Segmented, Tooltip} from "antd";
import {F} from "@/utils/intl";
import {BarsOutlined, CalendarOutlined, ProjectOutlined} from "@ant-design/icons";
import React from "react";
import {useDispatch, useModel, useSelector} from "umi";
import * as PreferencesSelector from "@/selectors/preferencesSelectors";
import PERMISSIONS from "@/constants/permissions";
import {hasAccess} from "@/utils/access";

export default function LeadViewSwitch(){
  const leadView = useSelector(PreferencesSelector.selectLeadView)
  const dispatch = useDispatch()
  const {initialState, setInitialState} = useModel('@@initialState');

  const options = [
    {
      value: 'kanban',
      icon: (
        <Tooltip title={<F id={`pages.lead.toolbar.view.kanban`}/>}>
          <ProjectOutlined/>
        </Tooltip>
      ),
    },
    {
      value: 'table',
      icon: (
        <Tooltip title={<F id={`pages.lead.toolbar.view.table`}/>}>
          <BarsOutlined/>
        </Tooltip>
      ),
    },
    {
      value: 'calendar',
      icon: (
        <Tooltip title={<F id={`pages.lead.toolbar.view.calendar`} defaultMessage={'Calendar'}/>}>
          <CalendarOutlined/>
        </Tooltip>
      ),
      access: [PERMISSIONS.ServiceRequests.VIEW, PERMISSIONS.BUSINESS_PARTNER]
    },
  ].filter(option => !option.access || hasAccess(initialState.currentUser, option.access))

  const changeLeadView = (mode) => {
    dispatch({
      type: 'preferences/update',
      payload: {
        key: 'leadListView',
        value: {
          mode
        },
      },
    });
  }
  return <Segmented
    value={leadView}
    onChange={changeLeadView}
    options={options}
  />
}
