import React, { useEffect, useState } from 'react';
import { FormattedMessage as F, useDispatch, useIntl, useSelector } from 'umi';
import { Button, Space } from 'antd';

import Drawer from '@/components/Drawer';
import styles from './index.less';
import EditSegmentSwitcher from './EditSegmentSwitcher';
import { checkNullString } from '@/utils/prettyname';

const EditUserDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { visible, updateUserLoading, user } = useSelector(
    ({ user: { editDrawer, conversionalRoles }, loading }: DefaultRootState) => ({
      ...editDrawer,
      conversionalRoles,
      updateUserLoading: loading.effects['user/edit'],
      user: editDrawer.user,
    }),
  );
  const [userData, setUserData] = useState(undefined);
  const [activeTab, setActiveTab] = useState('details');

  function toggle() {
    dispatch({
      type: 'user/toggleEditDrawer',
    });
  }
  useEffect(() => {
    if (visible && user) {
      setUserData(user);
    } else {
      setUserData(undefined);
    }
  }, [user, visible]);

  const getUserDisplayName = (user) => {
    if (!user?.firstname && !user?.lastname) {
      return user?.email;
    }
    return `${checkNullString(user.firstname)} ${checkNullString(user.lastname)}`;
  };

  function onUpdateUser() {
    dispatch({
      type: 'user/edit',
      payload: {
        ...userData,
        branches: userData?.branches?.map((branch) => (branch.id ? branch.id : branch)),
        branch_services: userData.branch_services?.map((service) =>
          service.id ? service.id : service,
        ),
        roles: userData?.roles?.map((role) => (role.id ? role.id : role)),
        id: user.id,
        intl,
        cb: () => {},
      },
    });
  }

  function getFooter() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Space>
          <Button
            className={'button-secondary button-bordered'}
            size={'large'}
            type={'primary'}
            onClick={toggle}
          >
            <F id={'pages.users.close'} defaultMessage={'Close'} />
          </Button>

          <Button
            size={'large'}
            loading={updateUserLoading}
            onClick={onUpdateUser}
            type={'primary'}
          >
            <F id={'pages.users.saveChanges'} defaultMessage={'Save Changes'} />
          </Button>
        </Space>
      </div>
    );
  }

  return (
    <div className={styles.drawerContainer}>
      <Drawer
        width={'40%'}
        footer={getFooter()}
        title={
          (activeTab === 'details'
            ? intl.formatMessage({
                id: 'pages.users.editUser',
                defaultMessage: 'Edit User',
              })
            : intl.formatMessage({
                id: 'pages.users.setAvail',
                defaultMessage: 'Set Availability',
              })) +
          ' - ' +
          (getUserDisplayName(user) || '')
        }
        visible={visible}
        destroyOnClose
        onVisibleChange={toggle}
      >
        <EditSegmentSwitcher
          onChangeTab={(activeTab) => {
            setActiveTab(activeTab);
          }}
          userData={userData}
          onUpdateUser={(user) => {
            setUserData(user);
          }}
        />
      </Drawer>
    </div>
  );
};

export default React.memo(EditUserDrawer, () => true);
