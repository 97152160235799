import * as React from "react"
import {useDispatch} from "umi";

import {LeadModel} from "@/typings/models/Lead";
import LeadPriorityDropdownFilters from "@/components/Filters/LeadPriorityDropdownFilters";
import styles from "./index.less"

const getLeadPriority = (lead: LeadModel) => lead?.priority?.value || 0
const LeadPriority = ({lead}: {lead: LeadModel}) => {
  const dispatch = useDispatch()
  const onChangeStatus = (newPriority) => {
    dispatch({
      type: 'leads/edit',
      payload: {
        priority: newPriority?.value || newPriority,
        leadId: lead.id
      }
    })
  }
  const priority = getLeadPriority(lead)
  return <LeadPriorityDropdownFilters className={styles.leadPriorityContainer} selectProps={{bordered: false, allowClear: false}} filterMode={"select"} onChange={onChangeStatus} value={priority}/>
}

const propsAreEqual = (prevProps, nextProps) => {
  const prevPriority = getLeadPriority(prevProps.lead)
  const newPriority = getLeadPriority(nextProps.lead)
  return prevPriority === newPriority
}
export default React.memo(LeadPriority, propsAreEqual)
