import { darken } from '@mui/material';
import {
  changeUserSettingsRequest,
  createUserRequest,
  deleteUserRequest,
  fetchAllUsersRequest,
  fetchConversionalRolesRequest,
  fetchUserBranches,
  fetchUserRequest,
  fetchUserSettingsRequest,
  updateUserRequest
} from "@/services/api/user";
import { message } from "antd";
import { processAPIResponse } from "@/utils/request";
import { BackofficeUserModel } from "@/typings/api/user";
import { trackModalView } from "@/services/tracker";

const initialState = {
  loggedIn: false,
  settings: {},
  list: [],
  conversionalRoles: [],
  userBranches: [],
  drawer: {
    visible: false
  },
  editDrawer:{
    visible: false,
    user: {}
  }
}

export default {
  namespace: 'user',

  state: initialState,

  effects: {
    * loggedIn({ payload = {} }: { payload: BackofficeUserModel }, { put }) {
      const { User, Partner, Journeys, Role } = payload;
      yield put({
        type: 'gotMe',
        // TODO: Check Avatar
        payload: {
          user: { ...User, avatar: "https://pbs.twimg.com/profile_images/1362000555820937217/fNKKSMBa_200x200.jpg" },
          partner: Partner,
          role: Role
        }
      })
      yield put({
        type: 'journey/gotJourneys',
        payload: Journeys
      })
      yield put({
        type: 'fetchSettings'
      })
    },
    * fetchSettings({ payload = {} }, { call, put }) {
      const { response, data } = yield call(fetchUserSettingsRequest)
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'fetchedSettings',
            payload: data
          })
        },
        * onError() {

        }
      })
    },
    * getUser({ payload: { id } }, { call, put }) {
      const { response, data } = yield call(fetchUserRequest, { params: { userId: id } })
    yield processAPIResponse({ response, data }, {
      * onSuccess(data) {
        yield put({
          type: 'setUser',
          payload: data?.data
        })
      },
      * onError() {

      }
    })
  },
    * create({ payload }, { call, put, select }) {

      const { response, data } = yield call(createUserRequest, { payload: payload.values });
      const createUserDrawer = yield select((state: DefaultRootState) => state.user.drawer)

      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'toggleDrawer',
            payload: {}
          })
          yield put({
            type: 'getAllUsers',
          })
          yield put({
            type: 'leads/fetchAssignees',
          })
          payload.intl && message.success(payload.intl.formatMessage({
            id: "pages.users.createUserSuccess",
            defaultMessage: 'User created successfully'
          }))
          if (createUserDrawer?.onCreate) createUserDrawer.onCreate(data)
        }
        , * onError(error) {
          message.error(error?.message || payload.intl.formatMessage({
            id: "pages.users.createUserFailed",
            defaultMessage: 'Creating user failed'
          }))
        }
      })

    },
    * edit({ payload: { cb, intl, id, ...body } }, { call, put, select }) {
      const { response, data } = yield call(updateUserRequest, {
        params: { userId: id },
        payload: body
      }
      );
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
        message.success( intl.formatMessage({
          id: "pages.users.editUserSuccess",
          defaultMessage: 'User updated successfully'
        }))
        yield put({
          type: 'getAllUsers',
        })
          yield put({
            type: "fetchUser"
          })
          cb && cb(data, true);
        },
        * onError(error) {
          message.error(error?.message || intl.formatMessage({
            id: "pages.users.editUserFailed",
            defaultMessage: 'Edit User failed'
          }))
        },
      })
    },
    * delete({ payload: { userId, intl } }, { call, put }) {
      const { response, data } = yield call(deleteUserRequest, { params: { userId } });
      yield processAPIResponse({ response, data }, {
        * onSuccess() {
          yield put({
            type: 'getAllUsers',
          })
          intl && message.success(intl.formatMessage({
            id: "pages.users.removeUserSuccess",
            defaultMessage: 'User removed successfully'
          }))
        },
        * onError(error) {
          message.error(error?.message || intl.formatMessage({
            id: "pages.users.removeUserFailed",
            defaultMessage: 'Removing user failed'
          }))

        },
      })
    },

    * getAllUsers({ payload = {} }: { payload: { search?: string, url?: string } }, { call, put, select }) {
      let query = {};
      if (payload.search) {
        query.search = payload.search;
      }
      const { response, data } = yield call(fetchAllUsersRequest, { query, url: payload.url })
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'fetchedAllUsers',
            payload: data
          })
        },
        * onError() {

        }
      })
    },
    * getUserBranches({ payload = {} }, { call, put }) {
      let query = {};
      if (payload.search) {
        query.search = payload.search;
      }
      query.all = 1

      const { response, data } = yield call(fetchUserBranches, { query, params: { all: 1 } })
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'fetchedUserBranches',
            payload: data
          })
        },
        * onError() {

        }
      })
    },
    * changeSettings({ payload = {} }, { put, call }) {
      yield call(changeUserSettingsRequest, {
        payload: {
          locale: payload["locale"]
        }
      })
      if (payload.cb) payload.cb()
      yield put({
        type: 'fetchSettings',
      })
    },
    * resetPassword({ payload = {} }, { put }) {
      yield put({
        type: 'resetPasswordResult',
        payload: {}
      })
    },
    * forgetPassword({ payload = {} }, { put }) {
      yield put({
        type: 'forgetPasswordResult',
        payload: {}
      })
    },
    * fetchConversionalRoles({ payload = {} }, { call, put }) {
      const { response, data } = yield call(fetchConversionalRolesRequest)
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'fetchedConversionalRoles',
            payload: data.data
          })
        },
        * onError() {

        }
      })
    },
  },
  reducers: {
    gotMe(state, action) {
      return {
        ...state,
        ...action.payload,
        loggedIn: true
      }
    },
    loggedOut() {
      return initialState
    },
    reset() {
      return initialState
    },
    gotUserData(state) {
      return {
        ...state
      }
    },
    fetchedSettings(state, action) {
      return {
        ...state,
        settings: action.payload
      }
    },
    fetchedAllUsers(state, action) {
      return {
        ...state,
        list: action.payload
      }
    },
    setUser(state, action) {
      const editDrawer = {
        ...(state.editDrawer || {}),
        user: action.payload
      };
      return {
        ...state,
        editDrawer
      }
    },
    changedSettings(state) {
      return {
        ...state
      }
    },
    resetPasswordResult(state) {
      return {
        ...state
      }
    },
    forgetPasswordResult(state) {
      return {
        ...state
      }
    },
    toggleDrawer(state, action) {
      const drawer = {
        ...(state.drawer || {}),
        ...(action.payload || {})
      };
      drawer.visible = !drawer.visible
      if (!drawer.visible) {
        delete drawer.onCreate
      }
      drawer.branch_id = action.payload?.branch_id
      trackModalView('new-user')
      return {
        ...state,
        drawer
      }
    },
    toggleEditDrawer(state, action) {
      const editDrawer = {
        ...state.editDrawer
      };
      editDrawer.visible = !editDrawer.visible
      return {
        ...state,
        editDrawer
      }
    },
    fetchedConversionalRoles(state, action) {
      return {
        ...state,
        conversionalRoles: action.payload
      }
    },
    fetchedUserBranches(state, action) {
      return {
        ...state,
        userBranches: action.payload
      }
    },
  },
};
