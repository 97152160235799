export const selectLeads = (state: DefaultRootState) => state?.leads?.list || {}
export const selectGroupedLeads = (state: DefaultRootState) => state?.leads?.groupedList || {}
export const selectFieldChangeLoading = (state: DefaultRootState) => state?.leads?.changeLoadings || {}

export const selectLeadAssignees = (state: DefaultRootState) => state?.leads?.assigneeList?.data || []

export const selectUnassignedLeads = (state: DefaultRootState) => state?.leads?.unassignedLeadsList || {}
export const selectAssignmentDrawerVisible = (state: DefaultRootState) => state?.leads?.assignmentDrawerVisible
export const selectAddLeadDrawer = (state: DefaultRootState) => state?.leads?.addLeadDrawer
export const selectLeadDetailsDrawer = (state: DefaultRootState) => state?.leads?.leadDetailsDrawer
export const selectLeadDetails = (state: DefaultRootState, leadId) => state?.leads?.details?.[leadId]
export const selectArchiveLoading = (state: DefaultRootState, leadId) => state?.leads?.archiveLoading?.[leadId]
export const selectIsLeadArchived = (state: DefaultRootState, leadId) => state?.leads?.archivedLeads?.[leadId]

export const selectAllLeadsData = (state: DefaultRootState) => state?.leads?.leads || {}
export const selectLeadData = (state: DefaultRootState, leadId) => state?.leads?.leads?.[leadId]

export const selectLeadFields = (state: DefaultRootState) => state?.leadSources?.leadFields || { default: [], meta: [] }

export const selectLeadsMagicFieldEditor = (state: DefaultRootState) => state?.leads?.magicFieldEditor
export const selectLeadsMagicTasks = (state: DefaultRootState) => state?.leads?.magicTasks
export const selectLeadsMagicAppointmentBooking = (state: DefaultRootState) => state?.leads?.magicAppointmentBooking
export const selectSelectedMegaSearchLead = (state: DefaultRootState) => state?.leads?.selectedMegaSearchLead
