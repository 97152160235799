import React, {useEffect, useState} from "react";
import ProForm from "@ant-design/pro-form";
import {Form} from "antd";
import {useDispatch, useIntl, useSelector} from "umi";
import FORM_TYPES from "@/constants/formTypes"
import {DYNAMIC_FORM_FIELD_TYPES, getFormBlurProps} from "@/utils/form";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import DrawerSection from "@/components/Drawer/DrawerSection";
import DynamicForm, {IDynamicField} from "@/components/DynamicForm";
import { BranchModel, ServiceModel } from "@/typings/models/Location";

interface LocationDetailsFormProps {
  selectedService: ServiceModel,
  isNew: boolean,
  onChange?: (location: ServiceModel) => void,
  branch:BranchModel
}

const LocationMetaForm: React.FC<LocationDetailsFormProps> = ({
  selectedService,
  onChange,
  isNew,
  branch,
}) => {
  const [form] = Form.useForm();

  const [meta, setMeta] = useState({});
  const intl = useIntl();
  const dispatch = useDispatch();

  const { loadings, journeyMetaConfig } = useSelector(
    ({ location: { changeLoadings }, journey }: DefaultRootState) => ({
      loadings: changeLoadings,
      journeyMetaConfig: get(journey, `config.${branch?.lead_source?.id}.calkit_meta`, []),
    }),
  );
  useEffect(() => {
    if (isEmpty(selectedService?.calendar?.meta)) return;

    setMeta(selectedService?.calendar?.meta);
    setTimeout(() => {
      form.setFieldsValue(selectedService?.calendar?.meta);
    }, 100);
  }, [selectedService]);

  function onValueChange(name: string, value: any) {
    if (isNew) return;
    if (get(selectedService?.calendar?.meta, name) === value) return;
    const field = metaToBeShown.find((meta) => meta.key === name);
    const newMeta = {
      ...meta,
      [name]: value,
    };
    setMeta(newMeta);

    if (isEmpty(value) && field.type !== DYNAMIC_FORM_FIELD_TYPES.TAGS) return;
    dispatch({
      type: 'location/updateService',
      payload: {
        id: selectedService?.id,
        data: {
          ...selectedService,
          branch_id: selectedService.branch?.id,
          agents: selectedService?.agents?.map((a) => a.id),
          calendar: {
            ...selectedService.calendar,
            meta: newMeta,
          },
        },
        intl,
      },
    });
  }

  const metaToBeShown = journeyMetaConfig.filter((meta) => {
    if (meta.read_only) return !isNew;
    return true;
  });

  if (metaToBeShown.length === 0) return null;
  const onFormChange = (changedValues, values) => {
    Object.keys(changedValues)
      .filter((changedKey) => {
        const field = metaToBeShown.find((meta) => meta.key === changedKey);
        return field?.type === DYNAMIC_FORM_FIELD_TYPES.TAGS;
      })
      .forEach((changedKey) => onValueChange(changedKey, changedValues[changedKey]));
    if (!isNew) return;
    onChange && onChange({ calendar: { ...selectedService.calendar, meta: values } });
  };
  const getExtraFieldProps = (field: IDynamicField) => ({
    ...getFormBlurProps({
      type: FORM_TYPES.TEXT,
      value: meta[field.key],
      callback: onValueChange,
      name: field.key,
      loadings,
    }),
    disabled: field.read_only || loadings.meta,
    hasFeedback: loadings.meta,
  });
  return (
    <DrawerSection
      title={intl.formatMessage({
        id: 'pages.locations.meta',
        defaultMessage: 'Meta',
      })}
    >
      <ProForm form={form} submitter={false} initialValues={meta} onValuesChange={onFormChange}>
        <DynamicForm fields={metaToBeShown} getExtraFieldProps={getExtraFieldProps} />
      </ProForm>
    </DrawerSection>
  );
};

export default LocationMetaForm
