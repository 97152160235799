import {TimeslotList} from "conversional-journey";
import InlineDatePicker from "@/components/Kanban/ServiceRequests/Calendar/InlineDatePicker";
import React, {useEffect} from "react";

import moment from "moment/moment";
import {F} from "@/utils/intl";
import {Button, Empty, Spin} from "antd";
import styles from "../index.less"
import {fetchLocationBookingTimeslotsRequest} from "@/services/api/widget";
import {MagicAppointmentBookingModel} from "@/typings/models/MagicAppointmentBooking";

const SlotButton = ({slot, onClick}) => {
  return <Button onClick={() => onClick(slot)} style={{fontWeight: 400, fontFamily: 'Rubik'}}>
    {slot.start.format('HH:mm')} - {slot.end.format('HH:mm')}
  </Button>

}
const TimeslotList = ({currentSlots, onSelectSlot}: {currentSlots: TimeslotList}) => {
  const morningSlots = currentSlots.filter(slot => slot.start.hour() < 12)
  const afternoonSlots = currentSlots.filter(slot => slot.start.hour() >= 12 && slot.start.hour() < 17)
  const eveningSlots = currentSlots.filter(slot => slot.start.hour() >= 17)

  return <div className={styles.timeSlotsListContainer}>
    <div>
      <F id={"pages.lead.booking.morning"} defaultMessage={'Morning'}/>
      {morningSlots.map(slot => <SlotButton key={slot.start.toString()} slot={slot} onClick={onSelectSlot}/>)}
    </div>
    <div>
      <F id={"pages.lead.booking.afternoon"} defaultMessage={'Afternoon'}/>
      {afternoonSlots.map(slot => <SlotButton key={slot.start.toString()} slot={slot} onClick={onSelectSlot}/>)}
    </div>
    <div>
      <F id={"pages.lead.booking.evening"} defaultMessage={'Evening'}/>
      {eveningSlots.map(slot => <SlotButton key={slot.start.toString()} slot={slot} onClick={onSelectSlot}/>)}
    </div>
  </div>
}

type AppointmentSlotSelectorProps = {location: any, magicAppointmentBookingConfiguration: MagicAppointmentBookingModel,  onSelectSlot: (slot) => void}
export default function AppointmentSlotSelector({location,magicAppointmentBookingConfiguration, onSelectSlot}: AppointmentSlotSelectorProps) {
  const [currentDate, setCurrentDate] = React.useState(new Date())
  const [processedSlots, setProcessedSlots] = React.useState([])
  const [timeslotsLoading, setTimeslotsLoading] = React.useState(false)

  useEffect(() => {
    if (!location?.id) return
    setTimeslotsLoading(true)
    fetchLocationBookingTimeslotsRequest({
      baseUrl: magicAppointmentBookingConfiguration.base_url,
      query: {
        apiKey: magicAppointmentBookingConfiguration.api_key
      },
      params: {
        locationId: location?.id
      }
    }).then(response => {
      setTimeslotsLoading(false)
      const slots = [...(response.data.data || [])]
        .sort((a, b) => (a.start > b.start ? 1 : -1))
        .map((slot) => ({
          start: moment(slot.start),
          rawStart: slot.start,
          resource_id: slot.resources[0].id,
          rawEnd: slot.end,
          end: moment(slot.end),
        }))
      setProcessedSlots(slots)
      setCurrentDate(slots[0].start.toDate())
    }).catch(() => {
      setProcessedSlots([])
      setTimeslotsLoading(false)
    })


  }, [location?.id])

  const shouldDisableDate = (date) => {
    const dayFormat = date.format('YYYY-MM-DD')
    const sameDaySlots = processedSlots.filter((slot) => {
      const slotDayFormat = slot.start.format('YYYY-MM-DD')
      return slotDayFormat === dayFormat
    })
    return sameDaySlots.length === 0;

  }

  const getCurrentDaySlots = () => {
    const dayFormat = moment(currentDate).format('YYYY-MM-DD')
    return processedSlots.filter((slot) => {
      const slotDayFormat = slot.start.format('YYYY-MM-DD')
      return slotDayFormat === dayFormat
    })
  }

  if (timeslotsLoading) return <div style={{height: 50, textAlign: 'center'}}>
    <Spin/>
  </div>

  if (processedSlots.length === 0) return <Empty description={"No available slots for current date"}/>

  return <div className={styles.timeslotSelectorContainer}>
    <div className={styles.inlineDatePickerContainer}>
      <InlineDatePicker disabledDate={shouldDisableDate} value={currentDate} onChange={setCurrentDate}/>
    </div>
    <TimeslotList currentSlots={getCurrentDaySlots()} onSelectSlot={onSelectSlot}/>
  </div>
}
