

import config from '../../../../../../gmap-api-key.json';


export const getAPIKey: () => string = () => {
  let API_KEY = '';
  if (config) {
    API_KEY = config["REACT_APP_GOOGLE_MAPS_API_KEY"];
  }
  return API_KEY
}
 const API_KEY = getAPIKey();
 const TIME_ZONE_API_URL = `https://maps.googleapis.com/maps/api/timezone/json`;
 
  export const getLatLngFromAddress = async (address: string) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address,
        )}&key=${API_KEY}`,
      );
      const data = await response.json();
      if (data?.result) {
        const location = data.results?.[0]?.geometry.location;
        return { lat: location.lat, lng: location.lng };
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error getting lat/lng from address:', error);
      throw new Error('Error getting lat/lng from address:' + JSON.stringify(error));
    }
  };
  
  export const getAddressFromLatLng = async (lat: number, lng: number) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`,
      );
      const data = await response.json();
      return data.results?.[0]?.formatted_address;
    } catch (error) {
      console.error('Error getting address from lat/lng:', error);
      throw new Error('Error getting lat/lng from address:' + JSON.stringify(error));
        }
  };

  export const fetchTimeZone = async (lat: number, lng: number) => {
    const timestamp = Math.floor(Date.now() / 1000);
    const url = `${TIME_ZONE_API_URL}?location=${lat},${lng}&timestamp=${timestamp}&key=${API_KEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === 'OK') {
        return data.timeZoneId;
      } else {
        console.log(`Error fetching time zone: ${data.errorMessage}`);
      }
    } catch (err) {
      console.log('Error fetching time zone');
    }
  };


