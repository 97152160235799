import { useState } from 'react';
import { Input, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useIntl } from 'umi';

const EditableInput = ({ defaultValue, onSubmit, style }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const intl = useIntl();

  const handleBlur = (e) => {
    setIsEditing(false);
    onSubmit(e);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
    setIsEditing(false);
    onSubmit(e);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {isEditing ? (
        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleBlur}
          autoFocus
          onKeyDown={handleKeyDown}
          style={{ ...style, backgroundColor: '#f0f0f0' }}
        />
      ) : (
        <div
          onClick={() => setIsEditing(true)}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <span style={{ marginRight: 8 }}>{value}</span>
          <Tooltip title=  {intl.formatMessage({ id: 'pages.roles.edit', defaultMessage: 'Edit' })}>
            <EditOutlined />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default EditableInput;