import React, {useEffect, useState} from "react";
import {closestCenter, DndContext, DragOverlay} from "@dnd-kit/core";
import {arrayMove, SortableContext, verticalListSortingStrategy,} from "@dnd-kit/sortable";
import get from "lodash/get";
import {message, Spin} from "antd";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import {useDispatch, useSelector} from "umi";
import EditableStateCard from "@/components/Kanban/StateManager/EditableStateCard";
import {LeadStateModel} from "@/typings/models/LeadState";

import styles from "./index.less"
import * as LeadStateSelectors from "@/selectors/leadStateSelectors";
import {createLeadStateRequest, deleteLeadStateRequest} from "@/services/api/leadState";
import StateConfigurationModal from "@/components/Kanban/StateManager/StateConfigurationModal";
import {INITIAL_NEW_STATUS_FORM} from "@/components/Kanban/StateManager/constants";
import {CheckAccess} from "@/utils/access";
import PERMISSIONS from "@/constants/permissions";

const LeadStateManager = () => {


  const [activeId, setActiveId] = useState(null);
  const [leadStates, setLeadStates] = useState<LeadStateModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [newStatusForm, setNewStatusForm] = useState(INITIAL_NEW_STATUS_FORM);
  const [currentActiveStateSetting, setCurrentActiveStateSetting] = useState<string>();

  const _states = useSelector(LeadStateSelectors.selectStates)
  const dispatch = useDispatch()

  useEffect(() => {
    setLeadStates(_states)
  }, [_states]);

  const reload = () => {
    dispatch({
      type: 'leadStates/fetch'
    })
    dispatch({
      type: 'leads/fetch'
    })
  }

  const handleDragStart = (event) => setActiveId(event.active.id);


  const handleDragEnd = (event) => {
    const {active, over} = event;
    setActiveId(null);

    const fromIndex = findStatusIndex(active.id)
    const toIndex = findStatusIndex(over.id)
    setLeadStates((prev) => {
      const newOrderedStatus = arrayMove(
        [...prev],
        fromIndex,
        toIndex
      )
      dispatch({
        type: "leadStates/changeOrder",
        payload: {
          order: newOrderedStatus.map(status => status.id)
        }
      })
      return newOrderedStatus
    });
  };

  const handleEdit = (id, newLabel) => {
    setLeadStates(prev => prev.map(card => {
      if (card.id === id) return sendEditRequest(id, {...card, label: newLabel})
      return card
    }));
  };

  const handleCreate = (_, label) => {
    setNewStatusForm(INITIAL_NEW_STATUS_FORM)
    if (isEmpty(label)) return
    const formData = {
      ...newStatusForm,
      label
    }
    setLoading(true)
    createLeadStateRequest({data: formData}).then(({response, data}) => {
      setLoading(false)
      setLeadStates((prev) => prev.concat(data?.data))
      reload()
    })
  };

  const handleDelete = (stateId: string) => {

    return new Promise((resolve, reject) => {
      deleteLeadStateRequest({
        params: {
          stateId
        }
      }).then(({response, data}) => {
        resolve(null)
        if (response?.status !== 200)
          return message.error(get(data, 'data.message', data?.message))

        setLeadStates(prev => prev.filter(status => status.id !== stateId))
        reload()
      })
    })
  };

  const handleColorSelect = (stateId: string, newColor: string) => {
    setLeadStates(prev => prev.map(card => {
      if (card.id === stateId) return sendEditRequest(stateId, {...card, color: newColor})
      return card
    }))
  }

  const sendEditRequest = (stateId, state) => {
    const payload = {...state}
    if (typeof payload.state_type === "object") {
      payload.state_type = payload.state_type.value
    }
    dispatch({
      type: 'leadStates/edit',
      payload: {
        stateId,
        ...payload,
      }
    })
    return state
  }
  const handleLeadStateTypeChange = (id, newStateType) => {
    setLeadStates(prev => prev.map(card => {
      if (card.id === id) return sendEditRequest(id, {...card, state_type: newStateType})
      return card
    }))
  }

  const handleMagicFieldChange = (id, magicFieldProperty, fieldType) => {
    setLeadStates(prev => prev.map(card => {

      if (card.id === id) {
        set(card, `meta.magicFields.${magicFieldProperty}`, fieldType)
        return sendEditRequest(id, {...card})
      }
      return card
    }))
  }

  const findStatusIndex = stateId => leadStates.findIndex((item) => item.id === stateId)

  const findStateById = stateId => leadStates.find(status => status.id === stateId)

  const handleShowSettings = (stateId: string) => {
    setCurrentActiveStateSetting(stateId)
  }
  const closeSettings = () => {
    setCurrentActiveStateSetting(null)
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      collisionDetection={closestCenter}
    >
      <SortableContext
        items={leadStates}
        strategy={verticalListSortingStrategy}
      >
        <Spin spinning={loading}>
          <div className={styles.statusCategoryContainer}>
            {leadStates.map(state => <EditableStateCard
              {...state}
              key={state.id}
              onEdit={handleEdit}
              draggable={leadStates.length > 1}
              onColorSelect={handleColorSelect}
              onDelete={handleDelete}
              showSettings={handleShowSettings}
            />)}

            <CheckAccess access={[PERMISSIONS.LeadStates.CREATE, PERMISSIONS.BUSINESS_PARTNER]}>
              <EditableStateCard isNew
                                 onColorSelect={(id, color) => {
                                   setNewStatusForm({
                                     ...newStatusForm,
                                     color
                                   })
                                 }}
                                 onEdit={handleCreate}
                                 {...newStatusForm}

              />
            </CheckAccess>
          </div>
        </Spin>

      </SortableContext>

      <DragOverlay>
        {activeId ? (
          <EditableStateCard
            {...findStateById(activeId)}
            dragPlaceholder
          />
        ) : null}
      </DragOverlay>
      <StateConfigurationModal
        onLeadStateTypeChange={handleLeadStateTypeChange}
        onMagicFieldChange={handleMagicFieldChange}
        visible={!isEmpty(currentActiveStateSetting)}
        state={findStateById(currentActiveStateSetting)}
        onClose={closeSettings}/>
    </DndContext>
  );
};

export default LeadStateManager;
