import pick from "lodash/pick";
import omit from "lodash/omit";

import {processAPIResponse} from "@/utils/request";
import {
  editLeadStateRequest,
  fetchAppointmentEvents,
  fetchLeadStates,
  reorderLeadStateRequest,
  updateAppointmentEventsRequest
} from "@/services/api/leadState";

const initialState = {
    list: [],
    appointmentEventsMap: {}
}
export default {
  namespace: 'leadStates',

  state: initialState,

  effects: {
    // Lead state creation / deletion request happens in the component itself so no need to add the respective effect/reducer here.
    * fetch({payload = {}}: { payload: { search?: string, url?: string } }, {call, put, select}) {
      const {response, data} = yield call(fetchLeadStates);
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'save',
            payload: data
          })
        }
      }, 'data')
    },
    * fetchLeadStateSettings(_, {call, put, select}){
      const leadLifeCycleEventsSettings = yield call(fetchAppointmentEvents);
      yield put({
        type: 'saveAppointmentEvents',
        payload: leadLifeCycleEventsSettings?.data?.data
      })
    },
    * edit({payload = {}}: { payload: { search?: string, url?: string } }, {call, put, select}) {
      const params = pick(payload, ['stateId'])
      const body = omit(payload, ['stateId', 'id'])
      const {response, data} = yield call(editLeadStateRequest, {params, data: body});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'fetch'
          })
          yield put({
            type: 'leads/fetch'
          })
        }
      })
    },
    * changeOrder({payload = {}}: { payload: { search?: string, url?: string } }, {call, put, select}) {
      const {response, data} = yield call(reorderLeadStateRequest, {data: payload});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'fetch'
          })
          yield put({
            type: 'leads/fetch'
          })
        }
      })
    },
    * updateAppointmentEvents({payload = {}}: { payload: { search?: string, url?: string } }, {call, put, select}) {
      yield put({
        type: 'modifyAppointmentEvents',
        payload
      })
      const {response, data} = yield call(updateAppointmentEventsRequest, {data: payload});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'fetch'
          })
          yield put({
            type: 'fetchLeadStateSettings'
          })

        }
      })
    },

  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    saveAppointmentEvents(state, action) {
      return {
        ...state,
        appointmentEventsMap: action.payload,
      };
    },
    modifyAppointmentEvents(state, action) {
      return {
        ...state,
        appointmentEventsMap: {
          ...state.appointmentEventsMap,
          ...(action.payload || {})
        },
      };
    },
    reset(){
      return initialState
    }
  }
};
