import React, {UIEvent, useRef, useState} from "react";
import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {Badge, Spin, Tag} from "antd";
import {useDispatch} from "umi";
import classNames from "classnames";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import throttle from "lodash/throttle";
import VirtualizedList from "rc-virtual-list";
import LeadCard from "@/components/Kanban/LeadCard";
import styles from "./styles.less"
import {useDroppable} from "@dnd-kit/core";

type ILeadColumnProps = {
  id: string,
  leadIds: string[],
  name: string,
  color: string,
  isSortingContainer: boolean
  dragOverlay?: any
}

// Columns are not draggable and order of status is manageable in Status Settings according to this PR: https://bitbucket.org/ConversionalDev/conversional-frontend/pull-requests/1725
const LeadColumn = (props: ILeadColumnProps) => {
  const {id, leadIds, name, color, isSortingContainer, dragOverlay, ...restProps} = props;
  const dispatch = useDispatch();
  const lastScrollTop = useRef(0);
  const [loading, setLoading] = useState(false)
  const listRef = useRef(null);
  const [leadCardHeight, setLeadCardHeight] = useState(150);

  // Enable droppable even when the column is empty
  const {setNodeRef, isOver} = useDroppable({
    id,
  });

  const shownLeadIds = leadIds


  const handleRecalculateCardHeight = throttle((height: number) => {
    if (height !== leadCardHeight) {
      setLeadCardHeight(height)
    }
  }, 500, {
    trailing: true
  })

  const triggerInfiniteScrollPagination = debounce(() => {
    if (restProps?.links?.next && !loading) {
      setLoading(true)
      dispatch({
        type: 'leads/fetchLeadsByUrl',
        payload: {
          url: restProps?.links?.next,
          groupId: id,
          then: () => {
            setLoading(false)
          }
        }
      })
    }
  }, 500, {
    leading: true,
    trailing: false
  })

  /**
   * Handle scroll event for the VirtualList.
   * Triggers data fetch when the user scrolls near the bottom.
   * @param {Object} e - The scroll event object.
   */
  const onScroll = (e: UIEvent) => {
    const {scrollTop, scrollHeight, clientHeight} = e.currentTarget;
    if (scrollTop > lastScrollTop.current) {
      if (scrollHeight - scrollTop <= clientHeight + 100 && !loading) {
        triggerInfiniteScrollPagination()
      }
    }
    lastScrollTop.current = scrollTop;
  }


  return (
    <div
      className={classNames(styles.kanbanColumn, 'kanban-column')}
    >
      <div
        className={classNames(styles.kanbanColumnHeader, {
          [styles.draggingColumn]: dragOverlay
        })}
        style={{'--border-color': color}}
      >
        <Tag color={color} className={styles.kanbanColumnTitle}>{name}</Tag>
        <Tag className={styles.columnBadgeTag}>
          <Badge
            count={restProps.total}
            showZero={true}
            overflowCount={Infinity}
            className={styles.columnBadge}
          />
        </Tag>
      </div>
      <SortableContext
        items={shownLeadIds.length > 0 ? shownLeadIds : ["__placeholder__"]}
        strategy={verticalListSortingStrategy}
      >
        <Spin spinning={loading} style={{height: "100%"}}>
          <div className={styles.kanbanColumnList} ref={shownLeadIds.length === 0 ? setNodeRef : null}>
            <VirtualizedList
              ref={listRef}
              data={leadIds}
              height={window.innerHeight - 360}
              itemKey={(item) => item}
              onScroll={onScroll}
              itemHeight={leadCardHeight + 15}
            >
              {(leadId) => (
                <LeadCard
                  id={leadId}
                  key={leadId}
                  disabled={isSortingContainer}
                  recalculateCardHeight={handleRecalculateCardHeight}
                />
              )}
            </VirtualizedList>
          </div>
        </Spin>
      </SortableContext>
      {/* <CheckAccess access={[PERMISSIONS.Leads.CREATE]} asterisk>
        <div className={styles.kanbanColumnFooter}>
          <Button
            type="text"
            icon={<PlusOutlined/>}
            size="small"
            style={{width: "100%", textAlign: "left"}}
            onClick={() => {
              dispatch({
                type: 'leads/toggleAddLeadsDrawer',
                payload: {
                  stateId: id.split("column-")[1],
                },
              });

            }
            }
          >
            <F id={"pages.leads.addLead"} defaultMessage={"Add Lead"}/>
          </Button>
        </div>
      </CheckAccess> */}
    </div>
  );
};

const propsAreEqual = (prevProps, nextProps) => isEqual(prevProps.leadIds, nextProps.leadIds)

export default React.memo(LeadColumn, propsAreEqual)
