import React, {useState} from 'react';
import {Avatar, Collapse, Menu, Popover} from 'antd';
import {FormattedMessage as F, useHistory} from 'umi';
import classNames from 'classnames';

import {useModel} from '@@/plugin-model/useModel';
import {getInitials} from '@/utils/functions';
import {logOut, setPartnerToken} from '@/services/auth';
import {checkUserAccessToPath} from '@/utils/access';
import {FOOTER_ROUTE_CONFIG} from './menuConfig';
import styles from './index.less';
import {UserModel} from "@/typings/models/User";
import {BankOutlined, CheckOutlined} from "@ant-design/icons";

const { Panel } = Collapse;


const PartnerSelection = ({partners}) => {
  const selectPartner = (partner) => {
    setPartnerToken(partner.id)
    location.replace("/check")
  }
  return <div className={styles.partnerSelectContainer}>
    <div className={styles.partnersTitle}>
      <F id={'pages.user.partner.select'} defaultMessage={'Your accounts'}/>
    </div>
    <Menu>
      {partners.map(partner => <Menu.Item disabled={partner.current_partner} className={styles.partnerItem} onClick={() => selectPartner(partner)} key={`Partner_${partner.id}`}>
        {partner.current_partner ? <CheckOutlined/>: <BankOutlined/>}
        {partner.name}
      </Menu.Item>)}
    </Menu>
  </div>
}
const UserMenuDropdown = ({ mini = false }: { mini?: boolean }) => {
  const [active, setActive] = useState(false);
  const history = useHistory();
  const { initialState } = useModel('@@initialState');

  const partners = initialState.currentUser?.Partners || [];

  function getUser(): UserModel{
    return initialState?.currentUser?.User
  }
  if (!getUser()) return <></>

  function getUserName() {
    const User = getUser();
    return (User.firstname || '') + ' ' + (User.lastname || '');
  }

  function getAvatar() {
    const User = getUser();
    if (User.avatar) {
      return (
        <Avatar
          src={
            'https://preview.keenthemes.com/metronic-v4/theme_rtl/assets/pages/media/profile/profile_user.jpg'
          }
        />
      );
    } else {
      return <Avatar>{getInitials(getUserName())}</Avatar>;
    }
  }

  function getEmail() {
    const User = getUser();
    return User.email;
  }

  function onMenuClick(path: string) {
    setActive(false);
    if (path === '/user/login') {
      logOut();
    }
    history.push(path);
  }

  const menu = (
    <Menu className={styles.userMenuItems}>
      {partners.length > 1 && <PartnerSelection partners={partners}/>}
      {FOOTER_ROUTE_CONFIG?.filter((route) => checkUserAccessToPath(initialState?.currentUser, route.path)).map((route) => (
        <Menu.Item key={route.name} onClick={() => onMenuClick(route.path)}>
          <route.icon />
          <F id={route.messageId} defaultMessage={route.defaultMessage} />
        </Menu.Item>
      ))}
    </Menu>
  );

  const header = (
    <div className={styles.userMenuHeader}>
      <div>{getAvatar()}</div>
        <div className={styles.userInfo}>
          <div className={styles.name} id={'auth-user-name'}>
            {getUserName()}
          </div>
          <div className={styles.email}>{getEmail()}</div>
        </div>
    </div>
  );

  // if (!mini)
  //   return (
  //     <div className={styles.userMenu}>
  //       <Collapse
  //         defaultActiveKey={['1']}
  //         activeKey={active ? '1' : '0'}
  //         expandIconPosition={'right'}
  //         onChange={() => setActive(!active)}
  //       >
  //         <Panel header={header} key="1">
  //           {menu}
  //         </Panel>
  //       </Collapse>
  //     </div>
  //   );

  return (
    <Popover
      overlayClassName={styles.miniPopover}
      placement={'bottom'}
      content={<div className={styles.mini}>{menu}</div>}
    >
      <div className={classNames(styles.miniTitle)}>{header}</div>
    </Popover>
  );
};

export default UserMenuDropdown;
