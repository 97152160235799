import React, {useEffect, useState} from 'react';
import dayjs from "dayjs";
import {Col, Divider, Row, Space, Spin} from "antd";
import {useDispatch, useModel} from "umi";

import MyPageContainer from "@/components/MyPageContainer";
import {TasksTable} from "@/pages/Tasks/TasksTable";
import {F} from "@/utils/intl";
import {fetchLeadTasksList} from "@/services/api/leadTask";
import {fetchCalendarViewServiceRequests} from "@/services/api/serviceRequest";
import BigCalendarWrapper from "@/components/Kanban/ServiceRequests/Calendar/BigCalendarWrapper";
import {processServiceRequestsForCalendar} from "@/components/Kanban/ServiceRequests/Calendar/utils";
import styles from "./index.less";

const TodaySalesAgentView: React.FC = () => {
  const {initialState, setInitialState} = useModel('@@initialState');
  const currentUserAlias = initialState?.currentUser?.User?.alias

  const [taskList, setTaskList] = useState([])
  const [taskListForTomorrow, setTaskListForTomorrow] = useState([])
  const [serviceRequestList, setServiceRequestList] = useState([])
  const [serviceRequestListForTomorrow, setServiceRequestListForTomorrow] = useState([])
  const [loading, setLoading] = useState(false)
  const [serviceRequestsLoading, setServiceRequestsLoading] = useState(false)
  const [tomorrowServiceRequestsLoading, setTomorrowServiceRequestsLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    fetchServiceRequestsList()
    fetchServiceRequestsListForTomorrow()
  }, []);

  const fetchTaskList = () => {
    setLoading(true)
    const today = dayjs().format("YYYY-MM-DD")
    fetchLeadTasksList({
      query: {
        due_to: today,
      }
    }).then(response => {
      setLoading(false)
      setTaskList(response.data.data)
    })
  }

  const fetchTaskListForTomorrow = () => {
    setLoading(true)
    const tomorrow = dayjs().add(1, 'day').format("YYYY-MM-DD")
    fetchLeadTasksList({
      query: {
        due_to: tomorrow,
        due_from: tomorrow,
      }
    }).then(response => {
      setLoading(false)
      setTaskListForTomorrow(response.data.data)
    })
  }

  const fetchServiceRequestsList = () => {
    const today = dayjs().format("YYYY-MM-DD")
    setServiceRequestsLoading(true)
    fetchCalendarViewServiceRequests({
      query: {
        start_date: today,
        end_date: today,
        status: 'confirmed,tentative',
        assignee: currentUserAlias,
        all: 1
      }
    }).then((response) => {
      const serviceRequests = response?.data?.data || []
      setServiceRequestList(serviceRequests)
      dispatch({
        type: 'leads/saveLeadsData',
        payload: {
          list: serviceRequests.map(serviceRequest => serviceRequest?.lead).filter(lead => lead)
        }
      })
      setServiceRequestsLoading(false)
    })
  }

  const fetchServiceRequestsListForTomorrow = () => {
    const tomorrow = dayjs().add(1, 'day').format("YYYY-MM-DD")
    setTomorrowServiceRequestsLoading(true)
    fetchCalendarViewServiceRequests({
      query: {
        start_date: tomorrow,
        end_date: tomorrow,
        assignee: currentUserAlias,
        status: 'confirmed,tentative',
        all: 1
      }
    }).then((response) => {
      const serviceRequests = response?.data?.data || []
      setServiceRequestListForTomorrow(serviceRequests)
      dispatch({
        type: 'leads/saveLeadsData',
        payload: {
          list: serviceRequests.map(serviceRequest => serviceRequest?.lead).filter(lead => lead)
        }
      })
      setTomorrowServiceRequestsLoading(false)
    })
  }
  return (
    <MyPageContainer >
      <Row gutter={12}>
        <Col xl={8}>
          <Divider><Space>
            <span><F id={'pages.leads.todayView.appointment'} defaultMessage={'Your Day'}/></span>
          </Space></Divider>
          <Spin spinning={serviceRequestsLoading}>
            <BigCalendarWrapper wrapperClassName={styles.todayCalendarView} currentDate={new Date()} events={processServiceRequestsForCalendar(serviceRequestList)} resources={[]} onEventsChange={() => {}}/>
          </Spin>
        </Col>
        <Col xl={16}>
          <Divider><F id={'pages.lead.drawer.tasks'} defaultMessage={'Tasks'}/></Divider>
          <TasksTable hideCreate hideShowDone data={taskList} loading={loading} request={fetchTaskList}/>
        </Col>
      </Row>
      <Divider><h2><F id={'pages.leads.todayView.tomorrow'} defaultMessage={'Tomorrow'}/></h2></Divider>
      <Row gutter={12}>
        <Col xl={8}>
          <Spin spinning={tomorrowServiceRequestsLoading}>
            <BigCalendarWrapper wrapperClassName={styles.todayCalendarView} currentDate={dayjs().add(1 ,'day').toDate()}
                                events={processServiceRequestsForCalendar(serviceRequestListForTomorrow)} resources={[]}
                                onEventsChange={() => {
                                }}/>
          </Spin>
        </Col>
        <Col xl={16}>
          <TasksTable hideCreate hideShowDone data={taskListForTomorrow} loading={loading}
                      request={fetchTaskListForTomorrow}/>
        </Col>
      </Row>
    </MyPageContainer>
  );
};

export default TodaySalesAgentView
