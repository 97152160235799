import styles from "@/components/Kanban/ServiceRequests/index.less";
import LeadName from "@/components/Lead/LeadName";
import {Popover, Space, Tag} from "antd";
import React from "react";
import {useDispatch} from "umi";
import LeadEmail from "@/components/Lead/LeadEmail";
import LeadPhone from "@/components/Lead/LeadPhone";
import {MailOutlined, PhoneOutlined} from "@ant-design/icons";

interface TeamViewCalendarEventLeadProps {
  event: any
}
const TeamViewCalendarEventLead = ({ event }: TeamViewCalendarEventLeadProps) => {
  const dispatch = useDispatch()
  const [visible, setVisible] = React.useState(false)
  const onLeadClick = () => {
    setVisible(false)
    dispatch({
      type: 'leads/toggleLeadDetailsDrawer',
      payload: {
        lead: event.lead
      }
    })
  }
  const popoverContent = <div className={styles.eventLeadOverviewPopoverContent}>
    <div className={styles.leadNameContainer} onClick={onLeadClick}>
      <LeadName nameClassName={styles.leadName} avatarClassName={styles.leadAvatar} lead={event.lead} readonly/>
    </div>
    <div>
      <Space>
        <MailOutlined/>
        <LeadEmail lead={event.lead} readonly/>
      </Space>
    </div>
    <div>
      <Space>
        <PhoneOutlined/>
        <LeadPhone lead={event.lead} readonly/>
      </Space>
    </div>
  </div>

  return <Popover open={visible} onOpenChange={setVisible} placement={"right"} content={popoverContent}>
    <Tag className={styles.eventLeadTag}
         onClick={onLeadClick}
         onMouseOver={e => e.stopPropagation()}>
      <LeadName lead={event.lead} readonly/>
    </Tag>
  </Popover>
}

export default TeamViewCalendarEventLead
