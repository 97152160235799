export const getDays = (intl)=> {


    const daysDictionary = [
        {
          day: 'monday',
          title: intl.formatMessage({
            id: 'component.locationDrawer.monday',
            defaultMessage: 'Monday',
          }),
          enabled: false,
          slots: [{ start: null, end: null }],
        },
        {
          day: 'tuesday',
          title: intl.formatMessage({
            id: 'component.locationDrawer.tuesday',
            defaultMessage: 'Tuesday',
          }),
          enabled: false,
          slots: [{ start: null, end: null }],
        },
        {
          day: 'wednesday',
          title: intl.formatMessage({
            id: 'component.locationDrawer.wednesday',
            defaultMessage: 'Wednesday',
          }),
          enabled: false,
          slots: [{ start: null, end: null }],
        },
        {
          day: 'thursday',
          title: intl.formatMessage({
            id: 'component.locationDrawer.thursday',
            defaultMessage: 'Thursday',
          }),
          enabled: false,
          slots: [{ start: null, end: null }],
        },
        {
          day: 'friday',
          title: intl.formatMessage({
            id: 'component.locationDrawer.friday',
            defaultMessage: 'Friday',
          }),
          enabled: false,
          slots: [{ start: null, end: null }],
        },
        {
          day: 'saturday',
          title: intl.formatMessage({
            id: 'component.locationDrawer.saturday',
            defaultMessage: 'Saturday',
          }),
          enabled: false,
          slots: [{ start: null, end: null }],
        },
        {
          day: 'sunday',
          title: intl.formatMessage({
            id: 'component.locationDrawer.sunday',
            defaultMessage: 'Sunday',
          }),
          enabled: false,
          slots: [{ start: null, end: null }],
        },
      ];
      return daysDictionary;
}