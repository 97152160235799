
import { Checkbox } from "antd";

const TriStateCheckbox = ({ checked, indeterminate, onChange, key, disabled }) => {
  return (
    <Checkbox
      disabled={disabled}
      key={key}
      checked={checked}
      indeterminate={indeterminate}
      onChange={onChange}
    />
  );
};
  

  export default TriStateCheckbox;