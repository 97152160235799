import React from "react";
import {useDispatch} from "umi";

import {LeadModel} from "@/typings/models/Lead";


const LeadMarketingChannel = ({lead, isTable}: {lead: LeadModel, isTable?: boolean}) => {
  const dispatch = useDispatch()
  const {customer} = lead

  // TODO: Next iteration
  // const onChangeMarketingChannel = (newMarketingChannel) => {
  //   dispatch({
  //     type: 'leads/edit',
  //     payload: {
  //       marketing_channel: newMarketingChannel,
  //       leadId: lead.id
  //     }
  //   })
  // }
  // return <LeadMarketingChannelDropdownFilters selectProps={{bordered: false, allowClear: false}} filterMode={"select"} onChange={onChangeMarketingChannel} value={lead.marketing_channel}/>
  const content = customer?.utm_medium
  if (isTable) return <div style={{minWidth: 130}}>{content}</div>
  return content
}

export default LeadMarketingChannel
