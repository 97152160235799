import ProTable, {ActionType, ProColumns, ProTableProps} from '@ant-design/pro-table';
import {Grid} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import get from 'lodash/get';

import EditableCell from '@/components/Table/EditableCell';
import EditableRow from '@/components/Table/EditableRow';
import MyPagination, {PAGINATION_MODES} from '@/components/MyPagination';
import {PaginationLinksModel} from '@/typings/models/PaginationLinks';

import Card from '../Card';
import debounce from 'lodash/debounce';

export type TableProps = {
  id?: string;
  loading: boolean;
  dataSource: any[];
  headerTitle?: string;
  pagination?: boolean;
  total?: number;
  search?: boolean;
  fullScreen?: boolean;
  reload?: boolean;
  hasPageSize?: boolean;
  rowSelection?: any;
  links?: PaginationLinksModel;
  request?: (payload: any) => void;
  onTableChange?: (payload: any) => void;
  columns: ProColumns<any, 'text'>[];
  rowKey?: string;
  header?: any;
  label?: string;
  expandableRow?: React.Component;
  rowClassName?: string;
  toolBarRender?: () => React.ReactElement;
  isClientSideSearch?: boolean;
  isCard?: boolean;
  xScroll?: boolean;
  scrollHeight?: number;
  onSave?: () => {};
  extraTableProps?: ProTableProps<any, any>;
  isRowExpandable?: (rowKey: any) => boolean;
  startPageIndex?: number;
  totalPages?: number;
  pageSize?: number;
  currentPage?: number;
};

const Table: React.FC<TableProps> = ({
  request = () => {},
  onTableChange = () => {},
  total = 0,
  isCard = true,
  onSave,
  loading,
  search = true,
  fullScreen = true,
  reload = true,
  pagination,
  label,
  links,
  rowSelection,
  header,
  dataSource,
  headerTitle,
  hasPageSize,
  columns,
  rowKey = 'key',
  expandableRow,
  toolBarRender,
  rowClassName,
  startPageIndex = 0,
  totalPages = 0,
  scrollHeight = 0,
  isClientSideSearch = false,
  xScroll = false,
  isRowExpandable,
  id,
  extraTableProps = {},
  pageSize = 30,
  currentPage = 0,
}) => {
  const actionRef = useRef<ActionType>();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [limit, setLimit] = useState(pageSize ?? 30);
  const [height, setHeight] = useState(500);
  const [filteredData, setFilteredData] = useState(dataSource);
  const lastScrollTop = useRef(0);

  const paginationMode = get(pagination, 'mode', PAGINATION_MODES.CURSOR);

  const [page, setPage] = useState(currentPage || startPageIndex || 0);

  const getScrollableContainerElement = () => document.querySelector(`#${id} .ant-table-body`);

  const triggerInfiniteScrollPagination = debounce(
    () => {
      setPage((prev) => {
        if (prev >= totalPages) return prev;
        request({
          page: ++prev,
          concat: true,
        });
        return prev;
      });
    },
    500,
    {
      leading: true,
      trailing: false,
    },
  );
  const onTableScroll = () => {
    const scrollableContainer = getScrollableContainerElement();
    const scrollTop = scrollableContainer.scrollTop;
    const scrollHeight = scrollableContainer.scrollHeight;
    const clientHeight = scrollableContainer.clientHeight;
    // Check if the user is near the end (within 100px of the bottom)
    if (scrollTop > lastScrollTop.current) {
      if (scrollHeight - scrollTop <= clientHeight + 100) {
        triggerInfiniteScrollPagination();
      }
    }

    lastScrollTop.current = scrollTop;
  };

  useEffect(() => {
    request();
    setHeight(scrollHeight || window.innerHeight * 0.5);

    if (paginationMode === PAGINATION_MODES.SCROLL && id)
      getScrollableContainerElement()?.addEventListener('scroll', onTableScroll);

    return () => {
      if (paginationMode === PAGINATION_MODES.SCROLL && id)
        getScrollableContainerElement()?.removeEventListener('scroll', onTableScroll);
    };
  }, []);

  const getColumns = () =>
    columns.map((col) => {
      if (!col.editable) return col;

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          rowKey,
          handleSave: onSave,
        }),
      };
    });

  function onPaginationChange(type, value) {
    let newPage = currentPage || page;
    switch (type) {
      case 'PREV': {
        newPage = page - 1;
        request({
          url: links?.prev,
          page: newPage,
        });
        break;
      }
      case 'FIRST': {
        newPage = 0;
        request({
          url: links?.first,
          page: newPage,
        });
        break;
      }
      case 'NEXT': {
        newPage = page + 1;
        request({
          url: links?.next,
          page: newPage,
        });
        break;
      }
      case 'LAST': {
        request({
          url: links?.last,
          page: newPage,
        });
        break;
      }
      case 'SIZE': {
        request({
          limit: value,
          page: newPage,
        });
        setLimit(value);
        break;
      }
    }
    setPage(newPage);
  }

  useEffect(() => {
    setFilteredData(dataSource);
  }, [JSON.stringify(dataSource)]);

  function processChange(filters) {
    if (isClientSideSearch) {
      const keyword = filters.keyword?.toLowerCase() || '';
      const filtered = dataSource.filter((item) => {
        return Object.keys(item).some((key) => String(item[key]).toLowerCase().includes(keyword));
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(dataSource);
    }

    setPage(startPageIndex);
    request({
      search: filters.keyword,
      page: startPageIndex,
    });
  }

  const Container = isCard ? Card : React.Fragment;

  const scroll = { y: height, scrollToFirstRowOnChange: true };
  if (xScroll) scroll.x = true;

  return (
    <Container>
      <div>
        {header}
        <ProTable
          rowClassName={rowClassName}
          id={id}
          headerTitle={false && headerTitle}
          pagination={false}
          loading={loading}
          actionRef={actionRef}
          rowKey={rowKey || 'key'}
          request={processChange}
          search={false}
          components={
            onSave
              ? {
                  body: {
                    cell: EditableCell,
                    row: EditableRow,
                  },
                }
              : {}
          }
          options={{
            search,
            density: false,
            fullScreen,
            setting: false,
            reload,
          }}
          scroll={scroll}
          onChange={onTableChange}
          toolBarRender={toolBarRender}
          dataSource={filteredData}
          columns={getColumns()}
          rowSelection={rowSelection}
          expandable={
            expandableRow && {
              expandedRowRender: expandableRow,
              rowExpandable: isRowExpandable || (() => !screens.xl),
            }
          }
          {...extraTableProps}
        />
        {pagination !== false && (links || paginationMode === PAGINATION_MODES.PAGE) && (
          <MyPagination
            tableLabel={label}
            count={dataSource?.length}
            links={links}
            onChange={onPaginationChange}
            loading={loading}
            size={limit}
            hasPageSize={hasPageSize}
            mode={paginationMode}
            hasMore={get(pagination, 'hasMore')}
            page={page}
            total={total}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </Container>
  );
};

export default Table;
