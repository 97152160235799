import {LeadTaskModel} from "@/typings/models/LeadTask";
import {Button} from "antd";
import {FormattedMessage as F} from "umi";
import {CheckOutlined} from "@ant-design/icons";
import * as React from "react";
import {useState} from "react";
import {editLeadTaskRequest} from "@/services/api/leadTask";

type IDoTaskProps = {
  task: LeadTaskModel,
  updateRequest: () => void
}
const DoTask : React.FC<IDoTaskProps> = ({task, updateRequest}: IDoTaskProps) => {
  const [loading, setLoading] = useState(false)
  function doTask() {
    setLoading(true)
    editLeadTaskRequest({
      params: {
        taskId: task.id
      },
      data: {
        ...task,
        due_date: task.due_date.replace(".000000Z", "Z"),
        is_completed: true
      }
    }).then(() => {
      setLoading(false)
      updateRequest()
    })
  }
  return (
    task.is_completed ? (
      <Button size={"small"} type="default" disabled>
        <F id="pages.lead.tasks.done" defaultMessage="Done" />
      </Button>
    ) : (
      <Button loading={loading} icon={<CheckOutlined/>} type="primary" size={"small"} onClick={doTask}/>
    )
  )
}

export default DoTask
