import React, {useState} from 'react'
import {Button, Empty, Popconfirm, Space, Spin, Switch} from "antd";
import {FormattedMessage as F, useDispatch, useIntl} from "umi";
import PrettyDate from "@/components/PrettyDate";
import {CheckOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import CreateTaskModal from "@/pages/Tasks/CreateTaskModal";
import LeadCard from "@/components/Kanban/LeadCard";
import {LeadTaskModel} from "@/typings/models/LeadTask";
import {editLeadTaskRequest} from "@/services/api/leadTask";
import Card from "@/components/Card";

import styles from "./index.less"

type ITasksTableProps = {
  data: LeadTaskModel[];
  request: () => void;
  loading?: boolean;
  leadId?: string;
  filterColumns?: (column) => boolean;
  hideCreate?: boolean;
  hideActionText?: boolean;
  hideShowDone?: boolean;
}

export function TasksList({
                            data,
                            request,
                            loading,
                            leadId,
                            filterColumns = (column) => true,
                            hideCreate,
                            hideActionText,
                            hideShowDone
                          }: ITasksTableProps) {
  const [showDone, setShowDone] = useState(false)
  const dispatch = useDispatch()
  const intl = useIntl()
  const [localLoading, setLocalLoading] = useState(false)
  const toggleShowDone = () => setShowDone(prev => !prev)
 const [showEdit, setShowEdit] = useState(false);
  const [selectedTask, setSelectedTask] = useState(undefined);
  function doTask(task: LeadTaskModel) {
    setLocalLoading(true)
    editLeadTaskRequest({
      params: {
        taskId: task.id
      },
      data: {
        ...task,
        due_date: task.due_date.replace(".000000Z", "Z"),
        is_completed: true
      }
    }).then(() => {
      setLocalLoading(false)
      request()
    })
  }
  const deleteTask = (id: string, resolve: Function) => {
    dispatch({
      type: 'leadTasks/deleteTask',
      payload: {
        taskId: id,
        cb: resolve,
        intl,
      },
    });
  };
  const tasks = data.filter(task => showDone ? task.is_completed : !task.is_completed)
  return (
    <Spin spinning={localLoading || loading || false}>
      <div className={styles.tasksListContainer}>
      {showEdit && (
        <CreateTaskModal
          leadId={leadId}
          showEdit={showEdit}
          updateRequest={request}
          task={selectedTask}
          setShowEdit={() => {
            setSelectedTask(undefined);
            setShowEdit(false);
          }}
        />
      )}
        <div className={styles.tasksListHeaderActions}>
          {!hideShowDone && (
            <Switch
              checked={showDone}
              onChange={toggleShowDone}
              checkedChildren={<F id="pages.lead.tasks.showOpen" defaultMessage="Show Open" />}
              unCheckedChildren={<F id="pages.lead.tasks.showDone" defaultMessage="Show Done" />}
            />
          )}
          {!hideCreate && <CreateTaskModal leadId={leadId} updateRequest={request} />}
        </div>
        {tasks.length === 0 && !loading && <Empty />}
        <div className={styles.tasksList}>
          {tasks.map((task) => (
            <Card key={task.id} className={styles.taskCard}>
              <div className={styles.taskContainer}>
                <div className={styles.taskInfo}>
                  <div className={styles.taskTitle}>{task.title}</div>
                  <div className={styles.taskDueDate}>
                    <Space>
                      <F id="pages.lead.tasks.taskDue" defaultMessage="Due date" />
                      <PrettyDate date={task.due_date} />
                    </Space>
                  </div>
                  {task.lead && task.lead?.customer && (
                    <LeadCard
                      leadContextMenuProps={{
                        hideTasksBadge: true,
                      }}
                      overrideVisibleProperties={['name']}
                      disableDrag
                      hideAssignee
                      lead={task.lead}
                      id={task.lead.id}
                    />
                  )}
                  {task.description_summary && (
                    <div className={styles.taskDescription}>{task.description_summary}</div>
                  )}
                </div>
                <div className={styles.taskActions}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {task.is_completed ? (
                      <Button size={'small'} type="default" disabled>
                        <F id="pages.lead.tasks.done" defaultMessage="Done" />
                      </Button>
                    ) : !hideActionText ? (
                      <Button
                        icon={<CheckOutlined />}
                        type="primary"
                        size={'small'}
                        onClick={() => doTask(task)}
                      >
                        Mark as done
                      </Button>
                    ) : (
                      <Button
                        onClick={() => doTask(task)}
                        icon={<CheckOutlined />}
                        shape={'round'}
                        type="primary"
                      />
                    )}
                    <Button
                      style={{ marginLeft: 15 }}
                      icon={<EditOutlined />}
                      onClick={() => {
                        setShowEdit(true);
                        setSelectedTask(task);
                      }}
                    />

                    <Popconfirm
                      onConfirm={() => new Promise((resolve) => deleteTask(task.id, resolve))}
                      placement={'topRight'}
                      title={
                        <F
                          id={'pages.lead.tasks.removeConfirm'}
                          defaultMessage={'Are you sure to remove this task?'}
                        />
                      }
                    >
                      <Button style={{ marginLeft: 15 }} icon={<DeleteOutlined />}  />
                    </Popconfirm>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </Spin>
  );
}
