import * as React from 'react';
import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Input, Popconfirm } from 'antd';
import { useDispatch, useSelector, FormattedMessage as F, useIntl } from 'umi';

import { LEAD_SOURCE_TYPE_WEBHOOK } from '../constants';
import NewWebhookSource from './NewWebHookSource';
import { CheckAccess } from '@/utils/access';
import PERMISSIONS from '@/constants/permissions';
const { Search } = Input;

const WebhookLeadSourceConfig: React.FC = () => {
  const [isNewSourceModalVisible, setIsNewSourceModalVisible] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredLeadSourceList, setFilteredLeadSourceList] = useState([]);

  const dispatch = useDispatch();
  const intl = useIntl();

  const { leadSourceList } = useSelector((state: DefaultRootState) => ({
    leadSourceList: state.journey.list,
    leadConfig: Object.values(state.journey.config)?.[0],
    loading: state.loading.effects['journey/fetchConfig'],
    leadSourceDetail: state.leadSources.leadSourceDetail?.lead_source,
  }));

  React.useEffect(() => {
    if (searchQuery) {
      const filteredList = leadSourceList.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      setFilteredLeadSourceList(filteredList);
    } else {
      setFilteredLeadSourceList(leadSourceList);
    }
  }, [searchQuery, leadSourceList]);

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const emptyLeadDetailState = () => {
    dispatch({
      type: 'leadSources/saveLeadSourceDetail',
      payload: {},
    });
  };

  React.useEffect(() => {
    emptyLeadDetailState();
  }, []);

  const fetchLeadConfig = (id) => {
    dispatch({
      type: 'leadSources/fetchLeadSource',
      payload: { id },
    });
  };

  const deleteLeadSource = (id: string, resolve: Function) => {
    dispatch({
      type: 'leadSources/deleteLeadSource',
      payload: {
        id,
        cb: resolve,
        intl,
      },
    });
  };

  const leadSourceListColumns = [
    {
      title: <F id={'pages.leadsources.name'} defaultMessage={'Name'} />,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <F id={'pages.customers.actions'} defaultMessage={'Name'} />,
      key: 'action',
      render: (record) => (
        <div>
          <CheckAccess access={[PERMISSIONS.LeadSource.EDIT]} asterisk every>
            <Button
              type="link"
              style={{ color: '#27AE9D' }}
              onClick={() => {
                fetchLeadConfig(record.hashId);
                setIsNew(false);
                setIsNewSourceModalVisible(true);
              }}
            >
              <F id={'pages.leadsources.webhook.edit'} defaultMessage={'Edit'} />
            </Button>
          </CheckAccess>
          <CheckAccess access={[PERMISSIONS.LeadSource.DELETE]} asterisk every>
            <Popconfirm
              onConfirm={() => new Promise((resolve) => deleteLeadSource(record.hashId, resolve))}
              placement={'topRight'}
              title={
                <F
                  id={'pages.leadsources.removeConfirm'}
                  defaultMessage={'Are you sure to remove this lead source?'}
                />
              }
            >
              <Button type="link" style={{ color: '#27AE9D' }}>
                <F id={'pages.leadsources.delete'} defaultMessage={'Delete'} />
              </Button>
            </Popconfirm>
          </CheckAccess>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div>
        {isNewSourceModalVisible && (
          <NewWebhookSource
            open={isNewSourceModalVisible}
            onClose={() => {
              emptyLeadDetailState();
              setIsNewSourceModalVisible(false);
            }}
            isNew={isNew}
          />
        )}
        {!isNewSourceModalVisible && (
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 25,
              }}
            >
              <Search
                placeholder={intl.formatMessage({
                  id: 'pages.leadsources.search',
                  defaultMessage: 'Search',
                })}
                allowClear
                onSearch={handleSearch}
                style={{ width: 200 }}
              />
              <CheckAccess access={[PERMISSIONS.LeadSource.CREATE,PERMISSIONS.Leads.CREATE]} asterisk every>
                <Button
                  type="link"
                  style={{ color: '#27AE9D' }}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setIsNew(true);
                    setIsNewSourceModalVisible(true);
                  }}
                >
                  <F id={'pages.leadsources.webhook.new'} defaultMessage={'Setup New Webhook'} />
                </Button>
              </CheckAccess>
            </div>
            <Table
              columns={leadSourceListColumns}
              dataSource={filteredLeadSourceList?.filter(
                (source) => source.type === LEAD_SOURCE_TYPE_WEBHOOK,
              )}
              pagination={{
                pageSize: 5,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50'],
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WebhookLeadSourceConfig;
