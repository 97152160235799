import React, {useEffect, useState} from 'react';
import {Col, DatePicker, Row, TimePicker} from 'antd';
import type {Dayjs} from 'dayjs';
import dayjs from 'dayjs';

import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(customParseFormat);
type IAppointmentSelectorProps = {
  value: {
    date?: any;
    startTime?: any;
    endTime?: any;
  };
  onChange: (value: IAppointmentSelectorProps['value']) => void;

}
const AppointmentSelector = ({value = {}, onChange}: IAppointmentSelectorProps) => {


  const [rangePickerOpen, setRangePickerOpen] = useState(false)

  useEffect(() => {
    return () => onChange({})
  }, []);

  const handleDateChange = (date) => {
    onChange({
      ...value,
      date
    })
    if (!value.startTime) setRangePickerOpen(true)
  };

  const handleTimeChange = (values) => {
    onChange({
      ...value,
      startTime: values[0],
      endTime: values[1],
    })
  };
  const disablePastDates = (current: Dayjs | null): boolean => {
    return !!current && current.isBefore(dayjs().startOf('day'));
  };
  return (
    <div style={{padding: '10'}}>
      <Row gutter={16} align="middle">
        <Col>
          <DatePicker
            onChange={handleDateChange}
            value={value.date}
            format="dddd, MMMM D"
            bordered={false}
            size="small"
            style={{width: 210}}
            dropdownClassName="dark-dropdown"
            placeholder="Select Date"
            allowClear={false}
            disabledDate={disablePastDates}
          />
        </Col>
        <Col>
          <TimePicker.RangePicker
            bordered={false}
            allowClear={false}
            open={rangePickerOpen}
            onOpenChange={setRangePickerOpen}
            onChange={handleTimeChange}
            minuteStep={15}
            size={"small"}
            value={[value.startTime, value.endTime]}
            format={"HH:mm"}/>
        </Col>
      </Row>
    </div>
  );
};

export default AppointmentSelector;
