import { PermissionModel, RolesPermissionModel } from "@/typings/models/Role";

  

  export const createRoleColumns = (roles: Role[], createRoleColumn: (name: string, id: string) => any) => {
    return roles.map((role) => createRoleColumn(role.name, role.id));
  };
  export const sortCategories = (categories: { [category: string]: PermissionModel[] }) => {
    return Object.entries(categories).sort(([a], [b]) => a.localeCompare(b));
  };
  
  export const generateInitialData = (
    sortedCategories: [string, PermissionModel[]][],
    roles: RolesPermissionModel[]
  ) => {
    return sortedCategories.flatMap(([category, permissions]) => {
      const categoryRow = {
        key: `group-${category}`,
        group: category,
        permission: category,
        description: '',
        isGroup: true,
  
      };
  
      const permissionRows = permissions.map((permission) => {
        const row = {
          key: permission.name,
          group: category,
          permission: permission.name,
          description: permission.description,
        };
        roles.forEach((role) => {
          row[role.id] = role.permissions.includes(permission.name);
          row[role.id + '-locked'] = role.are_permissions_locked
          categoryRow[category + role.id  + '-locked'] = role.are_permissions_locked
        });
        return row;
      });
  
      determineCategoryRowState(categoryRow, permissionRows, roles);
  
      return [categoryRow, ...permissionRows];
    });
  };
  
  export const determineCategoryRowState = (categoryRow: any, permissionRows: any[], roles: Role[]) => {
    roles.forEach((role) => {
      const allChecked = permissionRows.every((row) => row[role.id] === true);
      const someChecked = permissionRows.some((row) => row[role.id] === true);
      categoryRow[role.id] = allChecked ? true : someChecked ? 'indeterminate' : false;
    });
  };
