import React from 'react';
import {useIntl} from 'umi';

import OpenHours from './UserOpenHours';
import DrawerSection from '../../Drawer/DrawerSection';
import UserPeriodsForm from './UserPeriodsForm';

const UserWorkingHours: React.FC = ({onChangeUserAvailability,userData}) => {
  const intl = useIntl();
  const onChanges = (user) => {
    onChangeUserAvailability(user);
  }

  return (
    <div>
      <div>
        <DrawerSection
        >
          <OpenHours user={userData} onChange={onChanges} />
        </DrawerSection>

        <>
          <DrawerSection
            title={intl.formatMessage({
              id: 'pages.users.offHours',
              defaultMessage: 'Set Off Hours',
            })}
          >
            <UserPeriodsForm mode={'off'} user={userData} onChange={onChanges} />
          </DrawerSection>
          <DrawerSection
            title={intl.formatMessage({
              id: 'pages.users.additionalAvailability',
              defaultMessage: 'Additional Availability',
            })}
          >
            <UserPeriodsForm mode={'extra'} user={userData} onChange={onChanges} />
          </DrawerSection>
        </>
      </div>
    </div>
  );
};

export default React.memo(UserWorkingHours, () => true);
