import {getRequiredPermissionsForPath} from '../typings/roleMapping';
import {useModel} from "umi";
import {SOLUTION_KEYS} from "@/constants/solutions";
import {BackofficeUserModel} from "@/typings/api/user";
import PERMISSIONS from "@/constants/permissions";
import isEmpty from "lodash/isEmpty";

export function checkUserAccessToPath(user: BackofficeUserModel, path: string): boolean {
  const requiredPermissions = getRequiredPermissionsForPath(path)
  return requiredPermissions.includes('*') || hasAccess(user, requiredPermissions)
}

export const getPermissionsSet = (user: BackofficeUserModel) => {
  if (isEmpty((user))) return new Set()

  if (user.permissionsSet &&
    (user.permissionsSet instanceof Set)) return user.permissionsSet

  const allPermissions = Object.keys((user.Permissions || {})).reduce((acc, key) => {
    return acc.concat(user.Permissions[key]);
  }, []);

  return new Set(allPermissions)
}


// region Access functions

interface IHasAccessOptions {
  every?: boolean
  asterisk?: boolean
}
export const hasAccess = (user: BackofficeUserModel,
                          access: string | string[],
                          options: IHasAccessOptions = {}) => {
  if (!access) return true
  const permissions = getPermissionsSet(user)

  const every = options.every || false
  const asterisk = options.asterisk || false
  // Superuser
  if (asterisk && permissions.has('*')) return true

  if (!Array.isArray(access))
    access = [access]

  access = access.map((permission) => permission.replace('+', ''))

  if (every) return access.every((permission) => permissions.has(permission))
  return access.some((permission) => permissions.has(permission))
}

export const CheckAccess = ({access, every = false, children, fallback = null, asterisk = false}) => {
  const {initialState} = useModel('@@initialState')
  if (hasAccess(initialState.currentUser, access, {every, asterisk})) return children
  return fallback || null
}

// endregion

// region Solutions

export const getUserSolutions = (user: BackofficeUserModel) => {
  const solutions: string[] = []
  if (hasAccess(user, PERMISSIONS.BUSINESS_PARTNER))
    solutions.push(SOLUTION_KEYS.LEAD_GENERATION)
  if (hasAccess(user, [PERMISSIONS.Leads.VIEW], {asterisk: true}))
    solutions.push(SOLUTION_KEYS.LEAD_MANAGEMENT)

  return solutions
}

export const getDefaultSolution = (user: BackofficeUserModel) => {
  const solutions = getUserSolutions(user)
  return solutions[0]
}

// endregion
