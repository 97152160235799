import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {message} from 'antd';

import {
  addAgentToBranchRequest,
  createBranchRequest,
  createServiceRequest,
  deleteBranchRequest,
  deleteServiceRequest,
  fetchAgentsRequest,
  fetchBranchAgentsRequest,
  fetchBranchServicesRequest,
  fetchBranchStaffRequest,
  fetchServicesOfBranchRequest,
  getBranchRequest,
  getServiceRequest,
  removeAgentFromBranchRequest,
  updateBranchRequest,
  updateServiceRequest,
} from '@/services/api/location';
import {getChangeLoadings, setChangeLoadingsUtility} from '@/utils/form';
import {trackModalView, trackOnlineEditForm} from '@/services/tracker';
import {LOCATION_EDIT_ACTION} from '@/constants/trackerActions';
import {processAPIResponse} from '@/utils/request';
import {fetchAvailableTimezones, fetchUserBranches} from '@/services/api/user';

const initialState = {
  selectedService: {},
  selectedBranch: {},
  list: [],
  timezones:[],
  changeLoadings: {},
  userBranches: [],
  agentList: [],
  branchStaffList: [],
  branchDrawer: {
    branch: {},
    visible: false,
    branchId: '',
  },
  ServicesOfBranch: [],
  newService: {},

}
export default {
  namespace: 'location',

  state: {
    selectedService: {},
    selectedBranch: {},
    list: [],
    changeLoadings: {},
    userBranches: [],
    agentList: [],
    branchDrawer: {
      branch: {},
      visible: false,
      branchId: '',
    },
    ServicesOfBranch: [],
    newService: {},
  },

  effects: {
    *init({ }, { put }) {
      const actions = ['fetch'];
      for (const action of actions) {
        yield put({
          type: action,
        });
      }
    },
    * getTimeZones({payload = {}}, {call, put}) {
    const { response, data } = yield call(fetchAvailableTimezones, {  });
    yield processAPIResponse(
      {response, data},
      {
        * onSuccess(data) {
        yield put({
          type: 'setTimeZones',
          payload: data?.data,
        });

        },
        *onError() { },
      },
    );
  },
    * getUserBranches({payload = {}}, {call, put}) {
      let query = {};
      if (payload.search) {
        query.search = payload.search;
      }
      query.all = 1
      const { response, data } = yield call(fetchUserBranches, { query, params: { all: 1 } });
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'fetchedUserBranches',
              payload: data?.data,
            });
          },
          *onError() { },
        },
      );
    },
    * getBranch({payload: {branchId}}, {call, put}) {
      const {response, data} = yield call(getBranchRequest, {params: {branchId}});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'setBranch',
              payload: data?.data,
            });
          },
          *onError() { },
        },
      );
    },
    * getService({payload: {serviceId}}, {call, put}) {
      const {response, data} = yield call(getServiceRequest, {params: {serviceId}});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'setService',
              payload: data?.data,
            });
          },
          *onError() { },
        },
      );
    },
    * fetchAgents({}, {call, put}) {
      const { response, data } = yield call(fetchAgentsRequest, {});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'setAgentList',
              payload: data,
            });
          },
          *onError(error) { },
        },
      );
    },
    * fetchBranchStaff(payload, {call, put}) {
      const { branchId } = payload.payload;
      const { response, data } = yield call(fetchBranchStaffRequest, { params: { id: branchId } });
    yield processAPIResponse(
      {response, data},
      {
        * onSuccess(data) {
          yield put({
            type: 'setBranchStaffList',
            payload: data,
          });
        },
        *onError(error) { },
      },
    );
  },
    * fetchBranchAgentsRequest({payload: {branchId}}, {call, put}) {
      const { response, data } = yield call(fetchBranchAgentsRequest, {params: {branchId}});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'setBranchAgents',
              payload: {
                agents: data,
                branchId
              },
            });
          },
          * onError(error) {
          },
        },
      );
    },
    * fetch({all}, {call, put, select}) {
      const {response, data} = yield call(fetchBranchServicesRequest, {params: {all}});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'saveBranchServicesList',
              payload: data,
            });
          },
          *onError(error) { },
        },
      );
    },
    * fetchServicesOfBranch({payload: {branchId}}, {call, put}) {
      const {response, data} = yield call(fetchServicesOfBranchRequest, {params: {all: 1, branchId}});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'saveServicesOfBranch',
              payload: data,
            });
          },
          *onError(error) { },
        },
      );
    },
    * addAgentToBranch({payload}, {call, put, select}) {
      const branchId = payload.branchId
    const {response, data} = yield call(addAgentToBranchRequest, {payload});
    yield processAPIResponse(
      {response, data},
      {
        * onSuccess(data) {
        yield put({
          type: 'fetchBranchStaff',
          payload: {
            branchId:branchId
          },
        });

        },
        *onError(error) { },
      },
    );
  },
    * removeAgentFromBranch({payload}, {call, put, select}) {
      const branchId = payload.branchId
    const {response, data} = yield call(removeAgentFromBranchRequest, {payload});
    yield processAPIResponse(
      {response, data},
      {
        * onSuccess(data) {
          yield put({
          type: 'fetchBranchStaff',
          payload: {
            branchId:branchId
          },
        });

        },
        *onError(error) { },
      },
    );
  },
    * createBranch({payload,cb,intl}, {call, put, select}) {

      payload.is_active = get(payload, 'is_active', true);
      const {response, data} = yield call(createBranchRequest, {payload});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            cb && cb(data?.data,true)
            message.success(
               intl.formatMessage({ id: 'pages.locations.createBranchSucceeded', defaultMessage: 'Branch created successfully.' }),
            );
            yield put({
              type: 'getUserBranches',
            });
          },
          *onError(error) {
            message.success(
              intl.formatMessage({ id: 'pages.locations.createBranchFailed', defaultMessage: 'Branch creation failed' }),
           );
          },
        },
      );
    },
    * deleteBranch({payload: {branchId, cb, intl}}, {call, put}) {
      const {response, data} = yield call(deleteBranchRequest, {params: {branchId}});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess() {
            yield put({
              type: 'toggleBranchDrawer',
            });
            yield put({
              type: 'getUserBranches',
            });
            yield put({
              type: 'fetch',
            });
            cb && cb(data);
          },
          * onError(error) {
            message.error(
              error?.message || intl.formatMessage({ id: 'pages.locations.deleteBranchFailed', defaultMessage: 'Failed to delete branch' }),
            );
          },
        },
      );
    },
    * updateBranch({payload: {...body}}, {call, put, select}) {
      yield put({
        type: 'setChangeLoadings',
        payload: getChangeLoadings(body, true),
      });
      const {response, data} = yield call(updateBranchRequest, {
        params: { branchId: body.branchId },
        payload: body,
      });
      yield put({
        type: 'setChangeLoadings',
        payload: getChangeLoadings(body, false),
      });
      yield put({
        type: 'getUserBranches',
      });
      trackOnlineEditForm(body, LOCATION_EDIT_ACTION);

      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'fetch',
            });
          },
        },
      );
    },

    *createService({ payload }, { call, put, select }) {
      const cb = payload?.cb;
      const payloadData = payload?.data;
      if(isEmpty(payloadData.agents)){
        payloadData.agents = []
      }

      payloadData.calendar = { ...payloadData?.calendar, appointment_available_from: 48 };
      if (!payloadData?.calendar?.Availability) {
        payloadData.calendar.weekdays = [];
      }
      else {
        payloadData.calendar.weekdays = payloadData.calendar?.Availability?.map((w) => ({
          day: w.allow_day_and_time.day.toLowerCase(),
          start: w.allow_day_and_time.start,
          end: w.allow_day_and_time.end,
        }));
      }
      const { response, data } = yield call(createServiceRequest, {
        payload: { ...payloadData, calendar: { ...payloadData.calendar, process_type: 'instant' } },
      });
      yield processAPIResponse(
        {response, data},
        {
          *onSuccess(data) {
            const cb = payload?.cb;
            cb && cb(payloadData?.service_type)
            yield put({
              type: 'getBranch',
              payload:{
                branchId: data?.data?.branch?.id
              }
            });
            yield put({
              type: 'getUserBranches',
            });
          },
        },
      );
    },
    * deleteService({payload: {serviceId, cb, intl}}, {call, put}) {
      const {response, data} = yield call(deleteServiceRequest, {params: {serviceId}});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess() {
            yield put({
              type: 'getUserBranches',
            });
            intl &&
            message.success(intl.formatMessage({id: 'pages.locations.removeServiceSuccess', defaultMessage: 'Service removed successfully'}));
            cb && cb(data);
          },
          * onError(error) {
            message.error(
              error?.message || intl.formatMessage({id: 'pages.locations.removeServiceFailed', defaultMessage: 'Failed to remove service'}),
            );
          },
        },
      );
    },
    *updateService({ payload: { id, ...body } }, { call, put, select }) {
      const cb = body.cb;
      const fetchBranch= body.fetchBranch
      const intl = body.intl;
      const payloadData = body.data;
      if(isEmpty(payloadData.agents)){
        payloadData.agents = []
      }
      const { response, data } = yield call(updateServiceRequest, {
        params: { serviceId: id },
        payload: { ...payloadData },
      });
      yield put({
        type: 'setChangeLoadings',
        payload: getChangeLoadings(body, false),
      });

      trackOnlineEditForm(body, LOCATION_EDIT_ACTION);

      yield processAPIResponse(
        {response, data},
        {
          *onSuccess(data) {
            cb && cb(data, true);
            if (fetchBranch)
            yield put({
              type: 'fetch',
            });
            if (body.Availability) {
              yield put({
                type: 'updateDrawerLocation',
                payload: {
                  location: data?.data,
                },
              });
            }
          },
          *onError(error) {
            message.error(
              error?.message || intl.formatMessage({ id: 'pages.locations.updateServiceFailed', defaultMessage: 'Failed to update service' }),
            );
            cb && cb(error,false);
          },
        },
      );
    },

  },
  reducers: {
    fetchedUserBranches(state, action) {
      return {
        ...state,
        userBranches: action.payload,
      };
    },
    saveBranchServicesList(state, action) {
      return {
        ...state,
        list: action.payload.data,
      };
    },
    saveServicesOfBranch(state, action) {
      return {
        ...state,
        ServicesOfBranch: action.payload.data,
      };
    },
    setAgentList(state, action) {
      return {
        ...state,
        agentList: action.payload,
      };
    },
    setBranchStaffList(state, action) {
      return {
        ...state,
        branchStaffList: action.payload,
      };
    },
    setService(state, action) {
      return {
        ...state,
        selectedService: action.payload,
      };
    },
    setBranch(state, action) {
      return {
        ...state,
        selectedBranch: action.payload,
      };
    },
    setNewService(state, action) {
      return {
        ...state,
        newService: action.payload,
      };
    },
    setTimeZones(state, action) {
      return {
        ...state,
        timezones: action.payload,
      };
    },
    setChangeLoadings(state, action) {
      return setChangeLoadingsUtility(state, action);
    },
    setBranchToDrawer(state, action) {
      const payload = action?.payload;
      const branch = payload?.branch;
      const drawer = state.branchDrawer;

      if (branch) {
        drawer.branchId = branch.id;
        drawer.branch = branch;
        trackModalView('location-details');
      }
      return {
        ...state,
        branchDrawer: drawer,
      };
    },
    toggleBranchDrawer(state, action) {
      const payload = action?.payload;
      const branch = payload?.branch;
      const drawer = state.branchDrawer;

      if (branch) {
        drawer.branchId = branch.id;
        drawer.branch = branch;
        drawer.visible = true;
        trackModalView('location-details');
      } else {
        drawer.visible = false;
      }
      return {
        ...state,
        branchDrawer: drawer,
      };
    },
    reset(){
      return initialState
    }
  },
};
