import * as React from "react"

import {LeadModel} from "@/typings/models/Lead";
import {useDispatch, useModel} from "umi";
import BranchDropdownFilters from "@/components/Filters/BranchDropdownFilters";
import styles from "@/components/Lead/index.less";
import {hasAccess} from "@/utils/access";
import PERMISSIONS from "@/constants/permissions";


const getLeadBranchId = (lead: LeadModel) => lead?.branch?.id

const LeadBranch = ({lead, isTable, readonly}: {lead: LeadModel, isTable?: boolean, readonly?: boolean}) => {
  const {initialState, setInitialState} = useModel('@@initialState');
  const currentUser = initialState.currentUser
  const dispatch = useDispatch()
  const leadBranchId = getLeadBranchId(lead)
  const hasEditAccess = hasAccess(currentUser, [PERMISSIONS.Leads.CHANGE_BRANCH, PERMISSIONS.BUSINESS_PARTNER])
  const onChangeBranch = (branchId, branch) => {
    dispatch({
      type: 'leads/edit',
      payload: {
        branch_id: branchId,
        leadId: lead.id,
        leadOverride: {
          branch
        }
      }
    })
  }

  if (!hasEditAccess || readonly) return lead?.branch?.name || ' - '

  return <BranchDropdownFilters className={styles.branchContainer} selectProps={{bordered: false, allowClear: false}} filterMode={"select"} onChange={onChangeBranch} value={leadBranchId}/>
}
const propsAreEqual = (prevProps, nextProps) => {
  const prevBranch = getLeadBranchId(prevProps.lead)
  const newBranch = getLeadBranchId(nextProps.lead)
  return prevBranch === newBranch
}

export default React.memo(LeadBranch, propsAreEqual)
