import React, { useEffect, useState } from 'react';
import { useDispatch, useIntl, useSelector } from 'umi';
import isEmpty from 'lodash/isEmpty';
import { Button, Form, Popconfirm, Row, Space, Tabs, Tooltip, Typography } from 'antd';
import ProForm from '@ant-design/pro-form';
import { DeleteOutlined } from '@ant-design/icons';

import getLocationTypes from '@/constants/locationTypes';
import LocationAppointmentForm from './LocationAppointmentForm';
import LocationMetaForm from './LocationMetaForm';
import LocationPeriodsForm from './LocationPeriodsForm';
import DrawerSection from '@/components/Drawer/DrawerSection';
import { ServiceModel } from '@/typings/models/Location';
import styles from './index.less';
import TabPane from 'antd/lib/tabs/TabPane';
import OpenHours from './OpenHours';
import { mergeWith } from 'lodash';

const ServiceForm: React.FC = ({ services, branch, selectedNewServiceType,agents }) => {
  const [newServiceFormData, setNewServiceFormData] = useState<ServiceModel>({
    service_type: '',
  });
  const [activeServiceType, setActiveServiceType] = useState(selectedNewServiceType);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const intl = useIntl();

  const { selectedService, selectedBranch, visible,newService } = useSelector(
    ({
      location: { changeLoadings, selectedService, selectedBranch, branchDrawer,newService },
      loading,
    }: DefaultRootState) => ({
      createLocationLoading: loading.effects['location/createService'],
      availabilityLoading: changeLoadings.Availability,
      selectedService,
      selectedBranch,
      settingServiceLoading: loading.effects['location/setService'],
      visible: branchDrawer.visible,
      newService
    }),
  );

  const resetStoreSelectedService = () => {
    dispatch({
      type: 'location/setService',
      payload: {},
    });
  };
  const resetStateAndFormFields = () => {
    form.resetFields();
    setActiveServiceType('');
    resetStoreSelectedService();
  };
  useEffect(() => {
    resetStateAndFormFields();
    if (isEmpty(selectedBranch)) {
      return;
    }
    const serviceTypeValue =  newService?.service_type || selectedBranch?.services?.[0]?.service_type
    form.setFieldsValue({ service_type: serviceTypeValue });
    setActiveServiceType(serviceTypeValue);

    dispatch({
      type: 'location/setService',
      payload: selectedBranch?.services?.find(
        (service) => service.service_type === serviceTypeValue,
      ),
    });
  }, [JSON.stringify(selectedBranch)]);

  useEffect(() => {
    if (!visible) {
      resetStateAndFormFields();
    }
  }, [visible]);

  useEffect(() => {
    if (selectedNewServiceType) {
      resetStateAndFormFields();
      const serviceTypeValue = selectedNewServiceType;
      form.setFieldsValue({ service_type: serviceTypeValue });
      resetStoreSelectedService();
    }
  }, [selectedNewServiceType]);

  useEffect(() => {
    if (activeServiceType)
      dispatch({
        type: 'location/setService',
        payload: selectedBranch?.services?.filter(
          (service) => service.service_type === activeServiceType,
        )[0],
      });
  }, [activeServiceType]);

  useEffect(() => {
    setNewServiceFormData({
      ...newServiceFormData,
      branch_id: branch?.id,
    });
  }, [branch]);

  function isNewService() {
    return selectedService?.id === undefined;
  }
  const customizer = (objValue, srcValue) => {
    if (isEmpty(srcValue)) {
      return objValue;
    }
    return srcValue;
  };

  function handleFormChange(serviceData) {
    const mergedData = mergeWith({}, newServiceFormData, serviceData, customizer);

    setNewServiceFormData(mergedData);

    dispatch({
      type: 'location/setNewService',
      payload: mergedData,
    });
  }

  function onCreateService() {
    dispatch({
      type: 'location/createService',
      payload: {
        cb: (serviceType: string) => {
          setActiveServiceType(serviceType);
        },
        data: {
          ...newService,

          service_type: isEmpty(newService.service_type)
            ? selectedNewServiceType
            : newService.service_type,
        },
      },
    });
  }
  function onDiscard() {
    dispatch({
      type: 'location/setNewService',
      payload: {},
    });
  }

  const isServiceCreatedForBranch = (serviceType: string) => {
    return services?.some((service) => service.service_type === serviceType);
  };
  const serviceTypes = getLocationTypes()
    .filter(
      (type) =>
        isServiceCreatedForBranch(type.value) ||
        type.value === selectedNewServiceType ||
        newService?.service_type === type.value,
    )
    .map((option) => ({
      ...option,
      label: (
        <span>
          {option.label}{' '}
          {isServiceCreatedForBranch(option.value) && (
            <Popconfirm
              onConfirm={() =>
                new Promise((resolve) => deleteService(selectedService?.id, resolve))
              }
              placement={'topRight'}
              title={intl.formatMessage({
                id: 'pages.locations.deleteServiceMessage',
                defaultMessage: 'Are you sure to remove this service?',
              })}
            >
              <Tooltip
                title={intl.formatMessage({
                  id: 'pages.locations.deleteService',
                  defaultMessage: 'Delete Service',
                })}
              >
                <Button style={{ marginLeft: 12 }} type="link" icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          )}
        </span>
      ),
    }));
  const deleteService = (id: string, resolve) => {
    dispatch({
      type: 'location/deleteService',
      payload: {
        serviceId: id,
        cb: () => {
          resolve();
          form.resetFields();
          resetStateAndFormFields()
          dispatch({
            type: 'location/getBranch',
            payload: {
              branchId: branch?.id,
            },
          });
        },
        intl,
      },
    });
  };
  const handleTabChange = (value) => {
    setNewServiceFormData({ ...newServiceFormData, service_type: value });
    if (isServiceCreatedForBranch(value)) {
      dispatch({
        type: 'location/setService',
        payload: selectedBranch?.services?.filter((service) => service.service_type === value)[0],
      });
    } else {
      dispatch({
        type: 'location/setService',
        payload: {},
      });
    }
  };
  const updateService = (serviceData) => {
    if (isNewService()) return;
    dispatch({
      type: 'location/updateService',
      payload: {
        id: selectedService?.id,
        intl: intl,
        cb: () => {},
        data: serviceData,
      },
    });
  };

  return (
    <div className={styles.drawerContainer}>
      <ProForm form={form} submitter={false} onValuesChange={handleFormChange}>
        <Form.Item
          name="service_type"
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            background: 'white',
            paddingTop: 10,
          }}
        >
          <Tabs
            defaultActiveKey={selectedNewServiceType || activeServiceType}
            onChange={handleTabChange}
            style={{
              marginBottom: 20,
            }}
          >
            {serviceTypes.map((type) => (
              <TabPane tab={type.label} key={type.value} />
            ))}
          </Tabs>
        </Form.Item>
        {(!isEmpty(selectedBranch?.services) || !isEmpty(newService)) && (
          <>
            <DrawerSection
              title={intl.formatMessage({
                id: 'pages.locations.availability',
                defaultMessage: 'Open Hours',
              })}
            >
              <OpenHours
                onChange={isNewService() ? handleFormChange : updateService}
                service={selectedService}
                isNew={isNewService()}
              />
            </DrawerSection>

            <DrawerSection
              title={intl.formatMessage({
                id: 'pages.locations.appointments',
                defaultMessage: 'Appointments',
              })}
            >
              <LocationAppointmentForm
                newServiceFormData={newServiceFormData}
                onChange={isNewService() ? handleFormChange : undefined}
                service={selectedService}
                isNew={isNewService()}
              />
            </DrawerSection>
            {!isNewService() && (
              <>
                <DrawerSection
                  title={intl.formatMessage({
                    id: 'pages.locations.blockHours',
                    defaultMessage: 'Block Hours',
                  })}
                >
                  <LocationPeriodsForm
                    mode={'block'}
                    service={selectedService}
                    onChange={updateService}
                  />
                </DrawerSection>
                <DrawerSection
                  title={intl.formatMessage({
                    id: 'pages.locations.additionalOpenHours',
                    defaultMessage: 'Additional Open Hours',
                  })}
                >
                  <LocationPeriodsForm
                    mode={'open'}
                    service={selectedService}
                    onChange={updateService}
                  />
                </DrawerSection>
              </>
            )}

            {selectedBranch?.lead_source?.id && (
              <LocationMetaForm
                isNew={isNewService()}
                selectedService={selectedService}
                branch={selectedBranch}
                onChange={isNewService() ? handleFormChange : undefined}
              />
            )}
            {!isNewService() && !!selectedService?.migration_source && !!selectedService?.migration_id &&(
              <DrawerSection
                title={intl.formatMessage({
                  id: 'pages.locations.serviceMeta',
                  defaultMessage: 'Connected Booking Service',
                })}
              >
                  <Space
                    style={{
                      width: '100%',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginTop: 8,
                    }}
                  >
                    <Typography.Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                      {selectedService?.migration_source}-ID:
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: 14 }} copyable>
                      {selectedService?.migration_id}
                    </Typography.Text>
                  </Space>
              </DrawerSection>
            )}
            <Row>
              {isNewService() && (
                <Form.Item>
                  <Button type="primary" onClick={onCreateService} htmlType="submit">
                    {intl.formatMessage({
                      id: 'pages.locations.createService',
                      defaultMessage: 'Create Service',
                    })}
                  </Button>
                </Form.Item>
              )}
              {isNewService() && newService?.service_type === activeServiceType && (
                <Form.Item>
                  <Button
                    style={{ marginLeft: 8 }}
                    type="default"
                    onClick={onDiscard}
                    htmlType="submit"
                  >
                    {intl.formatMessage({
                      id: 'pages.locations.discardNewService',
                      defaultMessage: 'Discard',
                    })}
                  </Button>
                </Form.Item>
              )}
            </Row>
          </>
        )}
      </ProForm>
    </div>
  );
};

export default ServiceForm;
