import {InboxOutlined, InfoCircleOutlined, UserSwitchOutlined} from "@ant-design/icons";
import {Button, Popconfirm, Popover, Space, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {F} from "@/utils/intl";
import {useDispatch, useModel, useSelector} from "umi";
import * as LeadSelectors from "@/selectors/leadSelectors"
import {editLeadsRequest} from "@/services/api/lead";
import LeadAssigneeDropdownFilters from "@/components/Filters/LeadAssigneeDropdownFilters";
import {CheckAccess, hasAccess} from "@/utils/access";
import isEqual from "lodash/isEqual";
import PERMISSIONS from "@/constants/permissions";
import OpenTaskCountBadge from "@/components/Lead/LeadTasks/OpenTaskCountBadge";

type ILeadContextMenuProps = {
  leadId: string,
  className?: string,
  actionsFilter?: string[]
  archiveContent?: any
  assignContent?: any,
  disableAssignAction?: boolean
  hideTasksBadge?: boolean
}
const LeadContextMenu = ({
                           leadId,
                           setDraggable,
                           actionsFilter = [],
                           assignContent,
                           disableAssignAction = false,
                           archiveContent,
                           className = '',
                           hideTasksBadge = false
                         }: ILeadContextMenuProps) => {

  const {initialState, setInitialState} = useModel('@@initialState');

  const [assignee, setAssignee] = useState<string>()
  const [assigneePopoverOpen, setAssigneePopoverOpen] = useState<boolean>()
  const [archivePopoverOpen, setArchivePopoverOpen] = useState<boolean>()
  const archiveLoading = useSelector((state: DefaultRootState) => LeadSelectors.selectArchiveLoading(state, leadId)) || false
  const lead = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadData(state, leadId))


  useEffect(() => {
    if (!setDraggable) return

    if (archivePopoverOpen || assigneePopoverOpen) return setDraggable(false)
    setDraggable(true)
  }, [archivePopoverOpen, assigneePopoverOpen]);
  const dispatch = useDispatch()
  useEffect(() => {
    if (lead?.assignee)
      setAssignee(lead.assignee?.id)
  }, [])

  const handleAssigneePopoverOpenChange = () => {
    if (!hasAccess(initialState.currentUser, PERMISSIONS.Leads.ASSIGN, {asterisk: true})) return

    setAssigneePopoverOpen(!assigneePopoverOpen)
  }
  const handleAssigneeChange = (newAssignee, assignee) => {
    setAssignee(newAssignee)
    dispatch({
      type: 'leads/edit',
      payload: {
        leadId: lead.id,
        assignee_id: newAssignee,
        leadOverride: {
          assignee
        }
      }
    })
    setAssigneePopoverOpen(false)
  }

  const handleLeadArchive = () => {
    return new Promise((resolve, reject) => {
      editLeadsRequest({
        params: {
          leadId: lead.id
        },
        data: {
          is_archived: true
        }
      }).then(() => {
        resolve()
      })
    })
  }

  const handleLeadDetails = (e) => {
    e.stopPropagation()
    dispatch({
      type: "leads/toggleLeadDetailsDrawer",
      payload: {
        lead
      }
    })
  }
  const actionItems = [
    {
      key: 'assign',
      label: (
        <>
            <Tooltip title={!assignContent && <F id={"pages.lead.action.assign"}/>} placement={"bottom"}>
              <Popover trigger={disableAssignAction ? [] : ['click']} destroyTooltipOnHide overlayClassName={'popover-mini'}
                       open={assigneePopoverOpen}
                       placement={"right"}
                       onOpenChange={handleAssigneePopoverOpenChange}
                       content={<LeadAssigneeDropdownFilters filterMode={"select"}
                                                             autoFocus
                                                             value={assignee}
                                                             showCreate
                                                             closeContainerPopover={() => setAssigneePopoverOpen(false)}
                                                             onChange={handleAssigneeChange}/>}>
                {assignContent ? assignContent :
                  <Button size={"small"} type={"text"} icon={<UserSwitchOutlined size={10}/>}/>}
              </Popover>
            </Tooltip>
        </>
      ),
    },
    {
      key: 'archive',
      label: (
        <CheckAccess access={[PERMISSIONS.Leads.ARCHIVE]}>
          <Popconfirm placement={"right"} open={archivePopoverOpen} onOpenChange={setArchivePopoverOpen}
                      onConfirm={() => handleLeadArchive()}
                      title={<F id={"pages.lead.action.archive.confirmation"}
                                defaultMessage={"Are you sure to archive this lead?"}/>}>
            <Tooltip title={<F id={"pages.lead.action.archive"}/>}>
              {archiveContent ? archiveContent :
                <Button size={"small"} type={"text"} icon={<InboxOutlined/>} loading={archiveLoading}/>}
            </Tooltip>
          </Popconfirm>
        </CheckAccess>
      ),
    },
    {
      key: 'details',
      label: (
        <span onClick={handleLeadDetails}>
          <OpenTaskCountBadge lead={lead} hide={hideTasksBadge}>
            <Button size={"small"} type={"text"} icon={<InfoCircleOutlined/>}/>
          </OpenTaskCountBadge>
        </span>
      ),
    }
  ]

  const shownActionItems = actionsFilter.length === 0 ? actionItems : actionItems.filter(actionItem => actionsFilter.includes(actionItem.key))
  return <Space size={0} className={className}>
    {shownActionItems.map(actionItem => actionItem.label)}
  </Space>
}
const propsAreEqual = (prevProps, nextProps) => {
  const prevActionsFilter = prevProps.actionsFilter
  const newActionsFilter = nextProps.actionsFilter
  const equalActions = isEqual(prevActionsFilter, newActionsFilter)
  const equalLeadIds = prevProps.leadId === nextProps.leadId
  return !(equalActions && equalLeadIds)
}
export default React.memo(LeadContextMenu, propsAreEqual)
