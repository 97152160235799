import React from "react";
import {Checkbox, Collapse, Radio, Typography} from "antd";
import styles from "../index.less";
import classNames from "classnames";
import LeadName from "@/components/Lead/LeadName";
import {AssigneeModel} from "@/typings/models/Assignee";
import {F} from "@/utils/intl";

const {Text} = Typography;
const {Panel} = Collapse;

type ICalendarListProps = {
  agents: AssigneeModel[];
  value: string[],
  multiple: boolean,
  onChange: (assigneeId: string) => void,
  eventMapping: {
    [assigneeId: string]: {
      current: string[],
      total: string[]
    }
  }
}
const CalendarList: React.FC<ICalendarListProps> = ({
                                                      agents = [],
                                                      value = [],
                                                      multiple = true,
                                                      onChange,
                                                      eventMapping = {}
                                                    }: ICalendarListProps) => {

  const handleCheckboxChange = (id: string) => {
    onChange(id);
  };

  return (
    <div className={styles.calendarListContainer}>
      {/* Collapsible Container */}
      <Collapse
        defaultActiveKey={["1"]}
        bordered={false}
        expandIconPosition="right"
      >
        <Panel className={styles.agentsContainer}
               header={<F id={'pages.leads.serviceRequests.calendars'} defaultMessage={'Calendars'}/>} key="1">
          {/* Agent List */}
          {agents.map((agent) => {
            const isSelected = multiple ? !value.includes(agent.id) : value[0] === agent.id
            return (
              <div
                key={agent.id}
                className={classNames(styles.agentCard, {
                  [styles.activeAgent]: isSelected,
                  [styles.multiple]: multiple
                })}
                onClick={() => handleCheckboxChange(agent.id)}
              >
                <div className={styles.content}>
                  {/* Left Section */}
                  <div className={styles.leftSection}>
                    {multiple ? <Checkbox
                      checked={isSelected}
                      disabled={value.length === agents.length - 1 && isSelected}
                    /> : <Radio checked={isSelected}/>}
                    <LeadName nameClassName={styles.agentName} lead={{customer: agent}} readonly hideAvatar/>
                  </div>
                  <span>{eventMapping[agent.id]?.current?.length || 0}</span>
                </div>
              </div>
            )
          })}
        </Panel>
      </Collapse>
    </div>
  );
};

export default CalendarList;
