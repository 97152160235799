import React, {useState} from 'react';
import {useModel} from "umi";
import {SettingOutlined} from "@ant-design/icons";
import {Tabs} from "antd";

import Drawer from "@/components/Drawer"
import LeadToolbarItem from "@/components/Lead/LeadToolbarItem";
import {F} from "@/utils/intl";
import PropertyVisibilitySettings from "@/components/Kanban/VisibilitySettings/PropertyVisibilitySettings";
import {hasAccess} from "@/utils/access";
import LeadStateManager from "@/components/Kanban/StateManager";
import PERMISSIONS from "@/constants/permissions";

export default function LeadViewSettings() {
  const {initialState} = useModel('@@initialState');

  const [isVisible, setIsVisible] = useState(false);
  const [width, setWidth] = useState(400)
  const tabItems = [
    {
      label: <F id={"pages.lead.toolbar.propertyVisibilitySettings"} defaultMessage={'Fields'}/>,
      key: 'fields',
      children: <PropertyVisibilitySettings/>,
      width: 400
    },
    {
      label: <F id={'pages.lead.toolbar.statusManagementSettings'} defaultMessage={'Status'}/>,
      key: 'status',
      children: <LeadStateManager/>,
      access: [PERMISSIONS.LeadStates.CREATE, PERMISSIONS.LeadStates.EDIT, PERMISSIONS.LeadStates.DELETE],
      width: 400
    }
  ].filter(tabItem => tabItem.access ? hasAccess(initialState?.currentUser, tabItem.access, {
    asterisk: true
  }) : true)
  return (
    <div>
      <LeadToolbarItem icon={<SettingOutlined/>}
                       tooltip={<F id={"pages.lead.toolbar.settings"} defaultMessage={'Settings'}/>}
                       onClick={() => setIsVisible(true)}/>

      <Drawer
        title={<F id={'pages.lead.toolbar.settings'} defaultMessage={'Settings'}/>}
        placement="right"
        visible={isVisible}
        onVisibleChange={() => setIsVisible(false)}
        width={width}
      >
        <Tabs
          defaultActiveKey="fields"
          onChange={activeKey => setWidth(tabItems.find(tab => tab.key === activeKey).width)}
          items={tabItems}
        />
      </Drawer>
    </div>
  );
}
