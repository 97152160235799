import React from "react";

import {LeadModel} from "@/typings/models/Lead";

const LeadSource = ({lead, fallback}: {lead: LeadModel, fallback?: React.ReactElement | string}) => {
  return <div style={{minWidth: 60}}>
    {lead?.lead_source?.name || fallback}
  </div>
}

export default LeadSource
