import React from 'react';
import {connect, FormattedMessage as F, SelectLang, setLocale} from 'umi';
import dayjs from "dayjs";

import {DispatchFunction} from "@/typings/models/ReduxState";
import AccountMenuTabs from "@/components/Account/AccountMenuTabs";
import Card from "@/components/Card";
import styles from "./index.less"
import {getLanguageFromUmiLocale} from '@/utils/locale';
import MyPageContainer from "@/components/MyPageContainer";

interface LanguagePageProps {
  dispatch: DispatchFunction
}

const LanguagePage: React.FC<LanguagePageProps> = ({dispatch}: LanguagePageProps) => {
  function onItemClick(locale) {
    if (locale.key) {
      const localeValue = getLanguageFromUmiLocale(locale.key)
      setLocale(locale.key,false);
      dayjs.locale(localeValue)
      dispatch({
        type: 'user/changeSettings',
        payload: {
          locale: localeValue,
          cb: location.reload
        }
      });
    }
  }

  return (
    <MyPageContainer>
      <AccountMenuTabs />
      <Card className={styles.card}>
        <F
          defaultMessage={' Please Select Your Language:'}
          id={'component.account.language.select'}
        />{' '}
        <SelectLang onItemClick={onItemClick} />
      </Card>
    </MyPageContainer>
  );
};

export default connect()(LanguagePage);
