import React, {useRef, useState} from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import Card from "@/components/Card";
import {Button, Input, Popconfirm, Space} from "antd";
import classNames from "classnames";
import {useIntl, useModel} from "umi";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  MenuOutlined,
  PlusOutlined,
  SettingOutlined
} from "@ant-design/icons";

import {LeadStateColorPopover} from "@/components/Kanban/StateManager/LeadStateColor";
import {F} from "@/utils/intl";
import {LeadStateModel} from "@/typings/models/LeadState";

import styles from "./index.less";
import {CheckAccess, hasAccess} from "@/utils/access";
import PERMISSIONS from "@/constants/permissions";

type IEditableStateCardProps = Partial<LeadStateModel> & {
  onEdit?: (id: string, value: string) => void
  onColorSelect?: (id: string, color: string) => void
  dragPlaceholder?: boolean
  isNew?: boolean
  onDelete?: (id: string) => void,
  draggable?: boolean,
  showSettings?: (stateId: string) => void
}
const EditableStateCard = ({
                             id,
                             label,
                             color,
                             onEdit,
                             dragPlaceholder,
                             onColorSelect,
                             draggable = true,
                             isNew,
                             onDelete,
                             showSettings
                           }: IEditableStateCardProps) => {

  const intl = useIntl()

  const {initialState, setInitialState} = useModel('@@initialState');

  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(label);
  const [colorPopoverOpen, setColorPopoverOpen] = useState<boolean>(false);
  const [newStateColor, setNewStateColor] = useState<string>(color);
  const inputRef = useRef(null);

  const hasEditAccess = hasAccess(initialState.currentUser, [PERMISSIONS.LeadStates.EDIT, PERMISSIONS.BUSINESS_PARTNER])

  const {attributes, listeners, setNodeRef, transform, transition, isDragging} =
    useSortable({id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: (!isNew && isDragging) ? 0.5 : 1,
    marginBottom: "8px",
  };

  const handleColorPopoverChange = (newOpen) => {
    if (!hasEditAccess) return
    
    setTimeout(() => {
      setColorPopoverOpen(newOpen)
    }, 100)
  }
  const handleDoubleClick = () => {
    if (!hasEditAccess) return

    if (isEditing) return
    setIsEditing(true);
    if (isNew) setValue("")
  };
  const handleClick = isNew ? handleDoubleClick : () => {
  }

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = (e) => {
    if (isNew) return false

    if (colorPopoverOpen) {
      e.preventDefault()
      inputRef.current?.focus()
      return
    }
    setIsEditing(false);
    onEdit(id, value);
  };

  const handlePressEnter = (e) => {
    if (isNew) {
      onEdit(null, value)
      setIsEditing(false)
      return
    }

    handleBlur(e)
  };

  const getColor = () => isNew ? newStateColor : color
  const handleColorSelect = (id, color) => {
    if (isNew) setNewStateColor(color)
    onColorSelect(id, color)
  }


  const showDragHandle = !isEditing && draggable && !dragPlaceholder && !isNew

  const input = <Input size="small"
                       value={value}
                       onPressEnter={handlePressEnter}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       ref={inputRef}
                       autoFocus/>
  return (
    <div ref={setNodeRef} style={style} className={styles.eachStatus}>
      <Card className={classNames({
        [styles.newStatusCard]: isNew
      })} mini onDoubleClick={handleDoubleClick} onClick={handleClick}>
        <div className={styles.editableStatusInner}>
          <div className={styles.statusLeft}>
            {isNew && !isEditing && <PlusOutlined className={styles.addStatusIcon}/>}
            {showDragHandle && <Button
              {...listeners} // Drag listeners attached only to the handle
              {...attributes}
              type="text"
              size="small"
              icon={<MenuOutlined/>}
              onClick={(e) => e.stopPropagation()} // Prevent click from propagating
            />}
            {(!isNew || isEditing) &&
              <LeadStateColorPopover onOpenChange={handleColorPopoverChange} defaultOpen={isNew}
                                     onSelect={(newColor) => handleColorSelect(id, newColor)}
                                     color={getColor()}/>}
            {isEditing ? (!isNew ? input : <Space.Compact>
                {input}
                <Button onClick={(e) => {
                  e.stopPropagation()
                  setIsEditing(false)
                  onEdit(null, value)
                }} type="primary" icon={<CheckOutlined/>}/>
                <Button onClick={(e) => {
                  e.stopPropagation()
                  setIsEditing(false)
                }} type={"ghost"} icon={<CloseOutlined/>}/>
              </Space.Compact>) :
              <span>{isNew ? intl.formatMessage({
                id: 'pages.lead.status.new',
                defaultMessage: 'Add Status'
              }) : value}</span>}
          </div>
          {!isNew && !isEditing && <CheckAccess access={[PERMISSIONS.LeadStates.EDIT, PERMISSIONS.BUSINESS_PARTNER]}>
            <div>
              <Button type={"link"} icon={<SettingOutlined/>} onClick={() => showSettings(id)}/>
            </div>
          </CheckAccess>}
        </div>
      </Card>
      {draggable && !isNew && !dragPlaceholder &&
        <CheckAccess access={[PERMISSIONS.LeadStates.DELETE, PERMISSIONS.BUSINESS_PARTNER]}>
          <Popconfirm placement={"topRight"} onConfirm={() => onDelete(id)}
                      title={<F id={'pages.lead.status.removeStatusConfirmation'}
                                defaultMessage={"Are you sure to remove this status?"}/>}>
            <Button size={"small"} type={"text"} icon={<DeleteOutlined/>}></Button>
          </Popconfirm>
        </CheckAccess>
      }
    </div>
  );
};

export default EditableStateCard
