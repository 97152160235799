import React, {useState} from 'react';
import {Button, Popconfirm, Switch, Tag,} from 'antd';
import Table from "@/components/Table";
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {FormattedMessage as F, useDispatch, useIntl, useModel, useSelector} from 'umi';
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import {CheckAccess} from "@/utils/access";
import PERMISSIONS from "@/constants/permissions";
import NewTaskAutomation, {REDUX_FETCH_ACTION, REDUX_SET_ACTION} from "@/pages/Automation/NewTaskAutomation";
import {LeadStateTagPresentation} from "@/components/Filters/LeadStateFilters";
import {TaskAutomationModel} from "@/typings/models/TaskAutomationModel";
import * as LeadStateSelectors from "@/selectors/leadStateSelectors";

const TRACKING_LABEL = 'Email Automation';

const TaskAutomationList = () => {
  const intl = useIntl();
  const {initialState, setInitialState} = useModel('@@initialState');

  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentAutomation, setCurrentAutomation] = useState(null);
  const [updatingRecord, setUpdatingRecord] = useState(null);
  const taskAutomationList = useSelector(PreferencesSelectors.selectTaskAutomationList)
  const leadStates = useSelector(LeadStateSelectors.selectStates)
  const loading = useSelector((state: DefaultRootState) => state.loading.effects[REDUX_FETCH_ACTION] || state.loading.effects[REDUX_SET_ACTION])
  const getLeadState = (leadStateId) => {
    return leadStates.find((state) => state.id === leadStateId);
  }

  const handleAddAutomation = () => {
    setCurrentAutomation(null);
    showModal();

  };

  const handleEditAutomation = (automation) => {
    setCurrentAutomation(automation);
    showModal();
  };


  const fetchData = () => {
    dispatch({
      type: REDUX_FETCH_ACTION,
    });
  };

  const deleteTaskAutomation = (id: string, resolve) => {
    const newTaskAutomationList = taskAutomationList.filter((item) => item.id !== id);
    dispatch({
      type: REDUX_SET_ACTION,
      payload: {
        taskAutomationList: newTaskAutomationList,
        then: resolve
      }
    });
  };

  const updateTaskAutomation = (newAutomation: TaskAutomationModel) => {
    const index = taskAutomationList.findIndex((item) => item.id === newAutomation.id)
    const newTaskAutomationList = [...taskAutomationList]
    newTaskAutomationList[index] = newAutomation
    dispatch({
      type: REDUX_SET_ACTION,
      payload: {
        taskAutomationList: newTaskAutomationList,
      },
    });
  }

  const showModal = () => setIsModalVisible(true);

  const handleCancel = () => setIsModalVisible(false);



  const columns = [
    {
      title: intl.formatMessage({id: 'pages.leads.tasks.automation.name', defaultMessage: 'Name'}),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({id: 'pages.leads.tasks.automation.taskTitle', defaultMessage: 'Task Title'}),
      dataIndex: 'task_title',
      key: 'task_title',
    },
    {
      title: intl.formatMessage({id: 'pages.leads.tasks.automation.taskDueInDaysTableTitle', defaultMessage: 'Due in days'}),
      dataIndex: 'task_due_in_days',
      render: text => <Tag>{text}</Tag>
    },
    {
      title: intl.formatMessage({id: 'pages.emailAutomation.status', defaultMessage: 'Status'}),
      dataIndex: 'from_state_id',
      key: 'from_state_id',
      render: (text, record) => (
        <div>
          <LeadStateTagPresentation state={getLeadState(record.from_state_id)}/>
          {record.to_state_id && (
            <>
              <span style={{margin: '0 8px'}}><F id={'pages.emailAutomation.toState'} defaultMessage={'to'}/></span>
              <LeadStateTagPresentation state={getLeadState(record.to_state_id)}/>
            </>
          )}
        </div>
      ),
    },
    {
      title: intl.formatMessage({id: 'pages.emailAutomation.active', defaultMessage: 'Active'}),
      dataIndex: 'is_active',
      key: 'is_active',
      render: (active, record) => (
        <Switch
          checked={record.is_active}
          onChange={() => {
            updateTaskAutomation({...record, is_active: !record.is_active});
          }}
          loading={updatingRecord?.[record.id]?.loading === true}
        />
      ),
    },
    {
      title: intl.formatMessage({id: 'pages.emailAutomation.actions', defaultMessage: 'Actions'}),
      key: 'actions',
      render: (text, item) => (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CheckAccess access={[PERMISSIONS.EmailAutomation.EDIT, PERMISSIONS.BUSINESS_PARTNER]}>
            <Button type="link" onClick={() => handleEditAutomation(item)}>
              <EditOutlined/>
            </Button>
          </CheckAccess>
          <CheckAccess access={[PERMISSIONS.EmailAutomation.DELETE, PERMISSIONS.BUSINESS_PARTNER]}>
            <Popconfirm
              onConfirm={() => new Promise((resolve) => deleteTaskAutomation(item.id, resolve))}
              placement={'topRight'}
              title={
                <F
                  id={'pages.automations.removeConfirm'}
                  defaultMessage={'Are you sure to remove this automation?'}
                />
              }
            >
              <a>
                <DeleteOutlined/>
              </a>
            </Popconfirm>
          </CheckAccess>
        </div>
      ),
    },
  ];
  return (
    <>
      <Table
        search={false}
        columns={columns}
        dataSource={taskAutomationList}
        request={fetchData}
        rowKey="id"
        loading={loading}
        pagination={false}
        total={taskAutomationList.length}
        toolBarRender={() => (
          <CheckAccess access={[PERMISSIONS.EmailAutomation.CREATE, PERMISSIONS.BUSINESS_PARTNER]}>
            <Button type="primary" onClick={handleAddAutomation}>
              <PlusOutlined/>{' '}
              {intl.formatMessage({
                id: 'pages.leads.tasks.newAutomation',
                defaultMessage: 'New Task Automation',
              })}
            </Button>
          </CheckAccess>
        )}
      />
      <NewTaskAutomation
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        automation={currentAutomation}
      />
    </>
  );
};

export default TaskAutomationList;
