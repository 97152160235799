import React, { useEffect, useState } from "react";
import { Switch, TimePicker, Button, Space, Row, Col } from "antd";
import { DeleteOutlined, CopyOutlined } from "@ant-design/icons";
import { useIntl } from "umi";
import { getLocationAllowDateTimes, getLocationBlockPeriods, transformToServerFormat, transformToSlotsFormat } from "@/utils/location";
import { isEmpty } from "lodash";
import moment from 'moment';
import { getDays } from "./utils";

const OpenHours = ({ service, onChange, isNew }) => {
  const intl = useIntl();

  const dayMapping = {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  };

  const initialData = getDays(intl);

  const [data, setData] = useState(initialData);
  useEffect(() => {
    setData(initialData);
    if (isEmpty(service)) return;
    const availability = getLocationAllowDateTimes(service);
    if (isEmpty(availability)) return;
    const transformedData = transformToSlotsFormat(availability,intl);
    setData(transformedData);
  }, [service]);

  const handleSwitch = (index, checked) => {
    const newData = [...data];
    newData[index].enabled = checked;
    if (checked && newData[index].slots.length === 0) {
      newData[index].slots.push({ start: null, end: null });
    }
    setData(newData);
    triggerChange(newData);
  };

  const handleRemoveSlot = (dayIndex, slotIndex) => {
    const newData = [...data];
    newData[dayIndex].slots.splice(slotIndex, 1);
    if (newData[dayIndex].slots.length === 0) {
      newData[dayIndex].enabled = false;
    }
    setData(newData);
    triggerChange(newData);
  };

  const handleCopySlot = (dayIndex, slot) => {
    const newData = [...data];
    newData[dayIndex].slots.push({ ...slot });
    setData(newData);
    triggerChange(newData);
  };

  const handleTimeChange = (dayIndex, slotIndex, field, time) => {
    const newData = [...data];
    newData[dayIndex].slots[slotIndex][field] = time;
    setData(newData);
    triggerChange(newData);
  };

  const triggerChange = (newData) => {
    let updatedAvailability = newData
      .filter((day) => day.enabled)
      .map((day) => {
        const slots = day.slots
          .filter((slot) => slot.start && slot.end)
          .map((slot) => ({
            start: moment.isMoment(slot.start) ? slot.start.format('HH:mm') : slot?.start,
            end: moment.isMoment(slot.end) ? slot?.end?.format('HH:mm') : slot?.end,
          }));

        if (slots.length > 0) {
          return {
            allow_day_and_time: {
              day: dayMapping[day?.day?.toLowerCase()],
              slots: slots,
            },
          };
        }
        return null;
      })
      .filter((day) => day !== null);

    updatedAvailability = transformToServerFormat(updatedAvailability,intl);
    const blockPeriods = getLocationBlockPeriods(service).map((blockPeriod) => ({
      block_period: blockPeriod,
    }));
    const newAvailability = updatedAvailability.concat(blockPeriods);
    const updatedCalendar = isNew
      ? { Availability: [] }
      : { ...service?.calendar, Availability: newAvailability };
    onChange && onChange({ calendar: updatedCalendar });
  };

  return (
    <div style={{ padding: '2px' }}>
      {data.map((day, dayIndex) => (
        <div
          key={day.day}
          style={{ marginBottom: '16px', border: '1px solid #E9E9E9', padding: '16px' }}
        >
          <Row align="middle" justify="space-between">
            <Col>
              <strong>{day.title}</strong>
            </Col>
            <Col>
              <Switch
                checked={day.enabled}
                onChange={(checked) => handleSwitch(dayIndex, checked)}
              />
            </Col>
          </Row>

          {day.enabled && (
            <Row
              align="middle"
              justify="space-between"
              style={{
                backgroundColor: '#F4F4F4',
                padding: '8px',
                borderRadius: '8px',
                marginTop: '8px',
                display: 'flex',
              }}
            >
              {day?.slots?.map((slot, slotIndex) => (
                <div
                  key={slotIndex}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginBottom: 8,
                  }}
                >
                  {/* Left: TimePicker Range */}
                  <div style={{ flex: 1 ,paddingRight:16}}>
                    <TimePicker.RangePicker
                      value={[
                        slot.start ? moment(slot.start, 'HH:mm') : null,
                        slot.end ? moment(slot.end, 'HH:mm') : null,
                      ]}
                      format="HH:mm"
                      style={{ width: '100%' }}
                      onChange={(times) => {
                        const [start, end] = times || [null, null];
                        handleTimeChange(dayIndex, slotIndex, 'start', start);
                        handleTimeChange(dayIndex, slotIndex, 'end', end);
                      }}
                    />
                  </div>

                  {/* Right: Buttons */}
                  <Space>
                    <Button
                      type="text"
                      icon={<CopyOutlined />}
                      onClick={() => handleCopySlot(dayIndex, slot)}
                    />
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveSlot(dayIndex, slotIndex)}
                    />
                  </Space>
                </div>
              ))}
            </Row>
          )}
        </div>
      ))}
    </div>
  );
};

export default OpenHours;