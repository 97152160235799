// LeadCardClickContext.js
import React, {createContext, useContext, useState} from "react";

const LeadCardClickContext = createContext({
  setShouldIgnoreClick: (f: boolean, e?: Event) => {},
  shouldIgnoreClick: false,
});

export const useLeadCardClick = () => useContext(LeadCardClickContext);

export const LeadCardClickProvider: React.FC = ({children}: {children: React.ReactNode}) => {
  const [shouldIgnoreClick, _setShouldIgnoreClick] = useState(false);

  const setShouldIgnoreClick = (f: boolean, e?: Event) => {
    e?.stopPropagation()
    setTimeout(() => {
      _setShouldIgnoreClick(f)
    }, f ? 0 : 100)
  }

  return (
    <LeadCardClickContext.Provider value={{ setShouldIgnoreClick, shouldIgnoreClick }}>
      {children}
    </LeadCardClickContext.Provider>
  );
};
