import React, {useCallback, useRef, useState} from "react";
import {Dropdown, Input, Space, Table} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import debounce from "lodash/debounce";
import {fetchLeads} from "@/services/api/lead";
import {LeadModel} from "@/typings/models/Lead";
import LeadName from "@/components/Lead/LeadName";
import LeadEmail from "@/components/Lead/LeadEmail";
import {LeadStatePresentation} from "@/components/Filters/LeadStateFilters";
import LeadAssigneeOverview from "@/components/Lead/LeadAssigneeOverview";
import LeadBranch from "@/components/Lead/LeadBranch";
import {F} from "@/utils/intl";
import {useDispatch, useIntl} from "umi";
import styles from "./index.less"
import {FormatDate} from "@/components/PrettyDate";

const LeadMegaSearch = () => {
  const dispatch = useDispatch()
  const intl = useIntl();

  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState<LeadModel[]>([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const dropdownRef = useRef(null);

  const columns = [
    {
      title: <F id={'pages.lead.fields.name'} defaultMessage={'Name'}/>,
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (_, lead) => (
        <Space className={styles.nameContainer}>
          <LeadName avatarClassName={styles.leadAvatar} justAvatar readonly lead={lead}/>
          <Space direction={"vertical"} size={0}>
            <LeadName nameClassName={styles.leadName} hideAvatar readonly lead={lead}/>
            <LeadEmail noLink readonly lead={lead}/>
          </Space>
        </Space>
      ),
    },
    {
      title: <F id={'pages.lead.fields.state'} defaultMessage={'State'}/>,
      dataIndex: "status",
      key: "status",
      render: (_, lead) => <LeadStatePresentation className={styles.leadState} state={lead.lead_state}/>,
    },
    {
      title: <F id={'pages.leads.assignee'} defaultMessage={'Assignee'}/>,
      dataIndex: "assignee",
      key: "assignee",
      width: 100,
      render: (_, lead) => <LeadAssigneeOverview readonly lead={lead}/>,
    },
    {
      title: <F id={'pages.lead.fields.branch'} defaultMessage={'Branch'}/>,
      dataIndex: "branch",
      key: "branch",
      render: (_, lead) => <LeadBranch readonly lead={lead}/>,
    },
  ];

  const performSearch = useCallback(
    debounce((search) => {
      if (!search) return setResults([])

      setLoading(true)
      fetchLeads({
        query: {
          search,
          with: 'latest_appointment,latest_service_request',
        }
      }).then((response) => {
        setLoading(false)
        const list = response.data.data
        setResults(list)
        dispatch({
          type: 'leads/saveLeadsData',
          payload: {
            list
          }
        })
      })
    }, 500),
    []
  );


  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setVisible(true);
    performSearch(e.target.value)
  };

  const handleInputBlur = (e) => {
    // Check if the click is inside the dropdown
    if (dropdownRef.current && dropdownRef.current.contains(e.relatedTarget)) {
      return;
    }
    setVisible(false);
  };

  const handleInputFocus = () => {
    if (searchValue.length > 1) setVisible(true)
  }
  const handleDropdownMouseDown = (e) => {
    // Prevent blur event when interacting with the dropdown
    e.preventDefault();
  };
  const handleLeadSelect = (event, lead) => {
    event.stopPropagation()
    setVisible(false)
    dispatch({
      type: 'leads/toggleLeadDetailsDrawer',
      payload: {
        lead,
        activeTab: 'serviceRequests'
      }
    })
  }
  const menu = (
    <div
      ref={dropdownRef}
      className={styles.leadMegaSearchDropdownMenu}
      style={{ maxWidth: "600px" }}
      onMouseDown={handleDropdownMouseDown}
    >
      <Table
        columns={columns}
        dataSource={results}
        loading={loading}
        rowKey={'id'}
        pagination={false}
        expandable={{
          expandedRowRender: (record: LeadModel) => record.latest_appointment && (
            <div className={styles.latestAppointmentInformation} onClick={(e) => handleLeadSelect(e, record)}>
              <Space>
                <span><F id={"pages.lead.latestAppointment"} defaultMessage={"Latest appointment"}/></span>
                <strong><FormatDate date={record.latest_appointment?.utc_start_time}/></strong>
              </Space>
            </div>
          ),
          expandIcon: () => null,
          showExpandColumn: false,
          expandedRowKeys: results.map((lead) => lead.id),
        }}
        onRow={(record) => ({
          onClick: (e) => handleLeadSelect(e,record),
          className: styles.leadMegaSearchResultRow
        })}
        scroll={{ y: 240 }}
      />
    </div>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={[]}
      overlayClassName={styles.leadMegaSearchOverlay}
      open={visible}
      onOpenChange={setVisible}
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'pages.leadsources.search',
          defaultMessage: 'Search'
        })}
        value={searchValue}
        loading={loading}
        onChange={handleSearch}
        prefix={<SearchOutlined/>}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        style={{ width: 300 }}
      />
    </Dropdown>
  );
};

export default LeadMegaSearch;
